import * as userActions from 'store/modules/User/actions.js'

const initialState = {
  users: [],
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case userActions.USER_ADMIN_SEARCH_RESULTS:
      return {
        ...state,
        usersSearchResults: action.payload,
      }

    case userActions.UPDATE_CACHE_SET:
      return {
        ...state,
        users: action.payload,
      }

    default:
      return state
  }
}
