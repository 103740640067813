export const NEW_VERSION_AVAILABLE = 'notification/NEW_VERSION_AVAILABLE'
export const CLOSE_NOTIFICATION_MODAL = 'notification/CLOSE_NOTIFICATION_MODAL'

export function openNewVersionPopup() {
  return { type: NEW_VERSION_AVAILABLE }
}

export function closeNotificationModal() {
  return { type: CLOSE_NOTIFICATION_MODAL }
}
