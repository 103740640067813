import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactModal from 'react-modal'
import { withTranslation } from 'react-i18next'

import AdminTable from 'components/AdminTable'
import Button from 'components/Button'
import CloseButton from 'components/CloseButton'
import ErrorMessage from 'components/ErrorMessage'

import PageEquipmentNew from 'containers/PageEquipmentNew'

import {
  getAllEquipment,
  createEquipment,
  updateEquipment,
  deleteEquipment,
} from 'store/modules/Equipment/actions.js'

import './style.css'

const statusRefDeleteEquipment = 'PageEquipment-delete'

class PageEquipment extends Component {
  constructor() {
    super()

    this.state = {
      hasDoneInitialFetch: false,
    }

    this.buildSearchResultTableRow = this.buildSearchResultTableRow.bind(this)
    this.handleNewEquipmentClick = this.handleNewEquipmentClick.bind(this)
    this.handleCloseNewEquipmentModal = this.handleCloseNewEquipmentModal.bind(this)
    this.handleNewEquipmentSuccess = this.handleNewEquipmentSuccess.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(getAllEquipment({}))
  }

  buildSearchResultTableRow() {
    const { t } = this.props

    const results = this.props.equipment

    if (!results) {
      return []
    }

    return Object.keys(results).map((key) => {
      const item = results[key]

      return {
        columns: [
          { value: item.name },
          { value: <Button onClick={() => this.handleEditClick(item)}>{t('GLOBAL.edit')}</Button> },
          {
            value: (
              <Button onClick={() => this.handleDeleteClick(item.id)}>{t('GLOBAL.delete')}</Button>
            ),
          },
        ],
      }
    })
  }

  getSearchResultsHeaderRow() {
    const { t } = this.props

    return {
      columns: [
        { value: t('PageEquipment.subtitle-1') },
        { value: t('PageEquipment.subtitle-2') },
        { value: t('PageEquipment.subtitle-2') },
      ],
    }
  }

  handleNewEquipmentClick() {
    this.setState({
      newEquipmentActive: true,
    })
  }

  handleCloseNewEquipmentModal() {
    this.setState({
      newEquipmentActive: false,
      editEquipmentActive: null,
    })

    this.props.dispatch(getAllEquipment({}))
  }

  handleNewEquipmentSuccess() {
    setTimeout(() => {
      this.handleCloseNewEquipmentModal()
    }, 500)
  }

  handleEditClick(item) {
    this.setState({
      editEquipmentActive: item,
    })
  }

  handleDeleteClick(id) {
    this.props.dispatch(
      deleteEquipment({
        id,
        statusRef: statusRefDeleteEquipment,
      })
    )
  }

  handleSubmit(values) {
    if (this.state.newEquipmentActive) {
      this.props.dispatch(createEquipment({ ...values }))
    } else {
      this.props.dispatch(updateEquipment({ ...values }))
    }
  }

  render() {
    const { t } = this.props

    return (
      <div className="page-equipment">
        {(this.props.role === 'manager' || this.props.role === 'admin_referensiel') && (
          <div>
            <div>
              <Button onClick={this.handleNewEquipmentClick}>
                + {t('PageEquipment.add-new-equipment')}
              </Button>
            </div>

            <ErrorMessage status={this.props.statuses[statusRefDeleteEquipment]} />

            <AdminTable
              headerRow={this.getSearchResultsHeaderRow()}
              rows={this.buildSearchResultTableRow()}
            />

            {(this.state.newEquipmentActive || this.state.editEquipmentActive) && (
              <ReactModal
                isOpen={true}
                onRequestClose={this.handleCloseNewEquipmentModal}
                shouldCloseOnOverlayClick={true}
                className="modal-content modal-bilans-forms"
                overlayClassName="modal-overlay"
              >
                <CloseButton onClick={this.handleCloseNewEquipmentModal} />
                <PageEquipmentNew
                  formTitle={t('PageEquipment.new-edit')}
                  equipment={this.props.equipment}
                  handleSubmit={this.handleSubmit}
                  initialData={this.state.editEquipmentActive}
                  onFormSubmitSuccess={this.handleNewEquipmentSuccess}
                />
              </ReactModal>
            )}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.Account.user || null,
  role: state.Account.role || null,
  equipment: state.Equipment.equipments,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PageEquipment))
