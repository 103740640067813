import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import ImagePicker from 'components/ImagePicker'
import defaultAvatar from 'assets/default-avatar.png'
import './style.css'

// TODO: handle file paths
const imagePath = process.env.REACT_APP_IMAGE_URL

class TopSection extends Component {
  constructor() {
    super()

    this.state = {}

    this.handleAvatarSave = this.handleAvatarSave.bind(this)
  }

  handleAvatarSave(dataUrl) {
    this.setState({ avatar: dataUrl })
    this.props.handleAvatarChange(dataUrl)
  }

  render() {
    const { t } = this.props

    return (
      <div className="modal-profile-top-section">
        <div className="info-container">
          <div className="image-container">
            <div className="change-photo-container">
              <div id="update-user-avatar">
                <ImagePicker
                  className="avatar-picker"
                  handleSave={this.handleAvatarSave}
                  initialImage={
                    this.props.userData.photo
                      ? imagePath + this.props.userData.photo
                      : defaultAvatar
                  }
                  editText={t('ModalProfile.TopSection.image-picker')}
                  aspectRatio={1}
                />
              </div>
            </div>
          </div>

          <div className="details-container">
            <div className="data-row name">{this.props.userData.full_name}</div>

            <div className="data-row member-since">
              <div className="key">{t('ModalProfile.TopSection.label-1')}</div>

              <div className="value">2017-05-18 13:14:47</div>
            </div>

            <div className="data-row age">
              <div className="key">{t('ModalProfile.TopSection.label-2')}</div>

              <div className="value">48</div>
            </div>

            <div className="data-row dob">
              <div className="key">{t('ModalProfile.TopSection.label-3')}</div>

              <div className="value">{this.props.userData.birthdate}</div>
            </div>

            <div className="data-row member-of">
              <div className="key">{t('ModalProfile.TopSection.label-4')}</div>

              <div className="value">1 Cours Co</div>
            </div>
          </div>
        </div>

        <div className="y-container">
          {/* <svg width="346px" height="308px" viewBox="10 0 390 298" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
<defs>
<linearGradient x1="50%" y1="50%" x2="50%" y2="49.99%" id="linearGradient-y">
    <stop stop-color="#50E3C2" offset="0%"></stop>
    <stop stop-color="#FEFFFF" offset="100%"></stop>
</linearGradient>
</defs>
<g id="y-meter" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g id="iPad" transform="translate(-241.000000, -379.000000)">
      <g id="Y" transform="translate(240.000000, 379.000000)">
          <g id="y-meter">
              <g id="line-and-text-container" transform="translate(0, 136.5)">
                  <path d="M109.5,15.5 L305.515358,15.5" id="Line" stroke="#50E3C2" stroke-linecap="round" stroke-width="3"></path>
              </g>
              <text id="text-container" font-family="Chevin-Light, Chevin" font-size="24" font-weight="300" line-spacing="18" fill="#FFFFFF">
                  <tspan id="meter-text" x="170" y="274">aventurier</tspan>
              </text>
              <g id="Group" transform="translate(117.000000, 7.000000)" fill="url(#linearGradient-y)">
                  <path d="M165.107,1.8 C159.531,3.765 155.244,7.461 150.921,11.181 L150.921,11.181 C144.023,17.121 79.758,101.414 73.199,110.861 L73.199,110.861 C69.001,116.908 66.725,120.416 59.668,127.018 L59.668,127.018 C57.288,129.309 51.85,131.538 50.666,126.456 L50.666,126.456 C49.919,123.243 49.693,120.584 49.806,112.777 L49.806,112.777 C51.045,104.033 50.561,73.17 53.318,62.61 L53.318,62.61 C56.064,51.38 55.646,43.936 50.046,41.578 L50.046,41.578 C43.927,38.998 33.664,46.129 30.982,49.528 L30.982,49.528 C28.301,52.926 27.742,52.863 25.66,58.883 L25.66,58.883 C23.458,65.016 21.424,108.391 19.841,119.603 L19.841,119.603 C19.841,119.603 18.77,146.233 21.696,156.797 L21.696,156.797 C22.665,160.007 24.812,167 28.71,169.442 L28.71,169.442 C29.593,169.989 30.46,170.374 31.327,170.745 L31.327,170.745 C27.219,185.95 18.457,219.923 12.809,241.073 L12.809,241.073 C12.001,244.105 10.051,247.447 8.601,250.32 L8.601,250.32 C5.222,257.002 2.121,263.306 0.894,270.367 L0.894,270.367 C-0.626,279.102 1.615,285.594 8.703,288.406 L8.703,288.406 C17.518,291.904 26.013,287.716 28.697,275.98 L28.697,275.98 C29.671,271.73 30.584,267.953 31.959,263.748 L31.959,263.748 C36.478,247.703 40.405,229.358 44.895,213.435 L44.895,213.435 C57.736,156.426 140.44,68.577 161.349,45.731 L161.349,45.731 C169.353,36.979 177.339,28.211 185.086,19.292 L185.086,19.292 C189.439,14.279 189.564,9.052 186.152,4.714 L186.152,4.714 C183.962,1.93 178.469,0.608 173.162,0.608 L173.162,0.608 C170.223,0.608 167.341,1.013 165.107,1.8" id="Fill-240"></path>
              </g>
              <path d="M231.8851,23.1504 C228.7211,35.6404 217.2601,45.7594 206.2811,45.7544 C195.3021,45.7494 188.9641,35.6204 192.1241,23.1324 C195.2881,10.6454 206.7491,0.5264 217.7281,0.5304 C228.7091,0.5364 235.0471,10.6624 231.8851,23.1504" id="Fill-237" fill="#FBBE0A"></path>
              <path d="M282.107,8.8 C276.531,10.765 272.244,14.461 267.921,18.181 L267.921,18.181 C261.023,24.121 196.758,108.414 190.199,117.861 L190.199,117.861 C186.001,123.908 183.725,127.416 176.668,134.018 L176.668,134.018 C174.288,136.309 168.85,138.538 167.666,133.456 L167.666,133.456 C166.919,130.243 166.693,127.584 166.806,119.777 L166.806,119.777 C168.045,111.033 167.561,80.17 170.318,69.61 L170.318,69.61 C173.064,58.38 172.646,50.936 167.046,48.578 L167.046,48.578 C160.927,45.998 150.664,53.129 147.982,56.528 L147.982,56.528 C145.301,59.926 144.742,59.863 142.66,65.883 L142.66,65.883 C140.458,72.016 138.424,115.391 136.841,126.603 L136.841,126.603 C136.841,126.603 135.77,153.233 138.696,163.797 L138.696,163.797 C139.665,167.007 141.812,174 145.71,176.442 L145.71,176.442 C146.593,176.989 147.46,177.374 148.327,177.745 L148.327,177.745 C144.219,192.95 135.457,226.923 129.809,248.073 L129.809,248.073 C129.001,251.105 127.051,254.447 125.601,257.32 L125.601,257.32 C122.222,264.002 119.121,270.306 117.894,277.367 L117.894,277.367 C116.374,286.102 118.615,292.594 125.703,295.406 L125.703,295.406 C134.518,298.904 143.013,294.716 145.697,282.98 L145.697,282.98 C146.671,278.73 147.584,274.953 148.959,270.748 L148.959,270.748 C153.478,254.703 157.405,236.358 161.895,220.435 L161.895,220.435 C174.736,163.426 257.44,75.577 278.349,52.731 L278.349,52.731 C286.353,43.979 294.339,35.211 302.086,26.292 L302.086,26.292 C306.439,21.279 306.564,16.052 303.152,11.714 L303.152,11.714 C300.962,8.93 295.469,7.608 290.162,7.608 L290.162,7.608 C287.223,7.608 284.341,8.013 282.107,8.8" id="Fill-240" stroke="#979797" stroke-width="2"></path>
          </g>
      </g>
  </g>
</g>
</svg> */}
        </div>
      </div>
    )
  }
}

export default withTranslation()(TopSection)
