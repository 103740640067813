import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import AdminTable from 'components/AdminTable'
import Button from 'components/Button'
import Icon from 'components/Icon'
import ConfirmDeleteMessage from 'components/ConfirmDeleteMessage'
import Select from 'react-select'

import './style.css'

const baseClass = 'ModalCreateAssessment-objectives-selector'

class ObjectivesSelector extends Component {
  constructor(props) {
    super(props)
    this.selectInputRef = React.createRef()
    this.selectInputChildRef = React.createRef()
    this.state = {
      openModalConfirmationDeleteObjective: false,
    }

    this.handleCloseModalConfirmationDeleteObjective = this.handleCloseModalConfirmationDeleteObjective.bind(
      this
    )
    this.handleAddObjectiveIntoAssessments = this.handleAddObjectiveIntoAssessments.bind(this)
    this.handleRemoveObjectiveFromAssessments = this.handleRemoveObjectiveFromAssessments.bind(this)
    this.getProgramObjectivesNameFromId = this.getProgramObjectivesFromId.bind(this)
  }

  getObjectivesHeaderRow() {
    const { t } = this.props

    return {
      columns: [
        {
          value: t(
            'PageAssessments.ModalCreateAssessment.Objectives.ObjectivesSelector.subtitle-name'
          ),
        },
        { value: t('GLOBAL.empty') },
      ],
    }
  }

  getProgramObjectivesFromId(id) {
    return this.props.flatProgramObjectives.find((obj) => obj.id === id)
  }

  getParentName(objective) {
    const parentObjective = this.getProgramObjectivesFromId(objective.parent_id)

    return parentObjective ? parentObjective.name : undefined
  }

  buildObjectivesTableRows() {
    const { programObjectivesSelected } = this.props

    const objectivesId = programObjectivesSelected
      ? programObjectivesSelected.program_objectives
      : []
    return objectivesId.map((objectiveId, index) => {
      const objective = this.getProgramObjectivesFromId(objectiveId)

      if (typeof objective !== 'undefined') {
        return {
          columns: [
            {
              value: objective.parent_id ? (
                <div>
                  {this.getParentName(objective)}
                  <br /> - {objective.name}
                </div>
              ) : (
                objective.name
              ),
            },
            {
              value: (
                <Button
                  className="narrow"
                  onClick={() => this.handleOpenModalConfirmationDeleteObjective(objective, index)}
                >
                  <Icon className="small" src={'trash.svg'} />
                </Button>
              ),
            },
          ],
        }
      } else {
        return {
          columns: [],
        }
      }
    })
  }

  filterSelectedObjectives(objectives) {
    const { programObjectivesSelected } = this.props

    const selected = programObjectivesSelected ? programObjectivesSelected.program_objectives : []

    return objectives
      .filter((programObjective) => {
        let allChildSelected = false

        if (
          Object.prototype.hasOwnProperty.call(programObjective, 'children') &&
          programObjective.children.length > 0
        ) {
          const childNotSelected = programObjective.children.filter((child) => {
            return !selected.some((s) => s === child.id)
          })

          allChildSelected = childNotSelected.length === 0
        }

        return !selected.some((s) => s === programObjective.id) && !allChildSelected
      })
      .map((programObjective) => {
        return { value: programObjective.id, label: programObjective.name }
      })
  }

  async handleAddObjectiveIntoAssessments() {
    const { objective_id_child, objective_id } = this.state
    if (objective_id_child) {
      await this.props.handleAddObjectiveIntoAssessments(objective_id_child)
    } else {
      await this.props.handleAddObjectiveIntoAssessments(objective_id)
    }

    await this.setState({
      objective_id: null,
      objective_id_child: null,
      showChildren: null,
    })
    await this.selectInputRef.current.select.clearValue()
    if (this.selectInputChildRef.current) {
      await this.selectInputChildRef.current.select.clearValue()
    }
  }

  handleRemoveObjectiveFromAssessments(item) {
    this.props.handleRemoveObjectiveFromAssessments(item)
    this.handleCloseModalConfirmationDeleteObjective()
  }

  handleOpenModalConfirmationDeleteObjective(item, index) {
    this.setState({
      openModalConfirmationDeleteObjective: { ...item, index },
    })
  }

  handleCloseModalConfirmationDeleteObjective() {
    this.setState({
      openModalConfirmationDeleteObjective: false,
    })
  }

  handleSelectChange(name, value) {
    const { level } = this.props
    const programObjectives = level.program_objectives

    if (Array.isArray(programObjectives)) {
      const programObjective = programObjectives.find(
        (programObjective) => programObjective.id === value.value
      )

      if (
        typeof programObjective !== 'undefined' &&
        programObjective.children &&
        programObjective.children.length > 0
      ) {
        this.setState({
          showChildren: programObjective.children,
        })
      } else {
        this.setState({ showChildren: null })
      }
    }

    this.setState({
      [name]: value.value,
      objective_id_child: null,
    })
  }

  handleChildChange(name, value) {
    this.setState({
      [name]: value.value,
    })
  }

  render() {
    const { t, level } = this.props
    const { showChildren } = this.state

    return (
      <div className={`${baseClass}`}>
        <Select
          onChange={(value) => value && this.handleSelectChange('objective_id', value)}
          isMulti={false}
          name={'objective_id'}
          options={this.filterSelectedObjectives(level.program_objectives || [])}
          className="search-filter-bar-multi-select search-program"
          classNamePrefix={'react-select'}
          placeholder={t('GLOBAL.search')}
          ref={this.selectInputRef}
        />

        {this.state.showChildren && (
          <Select
            onChange={(value) => value && this.handleChildChange('objective_id_child', value)}
            className="left-indent search-filter-bar-multi-select search-program"
            isMulti={false}
            name="objective_id_child"
            required={true}
            options={this.filterSelectedObjectives(showChildren)}
            ref={this.selectInputChildRef}
          />
        )}

        <Button
          className="button-plus"
          onClick={this.handleAddObjectiveIntoAssessments}
          disabled={!(this.state.objective_id || this.state.objective_id_child)}
        >
          +
        </Button>

        <AdminTable
          headerRow={this.getObjectivesHeaderRow()}
          rows={this.buildObjectivesTableRows()}
        />

        {this.state.openModalConfirmationDeleteObjective && (
          <ConfirmDeleteMessage
            data={this.state.openModalConfirmationDeleteObjective}
            handleCancel={this.handleCloseModalConfirmationDeleteObjective}
            handleConfirm={this.handleRemoveObjectiveFromAssessments}
          />
        )}
      </div>
    )
  }
}

ObjectivesSelector.defaultProps = {
  level: {},
}

export default withTranslation()(ObjectivesSelector)
