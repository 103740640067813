import React, { Component } from 'react'
import ReactDOM from 'react-dom'
// import PropTypes from 'prop-types'; TODO

import './style.css'

class PlacesAutocomplete extends Component {
  constructor() {
    super()

    this.state = {}

    this.init = this.init.bind(this)
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
  }

  componentDidMount() {
    this.init()
  }

  init() {
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      console.log("window google doesn't exist, retrying")

      setTimeout(this.init(), 500)
      return
    }

    var input = ReactDOM.findDOMNode(this)

    var defaultBounds = new window.google.maps.LatLngBounds()

    var options = {
      bounds: defaultBounds,
      componentRestrictions: { country: 'fr' },
      types: [],
    }

    let autocomplete = new window.google.maps.places.Autocomplete(input, options)
    autocomplete.addListener('place_changed', () => this.handlePlaceChanged(autocomplete))

    window.google.maps.event.addDomListener(input, 'keyup', (event) => {
      if (event.keyCode === 13) {
        event.preventDefault()
      }

      if (event.target.value === '') {
        this.props.onChange(null)
      }
    })
  }

  handlePlaceChanged(autocomplete) {
    let place = autocomplete.getPlace()

    place.lat = null
    place.long = null

    if (place.geometry) {
      place.lat = place.geometry.location.lat()
      place.long = place.geometry.location.lng()
    }

    if (place.address_components) {
      place.address_components.map((item) => {
        item.types.map((item2) => {
          if (item2 === 'locality') {
            place.locality = item.long_name
          }
          return null
        })

        return null
      })

      place.address_components.map((item) => {
        item.types.map((item2) => {
          if (item2 === 'country') {
            place.country = item.long_name
            place.country_short = item.short_name
          }
          return null
        })

        return null
      })

      place.address_components.map((item) => {
        item.types.map((item2) => {
          if (item2 === 'postal_code') {
            place.postal_code = item.long_name
          }

          return null
        })

        return null
      })

      place.address_components.map((item) => {
        item.types.map((item2) => {
          if (item2 === 'street_number') {
            place.street_number = item.long_name
          }

          return null
        })

        return null
      })

      place.address_components.map((item) => {
        item.types.map((item2) => {
          if (item2 === 'route') {
            place.route = item.long_name
          }

          return null
        })

        return null
      })

      if (place.street_number && place.route)
        place.address = `${place.street_number} ${place.route}`
      else if (place.route) place.address = `${place.route}`
    }

    this.props.onChange(place)
  }

  render() {
    return (
      <input
        className={`places-autocomplete ${this.props.className || ''}`}
        name={this.props.name}
        placeholder={this.props.placeholder}
        required={this.props.required}
        disabled={this.props.disabled}
      />
    )
  }
}

PlacesAutocomplete.propTypes = {}

PlacesAutocomplete.defaultProps = {}

export default PlacesAutocomplete
