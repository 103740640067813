import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import Button from 'components/Button'
import ImagePicker from 'components/ImagePicker'
import AdminTableDraggable from 'components/AdminTableDraggable'

import Textarea from 'containers/Textarea'
import './style.css'

const imageUrl = process.env.REACT_APP_STORAGE_URL

class StepsContainer extends Component {
  constructor() {
    super()
    this.state = {
      steps: [],
    }

    this.handleAddStepClick = this.handleAddStepClick.bind(this)
    this.handleTextChange = this.handleTextChange.bind(this)
    this.setInitialSteps = this.setInitialSteps.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.handleRowDragEnd = this.handleRowDragEnd.bind(this)
  }

  componentDidMount() {
    this.setInitialSteps()
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.steps) !== JSON.stringify(this.props.steps)) {
      this.setInitialSteps()
    }
  }

  setInitialSteps() {
    this.setState({
      steps: this.props.steps,
    })
  }

  handleAddStepClick() {
    this.setState(
      {
        steps: [
          ...this.state.steps,
          {
            description: {},
            in_gif: '1',
          },
        ],
      },
      () => {
        this.props.handleStepsUpdate(this.state.steps)
      }
    )
  }

  handleRemoveStepClick(index) {
    let { steps } = this.state

    steps.splice(index, 1)

    this.setState(
      {
        steps,
      },
      () => {
        this.props.handleStepsUpdate(this.state.steps)
      }
    )
  }

  handleTextChange(e, values, index) {
    let { name } = e.target
    let steps = [...this.state.steps]

    if (name.includes('.')) {
      let splitName = name.split('.')

      steps[index] = {
        ...steps[index],
        [splitName[0]]: values,
      }
    }

    this.setState(
      {
        steps,
      },
      () => {
        this.props.handleStepsUpdate(this.state.steps)
      }
    )
  }

  handleImageSave(dataUrl, index) {
    let steps = this.state.steps
    steps[index].imageData = dataUrl

    this.setState(
      {
        steps: steps,
      },

      () => {
        this.props.handleStepsUpdate(this.state.steps)
      }
    )
  }

  handleCheckboxChange(event, index) {
    const target = event.target
    let value = target.checked
    value = value === true ? '1' : '0'
    let { steps } = this.state
    steps[index].in_gif = value

    this.setState(
      {
        steps,
      },
      () => {
        this.props.handleStepsUpdate(this.state.steps)
      }
    )
  }

  handleRowDragEnd(data) {
    const { destination, source } = data

    if (!destination) return

    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    let { steps } = this.state
    let temp = steps[source.index]
    steps.splice(source.index, 1)
    steps.splice(destination.index, 0, temp)

    steps = this.setPositionParams(steps)

    this.setState(
      {
        steps,
        forceRefresh: Date.now(),
      },
      () => {
        this.props.handleStepsUpdate(this.state.steps)
      }
    )
  }

  setPositionParams(steps) {
    if (!steps) return []

    return steps.map((item, index) => {
      return { ...item, position: index + 1 }
    })
  }

  renderStepsTable(steps) {
    if (!steps) return

    const { t } = this.props

    let tableRows = []

    steps.map((item, index) => {
      tableRows.push({
        columns: [
          {
            value: (
              <div
                className="modal-create-exercise-steps-container-row"
                key={`stepsIterator_${index}`}
              >
                <div className="modal-create-exercise-steps-container-text">
                  <Textarea
                    rows="6"
                    placeholder={t('FormExerciseCreate.StepsContainer.placeholder')}
                    cols="50"
                    name="description"
                    value={item.description || {}}
                    onChange={this.handleTextChange}
                    maxLength={250}
                    isTranslatable={true}
                    withCharsCount={true}
                    required={true}
                    index={index}
                  />
                </div>

                <div className="modal-create-exercise-steps-container-image">
                  <ImagePicker
                    handleSave={(dataUrl) => this.handleImageSave(dataUrl, index)}
                    editText={t('FormExerciseCreate.StepsContainer.image-picker')}
                    src={item.photo}
                    initialImage={item.photo ? imageUrl + item.photo : ''}
                    key={`stepsIterator_image_${index}_${this.state.forceRefresh}`}
                  />
                </div>

                <div className="modal-create-exercise-steps-container-checkbox">
                  <input
                    name="enable-disable-step"
                    type="checkbox"
                    onChange={(e) => this.handleCheckboxChange(e, index)}
                    checked={item.in_gif === '1' || item.in_gif === 1}
                  />
                  <label className="modal-create-exercise-steps-container-checkbox-label-right">
                    {t('FormExerciseCreate.StepsContainer.enable-disable')}
                  </label>
                </div>

                <Button onClick={() => this.handleRemoveStepClick(index)}>
                  {t('FormExerciseCreate.StepsContainer.button-remove')}
                </Button>
              </div>
            ),
          },
        ],
      })

      return null
    })

    return (
      <AdminTableDraggable
        // headerRow={headers}
        rows={tableRows}
        handleDragEnd={this.handleRowDragEnd}
      />
    )
  }

  render() {
    const { t } = this.props

    return (
      <div className={`modal-create-exercise-steps-container ${this.props.className}`}>
        {this.renderStepsTable(this.state.steps)}

        <div>
          {this.state.steps.length <= 5 && (
            <Button onClick={this.handleAddStepClick}>
              {t('FormExerciseCreate.StepsContainer.button')}
            </Button>
          )}
        </div>
      </div>
    )
  }
}

StepsContainer.propTypes = {
  steps: PropTypes.array,
}

StepsContainer.defaultProps = {
  steps: [],
}

export default withTranslation()(StepsContainer)
