import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import Button from 'components/Button'
import AdminTable from 'components/AdminTable'
import Pagination from 'components/Pagination'
import SearchFilterBar from 'components/SearchFilterBar'
import ConfirmDeleteMessage from 'components/ConfirmDeleteMessage'

import {
  searchPhysicalTests,
  deletePhysicalTest,
  updatePhysicalTestFilters,
} from 'store/modules/PhysicalTest/actions.js'

import './style.css'
import { getLocale } from 'services/localisation'
import { getListPrescribers } from 'store/modules/Prescriber/actions'
import { FETCH_EXERCISES_RESOURCES_REQUEST } from '../../store/modules/Resource/actions'

const statusRefSearch = 'PageTests-search'
const statusRefDeleteTest = 'PageTest-deleteTest'

class PageTests extends Component {
  constructor() {
    super()
    this.state = {
      openModalConfirmationDelete: false,
      searchData: {},
    }
  }

  componentDidMount() {
    const { searchFilters, isExerciseResourceDataFetched } = this.props
    this.props.dispatch(
      searchPhysicalTests({
        ...searchFilters,
        ...searchFilters.multiSelect,
        search: searchFilters.search_text,
        statusRef: statusRefSearch,
      })
    )
    this.props.dispatch(getListPrescribers({}))
    if (!isExerciseResourceDataFetched()) {
      this.props.dispatch({ type: FETCH_EXERCISES_RESOURCES_REQUEST })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.statuses[statusRefDeleteTest] &&
      (this.props.statuses[statusRefDeleteTest] || {}).state === 'finished'
    ) {
      if (
        !prevProps.statuses[statusRefDeleteTest] ||
        (prevProps.statuses[statusRefDeleteTest] || {}).state !== 'finished'
      ) {
        setTimeout(() => {
          this.handleCloseModalConfirmationDelete()
        }, 500)
      }
    }
  }

  fetchTest = (searchData) => {
    if (!searchData) return

    this.setState(
      {
        searchData,
      },
      () => {
        this.props.dispatch(
          searchPhysicalTests({
            ...searchData,
            ...searchData.multiSelect,
            search: searchData.search_text,
            statusRef: statusRefSearch,
          })
        )
      }
    )
  }

  handleOpenModalConfirmationDelete = (item) => {
    this.setState({
      openModalConfirmationDelete: item,
    })
  }

  handleCloseModalConfirmationDelete = () => {
    this.setState({
      openModalConfirmationDelete: null,
    })
  }

  handleDeleteTest = ({ id }) => {
    if (!id) return

    this.props.dispatch(
      deletePhysicalTest({
        id,
        statusRef: statusRefDeleteTest,
      })
    )
  }

  getPrescribers = (prescribersIds) => {
    const { prescribersList } = this.props
    if (!prescribersList.length) {
      return []
    }
    const prescribers = prescribersIds.map((prescriberId) =>
      prescribersList.find((p) => p.id === prescriberId)
    )

    return prescribers
      .reduce((prev, curr) => {
        if (!prev.find((p) => p?.id === curr.id)) return [...prev, curr]
        return prev
      }, [])
      .map(
        (el, index) =>
          el &&
          (index === 0
            ? el.first_name + ' ' + el.last_name
            : ', ' + el.first_name + ' ' + el.last_name)
      )
  }

  setLevelsResources = (level_ids) => {
    const { levels: levelResources } = this.props.resources
    if (!levelResources.length) {
      return []
    }
    return level_ids.map((level) =>
      levelResources.find((levelResource) => levelResource.id === level.id)
    )
  }

  buildTableRows() {
    const { t, searchResults } = this.props
    const { physical_tests: programs } = searchResults

    if (!programs) {
      return []
    }

    return (programs || []).map((item) => ({
      columns: [
        { value: item.translations[getLocale()].label },
        { value: this.setLevelsResources(item.levels || []).map((level) => `${level.name}, `) },
        { value: this.getPrescribers(item.prescribers_ids) },
        { value: item.assessments_count > 0 ? t('GLOBAL.yes') : t('GLOBAL.no') },
        { value: item.automatic ? t('GLOBAL.yes') : t('GLOBAL.no') },
        { value: item.test_exercises_nb },
        {
          value: (
            <Button
              isDisabled={item.used || item.assessments_count}
              className="narrow"
              onClick={(e) => {
                e.stopPropagation()
                e.nativeEvent.stopImmediatePropagation()
                this.handleOpenModalConfirmationDelete(item)
              }}
            >
              <Icon className="small" src={'trash.svg'} />
            </Button>
          ),
        },
      ],
      onClick: () => this.props.history.push(`/test/${item.id}`),
    }))
  }

  getHeaderRow() {
    const { t } = this.props

    return {
      columns: [
        { value: t('PageTests.subtitle-1') },
        { value: t('PageTests.subtitle-2') },
        { value: t('PageTests.subtitle-3') },
        { value: t('PageTests.subtitle-4') },
        { value: t('PageTests.subtitle-5') },
        { value: t('PageTests.subtitle-6') },
        { value: t('PageTests.subtitle-7') },
      ],
    }
  }

  updateStoredFilters = (filters) => {
    this.props.dispatch(updatePhysicalTestFilters({ ...filters }))
  }

  render() {
    const { t, statuses, searchFilters, searchResults } = this.props
    const { openModalConfirmationDelete, searchData } = this.state

    return (
      <div className="page-tests page">
        <div className="page-tests-title">{t('PageTests.title')}</div>

        <div>
          <Link to="/tests/create">
            <Button onClick={() => {}}>{t('PageTests.button')}</Button>
          </Link>
        </div>

        <SearchFilterBar
          filterTitle={''}
          // filterOptions={this.searchFilteroptions()}
          filterOptions={[]}
          handleSearchFormSubmit={this.fetchTest}
          onfilterChange={this.fetchTest}
          showLoadingIndicator={(statuses[statusRefSearch] || {}).state === 'busy'}
          storedFilters={searchFilters}
          updateStoredFilters={this.updateStoredFilters}
        />

        <AdminTable headerRow={this.getHeaderRow()} rows={this.buildTableRows()} />

        <Pagination
          searchData={searchData}
          limit={searchResults.limit}
          offset={searchResults.offset}
          total={searchResults.total_count}
          fetchData={this.fetchTest}
        />

        {openModalConfirmationDelete && (
          <ConfirmDeleteMessage
            data={openModalConfirmationDelete}
            handleCancel={this.handleCloseModalConfirmationDelete}
            handleConfirm={this.handleDeleteTest}
            statusRef={statuses[statusRefDeleteTest]}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  activeModal: state.Modal.activeModal,
  searchResults: state.PhysicalTest.searchResults,
  searchFilters: state.PhysicalTest.searchFilters,
  role: state.Account.role,
  statuses: state.Status.statuses,
  prescribersList: state.Prescriber.prescribersList,
  resources: state.Resource.exercises,
  isExerciseResourceDataFetched: () =>
    Object.values(state.Resource.exercises).some((c) => c.length > 0),
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PageTests))
)
