import { takeLatest, put, select, takeEvery, cancelled } from 'redux-saga/effects'

import * as ApiService from 'services/api'

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions.js'
import { ProtectedCall } from 'services/protected.api'

import * as exerciseActions from 'store/modules/Exercise/actions.js'

/*************************************************/
/** Selectors **/
const allExercisesSelector = (state) => {
  return state.Exercise.exercises
}

/*************************************************/
/** Sagas **/
export default function* sagaWatcher() {
  yield takeEvery(exerciseActions.CREATE_EXERCISE_REQUEST, createExerciseRequestSaga)
  yield takeEvery(exerciseActions.UPDATE_EXERCISE, updateExerciseSaga)

  yield takeEvery(exerciseActions.EXERCISE_MAKE_PUBLIC_REQUEST, makePublicRequestSaga)
  yield takeEvery(exerciseActions.EXERCISE_DELETE_REQUEST, deleteExerciseRequestSaga)
  yield takeEvery(exerciseActions.EXERCISE_DELETE_PERMANENTLY, deleteExercisePermanentlySaga)
  yield takeLatest(exerciseActions.EXERCISE_SEARCH_SUBMIT, exerciseSearchSaga)
  yield takeEvery(exerciseActions.UPDATE_EXERCISE_CACHE, updateExerciseCacheSaga)
  yield takeEvery(exerciseActions.EXERCISE_FETCH_SUBMIT, exerciseFetchSaga)
}

function* createExerciseRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Creating Exercise' })
    )

    const result = yield ProtectedCall(ApiService.CreateExerciseRequest, data.payload)
    const { exercise } = result.data.data

    yield put({ type: exerciseActions.UPDATE_EXERCISE_CACHE, payload: exercise })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exercise created',
        data: { newId: result.data.data.exercise.id },
      })
    )
  } catch (e) {
    console.log('*** Error caught in createExerciseRequestSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exercise creation error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* updateExerciseSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Updating Exercise' })
    )

    let result = yield ProtectedCall(
      ApiService.UpdateExercise,
      data.payload.exerciseId,
      data.payload
    )
    const { exercise } = result.data.data

    yield put({ type: exerciseActions.UPDATE_EXERCISE_CACHE, payload: exercise })

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Exercise updated' })
    )
  } catch (e) {
    console.log('*** Error caught in updateExerciseSaga ***', e)
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exercise update error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* makePublicRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Making exercise public',
      })
    )

    const result = yield ProtectedCall(ApiService.ExerciseUpdate, data.payload.id, { public: 1 })
    const { exercise } = result.data.data
    yield put({
      type: exerciseActions.UPDATE_EXERCISE_CACHE,
      payload: exercise,
      statusRef: data.payload.statusRef,
    })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Making exercise public complete',
      })
    )
  } catch (e) {
    console.log('*** Error caught in makePublicRequestSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Making exercise public error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* deleteExerciseRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Deleting exercise' })
    )

    yield ProtectedCall(ApiService.ExerciseDelete, data.payload.id)

    yield put({
      type: exerciseActions.UPDATE_EXERCISES_CACHE,
      payload: [data.payload],
      delete: true,
      statusRef: data.payload.statusRef,
    })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Delete exercise complete',
      })
    )
    data.payload.callback.success()
  } catch (e) {
    console.log('*** Error caught in deleteExerciseRequestSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Delete exercise error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* deleteExercisePermanentlySaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting permanently exercise',
      })
    )

    yield ProtectedCall(ApiService.ExerciseDelete, data.payload.exercise_id)

    yield put({
      type: exerciseActions.UPDATE_EXERCISES_CACHE,
      payload: [data.payload],
      delete: true,
      statusRef: data.payload.statusRef,
    })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Delete permanently exercise complete',
      })
    )
    data.payload.callback.success()
  } catch (e) {
    console.log('*** Error caught in deleteExercisePermanentlySaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Delete permanently exercise error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* exerciseSearchSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Searching exercises' })
    )

    let result = yield ProtectedCall(ApiService.ExerciseSearch, data.payload)

    if ((data.payload || {}).pending === 1) {
      yield put({
        type: exerciseActions.SET_EXERCISE_REQUESTS_SEARCH_RESULTS,
        payload: result.data.data,
        statusRef: data.payload.statusRef,
      })
    } else {
      yield put({
        type: exerciseActions.SET_EXERCISES_SEARCH_RESULTS,
        payload: result.data.data,
        statusRef: data.payload.statusRef,
      })
    }

    yield put({
      type: exerciseActions.UPDATE_EXERCISES_CACHE,
      payload: result.data.data.exercises,
      statusRef: data.payload.statusRef,
    })
  } catch (e) {
    console.log('*** Error caught in exerciseSearchSaga ***')
    yield put({ type: exerciseActions.SET_EXERCISES_SEARCH_RESULTS, payload: [] })
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exercise search error',
        state: 'error',
      })
    )
  } finally {
    if (yield cancelled()) {
      console.log('cancelled')
      yield put(
        decrementStatusCreator({
          statusRef: data.payload.statusRef,
          message: 'Exercise search cancelled',
        })
      )
    } else {
      yield put(
        decrementStatusCreator({
          statusRef: data.payload.statusRef,
          message: 'Exercise search complete',
        })
      )
    }
  }
}

function* exerciseFetchSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Fetching exercise' })
    )

    let result = yield ProtectedCall(ApiService.ExerciseFetch, data.payload)

    yield put({
      type: exerciseActions.UPDATE_EXERCISE_CACHE,
      payload: { ...result.data.data.exercise, ...result.data.data.errors },
      statusRef: data.payload.statusRef,
    })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Exercise fetch complete',
      })
    )
  } catch (e) {
    console.log('*** Error caught in exerciseFetchSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload?.statusRef,
        message: 'Exercise fetch error',
        state: 'error',
      })
    )
  }
}

function* updateExerciseCacheSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return
  }

  let exercises = yield select(allExercisesSelector) || []

  if (data.delete) {
    data.payload.map((item) => {
      let id = item
      if (typeof id === 'object') {
        id = id.id
      }

      // id = parseInt(id);
      let excercise = exercises[id]
      let index = exercises.indexOf(excercise)
      if (index > -1) {
        exercises.splice(index, 1)
      }

      return null
    })
    yield put({ type: exerciseActions.UPDATE_EXERCISES_CACHE_SET, payload: exercises })
  } else {
    yield put({ type: exerciseActions.UPDATE_EXERCISE_CACHE_SET, payload: data.payload })
  }
}
