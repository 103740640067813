import store from 'index.js'
import i18next from 'i18next'

const localStorageName = 'getphy_locale'

export function setLocale(locale) {
  localStorage.setItem(localStorageName, locale)
  document.documentElement.lang = locale
  i18next.changeLanguage(locale)
}

export function getLocale() {
  try {
    // start by using the browser locale
    let navLang = navigator.language
    navLang = navLang.split('-')[0]

    //if user has set then use that
    let fromStorage = localStorage.getItem(localStorageName)
    if (fromStorage) {
      navLang = fromStorage
    }

    // if user is logged in and has settings the use that
    let state = {}
    if (store) {
      state = store.getState()
    }

    if (state && state.Account && state.Account.user) {
      const user = state.Account.user
      if (user && user.language && user.language.code) {
        navLang = user.language.code
      }
    }

    // check if the language exist
    if (!['en', 'fr', 'es', 'de', 'it', 'nl', 'pt'].includes(navLang)) {
      navLang = 'fr'
    }

    return navLang
  } catch (e) {
    return 'fr'
  }
}
