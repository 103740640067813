import React, { Component } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import ModalAllList from 'containers/modals/ModalAllList'
import HeaderContainer from 'containers/HeaderContainer'
import Footer from 'components/Footer'

import { APP_INIT } from 'store/modules/Account/actions.js'

import IndexPage from 'containers/PageIndex'

import PagePasswordReset from 'containers/PagePasswordReset'
import PageConfirmEmail from 'containers/PageConfirmEmail'

import PageExercise from 'containers/PageExercise'
import PageExerciseEdit from 'containers/PageExerciseEdit'
import PageExercisesCreate from 'containers/PageExercisesCreate'
import PageExercises from 'containers/PageExercises'

import PageExercisesRequests from 'containers/PageExercisesRequests'
import PageSession from 'containers/PageSession'
import PageSessionEdit from 'containers/PageSessionEdit'
import PageSessions from 'containers/PageSessions'
import PageSessionsCreate from 'containers/PageSessionsCreate'
import PageSessionsRequests from 'containers/PageSessionsRequests'

import PageChallenge from 'containers/PageChallenge'
import PageChallengeEdit from 'containers/PageChallengeEdit'
import PageChallenges from 'containers/PageChallenges'
import PageChallengesCreate from 'containers/PageChallengesCreate'
import PageChallengesRequests from 'containers/PageChallengesRequests'

import PageProgram from 'containers/PageProgram'
import PageProgramEdit from 'containers/PageProgramEdit'
import PagePrograms from 'containers/PagePrograms'
import PageProgramsCreate from 'containers/PageProgramsCreate'
import PageProgramsRequests from 'containers/PageProgramsRequests'

import PageTest from 'containers/PageTest'
import PageTestEdit from 'containers/PageTestEdit'
import PageTests from 'containers/PageTests'
import PageTestsCreate from 'containers/PageTestsCreate'

import PageAssessments from 'containers/PageAssessments'

import PageArticles from 'containers/PageArticles'
import PageBag from 'containers/PageBag'

import PageEquipment from 'containers/PageEquipment'
import PageBodyParts from 'containers/PageBodyParts'

import NotFound404 from 'containers/PageNotFound404'

import './style.css'
import CookieConsentModal from '../CookieConsent'
import { InitGoogle, PageView } from '../../services/google'
import Cookies from 'js-cookie'

class App extends Component {
  constructor() {
    super()

    this.state = {
      routes: {
        routes: [],
        homeRoute: '/sac',
      },
    }

    this.processRoleChange = this.processRoleChange.bind(this)
  }

  componentDidMount() {
    this.props.dispatch({ type: APP_INIT })

    InitGoogle(this.props.location)

    this.processRoleChange()
  }

  componentDidUpdate(prevProps) {
    const roleChange = JSON.stringify(this.props.role) !== JSON.stringify(prevProps.role)
    const pathname = (this.props.location || {}).pathname || null

    if ((prevProps.location || {}).pathname !== (this.props.location || {}).pathname) {
      window.scrollTo(0, 0)
      PageView(pathname)
    }

    if (roleChange) {
      setTimeout(() => {
        this.processRoleChange()
      }, 100)
    }
  }

  processRoleChange() {
    const role = this.props.role

    let commonRoles = ['admin_referensiel', 'manager', 'coach']
    if (commonRoles.includes(role)) {
      this.setState({
        routes: this.loggedInCommonRoutes,
      })
      return
    }

    if (!role) {
      this.setState({
        routes: this.loggedOutRoutes,
      })
      return
    }
  }

  commonRoutes = {
    routes: [
      { path: '/password-reset', component: PagePasswordReset },
      { path: '/confirm-email', component: PageConfirmEmail },
    ],
  }

  loggedOutRoutes = {
    routes: [],
  }

  loggedInCommonRoutes = {
    routes: [
      { path: '/exercise/:exerciseId', component: PageExercise },
      { path: '/exercise/:exerciseId/edit', component: PageExerciseEdit },
      { path: '/exercises', component: PageExercises },
      { path: '/exercises/create', component: PageExercisesCreate },
      { path: '/exercises/requests', component: PageExercisesRequests },

      { path: '/session/:sessionId', component: PageSession },
      { path: '/session/:sessionId/edit', component: PageSessionEdit },
      { path: '/sessions', component: PageSessions },
      { path: '/sessions/create', component: PageSessionsCreate },
      { path: '/sessions/requests', component: PageSessionsRequests },

      { path: '/challenge/:challengeId', component: PageChallenge },
      { path: '/challenge/:challengeId/edit', component: PageChallengeEdit },
      { path: '/challenges', component: PageChallenges },
      { path: '/challenges/create', component: PageChallengesCreate },
      { path: '/challenges/requests', component: PageChallengesRequests },

      { path: '/program/:programId', component: PageProgram },
      { path: '/program/:programId/edit', component: PageProgramEdit },
      { path: '/programs', component: PagePrograms },
      { path: '/programs/create', component: PageProgramsCreate },
      { path: '/programs/requests', component: PageProgramsRequests },

      { path: '/test/:testId', component: PageTest },
      { path: '/test/:testId/edit', component: PageTestEdit },
      { path: '/tests', component: PageTests },
      { path: '/tests/create', component: PageTestsCreate },

      { path: '/assessments', component: PageAssessments },

      { path: '/articles', component: PageArticles },
      { path: '/sac', component: PageBag },
      { path: '/sac/:folderType', component: PageBag },
      { path: '/equipment', component: PageEquipment },
      { path: '/body-parts', component: PageBodyParts },
    ],
    homeRoute: '/sac',
  }

  acceptCookiePolicy() {
    InitGoogle(this.props.location)
  }

  declineCookiePolicy() {
    const splittedHostname = window.location.hostname.split('.')
    const domain = `.${splittedHostname[1]}.${splittedHostname[2]}`
    Cookies.remove('_ga', { path: '/', domain })
    Cookies.remove('_gat', { path: '/', domain })
    Cookies.remove('_gid', { path: '/', domain })
  }

  render() {
    return (
      <div className={`App role-${this.props.role}`}>
        <ModalAllList />

        <HeaderContainer homeRoute={this.state.routes.homeRoute} />

        <Switch>
          {this.commonRoutes.routes.map((item, index) => {
            return (
              <Route
                exact
                path={item.path}
                component={item.component}
                key={`route-common_${index}`}
              />
            )
          })}

          {this.state.routes.routes.map((item, index) => {
            return (
              <Route
                exact
                path={item.path}
                component={item.component}
                key={`user-routes_${index}`}
              />
            )
          })}

          <Route exact path="/" component={IndexPage} />

          <Route component={NotFound404} />
        </Switch>

        <Footer />
        <CookieConsentModal
          onAccept={() => this.acceptCookiePolicy()}
          onDecline={() => this.declineCookiePolicy()}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.Account.user || {},
  isLoggedIn: state.Account.isLoggedIn,
  role: state.Account.role,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
