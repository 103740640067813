import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import './style.css'

class HeaderLink extends Component {
  render() {
    return (
      <li className="header-navlink" key={this.props.to}>
        {this.props.to && <NavLink to={this.props.to}>{this.props.text}</NavLink>}

        {!this.props.to && this.props.type !== 'connectButton' && <a href="#">{this.props.text}</a>}

        {this.props.type === 'connectButton' && (
          <div className="connect-button" onClick={this.props.onClick}>
            {this.props.text}
          </div>
        )}

        {this.props.dropdown && (
          <div className="dropdown">
            {this.props.dropdown.map((item) => {
              return (
                <div className="link-container" key={item.to}>
                  <NavLink to={item.to}>{item.text}</NavLink>
                </div>
              )
            })}
          </div>
        )}
      </li>
    )
  }
}

export default HeaderLink
