import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { withTranslation } from 'react-i18next'

import CloseButton from 'components/CloseButton'
import ExerciseCard from 'components/ExerciseCard'
import ChallengeCard from 'components/ChallengeCard'
import SessionCard from 'components/SessionCard'
import SearchFilterBar from 'components/SearchFilterBar'
import Button from 'components/Button'
import Pagination from 'components/Pagination'

import './style.css'

class CardPicker extends Component {
  constructor() {
    super()

    this.state = {
      items: [],
    }

    this.handleCardClick = this.handleCardClick.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleCardClick(elem) {
    if (!elem) return

    let items = this.props.selectionLimit === 1 ? [] : this.state.items
    let elemDeleted = false

    items.map((item, index) => {
      if (item.id === elem.id) {
        items.splice(index, 1)
        elemDeleted = true
      }

      return null
    })

    if (!elemDeleted) {
      items.push(elem)
    }

    this.setState({
      items,
    })
  }

  handleSubmit() {
    this.props.handleSubmit(
      this.props.selectionLimit === 1 ? this.state.items[0] : this.state.items
    )

    setTimeout(() => {
      this.props.handleClose()
    }, 500)
  }
  setSessionResources = (session) => {
    const { objectives, levels, target_audiences } = this.props.resources

    const { objective_id, level_id, target_audience_id, exercises_count, ...rest } = session

    const objective = objectives.find((obj) => obj.id === objective_id) || {}
    const level = levels.find((level) => level.id === level_id) || {}
    const target_audience =
      target_audiences.find((target) => target.id === target_audience_id) || {}

    return { ...rest, objective, level, target_audience, nb_exercises: exercises_count }
  }

  setExerciseResources = (exercise) => {
    const { objectives, positions, counts } = this.props.resources
    const { objective_id, position_id, count_id, ...rest } = exercise

    const objective = objectives.find((obj) => obj.id === objective_id) || {}
    const position = positions.find((position) => position.id === position_id) || {}
    const count = counts.find((count) => count.id === count_id) || {}

    return { ...rest, objective, position, count }
  }

  render() {
    const {
      t,
      handleClose,
      searchFilteroptions,
      filterSubmit,
      searchFilterType,
      status,
      searchResults,
      type,
      total,
      offset,
      limit,
      searchData,
    } = this.props

    const { items } = this.state

    return (
      <ReactModal
        className={`modal-content modal-card-picker`}
        isOpen={true}
        onRequestClose={handleClose}
        shouldCloseOnOverlayClick={true}
        overlayClassName="modal-overlay"
      >
        <SearchFilterBar
          filterTitle={''}
          filterOptions={searchFilteroptions}
          handleSearchFormSubmit={filterSubmit}
          onfilterChange={filterSubmit}
          filterType={searchFilterType}
          showLoadingIndicator={(status || {}).state === 'busy'}
        />
        <div className={'form modal-form'}>
          <CloseButton onClick={handleClose} />

          {type === 'exercise' && <h1>{t('CardPicker.title-1')}</h1>}

          {type === 'session' && <h1>{t('CardPicker.title-2')}</h1>}

          {type === 'challenge' && <h1>{t('CardPicker.title-3')}</h1>}

          <div className={`actions-buttons`}>
            <Button onClick={this.handleSubmit} disabled={items.length <= 0}>
              {t('GLOBAL.submit')}
            </Button>
          </div>

          {(searchResults || []).map((item, index) => {
            let formattedItem = item
            switch (type) {
              case 'exercise': {
                formattedItem = this.setExerciseResources(item)
                break
              }
              case 'session': {
                formattedItem = this.setSessionResources(item)
                break
              }
              default:
                break
            }
            return (
              <span
                onClick={() => this.handleCardClick(formattedItem)}
                key={`exerciseCard_${index}`}
              >
                {type === 'exercise' && (
                  <ExerciseCard
                    className={`${items.includes(formattedItem) ? 'card-selected' : ''}`}
                    item={formattedItem}
                  />
                )}

                {type === 'session' && (
                  <SessionCard
                    className={`${items.includes(formattedItem) ? 'card-selected' : ''}`}
                    item={formattedItem}
                  />
                )}

                {type === 'challenge' && (
                  <ChallengeCard
                    className={`${items.includes(formattedItem) ? 'card-selected' : ''}`}
                    item={formattedItem}
                  />
                )}
              </span>
            )
          })}

          <Pagination
            searchData={searchData}
            limit={limit}
            offset={offset}
            total={total}
            fetchData={filterSubmit}
          />

          <div className={`actions-buttons`}>
            <Button onClick={handleClose}>{t('GLOBAL.cancel')}</Button>

            <Button type="submit" onClick={this.handleSubmit} disabled={items.length <= 0}>
              {t('GLOBAL.submit')}
            </Button>
          </div>
        </div>
      </ReactModal>
    )
  }
}

export default withTranslation()(CardPicker)
