import React, { Component } from 'react'
import { connect } from 'react-redux'

import FormExerciseCreate from 'components/FormExerciseCreate'

import { clearStatus } from 'store/modules/Status/actions.js'

import { CREATE_EXERCISE_REQUEST } from 'store/modules/Exercise/actions.js'
import { FETCH_EXERCISES_RESOURCES_REQUEST } from 'store/modules/Resource/actions.js'

import './style.css'

const statusRef = 'PageExercisesCreate'

class PageExercisesCreate extends Component {
  constructor() {
    super()
    this.state = {
      form: {
        public: 'false',
        name: {},
        interets: [],
        recommendations: [],
        step: [],
        muscles: [],
        equipment: [],
      },
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const { resources } = this.props
    this.props.dispatch(clearStatus({ statusRef: statusRef }))

    if (!Object.entries(resources).length) {
      this.props.dispatch({ type: FETCH_EXERCISES_RESOURCES_REQUEST })
    }
  }

  componentDidUpdate(prevProps) {
    const { statuses } = this.props
    if (statuses[statusRef]) {
      if (
        statuses[statusRef].state === 'finished' &&
        prevProps.statuses[statusRef].state !== 'finished'
      ) {
        const id = statuses[statusRef].data.newId

        setTimeout(() => {
          this.props.history.push(`/exercise/${id}`)
        }, 700)
      }
    }
  }

  handleSubmit(form) {
    this.props.dispatch({
      type: CREATE_EXERCISE_REQUEST,
      payload: {
        ...form,
        statusRef: statusRef,
      },
    })
  }

  render() {
    const { resources, statuses } = this.props
    return (
      <div className="page-create-exercise">
        <FormExerciseCreate
          resources={resources}
          submitStatus={statuses[statusRef]}
          handleSubmit={this.handleSubmit}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  role: state.Account.role,
  resources: state.Resource.exercises,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PageExercisesCreate)
