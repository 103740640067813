import 'react-app-polyfill/ie11' // For IE 11 support
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

// eslint-disable-next-line no-unused-vars
import i18n from './i18n' // used for translations, don't delete it !!

import { store } from './store'

import './index.css'

import App from 'containers/App'
import { unregister } from './registerServiceWorker'

let el = document.getElementById('root')

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,

  el
)

unregister()

export default store
