import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import Button from 'components/Button'
import './style.css'

class PersonalInfo extends Component {
  render() {
    const { t } = this.props

    return (
      <div className="profile-modal-personal-info form-profile">
        <div className="data-row data-row-half">
          <label>{t('ModalProfile.PersonalInfo.label-1')}</label>
          <input
            autoComplete="off"
            name="age"
            type="text"
            value={this.props.userData.age || ''}
            onChange={this.props.inputChange}
            maxLength={191}
          />
        </div>

        <div className="data-row data-row-half">
          <label>{t('ModalProfile.PersonalInfo.label-2')}</label>
          <input
            name="poids"
            type="text"
            value={this.props.userData.poids || ''}
            onChange={this.props.inputChange}
            maxLength={191}
          />
        </div>

        <div className="data-row data-row-half">
          <label>{t('ModalProfile.PersonalInfo.label-3')}</label>
          <textarea
            rows="3"
            placeholder="Dites nous en plus sur vous !"
            name="informations"
            cols="50"
            value={this.props.userData.informations || ''}
            onChange={this.props.inputChange}
            maxLength={1000}
          >
            {t('ModalProfile.PersonalInfo.textarea-1')}
          </textarea>
          <div>
            <p>
              {t('GLOBAL.chars-left')}:{' '}
              {1000 - parseInt((this.props.userData.informations || '').length, 10)}
            </p>
          </div>
        </div>

        <div className="data-row data-row-half">
          <label>{t('ModalProfile.PersonalInfo.label-4')}</label>
          <textarea
            rows="3"
            placeholder="En sport, dès qu'on s'arrête, on régresse."
            name="citation"
            cols="50"
            value={this.props.userData.citation || ''}
            onChange={this.props.inputChange}
            maxLength={1000}
          >
            {t('ModalProfile.PersonalInfo.textarea-2')}
          </textarea>
          <div>
            <p>
              {t('GLOBAL.chars-left')}:{' '}
              {1000 - parseInt((this.props.userData.citation || '').length, 10)}
            </p>
          </div>
        </div>

        <div className="data-row data-row-half">
          <label>{t('ModalProfile.PersonalInfo.label-5')}</label>
          <textarea
            rows="3"
            placeholder="J'ai longtemps pratiqué le tennis. Je cherche une activité plus douce mais qui maintienne mon endurance."
            name="goals_description"
            cols="50"
            value={this.props.userData.goals_description || ''}
            onChange={this.props.inputChange}
            maxLength={1000}
          >
            {t('ModalProfile.PersonalInfo.textarea-3')}
          </textarea>
          <div>
            <p>
              {t('GLOBAL.chars-left')}:{' '}
              {1000 - parseInt((this.props.userData.goals_description || '').length, 10)}
            </p>
          </div>
        </div>

        <div className="data-row data-row-half">
          <label>{t('ModalProfile.PersonalInfo.label-6')}</label>
          <textarea
            rows="3"
            placeholder="Quelles sont les activités physiques que vous pratiquezÂ ? ou celles que vous avez pratiquéesÂ dans le passéÂ ? (5 maximum)"
            name="physical_activities"
            cols="50"
            value={this.props.userData.physical_activities || ''}
            onChange={this.props.inputChange}
            maxLength={1000}
          >
            {t('ModalProfile.PersonalInfo.textarea-4')}
          </textarea>
          <div>
            <p>
              {t('GLOBAL.chars-left')}:{' '}
              {1000 - parseInt((this.props.userData.physical_activities || '').length, 10)}
            </p>
          </div>
        </div>

        <div className="data-row data-row-half">
          <label>{t('ModalProfile.PersonalInfo.label-7')}</label>
          <textarea
            rows="3"
            placeholder="Si vous en avez"
            name="problems"
            cols="50"
            value={this.props.userData.problems || ''}
            onChange={this.props.inputChange}
            maxLength={1000}
          >
            {t('ModalProfile.PersonalInfo.textarea-5')}
          </textarea>
          <div>
            <p>
              {t('GLOBAL.chars-left')}:{' '}
              {1000 - parseInt((this.props.userData.problems || '').length, 10)}
            </p>
          </div>
        </div>

        <div className="data-row data-row-half">
          <label>{t('ModalProfile.PersonalInfo.label-8')}</label>
          <textarea
            rows="3"
            placeholder="Dites nous en plus sur vous !"
            name="hobbies"
            cols="50"
            value={this.props.userData.hobbies || ''}
            onChange={this.props.inputChange}
            maxLength={1000}
          >
            {t('ModalProfile.PersonalInfo.textarea-6')}
          </textarea>
          <div>
            <p>
              {t('GLOBAL.chars-left')}:{' '}
              {1000 - parseInt((this.props.userData.hobbies || '').length, 10)}
            </p>
          </div>
        </div>
        <div>
          <Button onClick={this.props.cancelClick}>{t('GLOBAL.cancel')}</Button>

          <Button onClick={this.props.submitClick}>{t('GLOBAL.submit')}</Button>
        </div>
      </div>
    )
  }
}

export default withTranslation()(PersonalInfo)
