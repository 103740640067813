import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { closeNotificationModal } from 'store/modules/Notification/actions.js'

import { connect } from 'react-redux'

import CloseButton from 'components/CloseButton'
import './style.css'
import { withTranslation } from 'react-i18next'

ReactModal.setAppElement('#root')

class ModalNotification extends Component {
  constructor() {
    super()

    this.state = {}

    this.handleClose = this.handleClose.bind(this)
  }

  handleClose() {
    this.props.dispatch(closeNotificationModal())
  }

  render() {
    const {
      notification: { open, message },
    } = this.props.notification
    const { t } = this.props

    const messageKey = `ModalNotification.${message}`

    return (
      <div className={`modal-global-notification ${open ? 'notify' : ''}`}>
        <CloseButton onClick={this.handleClose} />
        <p className={'notification-text'}>{t(messageKey)}</p>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  notification: state.Notification,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalNotification))
