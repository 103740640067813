export const BODY_PARTS_GET_ALL = 'body_parts/BODY_PARTS_GET_ALL'
export const CREATE_BODY_PARTS_REQUEST = 'body_parts/CREATE_BODY_PARTS_REQUEST'
export const UPDATE_BODY_PARTS = 'body_parts/UPDATE_BODY_PARTS'
export const BODY_PARTS_DELETE_REQUEST = 'body_parts/BODY_PARTS_DELETE_REQUEST'

export const UPDATE_BODY_PARTS_CACHE = 'body_parts/UPDATE_BODY_PARTS_CACHE'
export const UPDATE_BODY_PARTS_CACHE_SET = 'body_parts/UPDATE_BODY_PARTS_CACHE_SET'

export function getAllBodyParts(payload) {
  return { type: BODY_PARTS_GET_ALL, payload: payload }
}

export function createBodyParts(payload) {
  return { type: CREATE_BODY_PARTS_REQUEST, payload: payload }
}

export function updateBodyParts(payload) {
  return { type: UPDATE_BODY_PARTS, payload: payload }
}

export function deleteBodyParts(payload) {
  return { type: BODY_PARTS_DELETE_REQUEST, payload: payload }
}
