import React, { Component } from 'react'
import './style.css'

class AccordionSection extends Component {
  constructor() {
    super()

    this.handleTitleClick = this.handleTitleClick.bind(this)
  }

  handleTitleClick() {
    this.props.titleClick(this.props.title)
  }

  render() {
    return (
      <div className={`accordion-section`}>
        <div className="title" onClick={this.handleTitleClick}>
          {this.props.title}
        </div>

        <div className="content" style={{ display: `${this.props.isOpen ? 'block' : 'none'}` }}>
          {this.props.content}
        </div>
      </div>
    )
  }
}

export default AccordionSection
