import axios from 'axios'

import { getToken } from 'services/token'
import { getCurrentRole } from 'services/roles'
import { getLocale } from 'services/localisation'

const apiUrl = process.env.REACT_APP_API_URL

export const Login = (email, password) => {
  let url = 'signin'
  return post(url, { email: email, password: password })
}

export const ForgotPasswordRequest = (data) => {
  let url = 'password'
  return get(url, data)
}

export const GetAppVersion = () => {
  return axios.get(`/versionfile?q=${new Date().getTime()}`)
}

export const SubmitNewPassword = (data) => {
  let url = 'password'
  return post(url, data)
}

export const Logout = (data) => {
  let url = 'logout'
  return get(url, data)
}

export const Register = (formData) => {
  let url = 'signup'
  return post(url, formData)
}

export const ConfirmEmail = (data) => {
  let url = 'confirm'
  return get(url, data.payload)
}

export const RefreshToken = () => {
  let url = 'refreshToken'
  return get(url)
}

export const UpdateUserLanguage = (data) => {
  let url = 'language'
  return put(url, data)
}

export const GetLanguages = () => {
  let url = `languages`
  return get(url)
}

export const GetUser = (id) => {
  let url = `users/${id}`
  return get(url)
}

export const GetUserCoach = (id) => {
  let url = `users/${id}/coaches`
  return get(url)
}

export const AdminUserSearch = (data) => {
  let url = `admin/users`
  return get(url, data)
}

export const UpdateProfile = (data) => {
  let url = `users/me`
  return put(url, data)
}

export const setUserAvatar = (userId, data) => {
  let url = `users/${userId}/photo`
  return post(url, data)
}

export const CreateExerciseRequest = (data) => {
  let url = `exercises`
  return post(url, data)
}

export const UpdateExercise = (id, data) => {
  let url = `exercises/${id}`
  return put(url, data)
}

export const ExerciseUpdate = (id, data) => {
  let url = `exercises/${id}`
  return put(url, data)
}

export const ExerciseDelete = (id) => {
  let url = `exercises/${id}`
  return deleteIt(url)
}

export const ExerciseSearch = (data) => {
  let url = `exercises`
  return get(url, data)
}

export const ExerciseFetch = (data) => {
  let url = `exercises/${data}`
  return get(url)
}

export const ExerciseStepSetImage = (exerciseId, stepId, data) => {
  let url = `exercises/${exerciseId}/steps/${stepId}/photo`
  return post(url, data)
}

export const FetchExercisesResources = () => {
  let url = `exercises_resources`
  return get(url)
}

export const FetchSessionsResources = () => {
  let url = `sessions_resources`
  return get(url)
}

export const FetchChallengesResources = () => {
  let url = `challenges_resources`
  return get(url)
}

export const FetchProgramsResources = () => {
  let url = `programs_resources`
  return get(url)
}

export const GetTasksResources = (data) => {
  let url = `tasks`
  return get(url, data)
}

export const GetCountries = () => {
  let url = `countries`
  return get(url)
}

export const SessionSearch = (data) => {
  let url = `sessions`
  return get(url, data)
}

export const CreateSession = (data) => {
  let url = `sessions`
  return post(url, data)
}

export const GetSession = (sessionId) => {
  let url = `sessions/${sessionId}`
  return get(url)
}

export const UpdateSession = (sessionId, data) => {
  let url = `sessions/${sessionId}`
  return put(url, data)
}

export const DuplicateSession = (data) => {
  let url = `duplicate_session/${data.session_id}`
  return post(url, data)
}

export const SessionFetch = (data) => {
  let url = `sessions/${data}`
  return get(url)
}

export const SessionUpdate = (id, data) => {
  let url = `sessions/${id}`
  return put(url, data)
}

export const SessionDelete = (id) => {
  let url = `sessions/${id}`
  return deleteIt(url)
}

//Equipment

export const EquipmentGetAll = () => {
  let url = `equipment`
  return get(url)
}

export const CreateEquipment = (data) => {
  let url = `equipment`
  return post(url, data)
}

export const UpdateEquipment = (equipmentId, data) => {
  let url = `equipment/${equipmentId}`
  return put(url, data)
}

export const EquipmentDelete = (equipmentId) => {
  let url = `equipment/${equipmentId}`
  return deleteIt(url)
}

//Body parts

export const BodyPartsGetAll = () => {
  let url = `body_parts`
  return get(url)
}

export const CreateBodyParts = (data) => {
  let url = `body_parts`
  return post(url, data)
}

export const UpdateBodyParts = (bodyPartId, data) => {
  let url = `body_parts/${bodyPartId}`
  return put(url, data)
}

export const BodyPartsDelete = (bodyPartId) => {
  let url = `body_parts/${bodyPartId}`
  return deleteIt(url)
}

// Challenges
export const ChallengeSearch = (data) => {
  let url = `challenges`
  return get(url, data)
}

export const ChallengeFetch = (data) => {
  let url = `challenges/${data}`
  return get(url)
}

export const CreateChallenge = (data) => {
  let url = `challenges`
  return post(url, data)
}

export const UpdateChallenge = (challengeId, data) => {
  let url = `challenges/${challengeId}`
  return put(url, data)
}

export const ChallengeAddExercise = (challengeId, data) => {
  let url = `challenges/${challengeId}/exercises`
  return post(url, data)
}

export const ChallengeRemoveExercise = (challengeId, exerciseId) => {
  let url = `challenges/${challengeId}/exercises/${exerciseId}`
  return deleteIt(url)
}

export const UpdateChallengeExercise = (challengeId, exerciseId, data) => {
  let url = `challenges/${challengeId}/exercises/${exerciseId}`
  return put(url, data)
}

export const ChallengeUpdate = (id, data) => {
  let url = `challenges/${id}`
  return put(url, data)
}

export const ChallengeDelete = (id) => {
  let url = `challenges/${id}`
  return deleteIt(url)
}

// Programmes
export const CreateProgram = (data) => {
  let url = `programs`
  return post(url, data)
}

export const UpdateProgram = (programId, data) => {
  let url = `programs/${programId}`
  return put(url, data)
}

export const GetProgram = (programId) => {
  let url = `programs/${programId}`
  return get(url)
}

export const ProgramSearch = (data) => {
  let url = `programs`
  return get(url, data)
}

export const ProgramFetch = (data) => {
  let url = `programs/${data}`
  return get(url)
}

export const ProgramUpdate = (id, data) => {
  let url = `programs/${id}`
  return put(url, data)
}

export const ProgramDelete = (id) => {
  let url = `programs/${id}`
  return deleteIt(url)
}

export const ProgramAddCycle = (programId, data) => {
  let url = `programs/${programId}/cycles`
  return post(url, data)
}

export const ProgramUpdateCycle = (programId, cycleId, data) => {
  let url = `programs/${programId}/cycles/${cycleId}`
  return put(url, data)
}

export const ProgramRemoveCycle = (programId, cycleId) => {
  let url = `programs/${programId}/cycles/${cycleId}`
  return deleteIt(url)
}

export const UpdateProgramCycle = (programId, cycleId, data) => {
  let url = `programs/${programId}/cycles/${cycleId}`
  return put(url, data)
}

export const CycleAddSession = (programId, cycleId, data) => {
  let url = `programs/${programId}/cycles/${cycleId}/sessions`
  return post(url, data)
}

export const CycleRemoveSession = (programId, cycleId, sessionId) => {
  let url = `programs/${programId}/cycles/${cycleId}/sessions/${sessionId}`
  return deleteIt(url)
}

export const UpdateCycleSession = (programId, cycleId, sessionId, data) => {
  let url = `programs/${programId}/cycles/${cycleId}/sessions/${sessionId}`
  return put(url, data)
}

export const CycleAddChallenge = (programId, cycleId, data) => {
  let url = `programs/${programId}/cycles/${cycleId}/challenges`
  return post(url, data)
}

export const CycleRemoveChallenge = (programId, cycleId, challengeId) => {
  let url = `programs/${programId}/cycles/${cycleId}/challenges/${challengeId}`
  return deleteIt(url)
}

//Prescriber
export const GetAllPrescribers = (data) => {
  let url = `prescribers`
  return get(url, data)
}

export const GetListPrescribers = (data) => {
  let url = `prescribers/list`
  return get(url, data)
}

//Physcial Tests
export const GetPhysicalTests = (data) => {
  let url = `physical_tests`
  return get(url, data)
}

export const GetPhysicalTest = (data) => {
  let url = `physical_tests/${data}`
  return get(url)
}

export const CreatePhysicalTest = (data) => {
  let url = `physical_tests`
  return post(url, data)
}

export const UpdatePhysicalTests = (testId, data) => {
  let url = `physical_tests/${testId}`
  return put(url, data)
}

export const DeletePhysicalTest = (testId) => {
  let url = `physical_tests/${testId}`
  return deleteIt(url)
}

//Folders
export const GetTopLevelFolders = (data) => {
  let url = `folders`
  return get(url, data)
}

export const GetFolder = (folderId, data) => {
  let url = `folders/${folderId}`
  return get(url, data)
}

export const CreateFolder = (data) => {
  let url = `folders`
  return post(url, data)
}

export const UpdateFolders = (folderId, data) => {
  let url = `folders/${folderId}`
  return put(url, data)
}

export const DeleteFolders = (folderId) => {
  let url = `folders/${folderId}`
  return deleteIt(url)
}

export const AddContentToFolder = (folderId, data) => {
  let url = `folders/${folderId}/content`
  return post(url, data)
}

export const DeleteContentFromFolder = (folderId, contentId) => {
  let url = `folders/${folderId}/content/${contentId}`
  return deleteIt(url)
}

export const AssessmentsGet = (data) => {
  let url = `assessments`
  return get(url, data)
}

export const AssessmentCreate = (data) => {
  let url = `assessments`
  return post(url, data)
}

export const AssessmentEdit = (data, assessment_id) => {
  let url = `assessments/${assessment_id}`
  return put(url, data)
}

export const AssessmentDelete = (assessment_id) => {
  let url = `assessments/${assessment_id}`
  return deleteIt(url)
}

export const EmailsTemplateGet = (data) => {
  let url = `email_templates`
  return get(url, data)
}

export const LevelsGet = (data) => {
  let url = `physical_test/levels`
  return get(url, data)
}

export const ProgramObjectivesGet = () => {
  let url = `program_objectives`
  return get(url)
}

function axiosConfig(params) {
  let config = {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
      'X-localization': getLocale(),
    },
  }

  let token = getToken()
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }

  let role = getCurrentRole()
  if (role) {
    config.headers['x-role'] = role
  }

  if (params) {
    config.params = params
    Object.keys(config.params).map(function (key) {
      if (config.params[key] === 'true' || config.params[key] === true) {
        config.params[key] = 1
      }
      if (config.params[key] === 'false' || config.params[key] === false) {
        config.params[key] = 0
      }

      return null
    })
  }

  return config
}

function post(url, data) {
  return axios.post(apiUrl + url, data, axiosConfig())
}

function put(url, data) {
  return axios.put(apiUrl + url, data, axiosConfig())
}

function deleteIt(url) {
  return axios.delete(apiUrl + url, axiosConfig())
}

function get(url, data) {
  return axios.get(apiUrl + url, axiosConfig(data))
}
