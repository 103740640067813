import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import TestDetails from 'components/TestDetails'
import ActionButtons from 'components/ActionButtons'

import ExerciseDetails from 'components/ExerciseDetails'
import ExerciseSteps from 'components/ExerciseSteps'
import ExerciseAdditionalInfo from 'components/ExerciseAdditionalInfo'

import { TEST_FETCH_SUBMIT } from 'store/modules/PhysicalTest/actions.js'

import './style.css'
import { FETCH_EXERCISES_RESOURCES_REQUEST } from 'store/modules/Resource/actions'
import { getLocale } from 'services/localisation'
import { getListPrescribers } from 'store/modules/Prescriber/actions'

const PageTest = ({
  t,
  match,
  physicalTest,
  history,
  resources = {},
  dispatch,
  isExerciseResourceDataFetched,
  prescribersList,
}) => {
  const [testId, setTestId] = useState(null)

  useEffect(() => {
    const { testId: testIdParam } = match.params
    setTestId(testIdParam)
    dispatch({ type: TEST_FETCH_SUBMIT, payload: testIdParam })
    if (!isExerciseResourceDataFetched()) {
      dispatch({ type: FETCH_EXERCISES_RESOURCES_REQUEST })
    }
    dispatch(getListPrescribers({}))
  }, [])

  const handleEditClick = () => {
    history.push(`/test/${testId}/edit`)
  }

  const getQuestion = (q) => {
    return typeof q === 'string' ? q : null
  }

  const setExerciseResources = (exercise) => {
    const { objectives, positions, counts } = resources
    const { objective_id, position_id, count_id, ...rest } = exercise

    const objective = objectives.find((obj) => obj.id === objective_id) || {}
    const position = positions.find((position) => position.id === position_id) || {}
    const count = counts.find((count) => count.id === count_id) || {}

    return { ...rest, objective, position, count }
  }

  const getPrescribers = (prescribersIds) => {
    if (!prescribersList.length) {
      return []
    }
    return prescribersList.find((p) => p.id === prescribersIds[0])
  }

  const setLevelsResources = (test) => {
    const { levels: levelResources } = resources
    if (!levelResources.length || !test) {
      return {}
    }
    const { levels: level_ids } = test

    const levels = level_ids.map((level) =>
      levelResources.find((levelResource) => levelResource.id === level.id)
    )
    const prescriber = getPrescribers(test.prescribers_ids)
    return { ...test, levels, prescriber }
  }

  return (
    <div className="page-test">
      <div className="page-test-content">
        <TestDetails test={setLevelsResources(physicalTest[testId])} />

        {!(physicalTest[testId] || {}).used && <ActionButtons handleEditClick={handleEditClick} />}

        {!!(physicalTest[testId] || {}).used && <p>{t('PageTest.text')}</p>}

        {(physicalTest[testId] || []).test_exercises?.map(({ exercise, translations }, idx) => (
          <div key={idx} className="page-test-exercises-row">
            <ExerciseDetails
              className={'exercise-details--small'}
              exercise={setExerciseResources(exercise)}
            />

            <ExerciseSteps className={'exercise-steps--small'} exercise={exercise} />
            <ExerciseAdditionalInfo
              className={'exercise-additional-info--small'}
              exercise={exercise}
            />

            <p>{getQuestion(translations[getLocale()].question)}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  theState: state,
  physicalTest: state.PhysicalTest.physicalTests,
  resources: state.Resource.exercises,
  isExerciseResourceDataFetched: () =>
    Object.values(state.Resource.exercises).some((c) => c.length > 0),
  prescribersList: state.Prescriber.prescribersList,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(PageTest)))
