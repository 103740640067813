import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import Button from 'components/Button'
import SessionCard from 'components/SessionCard'
import CardPicker from 'components/CardPicker'
import Icon from 'components/Icon'
import AdminTableDraggable from 'components/AdminTableDraggable'

import { sessionSearchCreator } from 'store/modules/Session/actions.js'
import { FETCH_SESSIONS_RESOURCES_REQUEST } from 'store/modules/Resource/actions.js'
import { getListPrescribers } from 'store/modules/Prescriber/actions.js'
import isEqual from 'lodash/isEqual'

import './style.css'

const statusRefSearch = 'FormProgramCreate-SessionList-search'

class SessionsList extends Component {
  constructor() {
    super()

    this.state = {
      sessions: [],
      searchData: {},
      showCardModal: false,
    }

    this.handleAddSessionClick = this.handleAddSessionClick.bind(this)
    this.handleSearchSessionsClick = this.handleSearchSessionsClick.bind(this)
    this.fetchSessions = this.fetchSessions.bind(this)
    this.handleCloseCardModal = this.handleCloseCardModal.bind(this)
    this.handleSessionRemove = this.handleSessionRemove.bind(this)
    this.searchFilteroptions = this.searchFilteroptions.bind(this)
    this.handleRowDragEnd = this.handleRowDragEnd.bind(this)
  }

  componentDidMount() {
    this.props.dispatch({ type: FETCH_SESSIONS_RESOURCES_REQUEST })
    this.props.dispatch(getListPrescribers({}))

    if (this.props.sessions) {
      this.setState({
        sessions: this.props.sessions,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.sessions, this.props.sessions)) {
      this.setState({
        sessions: this.props.sessions,
      })
    }
  }

  handleAddSessionClick(sessions) {
    let sessionsFormated = []

    sessions.map((session) => {
      sessionsFormated.push({
        session,
        session_id: session.id,
        start_after: 7,
      })
    })

    sessions = [...this.state.sessions, ...sessionsFormated]

    this.props.handleSessionChange(sessions, this.props.cycleIndex)
  }

  handleSearchSessionsClick() {
    this.setState(
      {
        showCardModal: true,
      },
      () => this.props.dispatch(sessionSearchCreator({ public: 1, statusRef: statusRefSearch }))
    )
  }

  handleCloseCardModal() {
    this.setState({
      showCardModal: false,
    })
  }

  fetchSessions(data) {
    if (!data) return

    this.setState(
      {
        searchData: data,
      },
      () => {
        this.props.dispatch(
          sessionSearchCreator({
            ...data,
            ...data.multiSelect,
            search: data.search_text,
            public: true,
            statusRef: statusRefSearch,
          })
        )
      }
    )
  }

  changeStartAfter(e, index) {
    let value = e.target.value
    value = parseInt(value, 10) || 0

    let sessions = this.state.sessions

    sessions[index] = {
      ...sessions[index],
      start_after: value,
    }

    this.props.handleSessionChange(sessions, this.props.cycleIndex)
  }

  handleSessionRemove(index) {
    const { sessions } = this.state
    sessions.splice(index, 1)
    this.setState(
      {
        sessions,
      },
      () => this.props.handleSessionRemove(this.state.sessions, this.props.cycleIndex)
    )
  }

  searchFilteroptions() {
    const { t } = this.props

    const resources = this.props.resources
    const prescribers = this.props.prescribers
    let toReturn = []

    if (resources.levels) {
      toReturn.push({
        label: t('PageSessions.SearchFilters.levels'),
        name: 'levels',
        value: 'levels',
        options: resources.levels.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.objectives) {
      toReturn.push({
        label: t('PageSessions.SearchFilters.objectives'),
        name: 'objectives',
        value: 'objectives',
        options: resources.objectives.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.target_audiences) {
      toReturn.push({
        label: t('PageSessions.SearchFilters.target_audiences'),
        name: 'target_audiences',
        value: 'target_audiences',
        options: resources.target_audiences.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (prescribers) {
      toReturn.push({
        label: t('PageSessions.SearchFilters.prescribers'),
        name: 'prescribers',
        value: 'prescribers',
        options: (prescribers || []).map((item) => {
          return { value: item.id, label: item.full_name }
        }),
      })
    }

    return toReturn
  }

  renderSessionsTable(sessions) {
    if (!sessions) return

    let tableRows = []

    sessions.map((item, index) => {
      tableRows.push({
        columns: [
          {
            value: (
              <div
                className="modal-create-program-cycle-session"
                key={`sessionCardSessions_${index}`}
              >
                <SessionCard className="small" item={item.session} />

                <div className="modal-create-program-cycle-session-delay-container">
                  <label>Delay (jour)</label>

                  <input
                    type="number"
                    value={item.start_after}
                    onChange={(e) => this.changeStartAfter(e, index)}
                  />
                </div>

                <div className="modal-create-program-cycle-session-delete-container">
                  <Button onClick={() => this.handleSessionRemove(index)} className={'narrow'}>
                    <Icon src="trash.svg" className="trash-icon large" />
                  </Button>
                </div>
              </div>
            ),
          },
        ],
      })

      return null
    })

    return (
      <AdminTableDraggable
        // headerRow={headers}
        rows={tableRows}
        handleDragEnd={this.handleRowDragEnd}
      />
    )
  }

  handleRowDragEnd(data) {
    const { destination, source } = data

    if (!destination) return

    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    let { sessions } = this.state
    let temp = sessions[source.index]
    sessions.splice(source.index, 1)
    sessions.splice(destination.index, 0, temp)

    sessions = this.setPositionParams(sessions)

    this.setState(
      {
        sessions,
      },
      () => {
        this.props.handleSessionChange(this.state.sessions, this.props.cycleIndex)
      }
    )
  }

  setPositionParams(sessions) {
    if (!sessions) return []

    return sessions.map((item, index) => {
      return { ...item, position: index + 1 }
    })
  }

  render() {
    const { t } = this.props

    return (
      <div className={`modal-create-program-cycle-sessions-container ${this.props.className}`}>
        <div className="modal-create-program-cycle-sessions-container-title">
          {' '}
          {t('FormProgramCreate.CyclesContainer.SessionsList.title')}{' '}
        </div>

        {this.renderSessionsTable(this.state.sessions)}

        <div>
          <Button onClick={this.handleSearchSessionsClick}>
            {t('FormProgramCreate.CyclesContainer.SessionsList.button')}
          </Button>
        </div>

        {this.state.showCardModal && (
          <CardPicker
            type={'session'}
            handleClose={this.handleCloseCardModal}
            searchResults={this.props.searchResults.sessions}
            limit={this.props.searchResults.limit}
            offset={this.props.searchResults.offset}
            total={this.props.searchResults.total_count}
            searchData={this.state.searchData}
            searchFilteroptions={this.searchFilteroptions()}
            searchFilterType={'multi-select'}
            filterSubmit={this.fetchSessions}
            handleSubmit={this.handleAddSessionClick}
            status={this.props.statuses[statusRefSearch]}
            resources={this.props.resources}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  searchResults: state.Session.searchResults,
  resources: state.Resource.sessions,
  prescribers: state.Prescriber.prescribersList,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SessionsList))
