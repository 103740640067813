import { call, put, delay, select } from 'redux-saga/effects'
import moment from 'moment'
import { getTokenInfo } from './token'

import { UPDATE_TOKEN } from 'store/modules/Auth/actions.js'

/**
 * Wrapped `call` effect
 * @description check token expiration before each `call`
 *  and update it if necessary.
 * @param {*} args
 */
export const ProtectedCall = function* (...args) {
  const { exp } = yield call(getTokenInfo)
  const { isRefreshingToken } = yield select((state) => state.Auth)

  if (exp - moment().format('X') <= 0 && !!exp) {
    if (!isRefreshingToken) {
      yield put({ type: UPDATE_TOKEN })
    }
  }
  if (isRefreshingToken) {
    yield delay(300)
    return yield ProtectedCall(...args)
  }

  return yield call(...args)
}
