import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import SessionCard from 'components/SessionCard'
import Button from 'components/Button'

import { Link } from 'react-router-dom'

import './style.css'

// const baseClass = 'pages-stats-general-stats'

class MySessions extends Component {
  constructor() {
    super()

    this.state = {}
  }

  render() {
    const { t } = this.props

    return (
      <div>
        <div>
          <Link to="/sessions/create">
            <Button onClick={() => {}}>{t('PageBag.MySessions.new-session')}</Button>
          </Link>
        </div>

        <div>
          {Object.keys(this.props.sessions).map((key, index) => {
            const item = this.props.sessions[key]
            return (
              <Link to={`/session/${item.id}`} key={`link-session-${index}`}>
                <SessionCard
                  //deletable={true}
                  //handleDeleteClick={this.props.handleDeleteClick}
                  item={item}
                />
              </Link>
            )
          })}
        </div>
      </div>
    )
  }
}

export default withTranslation()(MySessions)
