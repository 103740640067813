import { takeEvery, takeLatest, put, select, all, cancelled, call } from 'redux-saga/effects'

import * as ApiService from 'services/api'

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions.js'
import { ProtectedCall } from 'services/protected.api'

import { isEqual, differenceBy, difference, differenceWith } from 'lodash'

import * as programActions from 'store/modules/Program/actions.js'
/*************************************************/
/** Selectors **/
const allSelector = (state) => {
  return state.Program.programs
}

/*************************************************/
/** Sagas **/
export default function* sagaWatcher() {
  yield takeEvery(programActions.CREATE_PROGRAM_REQUEST, createProgramRequestSaga)
  yield takeEvery(programActions.UPDATE_PROGRAM, updateProgramSaga)

  yield takeEvery(programActions.PROGRAM_MAKE_PUBLIC_REQUEST, makePublicRequestSaga)
  yield takeEvery(programActions.PROGRAM_DELETE_REQUEST, deleteProgramRequestSaga)
  yield takeLatest(programActions.PROGRAM_SEARCH_SUBMIT, programSearchSaga)
  yield takeEvery(programActions.UPDATE_PROGRAM_CACHE, updateCacheSaga)
  yield takeEvery(programActions.PROGRAM_FETCH_SUBMIT, programFetchSaga)

  yield takeEvery(programActions.PROGRAM_ADD_CYCLE_REQUEST, programAddCycleSaga)
  yield takeEvery(programActions.UPDATE_PROGRAM_CYCLE, updateProgramCycleSaga)
  yield takeEvery(programActions.REMOVE_PROGRAM_CYCLE, removeProgramCycleSaga)
  yield takeEvery(programActions.UPDATE_CYCLE_SESSION, updateCycleSessionSaga)

  yield takeEvery(programActions.CYCLE_ADD_SESSION_REQUEST, cycleAddSessionSaga)
  yield takeEvery(programActions.CYCLE_ADD_CHALLENGE_REQUEST, cycleAddChallengeSaga)

  yield takeEvery(programActions.CYCLE_REMOVE_SESSION_REQUEST, cycleRemoveSessionSaga)
  yield takeEvery(programActions.CYCLE_REMOVE_CHALLENGE_REQUEST, cycleRemoveChallengeSaga)
}

function* createProgramRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Creating program' })
    )

    let result = yield ProtectedCall(ApiService.CreateProgram, data.payload)

    if (data.payload.cycles) {
      yield all(
        data.payload.cycles.map((item) => {
          return put(
            programActions.addCycleToProgramCreator({
              programId: result.data.data.program.id,
              cycle: item,
              statusRef: data.payload.statusRef,
              statusRefData: { programId: result.data.data.program.id },
            })
          )
        })
      )
    }

    yield put({ type: programActions.UPDATE_PROGRAM_CACHE, payload: [result.data.data] })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'program created',
        data: { newId: result.data.data.program.id },
      })
    )
  } catch (e) {
    console.log('*** Error caught in createProgramRequestSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'program creation error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* updateProgramSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Updating program' })
    )

    if (!data.payload.enabled) {
      data.payload.public = false
    }

    if (data.payload.getphy) {
      data.payload.public = false
    }
    if (data.payload.public) {
      data.payload.getphy = false
    }

    let result = yield ProtectedCall(
      ApiService.UpdateProgram,
      data.payload.programId,
      data.payload
    ) || {}

    let programs = yield select(allSelector)
    let oldProgram = programs[data.payload.programId] || {}

    const oldCycles = oldProgram.cycles || []
    const newCycles = data.payload.cycles || []

    if (!isEqual(newCycles, oldCycles)) {
      const cyclesToSet = differenceBy(newCycles, oldCycles, 'id')
      const cyclesToRemove = differenceBy(oldCycles, newCycles, 'id').map((item) => item.id)
      const cyclesToUpdate = difference(differenceWith(newCycles, oldCycles, isEqual), cyclesToSet)

      yield all(
        cyclesToRemove.map((item) => {
          return call(removeProgramCycleSaga, {
            payload: {
              programId: result.data?.data?.program?.id,
              cycleId: item,
              statusRef: data.payload.statusRef,
            },
          })
        })
      )

      yield all(
        cyclesToUpdate.map((item) => {
          return call(updateProgramCycleSaga, {
            payload: {
              programId: result.data?.data?.program?.id,
              oldProgram,
              cycle: item,
              statusRef: data.payload.statusRef,
            },
          })
        })
      )

      yield all(
        cyclesToSet.map((item) => {
          return call(programAddCycleSaga, {
            payload: {
              programId: result.data?.data?.program?.id,
              cycle: item,
              statusRef: data.payload.statusRef,
            },
          })
        })
      )
    }
    const newProgramData = result.data?.data?.program

    newProgramData.cycles = [...newCycles].map((cycle, key) => {
      cycle.position = ++key
      cycle.cycle_sessions = cycle.cycle_sessions?.map((session, key) => {
        session.position = ++key
        return session
      })
      cycle.challenges = cycle.challenges?.map((challenge, key) => {
        challenge.position = ++key
        return challenge
      })
      return cycle
    })

    yield put({ type: programActions.UPDATE_PROGRAM_CACHE, payload: [newProgramData] })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating program finished',
      })
    )
  } catch (e) {
    console.log('*** Error caught in updateProgramSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating program error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* programSearchSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'searching programs' })
    )

    let result = yield ProtectedCall(ApiService.ProgramSearch, data.payload)

    if ((data.payload || {}).public === 0) {
      yield put({
        type: programActions.SET_PROGRAM_REQUESTS_SEARCH_RESULTS,
        payload: result.data.data,
      })
    } else {
      yield put({ type: programActions.SET_PROGRAMS_SEARCH_RESULTS, payload: result.data.data })
    }

    // Passing an empty array to empty all previous programs
    yield put({ type: programActions.UPDATE_PROGRAM_CACHE_SET, payload: [] })

    yield put({ type: programActions.UPDATE_PROGRAM_CACHE, payload: result.data.data.programs })
  } catch (e) {
    console.log('*** Error caught in programSearchSaga ***')
    yield put({ type: programActions.SET_PROGRAMS_SEARCH_RESULTS, payload: [] })
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'programs search error',
        state: 'error',
        data: e.response.data,
      })
    )
  } finally {
    if (yield cancelled()) {
      yield put(
        decrementStatusCreator({
          statusRef: data.payload.statusRef,
          message: 'programs search cancelled',
        })
      )
    } else {
      yield put(
        decrementStatusCreator({
          statusRef: data.payload.statusRef,
          message: 'programs search finished',
        })
      )
    }
  }
}

function* programFetchSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'fetching program' })
    )

    let result = yield ProtectedCall(ApiService.ProgramFetch, data.payload)

    // Passing an empty array to empty all previous programs
    yield put({ type: programActions.UPDATE_PROGRAM_CACHE_SET, payload: [] })

    yield put({ type: programActions.UPDATE_PROGRAM_CACHE, payload: [result.data.data.program] })

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'program fetched' })
    )
  } catch (e) {
    console.log('*** Error caught in programFetchSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'program fetch error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* makePublicRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Making program public',
      })
    )

    // let result = yield ProtectedCall(ApiService.ProgramUpdate, data.payload.id, { public: 1, public_request: 0 } );
    let result = yield ProtectedCall(ApiService.ProgramUpdate, data.payload.id, { public: 1 })

    yield put({ type: programActions.UPDATE_PROGRAM_CACHE, payload: [result.data.data] })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Making program public complete',
      })
    )
  } catch (e) {
    console.log('*** Error caught in program.makePublicRequestSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Making program public error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* deleteProgramRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Deleting program' })
    )

    yield ProtectedCall(ApiService.ProgramDelete, data.payload.id)

    yield put({ type: programActions.UPDATE_PROGRAM_CACHE, payload: [data.payload], delete: true })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting program complete',
      })
    )
  } catch (e) {
    console.log('*** Error caught in program.deleteProgramRequestSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting program error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* programAddCycleSaga(data) {
  try {
    const { programId, cycle } = data.payload
    let result = yield ProtectedCall(ApiService.ProgramAddCycle, programId, cycle)

    if (cycle.cycle_sessions) {
      yield all(
        cycle.cycle_sessions.map((item) => {
          return call(cycleAddSessionSaga, {
            payload: {
              programId: programId,
              cycleId: result.data.data.cycle.id,
              session: item,
              statusRef: data.payload.statusRef,
              statusRefData: { programId: programId },
            },
          })
        })
      )
    }

    if (cycle.challenge) {
      yield call(cycleAddChallengeSaga, {
        payload: {
          programId: programId,
          cycleId: result.data.data.cycle.id,
          challenge: cycle.challenge,
          statusRef: data.payload.statusRef,
          statusRefData: { programId: programId },
        },
      })
    }
  } catch (e) {
    console.log('*** Error caught in programAddCycleSaga ***', e)
    throw e
  }
}

function* updateProgramCycleSaga(data) {
  try {
    const { programId, cycle, oldProgram } = data.payload

    yield ProtectedCall(ApiService.ProgramUpdateCycle, programId, cycle.id, cycle)

    if (cycle.cycle_sessions) {
      const { cycle_sessions: oldCycleSessions } = oldProgram.cycles.find((c) => c.id === cycle.id)
      const { cycle_sessions: newCycleSessions } = cycle

      const cycleSessionsToSet = differenceBy(newCycleSessions, oldCycleSessions, 'id')
      const cycleSessionsToRemove = differenceBy(oldCycleSessions, newCycleSessions, 'id').map(
        (item) => item.id
      )
      const cycleSessionsToUpdate = difference(
        differenceWith(newCycleSessions, oldCycleSessions, isEqual),
        cycleSessionsToSet
      )

      yield all(
        cycleSessionsToRemove.map((item) => {
          return call(cycleRemoveSessionSaga, {
            payload: {
              programId: programId,
              cycleId: cycle.id,
              sessionId: item,
              statusRef: data.payload.statusRef,
            },
          })
        })
      )

      yield all(
        cycleSessionsToSet.map((item) => {
          return call(cycleAddSessionSaga, {
            payload: {
              programId: programId,
              cycleId: cycle.id,
              session: item,
              statusRef: data.payload.statusRef,
            },
          })
        })
      )

      yield all(
        cycleSessionsToUpdate.map((item) => {
          return call(updateCycleSessionSaga, {
            payload: {
              programId: programId,
              cycleId: cycle.id,
              session: item,
              statusRef: data.payload.statusRef,
            },
          })
        })
      )
    }

    const { challenge: oldChallenges } = cycle

    let addedChallenges = cycle.challenges.filter(
      (challenge) => !oldChallenges.find((a) => challenge.id === a.id)
    )
    let removedChallenges = oldChallenges.filter(
      (challenge) => !cycle.challenges.find((a) => challenge.id === a.id)
    )

    if (addedChallenges.length > 0) {
      yield all(
        addedChallenges.map((challenge) => {
          return call(cycleAddChallengeSaga, {
            payload: {
              programId: programId,
              cycleId: cycle.id,
              challenge: challenge,
              statusRef: data.payload.statusRef,
            },
          })
        })
      )
    }

    if (removedChallenges.length > 0) {
      yield all(
        removedChallenges.map((challenge) => {
          return call(cycleRemoveChallengeSaga, {
            payload: {
              programId: programId,
              cycleId: cycle.id,
              challenge: challenge,
              statusRef: data.payload.statusRef,
            },
          })
        })
      )
    }
  } catch (e) {
    console.log('*** Error caught in updateProgramCycle ***', e)
    throw e
  }
}
function* removeProgramCycleSaga(data) {
  try {
    const { programId, cycleId } = data.payload

    yield ProtectedCall(ApiService.ProgramRemoveCycle, programId, cycleId)
  } catch (e) {
    console.log('*** Error caught in updateProgramCycle ***', e)
    throw e
  }
}

function* cycleAddSessionSaga(data) {
  try {
    const { programId, cycleId } = data.payload

    const session = data.payload.session

    yield ProtectedCall(ApiService.CycleAddSession, programId, cycleId, session)
    //TODO: ADD session cache update?
  } catch (e) {
    console.log('*** Error caught in cycleAddSessionSaga ***', e)
    throw e
  }
}

function* cycleRemoveSessionSaga(data) {
  try {
    const { programId, cycleId, sessionId } = data.payload
    yield ProtectedCall(ApiService.CycleRemoveSession, programId, cycleId, sessionId)
  } catch (e) {
    console.log('*** Error caught in cycleRemoveSessionSaga ***', e)
    throw e
  }
}

function* updateCycleSessionSaga(data) {
  try {
    const { programId, cycleId, session } = data.payload
    yield ProtectedCall(ApiService.UpdateCycleSession, programId, cycleId, session.id, session)
  } catch (e) {
    console.log('*** Error caught in updateCycleSessionSaga ***', e)
    throw e
  }
}

function* cycleAddChallengeSaga(data) {
  try {
    const { programId, cycleId, challenge } = data.payload

    challenge.challenge_id = challenge.id

    yield ProtectedCall(ApiService.CycleAddChallenge, programId, cycleId, challenge)
    //TODO: ADD session cache update?
  } catch (e) {
    console.log('*** Error caught in cycleAddChallengeSaga ***', e)
    throw e
  }
}

function* cycleRemoveChallengeSaga(data) {
  try {
    const { programId, cycleId, challenge } = data.payload

    yield ProtectedCall(ApiService.CycleRemoveChallenge, programId, cycleId, challenge.id)
  } catch (e) {
    console.log('*** Error caught in cycleRemoveChallengeSaga ***', e)
    throw e
  }
}

function* updateCacheSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return
  }

  const programs = yield select(allSelector) || []

  if (data.delete) {
    data.payload.map((item) => {
      let id = item
      if (typeof id === 'object') {
        id = id.id
      }

      // id = parseInt(id);
      let program = programs[id]
      let index = programs.indexOf(program)
      if (index > -1) {
        programs.splice(index, 1)
      }

      return null
    })
  } else {
    data.payload.map((item) => {
      programs[item.id] = item

      return null
    })
  }
  yield put({ type: programActions.UPDATE_PROGRAM_CACHE_SET, payload: programs })
}
