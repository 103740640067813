import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import Button from 'components/Button'
import SessionsList from './components/SessionsList'
import ChallengesList from './components/ChallengesList'
import AdminTableDraggable from 'components/AdminTableDraggable'
import isEqual from 'lodash/isEqual'

import './style.css'

class CyclesContainer extends Component {
  constructor() {
    super()
    this.state = {
      cycles: [],
    }

    this.handleAddCycleClick = this.handleAddCycleClick.bind(this)
    this.handleSessionChange = this.handleSessionChange.bind(this)
    this.handleSessionRemove = this.handleSessionRemove.bind(this)
    this.handleChallengeChange = this.handleChallengeChange.bind(this)
    this.handleChallengeRemove = this.handleChallengeRemove.bind(this)
    this.removeCycle = this.removeCycle.bind(this)
    this.handleRowDragEnd = this.handleRowDragEnd.bind(this)
  }

  componentDidMount() {
    this.setInitialData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialData) {
      if (!isEqual(this.props.initialData, prevProps.initialData)) {
        this.setInitialData()
      }
    }
  }

  setInitialData = () => {
    if (!this.props.initialData) return

    this.setState({
      cycles: this.props.initialData,
    })
  }

  handleAddCycleClick() {
    this.setState(
      {
        cycles: [
          ...this.state.cycles,
          {
            id: this.state.cycles.length,
          },
        ],
      },
      () => {
        this.props.handleCyclesUpdate(this.state.cycles)
      }
    )
  }

  handleSessionChange(cycle_sessions, cycleIndex) {
    const { cycles } = this.state

    cycles[cycleIndex].cycle_sessions = cycle_sessions.map((item) => {
      return {
        id: item.id,
        session: item.session,
        position: item.position,
        start_after: item.start_after,
        session_id: item.session_id,
      }
    })

    this.setState(
      {
        cycles,
      },
      () => {
        this.props.handleCyclesUpdate(this.state.cycles)
      }
    )
  }

  handleSessionRemove(cycleSessions, cycleIndex) {
    let cycles = [...this.state.cycles]

    cycles[cycleIndex].cycle_sessions = cycleSessions

    this.setState(
      {
        cycles,
      },
      () => {
        this.props.handleCyclesUpdate(this.state.cycles)
      }
    )
  }

  handleChallengeRemove(cycleIndex, challengeIndex) {
    let { cycles } = this.state

    let challenges = cycles[cycleIndex].challenges
    challenges = challenges
      .slice(0, challengeIndex)
      .concat(challenges.slice(challengeIndex + 1, challenges.length))
    cycles[cycleIndex].challenges = challenges

    this.setState(
      {
        cycles,
      },
      () => {
        this.props.handleCyclesUpdate(this.state.cycles)
      }
    )
  }

  handleChallengeChange(challenges, cycleIndex) {
    let { cycles } = this.state

    cycles[cycleIndex].challenges = challenges

    this.setState(
      {
        cycles,
      },
      () => {
        this.props.handleCyclesUpdate(this.state.cycles)
      }
    )
  }

  removeCycle(cycleIndex) {
    let { cycles } = this.state

    cycles.splice(cycleIndex, 1)

    this.setState(
      {
        cycles,
      },
      () => {
        this.props.handleCyclesUpdate(this.state.cycles)
      }
    )
  }

  renderCycleTable(cycles) {
    if (!cycles) return

    const { t } = this.props

    let tableRows = []

    cycles.map((item, index) => {
      tableRows.push({
        columns: [
          {
            value: (
              <div className="modal-create-program-cycle-row" key={`cycleRow_${index}`}>
                <div className="modal-create-program-cycle-rowindex-container">
                  {t('FormProgramCreate.CyclesContainer.label-1')} {index + 1}
                </div>

                <SessionsList
                  handleSessionChange={this.handleSessionChange}
                  handleSessionRemove={this.handleSessionRemove}
                  cycleIndex={index}
                  sessions={item.cycle_sessions}
                />

                <ChallengesList
                  handleChallengeChange={this.handleChallengeChange}
                  handleChallengeRemove={this.handleChallengeRemove}
                  cycleIndex={index}
                  challenges={item.challenges}
                />

                <div
                  className="remove-cycle-button"
                  onClick={() => this.removeCycle(index)}
                  key={`remove-cycle-button-${index}`}
                >
                  X
                </div>
              </div>
            ),
          },
        ],
      })

      return null
    })

    return (
      <AdminTableDraggable
        // headerRow={headers}
        rows={tableRows}
        handleDragEnd={this.handleRowDragEnd}
      />
    )
  }

  handleRowDragEnd(data) {
    const { destination, source } = data

    if (!destination) return

    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    let { cycles } = this.state
    let temp = cycles[source.index]
    cycles.splice(source.index, 1)
    cycles.splice(destination.index, 0, temp)

    cycles = this.setPositionParams(cycles)

    this.setState(
      {
        cycles,
      },
      () => {
        this.props.handleCyclesUpdate(this.state.cycles)
      }
    )
  }

  setPositionParams(cycles) {
    if (!cycles) return []

    return cycles.map((item, index) => {
      return { ...item, position: index + 1 }
    })
  }

  render() {
    const { t } = this.props

    return (
      <div className={`modal-create-program-cycles-container ${this.props.className}`}>
        <div>{this.renderCycleTable(this.state.cycles)}</div>

        <div>
          <Button onClick={this.handleAddCycleClick}>
            {t('FormProgramCreate.CyclesContainer.button')}
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CyclesContainer))
