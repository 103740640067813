export const CREATE_PHYSICAL_TEST = 'physicalTest/CREATE_PHYSICAL_TEST'
export const UPDATE_PHYSICAL_TEST = 'physicalTest/UPDATE_PHYSICAL_TEST'
export const DELETE_PHYSICAL_TEST = 'physicalTest/DELETE_PHYSICAL_TEST'

export const PHYSICAL_TEST_SEARCH = 'physicalTest/PHYSICAL_TEST_SEARCH'
export const PHYSICAL_TEST_FETCH = 'physicalTest/PHYSICAL_TEST_FETCH'

export const TEST_FETCH_SUBMIT = 'test/TEST_FETCH_SUBMIT'

export const UPDATE_CACHE = 'physicalTest/UPDATE_CACHE'
export const UPDATE_CACHE_SET = 'physicalTest/UPDATE_CACHE_SET'
export const DELETE_BY_ID = 'physicalTest/DELETE_BY_ID'
export const UPDATE_TEST_CACHE = 'program/UPDATE_TEST_CACHE'

export const SET_SEARCH_RESULTS = 'physicalTest/SET_PHYSICAL_TEST_SEARCH_RESULTS'
export const UPDATE_SEARCH_TEST_FILTERS = 'physicalTest/UPDATE_SEARCH_TEST_FILTERS'
// export const PROGRAM_MAKE_PUBLIC_REQUEST = 'program/PROGRAM_MAKE_PUBLIC_REQUEST';
// export const PROGRAM_DELETE_REQUEST = 'program/PROGRAM_DELETE_REQUEST';
// export const PROGRAM_ADD_CYCLE_REQUEST = 'program/PROGRAM_ADD_CYCLE_REQUEST';

// export const CYCLE_ADD_SESSION_REQUEST = 'program/CYCLE_ADD_SESSION_REQUEST';
// export const CYCLE_ADD_CHALLENGE_REQUEST = 'program/CYCLE_ADD_CHALLENGE_REQUEST';

// export const UPDATE_PROGRAM_CYCLE = 'program/UPDATE_PROGRAM_CYCLE';
// export const UPDATE_CYCLE_SESSION = 'program/UPDATE_CYCLE_SESSION';

/*************************************************/
/** Action creators **/

export function createPhysicalTest(payload) {
  return { type: CREATE_PHYSICAL_TEST, payload: payload }
}

export function updatePhysicalTest(payload) {
  return { type: UPDATE_PHYSICAL_TEST, payload: payload }
}

export function deletePhysicalTest(payload) {
  return { type: DELETE_PHYSICAL_TEST, payload: payload }
}

export function searchPhysicalTests(payload) {
  return { type: PHYSICAL_TEST_SEARCH, payload: payload }
}

export function updatePhysicalTestFilters(payload) {
  return { type: UPDATE_SEARCH_TEST_FILTERS, payload }
}
