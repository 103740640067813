import { takeEvery, put } from 'redux-saga/effects'

import * as ApiService from 'services/api'
import { ProtectedCall } from 'services/protected.api'

import * as resourceActions from 'store/modules/Resource/actions.js'

/*************************************************/
/** Selectors **/

/*************************************************/
/** Sagas **/
export default function* sagaWatcher() {
  yield takeEvery(
    resourceActions.FETCH_EXERCISES_RESOURCES_REQUEST,
    fetchExercisesResourcesRequestSaga
  )
  yield takeEvery(
    resourceActions.FETCH_SESSIONS_RESOURCES_REQUEST,
    fetchSessionsResourcesRequestSaga
  )
  yield takeEvery(
    resourceActions.FETCH_CHALLENGES_RESOURCES_REQUEST,
    fetchChallengesResourcesRequestSaga
  )
  yield takeEvery(
    resourceActions.FETCH_PROGRAMS_RESOURCES_REQUEST,
    fetchProgramsResourcesRequestSaga
  )
  yield takeEvery(resourceActions.GET_TASKS_RESOURCES, getTasksResourcesRequestSaga)

  yield takeEvery(resourceActions.GET_COUNTRIES, getCountriesSaga)
}

function* fetchExercisesResourcesRequestSaga() {
  try {
    let result = yield ProtectedCall(ApiService.FetchExercisesResources)

    yield put({
      type: resourceActions.UPDATE_RESOURCE_CACHE_SET,
      resource: 'exercises',
      payload: result.data.data,
    })
  } catch (e) {
    console.log('*** Error caught in fetchExercisesResourcesRequestSaga ***')
  }
}

function* fetchSessionsResourcesRequestSaga() {
  try {
    let result = yield ProtectedCall(ApiService.FetchSessionsResources)

    yield put({
      type: resourceActions.UPDATE_RESOURCE_CACHE_SET,
      resource: 'sessions',
      payload: result.data.data,
    })
  } catch (e) {
    console.log('*** Error caught in fetchSessionsResourcesRequestSaga ***')
  }
}

function* fetchChallengesResourcesRequestSaga() {
  try {
    let result = yield ProtectedCall(ApiService.FetchChallengesResources)

    yield put({
      type: resourceActions.UPDATE_RESOURCE_CACHE_SET,
      resource: 'challenges',
      payload: result.data.data,
    })
  } catch (e) {
    console.log('*** Error caught in fetchChallengesResourcesRequestSaga ***')
  }
}

function* fetchProgramsResourcesRequestSaga() {
  try {
    let result = yield ProtectedCall(ApiService.FetchProgramsResources)
    yield put({
      type: resourceActions.UPDATE_RESOURCE_CACHE_SET,
      resource: 'programs',
      payload: result.data.data,
    })
  } catch (e) {
    console.log('*** Error caught in fetchProgramsResourcesRequestSaga ***')
  }
}

function* getTasksResourcesRequestSaga() {
  try {
    let result = yield ProtectedCall(ApiService.GetTasksResources)

    yield put({
      type: resourceActions.UPDATE_RESOURCE_CACHE_SET,
      resource: 'tasks',
      payload: result.data.data.tasks,
    })
  } catch (e) {
    console.log('*** Error caught in getTasksResourcesRequestSaga ***')
  }
}

function* getCountriesSaga() {
  try {
    let result = yield ProtectedCall(ApiService.GetCountries)

    yield put({
      type: resourceActions.UPDATE_RESOURCE_CACHE_SET,
      resource: 'countries',
      payload: result.data.data.countries,
    })
  } catch (e) {
    console.log('*** Error caught in getQuestionannireResourcesSaga ***', e)
  }
}
