/**
 * Given a string it will return boolean
 *
 * @param {String} string
 * @returns {Boolean}
 */
const getBoolean = (value) => {
  switch (value) {
    case true:
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
      return true
    default:
      return false
  }
}

export default getBoolean
