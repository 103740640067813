import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import getTranslations from 'helpers/get-translations'

import FormGeneric from 'components/FormGeneric'
import Button from 'components/Button'
import Exercises from './containers/Exercises'
import LevelsSelector from './components/LevelsSelector'
import Input from 'containers/Input'
import ErrorMessage from 'components/ErrorMessage'

import './style.css'

const baseClass = 'form-test-create'

const FormTestCreate = ({ t, initialData, levels, status, handleSubmit }) => {
  const [form, setForm] = useState({
    enabled: true,
    automatic: true,
    levels: [],
  })
  const [test_exercises, setTestExercises] = useState([])

  useEffect(() => {
    setInitialData()
  }, [])

  useEffect(() => {
    setInitialData()
  }, [initialData])

  const setInitialData = () => {
    if (!initialData) return
    const test_exercises = initialData.test_exercises || []

    setForm({
      id: initialData.id || null,
      automatic: initialData.automatic || false,
      enabled: initialData.enabled || false,
      used: initialData.getphy || false,
      label: getTranslations(initialData.translations, 'label'),
      levels: initialData.levels || [],
    })
    setTestExercises(test_exercises)
  }

  const handleInputChange = (event) => {
    const { target } = event

    let value = target.type === 'checkbox' ? target.checked : target.value
    if (value === 'true' || value === 'false') {
      value = value === 'true'
    }

    const { name } = target

    let updatedForm = {}
    if (name.includes('.')) {
      //TODO: ALlows language to be specified in the input name.
      //Look at a more scalable way of doing this.
      const splitName = name.split('.')
      updatedForm = {
        ...form,
        [splitName[0]]: {
          ...form[splitName[0]],
          [splitName[1]]: value,
        },
      }
    } else {
      updatedForm = {
        ...form,
        [name]: value,
      }
    }
    setForm(updatedForm)
  }

  const handleInputTranslationChange = (event, values) => {
    const { target } = event
    const { name } = target
    let updatedForm = {}

    if (name.includes('.')) {
      let splitName = name.split('.')
      updatedForm = {
        ...form,
        [splitName[0]]: values,
      }
    }

    setForm(updatedForm)
  }

  const handleExercisesUpdate = (test_exercises) => {
    setTestExercises([...test_exercises])
  }

  const handleSubmitForm = () => {
    const testExercises = test_exercises.map((exercise) => {
      const question = {}
      if (exercise.translations) {
        Object.entries(exercise.translations).map(([key, value]) => {
          question[key] = value.question
        })
      }
      const test_answers = exercise.test_answers.map((answer) => ({
        ...answer,
        nb_to_reach: answer.nb_to_reach ?? 0,
      }))
      return { question, ...exercise, test_answers }
    })
    const levels = form.levels.map(({ id: level_id, ...level }) => ({
      ...level,
      level_id,
    }))
    handleSubmit({ ...form, levels }, testExercises)
  }

  const handleLevelsChange = (levels) => {
    setForm({
      ...form,
      levels,
    })
  }

  const isDisabled = () => {
    let isDisabled = false
    if (test_exercises.length > 0) {
      test_exercises.forEach((item) => {
        if ((item.test_answers || []).length <= 0) {
          isDisabled = true
        }
      })
    }

    return isDisabled
  }

  const handleBack = () => {
    window.history.go(-1)
  }

  return (
    <div className={`${baseClass}`}>
      <FormGeneric className="modal-create-program-form" onSubmit={handleSubmitForm}>
        <h1>{t('FormTestCreate.title')}</h1>

        <div className="row-container">
          <label>{t('FormTestCreate.label-2')}</label>

          <div className="left-indent">
            <Input
              type="text"
              name="label"
              onChange={handleInputTranslationChange}
              value={form.label || {}}
              required={'required'}
              maxLength={191}
              placeholder={t('FormTestCreate.label-2')}
              isTranslatable={true}
            />
          </div>
        </div>

        <div className="row-container">
          <label>{t('FormTestCreate.label-3')}</label>

          <div className="left-indent">
            <input
              type="checkbox"
              name="enabled"
              value={true}
              onChange={handleInputChange}
              checked={form.enabled === true || form.enabled === 1}
            />

            <label>{t('FormTestCreate.label-4')}</label>
          </div>
        </div>

        <div className="row-container">
          <label>{t('FormTestCreate.label-5')}</label>

          <div className="left-indent" />
          <div className="left-indent">
            <input
              type="checkbox"
              name="automatic"
              value={true}
              onChange={handleInputChange}
              checked={form.automatic === true || form.automatic === 1}
            />

            <label>{t('FormTestCreate.label-6')}</label>
          </div>
        </div>

        <LevelsSelector
          levels={levels}
          handleLevelsChange={handleLevelsChange}
          selectedLevels={form.levels}
        />

        <div>
          <label>{t('FormTestCreate.label-8')}</label>

          <Exercises
            test_exercises={test_exercises}
            handleExercisesUpdate={handleExercisesUpdate}
          />
        </div>

        <div className="row-container">
          <p>{t('FormTestCreate.label-9')}</p>

          <Button className="cancel-button" onClick={handleBack}>
            {t('GLOBAL.cancel')}
          </Button>

          <Button
            type="submit"
            className="signup-button"
            onClick={() => {}}
            status={status}
            disabled={isDisabled()}
          >
            {t('GLOBAL.submit')}
          </Button>

          <ErrorMessage status={status} />
        </div>
      </FormGeneric>
    </div>
  )
}

FormTestCreate.propTypes = {
  resources: PropTypes.object,
  status: PropTypes.object,
}

export default withTranslation()(FormTestCreate)
