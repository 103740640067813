import { put, select, takeEvery } from 'redux-saga/effects'

import * as ApiService from 'services/api'

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions.js'
import { ProtectedCall } from 'services/protected.api'

import * as folderActions from 'store/modules/Folder/actions.js'

/*************************************************/
/** Selectors **/
const allFolderSelector = (state) => {
  return state.Folder.folders
}

/*************************************************/
/** Sagas **/
export default function* sagaWatcher() {
  yield takeEvery(folderActions.GET_TOP_LEVEL_FOLDERS, getTopLevelFoldersSaga)
  yield takeEvery(folderActions.CREATE_TAB_FOLDER, createTabFolderSaga)
  yield takeEvery(folderActions.GET_FOLDER, getFolderSaga)
  yield takeEvery(folderActions.DELETE_CONTENT_FROM_FOLDER, deleteContentFromFolderSaga)
  yield takeEvery(folderActions.UPDATE_FOLDER_CACHE, updateFolderCacheSaga)
}

function* getTopLevelFoldersSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Fetching top level folders',
      })
    )

    let result = yield ProtectedCall(ApiService.GetTopLevelFolders, data.payload)

    let folder_type = null

    switch (data.payload.type) {
      case 1:
        folder_type = 'programs'
        break
      case 2:
        folder_type = 'sessions'
        break
      case 3:
        folder_type = 'exercises'
        break
    }

    yield put({
      type: folderActions.SET_TOP_LEVEL_FOLDERS,
      payload: {
        folders: result.data.data.folders,
        folder_type,
      },
    })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Folders top level fetch complete',
      })
    )
  } catch (e) {
    console.log('*** Error caught in foldersFetchSaga ***', e)
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Folders top level  fetch error',
        state: 'error',
      })
    )
  }
}

function* getFolderSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting folder' })
    )

    let result = yield ProtectedCall(ApiService.GetFolder, data.payload.folder_id, data.payload)

    yield put({
      type: folderActions.UPDATE_FOLDER_CACHE,
      payload: {
        data: result.data.data.folder,
        folderId: data.payload.folder_id,
      },
      delete: data.payload.delete || null,
    })

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Folder get complete' })
    )
  } catch (e) {
    console.log('*** Error caught in getFolderSaga ***', e)
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Folder get error',
        state: 'error',
      })
    )
  }
}

function* createTabFolderSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Creating Tab Folder' })
    )

    let result = yield ProtectedCall(ApiService.CreateFolder, data.payload)

    let folder_type = null

    switch (data.payload.type) {
      case 1:
        folder_type = 'programs'
        break
      case 2:
        folder_type = 'sessions'
        break
      case 3:
        folder_type = 'exercises'
        break
    }

    yield put({
      type: folderActions.SET_TOP_LEVEL_FOLDERS,
      payload: {
        folders: [result.data.data.folder],
        folder_type,
      },
      statusRef: data.payload.statusRef,
    })

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Tab Folder created' })
    )
  } catch (e) {
    console.log('*** Error caught in createTabFolderSaga ***', e)
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Tab Fodler creation error',
        state: 'error',
      })
    )
  }
}

function* deleteContentFromFolderSaga(data) {
  const { callback = { success: () => null } } = data.payload
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Delete content from Folder',
      })
    )

    yield ProtectedCall(
      ApiService.DeleteContentFromFolder,
      data.payload.folderId,
      data.payload.contentId,
      data.payload
    )

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Delete content from Folder finished',
      })
    )
    callback.success()
  } catch (e) {
    console.log('*** Error caught in deleteContentFromFolderSaga ***', e)
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Delete content from Folder error',
        state: 'error',
      })
    )
  }
}

function* updateFolderCacheSaga(data) {
  if (!data || !data.payload) {
    return
  }

  const folders = yield select(allFolderSelector) || {}
  const { folderId, data: folderData } = data.payload
  const { exercises, sessions, programs } = folderData

  if (sessions.length) {
    Object.entries(folders.sessions).forEach(([key, s]) => {
      if (!sessions.find((updated) => updated.id === s.id)) {
        delete folders.sessions[key]
      }
    })
  }
  sessions.map((session) => {
    folders.sessions[session.id] = { ...session, folder_id: folderId }
  })

  if (exercises.length) {
    Object.entries(folders.exercises).forEach(([key, s]) => {
      if (!exercises.find((updated) => updated.id === s.id)) {
        delete folders.exercises[key]
      }
    })
  }
  exercises.map((exercise) => {
    folders.exercises[exercise.id] = { ...exercise, folder_id: folderId }
  })

  programs.map((program) => {
    folders.programs[program.id] = { ...program, folder_id: folderId }
  })

  if (data.delete === 'session') {
    const sessionsIDs = sessions.map(({ id }) => String(id))

    if (sessionsIDs.length) {
      const sessionsToRemove = Object.keys(folders.sessions)
        .filter((x) => !sessionsIDs.includes(x))
        .concat(sessionsIDs.filter((x) => !Object.keys(folders.sessions).includes(x)))

      sessionsToRemove.map((sessionId) => {
        delete folders.sessions[sessionId]
      })
    }
  }

  if (data.delete === 'exercise') {
    const exercisesIDs = exercises.map(({ id }) => String(id))

    if (exercisesIDs.length) {
      const exercisesToRemove = Object.keys(folders.exercises)
        .filter((x) => !exercisesIDs.includes(x))
        .concat(exercisesIDs.filter((x) => !Object.keys(folders.exercises).includes(x)))
      exercisesToRemove.map((exerciseId) => {
        delete folders.exercises[exerciseId]
      })
    }
  }

  if (data.delete === 'program') {
    const programsIDs = programs.map(({ id }) => String(id))

    if (programsIDs.length) {
      const programsToRemove = Object.keys(folders.programs)
        .filter((x) => !programsIDs.includes(x))
        .concat(programsIDs.filter((x) => !Object.keys(folders.programs).includes(x)))

      programsToRemove.map((programId) => {
        delete folders.programs[programId]
      })
    }
  }
  yield put({ type: folderActions.UPDATE_FOLDER, payload: folders })
}
