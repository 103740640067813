import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import FormModal from 'components/FormModal'
import Button from 'components/Button'
import CloseButton from 'components/CloseButton'
import ErrorMessage from 'components/ErrorMessage'

import ModalForgotPassword from 'containers/modals/ModalForgotPassword'

import { loginRequestCreator } from 'store/modules/Account/actions.js'
import { CLOSE_MODALS, SET_ACTIVE_MODAL } from 'store/modules/Modal/actions.js'

import './style.css'
import { withRouter } from 'react-router-dom'

ReactModal.setAppElement('#root')

const statusRef = 'ModalLogin-login'

class ModalLogin extends Component {
  constructor() {
    super()

    this.state = {
      showModal: true,
      email: '',
      password: '',
      submitted: false,
      forgotPasswordModalActive: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleRegisterClick = this.handleRegisterClick.bind(this)

    this.handleForgotPasswordClick = this.handleForgotPasswordClick.bind(this)
    this.handleCloseForgotPassword = this.handleCloseForgotPassword.bind(this)
  }

  handleChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleCloseModal() {
    this.props.dispatch({ type: CLOSE_MODALS })
  }

  handleSubmit() {
    const { location } = this.props
    let pathname = '/sac'
    if (location?.pathname && location.pathname !== '/') {
      pathname = location.pathname
    }
    this.props.dispatch(
      loginRequestCreator({
        email: this.state.email,
        password: this.state.password,
        statusRef: statusRef,
        callback: {
          onSuccess: () => this.props.history.push(pathname),
        },
      })
    )
  }

  handleForgotPasswordClick() {
    this.setState({
      forgotPasswordModalActive: true,
    })
  }

  handleCloseForgotPassword() {
    this.setState({
      forgotPasswordModalActive: false,
    })
  }

  handleRegisterClick() {
    this.props.dispatch({ type: SET_ACTIVE_MODAL, payload: 'register' })
  }

  componentDidUpdate(prevProps) {
    const { loginRequestStatus } = this.props
    //After succesful login close login modal
    if (prevProps.loginRequestStatus !== 'success' && loginRequestStatus === 'success') {
      setTimeout(() => {
        this.props.dispatch({ type: CLOSE_MODALS })
      }, 900)
    }
  }

  render() {
    const { email, password } = this.state
    const { t } = this.props

    return (
      <ReactModal
        isOpen={this.props.activeModal === 'login'}
        onRequestClose={this.handleCloseModal}
        shouldCloseOnOverlayClick={true}
        className="modal-content modal-login"
        overlayClassName="modal-overlay"
      >
        <CloseButton onClick={this.handleCloseModal} />

        {this.state.forgotPasswordModalActive && (
          <ModalForgotPassword
            handleOnClose={this.handleCloseModal}
            handleCloseForgotPassword={this.handleCloseForgotPassword}
          />
        )}

        <FormModal id="login-form" className="login-form transparent" onSubmit={this.handleSubmit}>
          <h1>{t('ModalLogin.title')}</h1>

          <div className="row-container">
            <input
              type="email"
              name="email"
              value={email}
              placeholder={t('ModalLogin.email')}
              autoComplete="email"
              required={true}
              onChange={this.handleChange}
            />
          </div>

          <div className="row-container">
            <input
              type="password"
              name="password"
              value={password}
              placeholder={t('ModalLogin.password')}
              autoComplete="password"
              required={true}
              onChange={this.handleChange}
            />
          </div>

          <span className="login-status"></span>

          <div className="row-container">
            {/* <Button type="button" className="register-link" href="/register" onClick={this.handleRegisterClick}>
                {t('ModalLogin.register')}
              </Button> */}

            <Button
              type="submit"
              name="button"
              onClick={this.handleSubmit}
              status={this.props.statuses[statusRef]}
            >
              {t('ModalLogin.connect')}
            </Button>

            <ErrorMessage status={this.props.statuses[statusRef]} />
          </div>
        </FormModal>

        <Button
          className="button--link-type"
          onClick={this.handleForgotPasswordClick}
          type="button"
        >
          {t('ModalLogin.forgot_password')}
        </Button>
      </ReactModal>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  loginRequestStatus: state.Account.loginRequestStatus,
  loginError: state.Account.loginError,
  activeModal: state.Modal.activeModal,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withRouter(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalLogin))
)
