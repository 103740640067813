import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

class NotFound404Page extends Component {
  render() {
    const { t } = this.props

    return (
      <div className="App">
        <header className="App-header">
          <h1 className="App-title">{t('NotFound404Page.title')}</h1>
          <nav>
            <Link to="/">{t('NotFound404Page.link-1')}</Link>
            <Link to="/dashboard">{t('NotFound404Page.link-2')}</Link>
          </nav>
        </header>
        <p className="App-intro">
          {t('NotFound404Page.text-1')} <code>src/App.js</code> {t('NotFound404Page.text-2')}
        </p>
      </div>
    )
  }
}

export default withTranslation()(NotFound404Page)
