import * as prescriberActions from 'store/modules/Prescriber/actions.js'

const initialState = {
  prescribers: [],
  prescribersList: [],
  prescriberSearchResults: [],
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case prescriberActions.UPDATE_CACHE_PRESCRIBERS_SET:
      return {
        ...state,
        prescribers: action.payload,
      }

    case prescriberActions.UPDATE_CACHE_PRESCRIBERS_LIST_SET:
      return {
        ...state,
        prescribersList: action.payload,
      }

    default:
      return state
  }
}
