import React, { Component, Fragment } from 'react'
import isEqual from 'lodash/isEqual'
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Image as ImagePdf,
  Document,
  StyleSheet,
  Font,
} from '@react-pdf/renderer'
import Html from 'react-pdf-html'

import Button from 'components/Button/index'

import { withTranslation } from 'react-i18next'
import { getLocale } from 'services/localisation'
import getTranslations from 'helpers/get-translations'
import { normalizeTime } from 'services/time'

import { WITHOUT_MATERIAL } from 'services/equipment'

import './style.css'

const baseClass = 'page-session-printer'

const imageUrl = process.env.REACT_APP_STORAGE_URL

Font.register({
  family: 'Roboto',
  fonts: [
    { src: '/fonts/roboto/Roboto-Regular.ttf', fontWeight: 400 },
    { src: '/fonts/roboto/Roboto-Medium.ttf', fontWeight: 500 },
    { src: '/fonts/roboto/Roboto-Bold.ttf', fontWeight: 700 },
  ],
})

const styles = StyleSheet.create({
  exercise: {
    padding: 15,
  },
  table: {
    backgroundColor: '#EBF3FF',
  },
  tableRow: {
    flexDirection: 'row',
  },
  exerciseStats: {
    marginTop: 10,
  },
  additionalInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 5,
  },
  additionalInfoBlock: {
    width: '49%',
    color: 'white',
  },
  tableCol50: {
    width: '50%',
    padding: '0 10px',
  },
  tableCol100: {
    width: '100%',
    padding: '0 10px',
  },
  tableCell: {
    marginTop: 5,
    marginLeft: 10,
    fontSize: 10,
  },
  exerciseSteps: {
    margin: '30px 0 20px 0',
    display: 'flex',
    flexDirection: 'row',
  },
  exerciseStep: {
    padding: 0,
    width: 220,
    height: 337,
    marginRight: 5,
    backgroundColor: 'white',
    margin: '0 25px',
  },
  imageStep: {
    height: 166,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  instructions: {
    backgroundColor: '#027fc3',
    color: 'white',
    fontSize: 12,
    padding: 14,
    height: 171,
  },
  instructionsSuffix: {
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: 700,
  },
  exerciseName: {
    fontSize: 16,
    width: '100%',
    textAlign: 'left',
    paddingLeft: 20,
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#000060',
  },
  label: {
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: 700,
    width: '100%',
    textAlign: 'left',
    backgroundColor: '#FFB401',
    height: 30,
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  additionalInfoText: {
    color: 'black',
    padding: 15,
    backgroundColor: '#EBF3FF',
    fontSize: 12,
    height: 100,
  },
  tableCellSplited: {
    display: 'inline-flex',
    flexDirection: 'row',
    textAlign: 'center',
  },
  tableCellSplitedContainer: {
    padding: 3,
    borderStyle: 'solid',
    borderRightWidth: 1,
  },
  tableCellSplitedLabel: {
    fontSize: 14,
    paddingBottom: 2,
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
    fontWeight: 700,
    color: '#000060',
  },
  tableCellSplitedData: {
    fontWeight: 400,
  },
  sessionTitle: {
    fontSize: 18,
    fontFamily: 'Roboto',
    fontWeight: 700,
    color: '#006CB0',
    textAlign: 'center',
    margin: '10px 0',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '15px 25px',
  },
})

const summaryStylesheet = {
  p: {
    fontFamily: 'Roboto',
    fontSize: 12,
    color: '#020F56',
    fontWeight: 400,
  },
  span: {
    fontSize: 14,
    color: '#027fc3',
    fontWeight: 700,
    letterSpacing: 1,
  },
}

const MyDocument = (props) => {
  const { t, content } = (props || {}).props || {}
  const session = content || {}

  const locale = getLocale()
  const description = getTranslations(session.translations, 'description')?.[locale]
  const recommendations = getTranslations(session.translations, 'recommendations')?.[locale]

  let sessionExercises = []

  {
    ;(session.session_exercises || []).map((session_exercise) => {
      ;((session_exercise.exercise || {}).equipment || []).map((item) => {
        if (!sessionExercises.includes(item.name) && item.id !== WITHOUT_MATERIAL) {
          sessionExercises.push(item.name)
        }
      })
    })
  }

  const getSteps = (start, end, session_exercise, showAdditionalInfo, showTopInfoBar) => {
    return (
      <Page style={styles.exercise} orientation="landscape">
        <View style={[styles.table, { marginBottom: showTopInfoBar ? 0 : 30 }]}>
          {showTopInfoBar && (
            <View style={[styles.tableRow, styles.exerciseStats]}>
              <View style={styles.tableCol50}>
                <Text style={styles.exerciseName}>{session_exercise?.exercise?.name}</Text>
              </View>
              <View style={styles.tableCol50}>
                <View style={styles.tableCellSplited}>
                  <View style={[styles.tableCellSplitedContainer, { width: '30%' }]}>
                    <Text style={styles.tableCellSplitedLabel}>
                      {t('PageSessionsEdit.ExercisesContainer.subtitle-3')}{' '}
                      <Text style={styles.tableCellSplitedData}>{session_exercise?.series}</Text>
                    </Text>
                  </View>
                  <View style={[styles.tableCellSplitedContainer, { width: '30%' }]}>
                    <Text style={styles.tableCellSplitedLabel}>
                      {t('PageSessionsEdit.ExercisesContainer.subtitle-5')}{' '}
                      <Text style={styles.tableCellSplitedData}>{`${normalizeTime(
                        session_exercise?.duration,
                        'seconds',
                        true
                      )}`}</Text>
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tableCellSplitedContainer,
                      { borderRightWidth: 0, width: '44%' },
                    ]}
                  >
                    <Text style={styles.tableCellSplitedLabel}>
                      {t('PageSessionsEdit.ExercisesContainer.subtitle-6')}{' '}
                      <Text style={styles.tableCellSplitedData}>{`${normalizeTime(
                        session_exercise?.rest_time,
                        'seconds',
                        true
                      )}`}</Text>
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}
          <View style={[styles.exerciseSteps]}>
            {session_exercise?.exercise?.steps?.slice(start, end)?.map((step, idx) => {
              return (
                <View key={idx} style={styles.exerciseStep} wrap={false}>
                  <View style={styles.imageStep}>
                    <ImagePdf src={`${imageUrl}${step.photo}`} />
                  </View>
                  <View>
                    <Text style={styles.instructions}>
                      <Text style={styles.instructionsSuffix}>{`${step.position}.  `}</Text>
                      {step.description || ''}
                    </Text>
                  </View>
                </View>
              )
            })}
          </View>
        </View>
        {showAdditionalInfo && (
          <View style={[styles.tableRow, styles.additionalInfo]}>
            <View style={styles.additionalInfoBlock}>
              <View style={styles.label}>
                <Text>{t('ExerciseAdditionalInfo.title-4')}</Text>
              </View>
              <View style={styles.additionalInfoText}>
                <Text>{session_exercise?.exercise?.objective_details}</Text>
              </View>
            </View>
            <View style={styles.additionalInfoBlock}>
              <View style={styles.label}>
                <Text>{t('ExerciseAdditionalInfo.title-2')}</Text>
              </View>
              <View style={styles.additionalInfoText}>
                <Text>{session_exercise?.exercise?.recommendations}</Text>
              </View>
            </View>
          </View>
        )}
      </Page>
    )
  }

  return (
    <Document>
      <Page orientation="landscape">
        <View>
          <View>
            <Text style={styles.sessionTitle}>{session.name}</Text>
          </View>
          <View style={styles.infoContainer}>
            <Html stylesheet={summaryStylesheet}>
              {`<p><span>${t('ExerciseAdditionalInfo.title-5')} :</span>
                ${session.level?.name || ''}</p>`}
            </Html>
          </View>
          <View style={styles.infoContainer}>
            <Html stylesheet={summaryStylesheet}>
              {`<p><span>${t('SessionDetails.subtitle-2')} :</span>
                ${`${normalizeTime(session.duration, 'secondsRounded', true)}`}</p>`}
            </Html>
          </View>
          <View style={styles.infoContainer}>
            <Html stylesheet={summaryStylesheet}>
              {`<p><span>${t('ExerciseAdditionalInfo.title-3')} :</span>
                ${sessionExercises.map((sessionExercise) => {
                  return ` ${sessionExercise}`
                })}</p>`}
            </Html>
          </View>
          <View style={styles.infoContainer}>
            <Html stylesheet={summaryStylesheet}>
              {`<p><span>${t('FormSessionCreate.label-2')} :</span>
                ${description || ''}</p>`}
            </Html>
          </View>
          <View style={styles.infoContainer}>
            <Html stylesheet={summaryStylesheet}>
              {`<p><span>${t('FormSessionCreate.label-7')} :</span>
                ${recommendations || ''}</p>`}
            </Html>
          </View>
        </View>
      </Page>
      {session.session_exercises?.map((session_exercise) => {
        let stepsCount = session_exercise?.exercise?.steps?.length || 0

        let firstPage = getSteps(0, 3, session_exercise, stepsCount <= 3, true)
        let secondPage = null

        if (stepsCount > 3) {
          secondPage = getSteps(3, 6, session_exercise, true, false)
        }

        return (
          <Fragment key={session_exercise?.id}>
            {firstPage}
            {secondPage}
          </Fragment>
        )
      })}
    </Document>
  )
}

class SessionPrinter extends Component {
  constructor() {
    super()

    this.state = {
      canShowButtonDownloadPdf: false,
      componentDidMount: false,
      loaded: false,
    }

    this.getImagesDimension = this.getImagesDimension.bind(this)
    this.getPdfDownloadLink = this.getPdfDownloadLink.bind(this)
  }

  componentDidMount() {
    if (this.props.content) {
      this.getImagesDimension()
    }

    this.setState({
      componentDidMount: true,
    })
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.content, this.props.content) && this.props.content.session_exercises) {
      this.getImagesDimension()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isEqual(nextState, this.state) || !isEqual(nextProps.content, this.props.content))
      return true
    return false
  }

  getImagesDimension() {
    let totalImages = 0
    let loaded = 0
    let self = this

    ;(this.props.content.session_exercises || []).map((session_exercise) => {
      totalImages += ((session_exercise?.exercise || {}).steps || []).length
      ;((session_exercise?.exercise || {}).steps || []).map((step) => {
        let img = new Image()
        img.src = `${imageUrl}${step.photo}`
        img.onload = function () {
          if (!(step.photo_dimensions || {}).height || !(step.photo_dimensions || {}).width) {
            step.photo_dimensions = {
              height: img.height,
              width: img.width,
            }

            loaded++

            if (loaded >= totalImages) {
              self.setState({
                loaded: true,
              })
            }
          }
        }

        img.onerror = function () {
          loaded++

          if (loaded >= totalImages) {
            self.setState({
              loaded: true,
            })
          }
        }
      })
    })
  }

  getPdfDownloadLink(session, t) {
    if (!this.state.loaded) {
      return <Button>{t('PageSession.SessionPrinter.load_pdf')}</Button>
    }

    return (
      <PDFDownloadLink
        className={`button-download-pdf ${session.id ? 'visible' : 'hidden'}`}
        document={<MyDocument props={this.props} />}
        fileName={`${session.id}-${session.name}.pdf`}
      >
        {({ loading }) => {
          return loading
            ? t('PageSession.SessionPrinter.load_pdf')
            : t('PageSession.SessionPrinter.download_pdf')
        }}
      </PDFDownloadLink>
    )
  }

  render() {
    const session = this.props.content || {}
    const { t } = this.props

    return (
      <div className={`${baseClass}`}>
        {this.state.componentDidMount && this.getPdfDownloadLink(session, t)}
        {/* {this.state.componentDidMount && (
          <PDFViewer width={1300} height={2000}>
            <MyDocument props={this.props} />
          </PDFViewer>
        )} */}
      </div>
    )
  }
}

export default withTranslation()(SessionPrinter)
