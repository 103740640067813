import React, { Component } from 'react'
import './style.css'

class Footer extends Component {
  render() {
    return <div className="c-footer">Footer</div>
  }
}

export default Footer
