import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'

import Button from 'components/Button'
import AdminTableDraggable from 'components/AdminTableDraggable'
import CardPicker from 'components/CardPicker'
import Icon from 'components/Icon'

import { FETCH_EXERCISES_RESOURCES_REQUEST } from 'store/modules/Resource/actions.js'
import { exerciseSearchCreator, EXERCISE_FETCH_SUBMIT } from 'store/modules/Exercise/actions.js'

import './style.css'

const statusRefSearch = 'FormSessionCreate-Exercises-search'

const imageUrl = process.env.REACT_APP_STORAGE_URL

class Exercises extends Component {
  constructor() {
    super()
    this.state = {
      exercises: [],
      searchData: {},
      showCardModal: false,
      showModalExerciseEdit: false,
    }

    this.fetchExercises = debounce(this.fetchExercises.bind(this), 300)
  }

  componentDidMount() {
    this.props.dispatch({ type: FETCH_EXERCISES_RESOURCES_REQUEST })
    this.setInitialData()
  }

  componentDidUpdate(prevProps) {
    const { exercises } = this.props
    if (exercises && !isEqual(exercises, prevProps.exercises)) {
      this.setInitialData()
    }
  }

  setInitialData = () => {
    this.setState({
      exercises: this.props.exercises,
    })
  }

  handleAddExerciseClick = (exercises) => {
    let exercisesFormated = []

    exercises.map((exercise, index) => {
      let position = this.state.exercises.length + (index + 1)

      exercisesFormated.push({
        exercise,
        series: 1,
        repetitions: 1,
        duration: 30,
        rest_time: 30,
        position: position,
      })
    })

    this.setState(
      {
        exercises: [...this.state.exercises, ...exercisesFormated],
      },
      () => {
        this.props.handleExercisesUpdate(this.state.exercises)
      }
    )
  }

  handleSearchExercisesClick = () => {
    this.setState(
      {
        showCardModal: true,
      },
      () => this.props.dispatch(exerciseSearchCreator({ public: 1, statusRef: statusRefSearch }))
    )
  }

  handleCloseCardModal = () => {
    this.setState({
      showCardModal: false,
    })
  }

  fetchExercises = (data) => {
    if (!data) return

    this.setState(
      {
        searchData: data,
      },
      () => {
        this.props.dispatch(
          exerciseSearchCreator({
            ...data,
            ...data.multiSelect,
            search: data.search_text,
            public: true,
            statusRef: statusRefSearch,
          })
        )
      }
    )
  }

  handleRemoveExerciseLine = (index) => {
    let { exercises } = this.state

    exercises.splice(index, 1)

    this.setState(
      {
        exercises,
      },
      () => {
        this.props.handleExercisesUpdate(this.state.exercises)
      }
    )
  }

  handleRowDragEnd = (data) => {
    const { destination, source } = data

    if (!destination) return

    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    let { exercises } = this.state
    let temp = exercises[source.index]
    exercises.splice(source.index, 1)
    exercises.splice(destination.index, 0, temp)

    exercises = this.setPositionParams(exercises)

    this.setState(
      {
        exercises,
      },
      () => {
        this.props.handleExercisesUpdate(this.state.exercises)
      }
    )
  }

  setPositionParams = (exercises) => {
    if (!exercises) return []

    return exercises.map((item, index) => {
      return { ...item, position: index + 1 }
    })
  }

  getExerciseNameFromId = (id) => {
    const exercise = this.props.allExercises[id]
    if (!exercise) {
      this.props.dispatch({ type: EXERCISE_FETCH_SUBMIT, payload: id })
    } else {
      return (this.props.allExercises[id] || {}).name
    }
  }

  getExerciseImage = (exercise) => {
    if (!exercise) return

    if (exercise.steps && exercise.steps.length > 0) {
      return (
        <img className={`exercise-image`} src={`${imageUrl}${exercise.steps[0].photo}`} alt="" />
      )
    }
  }

  renderExercisesTable = () => {
    const { t } = this.props

    const headers = {
      columns: [
        { value: t('FormSessionCreate.Exercises.subtitle-1') },
        { value: '' },
        { value: t('FormSessionCreate.Exercises.subtitle-2') },
        { value: t('FormSessionCreate.Exercises.subtitle-3') },
        { value: t('FormSessionCreate.Exercises.subtitle-4') },
        { value: t('FormSessionCreate.Exercises.subtitle-5') },
        { value: t('FormSessionCreate.Exercises.subtitle-6') },
        { value: '' },
        { value: '' },
      ],
    }

    let tableRows = []

    if (this.state.exercises) {
      this.state.exercises.map((item, index) => {
        tableRows.push({
          columns: [
            { value: item.exercise.id },
            { value: this.getExerciseImage(item.exercise), className: 'with-image' },
            { value: item.exercise.name },
            {
              value: (
                <input
                  name="series"
                  type="number"
                  onChange={(e) => this.handleTableInputChange(e, index)}
                  value={item.series}
                />
              ),
            },
            {
              value: (
                <input
                  name="repetitions"
                  type="number"
                  onChange={(e) => this.handleTableInputChange(e, index)}
                  value={item.repetitions}
                />
              ),
            },
            {
              value: (
                <input
                  name="duration"
                  type="number"
                  onChange={(e) => this.handleTableInputChange(e, index)}
                  value={item.duration}
                />
              ),
            },
            {
              value: (
                <input
                  name="rest_time"
                  type="number"
                  onChange={(e) => this.handleTableInputChange(e, index)}
                  value={item.rest_time}
                />
              ),
            },
            {
              value: (
                <Button className="small" onClick={() => this.handleRemoveExerciseLine(index)}>
                  {' '}
                  <Icon src="trash.svg" />{' '}
                </Button>
              ),
            },
            {
              value: (
                <Button
                  className="small"
                  onClick={() => this.props.handleOpenEditExercise(item.exercise.id)}
                >
                  {' '}
                  <Icon className="small" src={'pen.svg'} />{' '}
                </Button>
              ),
            },
          ],
        })

        return null
      })
    }

    return (
      <AdminTableDraggable
        headerRow={headers}
        rows={tableRows}
        handleDragEnd={this.handleRowDragEnd}
      />
    )
  }

  handleTableInputChange = (event, index) => {
    const target = event.target

    let value = target.type === 'checkbox' ? target.checked : target.value
    if (value === 'true' || value === 'false') {
      value = value === 'true'
    }

    const name = target.name

    if (index > -1) {
      let exercises = this.state.exercises
      exercises[index] = {
        ...this.state.exercises[index],
        [name]: value,
      }

      this.setState(
        {
          exercises: exercises,
        },
        () => {
          this.props.handleExercisesUpdate(this.state.exercises)
        }
      )
    }
  }

  searchFilteroptions = () => {
    const { t } = this.props

    const resources = this.props.resources
    let toReturn = []

    if (resources.equipment) {
      toReturn.push({
        label: t('PageExercises.equipment'),
        name: 'equipment',
        value: 'equipment',
        options: resources.equipment.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.muscles) {
      toReturn.push({
        label: t('PageExercises.muscles'),
        name: 'muscles',
        value: 'muscles',
        options: resources.muscles.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.objectives) {
      toReturn.push({
        label: t('PageExercises.objectives'),
        name: 'objectives',
        value: 'objectives',
        options: resources.objectives.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.positions) {
      toReturn.push({
        label: t('PageExercises.positions'),
        name: 'positions',
        value: 'positions',
        options: resources.positions.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    return toReturn
  }

  render() {
    const { t } = this.props

    return (
      <div className={`modal-create-session-exercises-container ${this.props.className}`}>
        <div>{this.renderExercisesTable()}</div>

        <div>
          <Button onClick={this.handleSearchExercisesClick}>
            {t('FormSessionCreate.Exercises.button')}
          </Button>
        </div>

        {this.state.showCardModal && (
          <CardPicker
            type={'exercise'}
            handleClose={this.handleCloseCardModal}
            searchResults={this.props.searchResults.exercises}
            limit={this.props.searchResults.limit}
            offset={this.props.searchResults.offset}
            total={this.props.searchResults.total_count}
            searchFilteroptions={this.searchFilteroptions()}
            searchFilterType={'multi-select'}
            filterSubmit={this.fetchExercises}
            searchData={this.state.searchData}
            fetchDatas={this.fetchExercises}
            handleSubmit={this.handleAddExerciseClick}
            status={this.props.statuses[statusRefSearch]}
            resources={this.props.resources}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  searchResults: state.Exercise.searchResults,
  resources: state.Resource.exercises,
  allExercises: state.Exercise.exercises,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Exercises))
)
