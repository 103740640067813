import * as programActions from 'store/modules/Program/actions.js'

const initialState = {
  searchResults: [],
  requestsSearchResults: [],
  programs: [],
  searchFilters: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case programActions.SET_PROGRAMS_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      }

    case programActions.SET_PROGRAM_REQUESTS_SEARCH_RESULTS:
      return {
        ...state,
        requestsSearchResults: action.payload,
      }

    case programActions.UPDATE_PROGRAM_CACHE_SET:
      return {
        ...state,
        programs: action.payload,
      }

    case programActions.UPDATE_SEARCH_PROGRAM_FILTERS:
      return {
        ...state,
        searchFilters: action.payload,
      }
    default:
      return state
  }
}
