export function hasLanguageChanged(propsUser, prevPropsUser) {
  if (propsUser && propsUser.language) {
    if (!prevPropsUser || !prevPropsUser.language) {
      return true
    }

    if (prevPropsUser.language.code !== propsUser.language.code) {
      return true
    }
  }

  return false
}
