import * as physicalTestActions from 'store/modules/PhysicalTest/actions.js'

const initialState = {
  searchResults: [],
  physicalTests: [],
  searchFilters: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case physicalTestActions.SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      }

    case physicalTestActions.UPDATE_CACHE_SET:
      return {
        ...state,
        physicalTests: action.payload,
      }

    case physicalTestActions.DELETE_BY_ID:
      return {
        searchResults: {
          ...state.searchResults,
          physical_tests:
            state.searchResults.physical_tests &&
            state.searchResults.physical_tests.filter((t) => t.id !== action.deleted_id),
        },
        physicalTests: state.physicalTests.filter((t) => t.id !== action.deleted_id),
      }

    case physicalTestActions.UPDATE_SEARCH_TEST_FILTERS:
      return {
        ...state,
        searchFilters: action.payload,
      }

    default:
      return state
  }
}
