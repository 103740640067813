import * as folderActions from 'store/modules/Folder/actions.js'

const initialState = {
  topLevelFolders: {
    exercises: [],
    sessions: [],
    programs: [],
  },
  folders: {
    exercises: {},
    sessions: {},
    programs: {},
  },
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case folderActions.SET_FOLDER:
      return {
        ...state,
        folders: {
          ...state.folders,
          [action.folder_id]: action.payload,
        },
      }

    case folderActions.SET_TOP_LEVEL_FOLDERS:
      return {
        ...state,
        topLevelFolders: {
          ...state.topLevelFolders,
          [action.payload.folder_type]: action.payload.folders,
        },
      }

    case folderActions.UPDATE_FOLDER:
      return {
        ...state,
        folders: action.payload,
      }

    default:
      return state
  }
}
