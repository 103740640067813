import * as accountActions from 'store/modules/Account/actions'

const initialState = {
  user: {
    roles: [],
  },
  isLoggedIn: false,
  role: null,
  languages: [],
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case accountActions.SET_LOGIN_REQUEST_STATUS:
      return {
        ...state,
        loginError: action.payload.error,
        loginRequestStatus: action.payload.status,
      }

    case accountActions.SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      }

    case accountActions.SET_LOGGEDIN_STATE:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload.user,
      }

    case accountActions.SET_CURRENT_ROLE_SET:
      return {
        ...state,
        role: action.payload,
      }

    case accountActions.SET_LOGGEDOUT_STATE:
      return initialState

    case accountActions.SET_REGISTER_REQUEST_STATUS:
      return {
        ...state,
        registerError: action.payload.error,
        registerRequestStatus: action.payload.status,
      }

    case accountActions.SET_REGISTERED_STATE:
      return {
        ...state,
        user: action.payload.user,
      }

    case accountActions.SET_REGISTER_CONFIRM_EMAIL_STATUS:
      return {
        ...state,
        confirmEmailError: action.payload.error,
        confirmEmailRequestStatus: action.payload.status,
      }

    default:
      return state
  }
}
