import React, { Component } from 'react'

import './style.css'

class AdminTableRow extends Component {
  render() {
    return (
      <tr
        className={`admin-table-row ${this.props.className || ''} ${
          this.props.onClick ? 'clickable-row' : ''
        }`}
        onClick={this.props.onClick}
      >
        {this.props.row.columns.map((column, index) => {
          return (
            <td key={`table_row_row_${index}`} className={`${column.className}`}>
              {column.value}
            </td>
          )
        })}
      </tr>
    )
  }
}

AdminTableRow.defaultProps = {
  row: {
    columns: [],
  },
}

export default AdminTableRow
