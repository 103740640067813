import React from 'react'

export default function getStatusErrors(sRef, statusStore) {
  if (!statusStore[sRef]) return
  if (!statusStore[sRef].data) return
  if (!statusStore[sRef].data.errors) return

  const { errors } = statusStore[sRef].data
  let toReturn = ''

  if (typeof errors === 'object') {
    Object.keys(errors).map((key) => {
      const item = errors[key]
      toReturn += item + '<br />'

      return null
    })
  }

  if (Array.isArray(errors)) {
    errors.map((key) => {
      const item = errors[key]
      toReturn += item + '<br />'

      return null
    })
  }

  return <div dangerouslySetInnerHTML={{ __html: toReturn }} />
}

export function getResponseErrors(errors) {
  if (!errors) return

  let toReturn = []

  if (errors.error) {
    toReturn.push(<div> {errors.error} </div>)
  }

  if (errors.errors && typeof errors.errors === 'object') {
    Object.keys(errors.errors).map((key) => {
      const item = errors.errors[key]
      toReturn.push(<div>{item}</div>)

      return null
    })
  }

  return toReturn
}
