import React, { Component } from 'react'
import './style.css'

class Icon extends Component {
  render() {
    const imgSrc = require('./assets/' + this.props.src)
    return <img src={imgSrc} className={`icon ${this.props.className}`} alt={this.props.src} />
  }
}

export default Icon
