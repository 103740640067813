import { takeEvery, takeLatest, put, select, cancelled } from 'redux-saga/effects'

import * as ApiService from 'services/api'

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions.js'
import { ProtectedCall } from 'services/protected.api'

import * as physicalTestActions from 'store/modules/PhysicalTest/actions.js'

/*************************************************/
/** Selectors **/
const allSelector = (state) => {
  return state.PhysicalTest.physicalTests
}

/*************************************************/
/** Sagas **/
export default function* sagaWatcher() {
  yield takeEvery(physicalTestActions.CREATE_PHYSICAL_TEST, createPhysicalTestSaga)
  yield takeEvery(physicalTestActions.UPDATE_PHYSICAL_TEST, updatePhysicalTestSaga)
  yield takeEvery(physicalTestActions.DELETE_PHYSICAL_TEST, deletePhysicalTestSaga)
  yield takeLatest(physicalTestActions.PHYSICAL_TEST_SEARCH, searchPhysicalTestsSaga)
  yield takeEvery(physicalTestActions.TEST_FETCH_SUBMIT, testFetchSaga)
  yield takeEvery(physicalTestActions.UPDATE_TEST_CACHE, updateCacheSaga)
}

function* createPhysicalTestSaga(data) {
  try {
    const { callback = { success: () => null } } = data.payload
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating physical test',
      })
    )

    let result = yield ProtectedCall(ApiService.CreatePhysicalTest, data.payload)

    yield put({
      type: physicalTestActions.UPDATE_TEST_CACHE,
      payload: [result.data.data.physical_test],
    })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating physical test created',
        data: { testId: result.data.data.physical_test.id },
      })
    )
    callback.success(result.data.data.physical_test.id)
  } catch (e) {
    console.log('*** Error caught in createPhysicalTestSaga ***', e)
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Creating physical test creation error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* updatePhysicalTestSaga(data) {
  try {
    const { callback = { success: () => null } } = data.payload
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Update physical test' })
    )

    let result = yield ProtectedCall(ApiService.UpdatePhysicalTests, data.payload.id, data.payload)

    yield put({
      type: physicalTestActions.UPDATE_TEST_CACHE,
      payload: [result.data.data.physical_test],
    })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Physical test updated',
      })
    )
    callback.success()
  } catch (e) {
    console.log('*** Error caught in updatePhysicalTestSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Physical test update error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* deletePhysicalTestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Delete physical test' })
    )

    yield ProtectedCall(ApiService.DeletePhysicalTest, data.payload.id)

    yield put({ type: physicalTestActions.DELETE_BY_ID, deleted_id: data.payload.id })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Physical test deleted',
      })
    )
  } catch (e) {
    console.log('*** Error caught in deletePhysicalTestSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Physical test delete error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* searchPhysicalTestsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'searching physical tests',
      })
    )

    let result = yield ProtectedCall(ApiService.GetPhysicalTests, data.payload)

    yield put({ type: physicalTestActions.SET_SEARCH_RESULTS, payload: result.data.data })

    yield put({
      type: physicalTestActions.UPDATE_TEST_CACHE,
      payload: result.data.data.physical_tests,
    })
  } catch (e) {
    console.log('*** Error caught in searchPhysicalTestsSaga ***')
    yield put({ type: physicalTestActions.SET_SEARCH_RESULTS, payload: [] })
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'searching physical tests error',
        state: 'error',
      })
    )
  } finally {
    if (yield cancelled()) {
      yield put(
        decrementStatusCreator({
          statusRef: data.payload.statusRef,
          message: 'searching physical tests cancelled',
        })
      )
    } else {
      yield put(
        decrementStatusCreator({
          statusRef: data.payload.statusRef,
          message: 'searching physical tests finished',
        })
      )
    }
  }
}

function* testFetchSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'fetching test' })
    )

    let result = yield ProtectedCall(ApiService.GetPhysicalTest, data.payload)

    yield put({
      type: physicalTestActions.UPDATE_TEST_CACHE,
      payload: [result.data.data.physical_test],
    })

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'test fetched' })
    )
  } catch (e) {
    console.log('*** Error caught in testFetchSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'test fetch error',
        state: 'error',
      })
    )
  }
}

function* updateCacheSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return
  }

  let physicalTests = yield select(allSelector) || []

  if (data.delete) {
    data.payload.map((item) => {
      let id = item
      if (typeof id === 'object') {
        id = id.id
      }

      // id = parseInt(id);
      let physicalTest = physicalTests[id]
      let index = physicalTests.indexOf(physicalTest)
      if (index > -1) {
        physicalTests.splice(index, 1)
      }

      return null
    })
  } else {
    data.payload.map((item) => {
      physicalTests[item.id] = item

      return null
    })
  }

  yield put({ type: physicalTestActions.UPDATE_CACHE_SET, payload: physicalTests })
}
