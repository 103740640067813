export const CREATE_PROGRAM_REQUEST = 'program/CREATE_PROGRAM_REQUEST'
export const UPDATE_PROGRAM = 'program/UPDATE_PROGRAM'

export const PROGRAM_SEARCH_SUBMIT = 'program/PROGRAM_SEARCH_SUBMIT'
export const PROGRAM_FETCH_SUBMIT = 'program/PROGRAM_FETCH_SUBMIT'
export const UPDATE_PROGRAM_CACHE = 'program/UPDATE_PROGRAM_CACHE'
export const UPDATE_PROGRAM_CACHE_SET = 'program/UPDATE_PROGRAM_CACHE_SET'
export const SET_PROGRAMS_SEARCH_RESULTS = 'program/SET_PROGRAMS_SEARCH_RESULTS'
export const SET_PROGRAM_REQUESTS_SEARCH_RESULTS = 'program/SET_PROGRAM_REQUESTS_SEARCH_RESULTS'
export const PROGRAM_MAKE_PUBLIC_REQUEST = 'program/PROGRAM_MAKE_PUBLIC_REQUEST'
export const PROGRAM_DELETE_REQUEST = 'program/PROGRAM_DELETE_REQUEST'
export const PROGRAM_ADD_CYCLE_REQUEST = 'program/PROGRAM_ADD_CYCLE_REQUEST'
export const REMOVE_PROGRAM_CYCLE = 'program/REMOVE_PROGRAM_CYCLE'

export const CYCLE_ADD_SESSION_REQUEST = 'program/CYCLE_ADD_SESSION_REQUEST'
export const CYCLE_ADD_CHALLENGE_REQUEST = 'program/CYCLE_ADD_CHALLENGE_REQUEST'

export const UPDATE_PROGRAM_CYCLE = 'program/UPDATE_PROGRAM_CYCLE'
export const UPDATE_CYCLE_SESSION = 'program/UPDATE_CYCLE_SESSION'

export const CYCLE_REMOVE_SESSION_REQUEST = 'program/CYCLE_REMOVE_SESSION_REQUEST'
export const CYCLE_REMOVE_CHALLENGE_REQUEST = 'program/CYCLE_REMOVE_CHALLENGE_REQUEST'
export const UPDATE_SEARCH_PROGRAM_FILTERS = 'program/UPDATE_SEARCH_PROGRAM_FILTERS'

export function createProgramCreator(payload) {
  return { type: CREATE_PROGRAM_REQUEST, payload: payload }
}

export function updateProgram(payload) {
  return { type: UPDATE_PROGRAM, payload: payload }
}

export function programSearchCreator(payload) {
  return { type: PROGRAM_SEARCH_SUBMIT, payload: payload }
}

export function addCycleToProgramCreator(payload) {
  return { type: PROGRAM_ADD_CYCLE_REQUEST, payload: payload }
}

export function updateProgramCycle(payload) {
  return { type: UPDATE_PROGRAM_CYCLE, payload: payload }
}

export function removeProgramCycle(payload) {
  return { type: REMOVE_PROGRAM_CYCLE, payload: payload }
}

export function updateCycleSession(payload) {
  return { type: UPDATE_CYCLE_SESSION, payload: payload }
}

export function addSessionToCycleCreator(payload) {
  return { type: CYCLE_ADD_SESSION_REQUEST, payload: payload }
}

export function removeSessionToCycleCreator(payload) {
  return { type: CYCLE_REMOVE_SESSION_REQUEST, payload: payload }
}

export function addChallengeToCycle(payload) {
  return { type: CYCLE_ADD_CHALLENGE_REQUEST, payload: payload }
}

export function removeChallengeToCycle(payload) {
  return { type: CYCLE_REMOVE_CHALLENGE_REQUEST, payload: payload }
}

export function updateProgramSearchFilters(payload) {
  return { type: UPDATE_SEARCH_PROGRAM_FILTERS, payload }
}
