import * as emailActions from 'store/modules/Email/actions.js'

const initialState = {
  emails: {},
  searchResults: {
    emails: [],
  },
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case emailActions.UPDATE_EMAILS_CACHE_SET:
      return {
        ...state,
        emails: action.payload,
      }

    case emailActions.UPDATE_EMAIL_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      }

    default:
      return state
  }
}
