export const EQUIPMENT_GET_ALL = 'equipment/EQUIPMENT_GET_ALL'
export const CREATE_EQUIPMENT_REQUEST = 'equipment/CREATE_EQUIPMENT_REQUEST'
export const UPDATE_EQUIPMENT = 'equipment/UPDATE_EQUIPMENT'
export const EQUIPMENT_DELETE_REQUEST = 'equipment/EQUIPMENT_DELETE_REQUEST'

export const UPDATE_EQUIPMENT_CACHE = 'equipment/UPDATE_EQUIPMENT_CACHE'
export const UPDATE_EQUIPMENT_CACHE_SET = 'equipment/UPDATE_EQUIPMENT_CACHE_SET'

export function getAllEquipment(payload) {
  return { type: EQUIPMENT_GET_ALL, payload: payload }
}

export function createEquipment(payload) {
  return { type: CREATE_EQUIPMENT_REQUEST, payload: payload }
}

export function updateEquipment(payload) {
  return { type: UPDATE_EQUIPMENT, payload: payload }
}

export function deleteEquipment(payload) {
  return { type: EQUIPMENT_DELETE_REQUEST, payload: payload }
}
