import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import ReactCropperJs from 'ddm-react-cropperjs'
import { withTranslation } from 'react-i18next'

import Button from 'components/Button'
import './style.css'

class ImagePicker extends Component {
  constructor() {
    super()

    this.state = {
      inputSrc: '',
      cropperProps: {
        guides: true,
        zoomable: false,
        viewMode: 1,
        toggleDragModeOnDblclick: false,
        dragMode: 'crop',
        autoCrop: true,
        imgType: 'image/png',
      },
    }

    this.handleEditClick = this.handleEditClick.bind(this)
    this.inputChange = this.inputChange.bind(this)
    this.handleCrop = this.handleCrop.bind(this)
    this.saveButtonClick = this.saveButtonClick.bind(this)
    this.cancelButtonClick = this.cancelButtonClick.bind(this)
  }

  componentDidMount() {
    if (this.props.initialImage) {
      this.setState({
        inputSrc: this.props.initialImage,
        dataUrl: this.props.initialImage,
      })
    }

    if (this.props.aspectRatio) {
      this.setState({
        cropperProps: {
          ...this.state.cropperProps,
          aspectRatio: this.props.aspectRatio,
        },
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.aspectRatio !== prevProps.aspectRatio) {
      this.setState({
        cropperProps: {
          ...this.state.cropperProps,
          aspectRatio: this.props.aspectRatio,
        },
      })
    }

    if (this.props.initialImage !== prevProps.initialImage) {
      this.setState({
        inputSrc: this.props.initialImage,
        dataUrl: this.props.initialImage,
      })
    }
  }

  handleEditClick() {
    this.setState({ isEditing: true })
  }

  handleCrop = (event, dataUrl) => {
    // this.setState({eventDetails: event.detail});
    this.setState({ dataUrl: dataUrl })
    this.setState({ showSaveButton: true })
  }

  saveButtonClick() {
    this.props.handleSave(this.state.dataUrl)
    this.setState({ isEditing: false })
  }

  cancelButtonClick() {
    this.props.handleCancel()
    this.setState({
      isEditing: false,
      dataUrl: this.props.initialImage,
    })
  }

  inputChange(e) {
    e.preventDefault()

    // Make sure one file was selected
    if (!e.target.files || e.target.files.length !== 1) {
      console.error('[Uploader:listen] Select only one file.')
    }

    let file = e.target.files[0]
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (elem) => {
      this.setState({ inputSrc: elem.target.result })
      this.setState({ showSaveButton: true })
    }
  }

  render() {
    const { t } = this.props

    return (
      <div className={`image-picker ${this.props.className}`}>
        <div className="cropperjs-wrapper">
          <div className="preview-container"></div>

          {!this.state.isEditing && (
            <div>
              {this.state.dataUrl && (
                <img src={this.state.dataUrl} className="non-editing-image" alt={'picked'} />
              )}

              <div className="edit-text" onClick={this.handleEditClick}>
                {this.props.editText}
              </div>
            </div>
          )}

          {this.state.isEditing && (
            <ReactModal
              className="modal-image-picker modal-content image-picker-modal"
              isOpen={true}
              onRequestClose={this.cancelButtonClick}
              shouldCloseOnOverlayClick={true}
              overlayClassName="modal-overlay"
            >
              <ReactCropperJs
                // eslint-disable-next-line react/no-string-refs
                ref="cropper"
                src={this.state.inputSrc}
                crop={this.handleCrop}
                {...this.state.cropperProps}
              />

              <input
                type="file"
                name="cropped_photo"
                onChange={this.inputChange}
                accept="image/jpeg,image/png,image/gif"
              />

              <label htmlFor="cropped_photo">+</label>

              <Button onClick={this.cancelButtonClick}>{t('GLOBAL.cancel')}</Button>

              {this.state.showSaveButton && (
                <Button onClick={this.saveButtonClick} disabled={!this.state.showSaveButton}>
                  {t('GLOBAL.save')}
                </Button>
              )}
            </ReactModal>
          )}
        </div>
      </div>
    )
  }
}

ImagePicker.propTypes = {
  handleCancel: PropTypes.func,
}

ImagePicker.defaultProps = {
  handleCancel: () => {},
}

export default withTranslation()(ImagePicker)
