import React, { Component } from 'react'

import AdminTableRow from './components/AdminTableRow'
import './style.css'

class AdminTable extends Component {
  render() {
    return (
      <table className={`admin-table ${this.props.className}`}>
        <thead>
          <tr>
            {this.props.headerRow.columns &&
              this.props.headerRow.columns.map((item, index) => {
                return (
                  <th key={`table_header_row_${index}`} className={`${item.className}`}>
                    {item.value}
                  </th>
                )
              })}
          </tr>
        </thead>

        <tbody>
          {this.props.rows &&
            this.props.rows.map((row, index) => {
              return <AdminTableRow row={row} key={`table_row_${index}`} onClick={row.onClick} />
            })}
        </tbody>
      </table>
    )
  }
}

AdminTable.defaultProps = {
  headerRow: {},
  rows: [],
}

export default AdminTable
