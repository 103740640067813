import { put, call, takeLatest } from 'redux-saga/effects'

import * as ApiService from 'services/api'
import { setToken, clearToken } from 'services/token'
import { SET_LOGGEDOUT_STATE } from 'store/modules/Account/actions.js'

import * as authActions from 'store/modules/Auth/actions.js'

/*************************************************/
/** Sagas **/
export default function* sagaWatcher() {
  yield takeLatest(authActions.UPDATE_TOKEN, updateToken)
}

function* updateToken() {
  try {
    const { data } = yield call(ApiService.RefreshToken)
    yield call(setToken, data.data)
    yield put({ type: authActions.UPDATE_TOKEN_SUCCESS })
  } catch (e) {
    console.log('*** Error caught in auth.js/updateToken *** ', e)
    yield call(clearToken)
    yield put({
      type: SET_LOGGEDOUT_STATE,
    })
    yield put({ type: authActions.UPDATE_TOKEN_ERROR })
  }
}
