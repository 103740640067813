import moment from 'moment'
import t from './translate'

export function normalizeTime(value, format, spaced) {
  if (value === undefined || value === null || value === '') return

  switch (format) {
    case 'seconds':
      return convertSeconds(value, false, spaced)
    case 'secondsRounded':
      return convertSeconds(value, true, spaced)
    case 'minutes':
      return convertMinutes(value)
    case 'hours_minutes':
      return convertHoursMinutes(value)
    case 'date':
      return formatDate(value)
    case 'datetime':
      return formatDatetime(value)
    case 'time':
      return formatTime(value)
    case 'formattedDifference':
      return formattedDifference(value)
    default:
      console.log('Error : format not allowed')
      break
  }
}

export function formatDate(datetime) {
  return moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')
}

export function formatDatetime(datetime) {
  return moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm')
}

export function formatTime(time) {
  return moment(time, 'HH:mm:ss').format('HH:mm')
}

export function convertSeconds(time, secondRounded, spaced) {
  time = Number(time)
  var h = Math.floor(time / 3600)
  var m = Math.floor((time % 3600) / 60)
  var s = Math.floor((time % 3600) % 60)

  if (secondRounded && s > 30) {
    m += 1
  }

  var hDisplay
  var sDisplay
  var mDisplay

  if (!spaced) {
    hDisplay = h > 0 ? h + 'h ' : ''
    sDisplay = s > 0 ? s + 's' : ''
    mDisplay = m > 0 ? m + 'min ' : ''
  } else {
    hDisplay = h > 0 ? h + ' h ' : ''
    sDisplay = s > 0 ? s + ' s' : ''
    mDisplay = m > 0 ? m + ' min ' : ''
  }

  return secondRounded ? hDisplay + mDisplay : hDisplay + mDisplay + sDisplay
}

export function convertMinutes(time) {
  time = Number(time)
  var h = Math.floor((time % 3600) / 60)
  var m = Math.floor((time % 3600) % 60)

  var hDisplay = h > 0 ? h + 'H' : ''
  var mDisplay = m > 0 ? m + ' min' : ''
  return hDisplay + mDisplay
}

export function convertHoursMinutes(time) {
  time = Number(time)
  var h = Math.floor((time % 3600) / 60)
  var m = Math.floor((time % 3600) % 60)

  var formattedH = ('0' + h).slice(-2)
  var formattedM = ('0' + m).slice(-2)

  return formattedH + 'H' + formattedM
}

export function formattedDifference(datetime) {
  const today = moment()
  const messageDateTime = moment(datetime)
  const isCurrentDay = messageDateTime.isSame(today, 'day')

  if (isCurrentDay) {
    return messageDateTime.format('HH:mm')
  }

  return `${t('Services.time.ago')} ${today.diff(messageDateTime, 'days')} ${t(
    'Services.time.days'
  )}`
}
