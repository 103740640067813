import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import getTranslations from 'helpers/get-translations'

import FormGeneric from 'components/FormGeneric'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'

import Input from 'containers/Input'

import './style.css'

const baseClass = 'new-equipment-form'

const statusRef = 'PageEquipmentNew'

class PageEquipmentNew extends Component {
  constructor() {
    super()

    this.state = {
      form: {
        name: {},
      },
    }

    this.handleInputTranslationChange = this.handleInputTranslationChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.setInitialData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.statuses[statusRef]) {
      if (
        this.props.statuses[statusRef].state === 'finished' &&
        prevProps.statuses[statusRef].state !== 'finished'
      ) {
        this.props.onFormSubmitSuccess()
      }
    }

    if (prevProps.initialData !== this.props.initialData) {
      this.setInitialData()
    }
  }

  setInitialData() {
    if (!this.props.initialData) return

    let id = this.props.initialData.id || null
    let name = getTranslations(this.props.initialData.translations, 'name')

    this.setState({
      form: {
        name,
        id,
      },
    })
  }

  handleInputTranslationChange = (event, values) => {
    const { target } = event
    const { name } = target

    if (name.includes('.')) {
      let splitName = name.split('.')
      this.setState({
        form: {
          ...this.state.form,
          [splitName[0]]: values,
        },
      })
    }
  }

  handleSubmit() {
    this.props.handleSubmit({
      ...this.state.form,
      statusRef,
    })
  }

  render() {
    const { t } = this.props

    return (
      <div className={`${baseClass}`}>
        <FormGeneric className="model-new-equipment" onSubmit={this.handleSubmit}>
          <div className={`${baseClass}-form-title`}>
            <h1>{t('PageEquipment.new-edit')}</h1>
          </div>

          <div className="row-container">
            <label>{t('PageEquipment.subtitle-1')}</label>
            <div className="left-indent">
              <Input
                type="text"
                name="name"
                value={this.state.form.name || {}}
                onChange={this.handleInputTranslationChange}
                placeholder={t('PageEquipment.subtitle-1')}
                autoComplete="false"
                required={true}
                maxLength={191}
                isTranslatable={true}
              />
            </div>
          </div>

          <Button type="submit" onClick={() => {}} status={this.props.statuses[statusRef]}>
            {t('GLOBAL.submit')}
          </Button>

          <ErrorMessage status={this.props.statuses[statusRef]} />
        </FormGeneric>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PageEquipmentNew))
