/**
 * Given a string it will return the nested object by splitting the string
 * and traversing through the object.
 *
 * @param {String} string to split and use to traverse object
 * @param {Object} the object which will have the nested element returned from
 * @param {String} the delimiter used to split the string
 * @returns {Object} the nested object found or an empty object
 */

const getNestedObject = (objectPath, theObject, delimiter) => {
  try {
    let _objectPath = objectPath

    // If the delimiter exists at the beginning of the string then remove it
    if (objectPath.indexOf(delimiter) === 0) {
      _objectPath = objectPath.substring(delimiter.length)
    }

    return _objectPath.split(delimiter).reduce((o, key) => o[key], theObject)
  } catch (e) {
    return null
  }
}

export default getNestedObject
