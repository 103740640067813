import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getLocale } from 'services/localisation'

import TranslationTabs from 'components/TranslationTabs'

import { staffLanguagesCode } from 'constants/constants'

import './style.css'

const baseClass = 'input'

class Input extends Component {
  constructor() {
    super()

    this.state = {
      lang: getLocale() || 'fr',
    }

    this.handleLanguageChange = this.handleLanguageChange.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  handleLanguageChange(lang) {
    this.setState({
      lang,
    })
  }

  getInputProps(language) {
    const props = {}

    Object.entries(this.props).forEach(([key, value]) => {
      if (key === 'required') {
        if (staffLanguagesCode.includes(language.code) && language.code === 'fr') {
          props[key] = value
        }
      } else {
        props[key] = value
      }
    })

    return props
  }

  onChange(event, item) {
    const { languages, onChange, value } = this.props
    const inputValues = {}
    languages.forEach((language) => {
      if (item.code === language.code) {
        inputValues[language.code] = event.target.value
      } else if (Object.prototype.hasOwnProperty.call(value, language.code)) {
        inputValues[language.code] = value[language.code]
      } else {
        inputValues[language.code] = null
      }
    })
    onChange(event, inputValues)
  }

  render() {
    return (
      <div className={`${baseClass}`}>
        {this.props.isTranslatable && (
          <TranslationTabs
            className={`${baseClass}-${this.props.className}-tabs`}
            currentLanguage={this.state.lang}
            handleLanguageChange={this.handleLanguageChange}
            languages={this.props.languages}
          />
        )}

        {this.props.languages.map((item, index) => {
          return (
            <input
              key={`input-${index}`}
              {...this.getInputProps(item)}
              onChange={(event) => this.onChange(event, item)}
              name={`${this.props.name}.${item.code}`}
              value={this.props.value[item.code] || ''}
              className={`${this.props.className} ${baseClass}-input-element ${
                item.code === this.state.lang ? 'active-element' : ''
              } ${this.props.isTranslatable ? 'isTranslatable' : 'notTranslatable'}`}
            />
          )
        })}
      </div>
    )
  }
}

Input.propTypes = {
  isTranslatable: PropTypes.bool,
}

Input.defaultProps = {
  isTranslatable: false,
}

const mapStateToProps = (state) => ({
  theState: state,
  languages: state.Account.languages,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(Input)
