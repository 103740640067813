export const GET_ALL = 'GET_ALL'
export const GET_LIST_PRESCRIBERS = 'GET_LIST_PRESCRIBERS'

export const UPDATE_CACHE_PRESCRIBERS = 'UPDATE_CACHE_PRESCRIBERS'
export const UPDATE_CACHE_PRESCRIBERS_SET = 'UPDATE_CACHE_PRESCRIBERS_SET'
export const UPDATE_CACHE_PRESCRIBERS_LIST_SET = 'UPDATE_CACHE_PRESCRIBERS_LIST_SET'

export function getAllPrescribers(payload) {
  return { type: GET_ALL, payload: payload }
}

export function getListPrescribers(payload) {
  return { type: GET_LIST_PRESCRIBERS, payload: payload }
}
