import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './style.css'

const baseClass = 'pagination'

class Pagination extends Component {
  constructor() {
    super()

    this.state = {
      currentPage: 1,
      offset: 0,
    }

    this.nextPage = this.nextPage.bind(this)
    this.prevPage = this.prevPage.bind(this)
    this.goToPage = this.goToPage.bind(this)
    this.getPages = this.getPages.bind(this)
  }

  componentDidMount() {
    if (this.props.offset) {
      this.setState({
        offset: this.props.offset,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.offset !== this.props.offset) {
      this.setState({
        offset: this.props.offset,
      })
    }
  }

  nextPage() {
    let offset = this.state.offset + this.props.limit
    if (offset >= this.props.total) return

    this.setState(
      {
        offset: offset,
      },
      () => {
        this.props.fetchData({
          ...this.props.searchData,
          offset: this.state.offset,
        })
      }
    )
  }

  prevPage() {
    let offset = this.state.offset
    if (offset <= 0) return

    this.setState(
      {
        offset: offset - this.props.limit,
      },
      () => {
        this.props.fetchData({
          ...this.props.searchData,
          offset: this.state.offset,
        })
      }
    )
  }

  goToPage(currentPage) {
    if (currentPage === (this.state.offset + this.props.limit) / this.props.limit) return

    let offset = currentPage * this.props.limit - this.props.limit

    this.setState(
      {
        offset: offset,
        currentPage: currentPage,
      },
      () => {
        this.props.fetchData({
          ...this.props.searchData,
          offset: this.state.offset,
        })
      }
    )
  }

  getPages() {
    let totalPages = Math.ceil(this.props.total / this.props.limit)

    let currentPage = this.state.offset / this.props.limit

    if (totalPages > 1) {
      var toReturn = new Array(totalPages).fill(0).map((unused, index) => (
        <div
          key={index}
          className={`${baseClass}-num ${currentPage === index ? 'active' : ''}`}
          onClick={() => this.goToPage(index + 1)}
        >
          {`${index + 1}`}
        </div>
      ))
    }

    return toReturn
  }

  render() {
    return (
      <div className={`${baseClass}`}>
        {this.props.total > this.props.limit && (
          <div className={`${baseClass}-prev`} onClick={this.prevPage}>
            {'<<'}
          </div>
        )}

        {this.getPages()}

        {this.props.total > this.props.limit && (
          <div className={`${baseClass}-next`} onClick={this.nextPage}>
            {'>>'}
          </div>
        )}
      </div>
    )
  }
}

Pagination.propTypes = {
  limit: PropTypes.number,
  offset: PropTypes.number,
  total: PropTypes.number,
  fetchData: PropTypes.func,
}

Pagination.defaultProps = {
  limit: 50,
  offset: 0,
  total: 0,
}

export default Pagination
