import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import FormExerciseCreate from 'components/FormExerciseCreate'

import { updateExercise, EXERCISE_FETCH_SUBMIT } from 'store/modules/Exercise/actions.js'
import { FETCH_EXERCISES_RESOURCES_REQUEST } from 'store/modules/Resource/actions.js'

import { clearStatus } from 'store/modules/Status/actions.js'

import './style.css'

const statusRef = 'PageExerciseEdit'

class PageExerciseEdit extends Component {
  constructor() {
    super()
    this.state = {
      form: {
        public: 'false',
        name: {},
        interests: [],
        recommendations: [],
        step: [],
        muscles: [],
        equipment: [],
      },
    }
  }

  componentDidMount() {
    const { resources } = this.props
    this.props.dispatch(clearStatus({ statusRef }))

    this.setState({ exerciseId: Number(this.props.match.params.exerciseId) }, () => {
      const { exerciseId } = this.state
      const { exercise } = this.props
      if (exercise.id !== exerciseId) {
        this.props.dispatch({ type: EXERCISE_FETCH_SUBMIT, payload: exerciseId })
      }
    })

    if (
      !resources ||
      !resources.objectives.length ||
      !resources.positions.length ||
      !resources.positions.length
    ) {
      this.props.dispatch({ type: FETCH_EXERCISES_RESOURCES_REQUEST })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.statuses[statusRef] && this.props.statuses[statusRef].state === 'finished') {
      if (prevProps.statuses[statusRef].state !== 'finished') {
        setTimeout(() => {
          this.props.history.push(`/exercise/${this.state.exerciseId}`)
        }, 700)
      }
    }
  }

  handleSubmit = (form) => {
    this.props.dispatch(
      updateExercise({
        ...form,
        exerciseId: this.state.exerciseId,
        statusRef,
      })
    )
  }

  getStepsToUpdate(steps, originalSteps) {
    if (JSON.stringify(steps) === JSON.stringify(originalSteps)) return

    let changedSteps = []

    ;(steps || []).map((item, index) => {
      let isIdentical = true
      const originalItem = originalSteps[index]

      if (!originalItem) {
        return changedSteps.push(item)
      }

      if (originalItem.id !== item.id) isIdentical = false
      if (originalItem.in_gif !== item.in_gif) isIdentical = false
      if (item.imageData) isIdentical = false
      if (originalItem.description !== (item.description || {}).fr) isIdentical = false

      if (isIdentical) return

      return changedSteps.push(item)
    })

    return changedSteps
  }

  render() {
    const { exercise, resources, statuses } = this.props
    return (
      <div className="page-exercise-edit">
        <FormExerciseCreate
          resources={resources}
          submitStatus={statuses[statusRef]}
          handleSubmit={this.handleSubmit}
          initialData={exercise}
        />
      </div>
    )
  }
}

PageExerciseEdit.defaultProps = {
  exercise: {},
  resources: {},
  statuses: {},
}

const mapStateToProps = (state) => ({
  exercise: state.Exercise.exercise,
  resources: state.Resource.exercises,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageExerciseEdit))
