import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { connect } from 'react-redux'

import FormModal from 'components/FormModal'
import FormExerciseCreate from 'components/FormExerciseCreate'
import CloseButton from 'components/CloseButton'

import { updateExercise, EXERCISE_FETCH_SUBMIT } from 'store/modules/Exercise/actions.js'
import { FETCH_EXERCISES_RESOURCES_REQUEST } from 'store/modules/Resource/actions.js'
import { clearStatus } from 'store/modules/Status/actions.js'

import './style.css'

const statusRef = 'ModalExerciseEdit'

class ModalExerciseEdit extends Component {
  constructor() {
    super()
    this.state = {
      form: {
        public: 'false',
        name: {},
        interets: [],
        recommendations: [],
        step: [],
        muscles: [],
        equipment: [],
      },
    }
  }

  componentDidUpdate(prevProps) {
    const { exerciseId } = this.props
    if (exerciseId && exerciseId !== prevProps.exerciseId) {
      this.setState(
        {
          exerciseId,
        },
        () => this.props.dispatch({ type: EXERCISE_FETCH_SUBMIT, payload: exerciseId })
      )

      this.props.dispatch({ type: FETCH_EXERCISES_RESOURCES_REQUEST })
      this.props.dispatch(clearStatus({ statusRef: statusRef }))
    }
    if (
      this.props.statuses[statusRef] &&
      this.props.statuses[statusRef].state === 'finished' &&
      prevProps.statuses[statusRef].state !== 'finished'
    ) {
      this.props.handleCloseModal()
    }
  }

  handleSubmit = (form) => {
    this.props.dispatch(
      updateExercise({
        ...form,
        exerciseId: this.state.exerciseId,
        statusRef: statusRef,
      })
    )
  }

  getStepsToUpdate(steps, originalSteps) {
    if (JSON.stringify(steps) === JSON.stringify(originalSteps)) return

    let changedSteps = []

    ;(steps || []).map((item, index) => {
      let isIdentical = true
      const originalItem = originalSteps[index]

      if (!originalItem) {
        return changedSteps.push(item)
      }

      if (originalItem.id !== item.id) isIdentical = false
      if (originalItem.in_gif !== item.in_gif) isIdentical = false
      if (item.imageData) isIdentical = false
      if (originalItem.description !== (item.description || {}).fr) isIdentical = false

      if (isIdentical) return

      return changedSteps.push(item)
    })

    return changedSteps
  }

  render() {
    const { isOpen, handleCloseModal, resources, statuses, exercise } = this.props
    return (
      <ReactModal
        className="modal-exercise-edit-success modal-content"
        isOpen={isOpen}
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick={true}
        overlayClassName="modal-overlay"
      >
        <CloseButton onClick={handleCloseModal} />

        <FormModal id="modal-exercise-edit" className="login-form transparent" onSubmit={() => {}}>
          <FormExerciseCreate
            isModal={true}
            resources={resources}
            submitStatus={statuses[statusRef]}
            handleSubmit={this.handleSubmit}
            initialData={exercise}
            handleCancel={handleCloseModal}
          />
        </FormModal>
      </ReactModal>
    )
  }
}

const mapStateToProps = (state) => ({
  resources: state.Resource.exercises,
  statuses: state.Status.statuses,
  exercise: state.Exercise.exercise,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalExerciseEdit)
