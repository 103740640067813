import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import Button from 'components/Button'
import ChallengeCard from 'components/ChallengeCard'
import CardPicker from 'components/CardPicker'

import { challengeSearchCreator } from 'store/modules/Challenge/actions.js'
import { FETCH_CHALLENGES_RESOURCES_REQUEST } from 'store/modules/Resource/actions.js'

import './style.css'

const statusRefSearch = 'FormProgramCreate-ChallengeList-search'

class ChallengesList extends Component {
  constructor() {
    super()
    this.state = {
      challenges: [],
      searchData: {},
      showCardModal: false,
    }

    this.handleAddChallengeClick = this.handleAddChallengeClick.bind(this)
    this.handleSearchChallengesClick = this.handleSearchChallengesClick.bind(this)
    this.fetchChallenges = this.fetchChallenges.bind(this)
    this.handleCloseCardModal = this.handleCloseCardModal.bind(this)
  }

  componentDidMount() {
    this.props.dispatch({ type: FETCH_CHALLENGES_RESOURCES_REQUEST })
  }

  handleAddChallengeClick(challenge) {
    let challenges = this.props.challenges || []
    challenges.push(challenge)
    this.setState(
      {
        challenges: challenges,
      },
      () => {
        this.props.handleChallengeChange(this.state.challenges, this.props.cycleIndex)
      }
    )
  }

  handleSearchChallengesClick() {
    this.setState(
      {
        showCardModal: true,
      },
      () => this.props.dispatch(challengeSearchCreator({ public: 1, statusRef: statusRefSearch }))
    )
  }

  handleCloseCardModal() {
    this.setState({
      showCardModal: false,
    })
  }

  fetchChallenges(data) {
    if (!data) return

    this.setState(
      {
        searchData: data,
      },
      () => {
        this.props.dispatch(
          challengeSearchCreator({
            ...data,
            ...data.multiSelect,
            search: data.search_text,
            public: true,
            statusRef: statusRefSearch,
          })
        )
      }
    )
  }

  changeStartAfter(e, index) {
    let value = e.target.value
    value = parseInt(value, 10) || 0

    let challenges = this.props.challenges

    challenges[index] = {
      ...challenges[index],
      start_after: value,
    }

    this.props.handleChallengeChange(challenges, this.props.cycleIndex)
  }

  searchFilteroptions() {
    const { t } = this.props

    const resources = this.props.resources
    let toReturn = []

    if (resources.objectives) {
      toReturn.push({
        label: t('PageChallenges.SearchFilters.objectives'),
        name: 'objectives',
        value: 'objectives',
        options: resources.objectives.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.muscles) {
      toReturn.push({
        label: t('PageChallenges.SearchFilters.muscles'),
        name: 'muscles',
        value: 'muscles',
        options: resources.muscles.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.positions) {
      toReturn.push({
        label: t('PageChallenges.SearchFilters.positions'),
        name: 'positions',
        value: 'positions',
        options: resources.positions.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.equipment) {
      toReturn.push({
        label: t('PageChallenges.SearchFilters.equipment'),
        name: 'equipment',
        value: 'equipment',
        options: resources.equipment.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.levels) {
      toReturn.push({
        label: t('PageChallenges.levels'),
        name: 'levels',
        value: 'levels',
        options: resources.levels.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    return toReturn
  }

  render() {
    const { t } = this.props

    return (
      <div className={`modal-create-program-cycle-challenges-container ${this.props.className}`}>
        <div className="modal-create-program-cycle-challenges-container-title">
          {t('FormProgramCreate.CyclesContainer.ChallengesList.title')}
        </div>

        {this.props.challenges &&
          this.props.challenges.map((challenge, index) => {
            return (
              <div className="modal-create-program-cycle-challenge" key={index}>
                <ChallengeCard item={challenge} />

                <div className="modal-create-program-cycle-challenge-delete-container">
                  <Button
                    onClick={() => this.props.handleChallengeRemove(this.props.cycleIndex, index)}
                  >
                    {t('GLOBAL.delete')}
                  </Button>
                </div>
              </div>
            )
          })}

        <div>
          <Button onClick={this.handleSearchChallengesClick}>
            {t('FormProgramCreate.CyclesContainer.ChallengesList.button')}
          </Button>
        </div>

        {this.state.showCardModal && (
          <CardPicker
            type={'challenge'}
            handleClose={this.handleCloseCardModal}
            searchResults={this.props.searchResults.challenges}
            limit={this.props.searchResults.limit}
            offset={this.props.searchResults.offset}
            total={this.props.searchResults.total_count}
            searchData={this.state.searchData}
            searchFilteroptions={this.searchFilteroptions()}
            searchFilterType={'multi-select'}
            filterSubmit={this.fetchChallenges}
            handleSubmit={this.handleAddChallengeClick}
            status={this.props.statuses[statusRefSearch]}
            selectionLimit={1}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  searchResults: state.Challenge.searchResults,
  resources: state.Resource.challenges,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChallengesList))
