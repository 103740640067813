import { takeLatest, put, takeEvery, select } from 'redux-saga/effects'

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions.js'

import * as ApiService from 'services/api'
import { ProtectedCall } from 'services/protected.api'

import * as userActions from 'store/modules/User/actions.js'

/*************************************************/
/** Selectors **/
const allUsersSelector = (state) => {
  return JSON.parse(JSON.stringify(state.User.users))
}

/*************************************************/
/** Sagas **/
export default function* sagaWatcher() {
  yield takeLatest(userActions.USER_ADMIN_SEARCH, adminUserSearchSaga)
  yield takeEvery(userActions.GET_USER_BY_ID, getUserByIdSaga)
  yield takeEvery(userActions.GET_USER_COACH, getUserCoachSaga)
  yield takeLatest(userActions.SET_USER_AVATAR_REQUEST, setUserAvatarSaga)
}

function* adminUserSearchSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Admin user search' })
    )

    let result = yield ProtectedCall(ApiService.AdminUserSearch, data.payload)
    yield put({ type: userActions.USER_ADMIN_SEARCH_RESULTS, payload: result.data.data })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Admin user search finished',
      })
    )
  } catch (e) {
    console.log('*** Error caught in fetchAllUsersSaga ***', e)
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Admin user search error',
        state: 'error',
      })
    )
  }
}

function* getUserByIdSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting user by id' })
    )

    const result = yield ProtectedCall(ApiService.GetUser, data.payload)

    yield put({ type: userActions.UPDATE_CACHE, payload: [result.data.data.user] })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting user by idfinished',
      })
    )
  } catch (e) {
    console.log('*** Error caught in getUserByIdSaga ***', e)
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting user by id error',
        state: 'error',
      })
    )
  }
}

function* getUserCoachSaga(data) {
  try {
    if (!data || !data.payload || !data.payload.user_id) return

    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting user coach' })
    )

    const result = yield ProtectedCall(ApiService.GetUserCoach, data.payload.user_id)

    let users = yield select(allUsersSelector) || {}
    let user = users[data.payload.user_id] || { id: data.payload.user_id }

    user.coaches = result.data.data.coaches

    yield put({ type: userActions.UPDATE_CACHE, payload: [user] })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting user coach finished',
      })
    )
  } catch (e) {
    console.log('*** Error caught in getUserCoachSaga ***', e)
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting user coach error',
        state: 'error',
      })
    )
  }
}

function* setUserAvatarSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Setting user avatar' })
    )

    let userId = data.data.userId
    let imageData = {
      filename: 'avatar_' + userId + '.png',
      imageData: data.data.dataUrl,
    }

    yield ProtectedCall(ApiService.setUserAvatar, userId, imageData)

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Setting user avatar finished',
      })
    )
  } catch (e) {
    console.log('*** Error caught in setUserAvatarSaga ***', e)
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Setting user avatar error',
        state: 'error',
      })
    )
  }
}

// function* updateCacheSaga(data) {
//   if (!data || !data.payload || data.payload.length === 0) {
//     return
//   }
//
//   let users = yield select(allUsersSelector) || {}
//
//   data.payload.map((item) => {
//     users[item.id] = item
//
//     return null
//   })
//
//   yield put({ type: UPDATE_CACHE_SET, payload: users })
// }
