import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import './style.css'

class PageArticles extends Component {
  render() {
    const { t } = this.props

    return <div className="page-articles">{t('PageArticles.text')}</div>
  }
}

export default withTranslation()(PageArticles)
