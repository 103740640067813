import React, { Component } from 'react'
import isEqual from 'lodash/isEqual'
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Image as ImagePdf,
  Document,
  StyleSheet,
} from '@react-pdf/renderer'

import { withTranslation } from 'react-i18next'

import { normalizeTime } from 'services/time'

import { WITHOUT_MATERIAL } from 'services/equipment'

import './style.css'
import Button from 'components/Button/index'
import { getLocale } from 'services/localisation'
import getTranslations from 'helpers/get-translations'

const baseClass = 'page-session-printer'

const imageUrl = process.env.REACT_APP_STORAGE_URL

const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    backgroundColor: '#B6F0E0',
    margin: 10,
    padding: 3,
    color: '#020F56',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol33: {
    width: '30%',
    backgroundColor: '#001588',
    color: 'white',
    padding: 5,
    margin: 10,
  },
  tableCol50: {
    width: '50%',
    padding: 10,
  },
  tableCol100: {
    width: '100%',
    padding: 10,
  },
  tableCell: {
    marginTop: 5,
    marginLeft: 10,
    fontSize: 10,
  },
  image_container: {
    padding: 0,
  },
  instructions: {
    backgroundColor: '#027fc3',
    color: 'white',
    fontSize: 10,
    padding: 5,
    height: 100,
  },
  instructionsSuffix: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  exercise_name: {
    fontSize: 18,
    width: '100%',
    textAlign: 'left',
  },
  label: {
    fontSize: 12,
    fontWeight: 900,
    width: '100%',
    textAlign: 'left',
  },
  tableCellSplited: {
    display: 'inline-flex',
    flexDirection: 'row',
    textAlign: 'center',
  },
  tableCellSplitedContainer: {
    width: '25%',
    padding: 3,
    borderStyle: 'solid',
    borderRightWidth: 1,
    borderColor: '#027fc3',
  },
  tableCellSplitedElement: {
    fontSize: 10,
  },
  tableCellSplitedLabel: {
    fontSize: 8,
    color: '#027fc3',
    paddingBottom: 2,
    height: 25,
  },
  sessionTitle: {
    fontSize: 25,
    color: '#020F56',
    textTransform: 'uppercase',
  },
  sessionLabel: {
    marginTop: 20,
    fontSize: 14,
    color: '#027fc3',
  },
  sessionInformations: {
    fontsize: 10,
    color: '#020F56',
    width: '95%',
  },
})

const MyDocument = (props) => {
  const { t, content } = (props || {}).props || {}
  const session = content || {}

  const locale = getLocale()
  const description = getTranslations(session.translations, 'description')?.[locale]
  const recommendations = getTranslations(session.translations, 'recommendations')?.[locale]

  let sessionExercises = []

  {
    ;(session.session_exercises || []).map((session_exercise) => {
      ;((session_exercise.exercise || {}).equipment || []).map((item) => {
        if (!sessionExercises.includes(item.name) && item.id !== WITHOUT_MATERIAL) {
          sessionExercises.push(item.name)
        }
      })
    })
  }

  return (
    <Document>
      <Page>
        <View style={styles.session}>
          <View>
            <Text style={[styles.tableCell, styles.sessionTitle]}>{session.name}</Text>
          </View>
          <View>
            <Text style={[styles.tableCell, styles.sessionLabel]}>
              {t('FormSessionCreate.label-2')}
            </Text>
            <Text style={[styles.tableCell, styles.sessionInformations]}>{description}</Text>
          </View>
          <View>
            <Text style={[styles.tableCell, styles.sessionLabel]}>
              {t('FormSessionCreate.label-7')}
            </Text>
            <Text style={[styles.tableCell, styles.sessionInformations]}>{recommendations}</Text>
          </View>
          <View>
            <Text style={[styles.tableCell, styles.sessionLabel]}>
              {t('ExerciseAdditionalInfo.title-3')}
            </Text>
            <Text style={[styles.tableCell, styles.sessionInformations]}>
              {sessionExercises.map((sessionExercise, index) => {
                return `${index > 0 ? ', ' : ''}${sessionExercise}`
              })}
            </Text>
          </View>
          <View>
            <Text style={[styles.tableCell, styles.sessionLabel]}>
              {t('SessionDetails.subtitle-2')}
            </Text>
            <Text style={[styles.tableCell, styles.sessionInformations]}>{`${normalizeTime(
              session.duration,
              'secondsRounded'
            )}`}</Text>
          </View>
          <View>
            <Text style={[styles.tableCell, styles.sessionLabel]}>
              {t('SessionDetails.subtitle-1')}
            </Text>
            <Text style={[styles.tableCell, styles.sessionInformations]}>
              {(session.level || {}).name}
            </Text>
          </View>
        </View>
        <View style={styles.exercises}>
          {(session.session_exercises || []).map((session_exercise, idx) => {
            return (
              <View key={idx} style={styles.table} wrap={false}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol50}>
                    <Text style={styles.exercise_name}>
                      {((session_exercise || {}).exercise || {}).name}
                    </Text>
                  </View>
                  <View style={styles.tableCol50}>
                    <View style={styles.tableCellSplited}>
                      <View style={[styles.tableCellSplitedContainer, { borderLeftWidth: 1 }]}>
                        <Text style={styles.tableCellSplitedLabel}>
                          {t('PageSessionsEdit.ExercisesContainer.subtitle-3')}
                        </Text>
                        <Text style={styles.tableCellSplitedElement}>
                          {(session_exercise || {}).series}
                        </Text>
                      </View>
                      <View style={styles.tableCellSplitedContainer}>
                        <Text style={styles.tableCellSplitedLabel}>
                          {t('PageSessionsEdit.ExercisesContainer.subtitle-4')}
                        </Text>
                        <Text style={styles.tableCellSplitedElement}>
                          {isEqual(session_exercise?.repetitions, 9999)
                            ? t('PageSession.SessionPrinter.max-repetitions')
                            : session_exercise?.repetitions}
                        </Text>
                      </View>
                      <View style={styles.tableCellSplitedContainer}>
                        <Text style={styles.tableCellSplitedLabel}>
                          {t('PageSessionsEdit.ExercisesContainer.subtitle-5')}
                        </Text>
                        <Text style={styles.tableCellSplitedElement}>{`${normalizeTime(
                          (session_exercise || {}).duration,
                          'seconds'
                        )}`}</Text>
                      </View>
                      <View style={[styles.tableCellSplitedContainer, { borderRightWidth: 0 }]}>
                        <Text style={styles.tableCellSplitedLabel}>
                          {t('PageSessionsEdit.ExercisesContainer.subtitle-6')}
                        </Text>
                        <Text
                          style={[styles.tableCellSplitedElement, { borderRightWidth: 0 }]}
                        >{`${normalizeTime((session_exercise || {}).rest_time, 'seconds')}`}</Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  {(((session_exercise || {}).exercise || {}).steps || []).map((step, idx) => {
                    let nbImages =
                      (((session_exercise || {}).exercise || {}).steps || []).filter((item) => {
                        return item.photo
                      }).length || 0
                    let containerLength = 500

                    if (nbImages < 5) nbImages = 5

                    let containerWidth = containerLength / nbImages
                    let containerHeight = 170
                    let imageHeight = 120

                    let imageMarginTop = 0
                    let imageMarginBottom = 0

                    let widthInPt = ((step.photo_dimensions || {}).width || 0) * 0.75
                    let heightInPt = ((step.photo_dimensions || {}).height || 0) * 0.75

                    let imageWidth = (widthInPt / heightInPt) * imageHeight || containerWidth

                    if (imageWidth >= containerWidth) {
                      imageWidth = containerWidth
                      imageHeight = (heightInPt * imageWidth) / widthInPt
                      imageMarginTop = 20
                      imageMarginBottom = 20
                    }

                    return (
                      <View
                        key={idx}
                        style={
                          (styles.image_container,
                          {
                            width: containerWidth,
                            height: containerHeight,
                            marginBottom: 100,
                            marginRight: 5,
                            backgroundColor: 'white',
                            display: 'flex',
                          })
                        }
                      >
                        <ImagePdf
                          style={{
                            width: imageWidth,
                            height: imageHeight,
                            alignSelf: 'center',
                            marginTop: imageMarginTop,
                            marginBottom: imageMarginBottom,
                          }}
                          src={`${imageUrl}${step.photo}`}
                        />
                        <View>
                          <Text style={styles.instructions}>
                            <Text style={styles.instructionsSuffix}>{`${step.position}  `}</Text>
                            {step.description || ''}
                          </Text>
                        </View>
                      </View>
                    )
                  })}
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol33}>
                    <Text style={styles.label}>{t('ExerciseAdditionalInfo.title-4')}</Text>
                    <Text style={styles.tableCell}>
                      {((session_exercise || {}).exercise || {}).objective_details}
                    </Text>
                  </View>
                  <View style={styles.tableCol33}>
                    <Text style={styles.label}>{t('ExerciseAdditionalInfo.title-2')}</Text>
                    <Text style={styles.tableCell}>
                      {((session_exercise || {}).exercise || {}).recommendations}
                    </Text>
                  </View>
                  <View style={[styles.tableCol33, { borderRightWidth: 0 }]}>
                    <Text style={styles.label}>{t('ExerciseAdditionalInfo.title-3')}</Text>
                    <Text style={styles.tableCell}>
                      {(((session_exercise || {}).exercise || {}).equipment || []).map(
                        (item, index) => {
                          return item.id === WITHOUT_MATERIAL
                            ? ''
                            : `${index > 0 ? ', ' : ''}${item.name}` // Don't display "without material"
                        }
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            ) // end-table
          })}
        </View>
      </Page>
    </Document>
  )
}

class SessionPrinter extends Component {
  constructor() {
    super()

    this.state = {
      canShowButtonDownloadPdf: false,
      componentDidMount: false,
      loaded: false,
    }

    this.getImagesDimension = this.getImagesDimension.bind(this)
    this.getPdfDownloadLink = this.getPdfDownloadLink.bind(this)
  }

  componentDidMount() {
    if (this.props.content) {
      this.getImagesDimension()
    }

    this.setState({
      componentDidMount: true,
    })
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.content, this.props.content) && this.props.content.session_exercises) {
      this.getImagesDimension()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isEqual(nextState, this.state) || !isEqual(nextProps.content, this.props.content))
      return true
    return false
  }

  getImagesDimension() {
    let totalImages = 0
    let loaded = 0
    let self = this

    ;(this.props.content.session_exercises || []).map((session_exercise) => {
      totalImages += (((session_exercise || {}).exercise || {}).steps || []).length
      ;(((session_exercise || {}).exercise || {}).steps || []).map((step) => {
        let img = new Image()
        img.src = `${imageUrl}${step.photo}`
        img.onload = function () {
          if (!(step.photo_dimensions || {}).height || !(step.photo_dimensions || {}).width) {
            step.photo_dimensions = {
              height: img.height,
              width: img.width,
            }

            loaded++

            if (loaded >= totalImages) {
              self.setState({
                loaded: true,
              })
            }
          }
        }

        img.onerror = function () {
          loaded++

          if (loaded >= totalImages) {
            self.setState({
              loaded: true,
            })
          }
        }
      })
    })
  }

  getPdfDownloadLink(session, t) {
    if (!this.state.loaded) {
      return <Button>{t('PageSession.SessionPrinter.load_pdf')}</Button>
    }

    return (
      <PDFDownloadLink
        className={`button-download-pdf ${session.id ? 'visible' : 'hidden'}`}
        document={<MyDocument props={this.props} />}
        fileName={`${session.id}-${session.name}.pdf`}
      >
        {({ loading }) => {
          return loading
            ? t('PageSession.SessionPrinter.load_pdf')
            : t('PageSession.SessionPrinter.download_pdf')
        }}
      </PDFDownloadLink>
    )
  }

  render() {
    const session = this.props.content || {}
    const { t } = this.props

    return (
      <div className={`${baseClass}`}>
        {this.state.componentDidMount && this.getPdfDownloadLink(session, t)}
        {/* {this.state.componentDidMount && 
        <PDFViewer>
          <MyDocument 
            props={this.props}
          />
        </PDFViewer>} */}
      </div>
    )
  }
}

export default withTranslation()(SessionPrinter)
