import { takeEvery, takeLatest, put, select, cancelled } from 'redux-saga/effects'

import * as ApiService from 'services/api'

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions.js'
import { ProtectedCall } from 'services/protected.api'

import * as sessionActions from 'store/modules/Session/actions.js'
/*************************************************/
/** Selectors **/
const allSessionsSelector = (state) => {
  return state.Session.sessions
}

/*************************************************/
/** Sagas **/
export default function* sagaWatcher() {
  yield takeEvery(sessionActions.CREATE_SESSION_REQUEST, createSessionRequestSaga)
  yield takeEvery(sessionActions.DUPLICATE_SESSION, dupicateSessionSaga)
  yield takeEvery(sessionActions.UPDATE_SESSION, updateSessionSaga)
  yield takeEvery(sessionActions.SESSION_MAKE_PUBLIC_REQUEST, makePublicRequestSaga)
  yield takeEvery(sessionActions.SESSION_DELETE_REQUEST, deleteExerciseRequestSaga)
  yield takeEvery(sessionActions.SESSION_DELETE_PERMANENTLY, deleteSessionPermanentlySaga)
  yield takeLatest(sessionActions.SESSION_SEARCH_SUBMIT, sessionSearchSaga)
  yield takeEvery(sessionActions.UPDATE_SESSION_CACHE, updateSessionCacheSaga)
  yield takeEvery(sessionActions.SESSION_FETCH_SUBMIT, sessionFetchSaga)
  // yield takeLatest(EXERCISE_STEP_SET_IMAGE_REQUEST, setExerciseStepImage);
}

function* createSessionRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Creating session' })
    )

    let result = yield ProtectedCall(ApiService.CreateSession, data.payload)
    const { session } = result.data.data

    yield put({ type: sessionActions.UPDATE_SESSION_CACHE, payload: session })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'session created',
        data: { newId: session.id },
      })
    )
  } catch (e) {
    console.log('*** Error caught in createSessionRequestSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'session creation error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* dupicateSessionSaga(data) {
  const { callback = { success: () => null } } = data.payload
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Duplicating session' })
    )

    let result = yield ProtectedCall(ApiService.DuplicateSession, data.payload)
    const { session } = result.data.data

    yield put({ type: sessionActions.UPDATE_SESSION_CACHE, payload: session })
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Duplicate of the session created',
        data: { duplicateId: session.id },
      })
    )
    callback.success({ duplicateId: session.id })
  } catch (e) {
    console.log('*** Error caught in duplicateSessionSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'session duplication error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* updateSessionSaga(data) {
  const { callback = { success: () => null } } = data.payload
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Updating session' })
    )

    let result = yield ProtectedCall(ApiService.UpdateSession, data.payload.sessionId, data.payload)
    const { session } = result.data.data
    yield put({ type: sessionActions.UPDATE_SESSION_CACHE, payload: session })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating session finished',
      })
    )
    callback.success()
  } catch (e) {
    console.log('*** Error caught in updateSessionSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating session error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* makePublicRequestSaga(data) {
  const { callback = { success: () => null } } = data.payload
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Making session public',
      })
    )

    const result = yield ProtectedCall(ApiService.SessionUpdate, data.payload.id, { public: 1 })
    const { session } = result.data.data

    yield put({ type: sessionActions.UPDATE_SESSION_CACHE, payload: session })

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'session made public' })
    )
    callback.success()
  } catch (e) {
    console.log('*** Error caught in session.makePublicRequestSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'session making public error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* deleteExerciseRequestSaga(data) {
  const { callback = { success: () => null } } = data.payload
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Deleting session' })
    )

    yield ProtectedCall(ApiService.SessionDelete, data.payload.id)

    yield put({ type: sessionActions.UPDATE_SESSIONS_CACHE, payload: [data.payload], delete: true })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting session complete',
      })
    )
    callback.success()
  } catch (e) {
    console.log('*** Error caught in session deleteExerciseRequestSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting session error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* deleteSessionPermanentlySaga(data) {
  const { callback = { success: () => null } } = data.payload
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Deleting permanently session',
      })
    )

    yield ProtectedCall(ApiService.SessionDelete, data.payload.session_id)

    yield put({
      type: sessionActions.UPDATE_SESSIONS_CACHE,
      payload: [data.payload],
      delete: true,
      statusRef: data.payload.statusRef,
    })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Delete permanently session complete',
      })
    )
    callback.success()
  } catch (e) {
    console.log('*** Error caught in deleteSessionPermanentlySaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Delete permanently session error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* sessionSearchSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Searching sessions' })
    )

    let result = yield ProtectedCall(ApiService.SessionSearch, data.payload)

    if ((data.payload || {}).public_request === 1) {
      yield put({
        type: sessionActions.SET_SESSION_REQUESTS_SEARCH_RESULTS,
        payload: result.data.data,
      })
    } else {
      yield put({ type: sessionActions.SET_SESSIONS_SEARCH_RESULTS, payload: result.data.data })
    }

    yield put({ type: sessionActions.UPDATE_SESSIONS_CACHE, payload: result.data.data.sessions })
  } catch (e) {
    console.log('*** Error caught in sessionSearchSaga ***')
    yield put({ type: sessionActions.SET_SESSIONS_SEARCH_RESULTS, payload: [] })
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Searching sessions error',
        state: 'error',
      })
    )
  } finally {
    const { callback = { onSuccess: () => null } } = data.payload
    if (yield cancelled()) {
      yield put(
        decrementStatusCreator({
          statusRef: data.payload.statusRef,
          message: 'Searching sessions cancelled',
        })
      )
    } else {
      yield put(
        decrementStatusCreator({
          statusRef: data.payload.statusRef,
          message: 'Searching sessions complete',
        })
      )
      callback.onSuccess()
    }
  }
}

function* sessionFetchSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Fetching session' })
    )

    let result = yield ProtectedCall(ApiService.SessionFetch, data.payload)
    const { session } = result.data.data

    yield put({ type: sessionActions.UPDATE_SESSION_CACHE, payload: session })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Fetching session complete',
      })
    )
  } catch (e) {
    console.log('*** Error caught in sessionFetchSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Fetching session error',
        state: 'error',
      })
    )
  }
}

function* updateSessionCacheSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) return

  let sessions = yield select(allSessionsSelector) || []

  if (data.delete) {
    data.payload.map((item) => {
      let id = item
      if (typeof id === 'object') {
        id = id.id
      }

      // id = parseInt(id);
      let session = sessions[id]
      let index = sessions.indexOf(session)
      if (index > -1) {
        sessions.splice(index, 1)
      }

      return null
    })
    yield put({ type: sessionActions.UPDATE_SESSIONS_CACHE_SET, payload: sessions })
  } else {
    yield put({ type: sessionActions.UPDATE_SESSION_CACHE_SET, payload: data.payload })
  }
}
