import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import FormChallengeCreate from 'components/FormChallengeCreate'

import { CHALLENGE_FETCH_SUBMIT, updateChallenge } from 'store/modules/Challenge/actions.js'
import {
  FETCH_CHALLENGES_RESOURCES_REQUEST,
  FETCH_SESSIONS_RESOURCES_REQUEST,
} from 'store/modules/Resource/actions.js'
import { clearStatus } from 'store/modules/Status/actions.js'

import './style.css'

const statusRef = 'PageChallengeEdit'

class PageChallengeEdit extends Component {
  constructor() {
    super()
    this.state = {}

    this.handleSubmit = this.handleSubmit.bind(this)
    this.exercisesToUpdate = this.exercisesToUpdate.bind(this)
  }

  //TODO: Getting called on app initialisation. Why? Should only get called on modal open.
  componentDidMount() {
    this.props.dispatch(clearStatus({ statusRef }))

    this.setState({ challengeId: this.props.match.params.challengeId }, () => {
      this.props.dispatch({
        type: CHALLENGE_FETCH_SUBMIT,
        payload: this.state.challengeId,
      })
    })

    this.props.dispatch({ type: FETCH_CHALLENGES_RESOURCES_REQUEST })
    this.props.dispatch({ type: FETCH_SESSIONS_RESOURCES_REQUEST })
  }

  componentDidUpdate(prevProps) {
    if (this.props.statuses[statusRef] && this.props.statuses[statusRef].state === 'finished') {
      if (prevProps.statuses[statusRef].state !== 'finished') {
        setTimeout(() => {
          this.props.history.push(`/challenge/${this.state.challengeId}`)
        }, 700)
      }
    }
  }

  handleSubmit(challengeForm, exercises) {
    this.props.dispatch(
      updateChallenge({
        ...challengeForm,
        exercises: this.exercisesToUpdate(
          exercises,
          ...(this.props.challenges[this.state.challengeId] || {}).challenge_exercises
        ),
        statusRef,
      })
    )
  }

  exercisesToUpdate(exercises, originalExercises) {
    if (JSON.stringify(exercises) === JSON.stringify(originalExercises)) return

    let changedExercises = []

    exercises.map((item, index) => {
      const originalItem = originalExercises[index]

      if (JSON.stringify(item) === JSON.stringify(originalItem)) return

      changedExercises.push({
        ...item,
        exercise_id: item.exercise.id,
      })
    })

    return changedExercises
  }

  render() {
    return (
      <div className="page-challenges-create">
        <FormChallengeCreate
          resources={this.props.resources}
          resourcesSessions={this.props.resourcesSessions}
          statusSubmit={this.props.statuses[statusRef]}
          handleSubmit={this.handleSubmit}
          initialData={this.props.challenges[this.state.challengeId]}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  activeModal: state.Modal.activeModal,
  role: state.Account.role,
  resources: state.Resource.challenges,
  resourcesSessions: state.Resource.sessions,
  statuses: state.Status.statuses,
  challenges: state.Challenge.challenges || {},
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageChallengeEdit))
