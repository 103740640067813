import React, { Component } from 'react'

import './style.css'

import Image from 'components/Image'

class Logo extends Component {
  constructor(props) {
    super(props)
    // Don't call this.setState() here!
    this.state = {
      hamburgerActive: false,
    }

    this.hamburgerClick = this.hamburgerClick.bind(this)
  }

  hamburgerClick(e) {
    e.stopPropagation()

    this.setState((prevState) => ({
      hamburgerActive: !prevState.hamburgerActive,
    }))
  }

  render() {
    return (
      <div
        className={`header-hamburger ${this.state.hamburgerActive ? 'active' : ''}`}
        onClick={this.hamburgerClick}
      >
        <div className="header-hamburger-icon">
          <Image src="hamburger.svg" className="image-header-hamburger"></Image>
        </div>

        <div className="header-hamburger-inner">{this.props.children}</div>
      </div>
    )
  }
}

export default Logo
