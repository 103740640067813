import React, { Component } from 'react'

import { withTranslation } from 'react-i18next'

import PlacesAutocomplete from 'components/PlacesAutocomplete'
// import PropTypes from 'prop-types'; TODO

import './style.css'

class Location extends Component {
  constructor() {
    super()

    this.state = {
      place: {},
    }

    this.handlePlaceChange = this.handlePlaceChange.bind(this)
    this.inputChange = this.inputChange.bind(this)
    this.getCountryIdFromPlace = this.getCountryIdFromPlace.bind(this)
  }

  inputChange(e) {
    const { name, value } = e.target

    this.setState(
      {
        place: {
          ...this.state.place,
          [name]: value,
        },
      },
      () => {
        this.props.onChange(this.state.place)
      }
    )
  }

  handlePlaceChange(place) {
    if (!place) return

    this.setState(
      {
        place: {
          ...this.state.place,
          address: place.address,
          city: place.locality,
          zip_code: place.postal_code,
          latitude: place.lat,
          longitude: place.long,
          country_id: this.getCountryIdFromPlace(place.country_short),
        },
      },
      () => {
        this.props.onChange(this.state.place)
      }
    )
  }

  getCountryIdFromPlace(countryShortCode) {
    if (!countryShortCode) return null

    let toReturn = null

    ;(this.props.countries || []).map((item) => {
      if (item.code.toLowerCase() === countryShortCode.toLowerCase()) {
        toReturn = item.id
      }
      return null
    })

    return toReturn
  }

  render() {
    const { t } = this.props

    return (
      <div className="location">
        <PlacesAutocomplete
          type="text"
          name="search_place"
          initialValue=""
          onChange={this.handlePlaceChange}
          placeholder={t('Location.placeholder-1')}
          autoComplete="off"
          required={this.props.searchRequired}
          disabled={this.props.disabled}
          maxLength={191}
        />

        {!this.props.hideAddress && (
          <div className="data-row data-row-half">
            <label>{t('Location.label-1')}</label>
            <input
              autoComplete="street-address"
              name="address"
              type="text"
              value={(this.props.data || {}).address || ''}
              onChange={this.inputChange}
              placeholder={t('Location.placeholder-2')}
              required={this.props.required}
              disabled={this.props.disabled}
              maxLength={191}
            />
          </div>
        )}

        {!this.props.hideAddressDetails && (
          <div className="data-row data-row-half">
            <label>{t('Location.label-2')}</label>
            <input
              autoComplete="street-address2"
              name="address_details"
              type="text"
              value={(this.props.data || {}).address_details || ''}
              onChange={this.inputChange}
              placeholder={t('Location.placeholder-3')}
              disabled={this.props.disabled}
              maxLength={191}
            />
          </div>
        )}

        {!this.props.hideCity && (
          <div className="data-row data-row-half">
            <label>{t('Location.label-3')}</label>
            <input
              autoComplete="address-level2"
              name="city"
              type="text"
              value={(this.props.data || {}).city || ''}
              onChange={this.inputChange}
              disabled={true}
              placeholder={t('Location.placeholder-4')}
              maxLength={191}
            />
          </div>
        )}

        {!this.props.hideZip_code && (
          <div className="data-row data-row-half">
            <label>{t('Location.label-4')}</label>
            <input
              autoComplete="postal-code"
              name="zip_code"
              type="text"
              value={(this.props.data || {}).zip_code || ''}
              onChange={this.inputChange}
              disabled={true}
              placeholder={t('Location.placeholder-5')}
              maxLength={191}
            />
          </div>
        )}

        {!this.props.hideCountry && (
          <div className="data-row data-row-half">
            <label>{t('Location.label-5')}</label>
            <select
              autoComplete="country"
              name="country_id"
              value={
                (this.props.data || {}).country_id ||
                ((this.props.data || {}).country || {}).id ||
                ''
              }
              onChange={this.inputChange}
              disabled={true}
            >
              <option value="">{t('Location.placeholder-6')}</option>
              {(this.props.countries || []).map((item, idx) => {
                return (
                  <option key={idx} value={item.id}>
                    {item.name}
                  </option>
                )
              })}
            </select>
          </div>
        )}
      </div>
    )
  }
}

Location.propTypes = {}

Location.defaultProps = {}

export default withTranslation()(Location)
