import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import ReactModal from 'react-modal'

import FormModal from 'components/FormModal'
import Button from 'components/Button'
import CloseButton from 'components/CloseButton'
import ErrorMessage from 'components/ErrorMessage'

import './style.css'

const baseClass = 'page-users-confirm-delete-message'

class ConfirmDeleteMessage extends Component {
  constructor() {
    super()

    this.state = {}
  }

  render() {
    const { t } = this.props

    return (
      <div className={`${baseClass}`}>
        <ReactModal
          className={`${baseClass}-modal modal-content`}
          isOpen={true}
          onRequestClose={this.props.handleCancel}
          shouldCloseOnOverlayClick={true}
          overlayClassName="modal-overlay"
        >
          <CloseButton onClick={this.props.handleCancel} />

          <FormModal className={`${baseClass}-modal-form`} onSubmit={() => {}}>
            <h1>
              {t('ConfirmDeleteMessage.title-1')}
              <u>
                <b>{this.props.data.name}</b>
              </u>{' '}
              {t('ConfirmDeleteMessage.title-2')}
            </h1>

            <h2>{t('ConfirmDeleteMessage.subtitle')}</h2>

            <Button type="button" onClick={this.props.handleCancel}>
              {t('GLOBAL.cancel')}
            </Button>
            <Button
              type="button"
              onClick={() => this.props.handleConfirm(this.props.data)}
              status={this.props.statusRef}
            >
              {t('ConfirmDeleteMessage.button')}
            </Button>

            <ErrorMessage status={this.props.statusRef} />
          </FormModal>
        </ReactModal>
      </div>
    )
  }
}

ConfirmDeleteMessage.propTypes = {
  data: PropTypes.object,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  statusRef: PropTypes.object,
}

ConfirmDeleteMessage.defaultProps = {}

export default withTranslation()(ConfirmDeleteMessage)
