import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './style.css'

class Button extends Component {
  constructor() {
    super()
    this.state = {}

    // this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  isSuccess() {
    if (!this.props.status) return this.props.isSuccess

    return this.props.status.state === 'finished'
  }

  isDisabled() {
    if (!this.props.status) return this.props.isDisabled

    return this.props.status.count > 0
  }

  isError() {
    if (!this.props.status) return this.props.isError

    return this.props.status.state === 'error'
  }

  isLoading() {
    if (!this.props.status) return this.props.isLoading

    return this.props.status.count > 0
  }

  render() {
    return (
      <button
        id={this.props.id}
        className={`button ${this.props.className}`}
        onClick={this.props.onClick}
        type={this.props.type}
        disabled={this.isDisabled() || this.props.disabled}
        data-cy={this.props['data-cy']}
      >
        {this.props.children}

        {this.isLoading() && <span className="status isLoading"> &#9741; </span>}

        {this.isSuccess() && <span className="status"> &#10003; </span>}

        {this.isError() && <span className="status"> &#9888; </span>}
      </button>
    )
  }
}

Button.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,

  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

Button.defaultProps = {
  type: 'button',
  isLoading: false,
  isSuccess: false,
  isError: false,
  isDisabled: false,
}

export default Button
