import { put, select, takeEvery } from 'redux-saga/effects'

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions.js'

import * as ApiService from 'services/api'
import { ProtectedCall } from 'services/protected.api'

import * as prescriberActions from 'store/modules/Prescriber/actions.js'

/*************************************************/
/** Selectors **/
const allPrescribersSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Prescriber.prescribers))
}

/*************************************************/
/** Sagas **/
export default function* sagaWatcher() {
  yield takeEvery(prescriberActions.GET_ALL, getAllPrescribersSaga)
  yield takeEvery(prescriberActions.GET_LIST_PRESCRIBERS, getListPrescribersSaga)

  yield takeEvery(prescriberActions.UPDATE_CACHE_PRESCRIBERS, updateCachePrescribersSaga)
}

function* getAllPrescribersSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting prescriber' })
    )

    let result = yield ProtectedCall(ApiService.GetAllPrescribers, data.payload)

    yield put({
      type: prescriberActions.UPDATE_CACHE_PRESCRIBERS,
      payload: result.data.data.prescribers,
    })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting prescriber finished',
      })
    )
  } catch (e) {
    console.log('*** Error caught in getAllPrescribersSaga ***', e)
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting prescriber error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* getListPrescribersSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting prescriber list',
      })
    )

    let result = yield ProtectedCall(ApiService.GetListPrescribers, data.payload)

    yield put({
      type: prescriberActions.UPDATE_CACHE_PRESCRIBERS_LIST_SET,
      payload: result.data.data.prescribers,
    })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting prescriber list finished',
      })
    )
  } catch (e) {
    console.log('*** Error caught in getListPrescribersSaga ***', e)
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting prescriber list error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* updateCachePrescribersSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return
  }

  let all = yield select(allPrescribersSelector) || {}

  data.payload.map((item) => {
    all[item.id] = item
  })

  yield put({ type: prescriberActions.UPDATE_CACHE_PRESCRIBERS_SET, payload: all })
}
