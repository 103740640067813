import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'

import Button from 'components/Button'
import AdminTableDraggable from 'components/AdminTableDraggable'
import Icon from 'components/Icon'
import ConfirmDeleteMessage from 'components/ConfirmDeleteMessage'

import './style.css'

const baseClass = 'ModalCreateAssessment-programs-custom'

class CustomPrograms extends Component {
  constructor() {
    super()

    this.state = {
      openModalConfirmationDeleteCustomProgramObjective: null,
    }

    this.getCustomProgramsHeaderRow = this.getCustomProgramsHeaderRow.bind(this)
    this.buildCustomProgramsTableRows = this.buildCustomProgramsTableRows.bind(this)
    this.handleRemoveCustomProgramObjectiveFromAssessments = this.handleRemoveCustomProgramObjectiveFromAssessments.bind(
      this
    )
    this.handleOpenModalConfirmationDeleteCustomProgramObjective = this.handleOpenModalConfirmationDeleteCustomProgramObjective.bind(
      this
    )
    this.handleCloseModalConfirmationDeleteCustomProgramObjective = this.handleCloseModalConfirmationDeleteCustomProgramObjective.bind(
      this
    )
    this.getCustomProgramObjectiveOptions = this.getCustomProgramObjectiveOptions.bind(this)
  }

  getCustomProgramsHeaderRow() {
    const { t } = this.props

    return {
      columns: [
        { value: t('PageAssessments.ModalCreateAssessment.subtitle-name') },
        { value: t('GLOBAL.empty') },
      ],
    }
  }

  getCustomProgramObjectiveOptions() {
    const { programObjectives, formLevelObjectives, staticProgramObjectives } = this.props
    const formProgramObjectiveIds = []
    const customProgramObjectiveOptions = Object.keys(staticProgramObjectives).map(
      (staticProgramObjectiveKey) => ({
        value: staticProgramObjectives[staticProgramObjectiveKey].id,
        label: staticProgramObjectives[staticProgramObjectiveKey].name,
      })
    )

    formLevelObjectives.forEach((level) => {
      level.program_objectives.forEach((programObjectiveId) => {
        if (!formProgramObjectiveIds.includes(programObjectiveId))
          formProgramObjectiveIds.push(programObjectiveId)
      })
    })

    formProgramObjectiveIds.forEach((formProgramObjectiveId) => {
      programObjectives.forEach((programObjective) => {
        if (programObjective.id === formProgramObjectiveId) {
          customProgramObjectiveOptions.push({
            value: programObjective.id,
            label: programObjective.name,
          })
        } else {
          programObjective.children.forEach((programObjectiveChild) => {
            if (programObjectiveChild.id === formProgramObjectiveId)
              customProgramObjectiveOptions.push({
                value: programObjectiveChild.id,
                label: `${programObjective.name} - ${programObjectiveChild.name}`,
              })
          })
        }
      })
    })

    return customProgramObjectiveOptions
  }

  buildCustomProgramsTableRows() {
    return ((this.props.custom_programs || {}).programs || []).map((item, index) => {
      return {
        columns: [
          { value: (item.program_objective || {}).name || item.name },
          {
            value: (
              <Button
                className="narrow"
                onClick={() =>
                  this.handleOpenModalConfirmationDeleteCustomProgramObjective(
                    item.program_objective || item,
                    index
                  )
                }
              >
                <Icon className="small" src={'trash.svg'} />
              </Button>
            ),
          },
        ],
      }
    })
  }

  handleOpenModalConfirmationDeleteCustomProgramObjective(item, index) {
    this.setState({
      openModalConfirmationDeleteCustomProgramObjective: { ...item, index },
    })
  }

  handleCloseModalConfirmationDeleteCustomProgramObjective() {
    this.setState({
      openModalConfirmationDeleteCustomProgramObjective: null,
    })
  }

  handleRemoveCustomProgramObjectiveFromAssessments(item) {
    this.props.handleRemoveCustomProgramObjectiveFromAssessments(item)
    this.handleCloseModalConfirmationDeleteCustomProgramObjective()
  }

  render() {
    const { t } = this.props

    return (
      <div className={`${baseClass}`}>
        <div className={`row-container`}>
          <label>
            {t('PageAssessments.ModalCreateAssessment.Programs.CustomPrograms.programs_limit')}
          </label>

          <input
            className={`width-33`}
            type="number"
            name="custom_programs.program_limit"
            value={(this.props.custom_programs || {}).program_limit}
            onChange={this.props.handleInputChange}
            maxLength={191}
          />
        </div>

        <div className={`row-container`}>
          <Select
            onChange={(values) =>
              this.props.handleSelectChange('custom_program_objective_id', values)
            }
            isMulti={false}
            // value={this.state.form.custom_program_objective_id}
            name={'custom_program_objective_id'}
            options={this.getCustomProgramObjectiveOptions()}
            className="search-filter-bar-multi-select search-program"
            classNamePrefix={'react-select'}
            placeholder={t('GLOBAL.search')}
          />

          <Button
            className="button-plus"
            onClick={this.props.handleAddCustomProgramObjectivesIntoAssessments}
            disabled={this.props.custom_program_objective_id ? false : true}
          >
            +
          </Button>

          <AdminTableDraggable
            headerRow={this.getCustomProgramsHeaderRow()}
            rows={this.buildCustomProgramsTableRows()}
            handleDragEnd={this.props.handleCustomProgramsDragEnd}
          />

          {this.state.openModalConfirmationDeleteCustomProgramObjective && (
            <ConfirmDeleteMessage
              data={this.state.openModalConfirmationDeleteCustomProgramObjective}
              handleCancel={this.handleCloseModalConfirmationDeleteCustomProgramObjective}
              handleConfirm={this.handleRemoveCustomProgramObjectiveFromAssessments}
            />
          )}
        </div>
      </div>
    )
  }
}

export default withTranslation()(CustomPrograms)
