import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import './style.css'
import { getLocale } from 'services/localisation'

class TestDetails extends Component {
  constructor() {
    super()

    this.state = {}
  }

  render() {
    const { t } = this.props

    return (
      <div className={`test-details ${this.props.className || ''}`}>
        <div className="test-details-title-container">
          <h1 className="test-details-title">
            {this.props.test.translations && this.props.test.translations[getLocale()].label}
          </h1>
        </div>

        <div className="test-details-info-container">
          <div className="test-details-info-block">
            <div className="test-details-info-block-key">{t('TestDetails.subtitle-1')}</div>
            <div className="test-details-info-block-value">
              {(this.props.test.automatic === 1 || this.props.test.automatic === true) && (
                <span>&#10004;</span>
              )}
            </div>
          </div>

          <div className="test-details-info-block">
            <div className="test-details-info-block-key">{t('TestDetails.subtitle-2')}</div>
            <div className="test-details-info-block-value">
              {(this.props.test.enabled === 1 || this.props.test.enabled === true) && (
                <span>&#10004;</span>
              )}
            </div>
          </div>

          <div className="test-details-info-block">
            <div className="test-details-info-block-key">{t('TestDetails.subtitle-3')}</div>
            <div className="test-details-info-block-value">
              <span>
                {(this.props.test.prescriber || {}).first_name}{' '}
                {(this.props.test.prescriber || {}).last_name}
              </span>
            </div>
          </div>

          <div className="test-details-info-block">
            <div className="test-details-info-block-key">{t('TestDetails.subtitle-4')}</div>

            {this.props.test.levels &&
              this.props.test.levels.map((item, idx) => {
                return (
                  <div key={idx} className="test-details-info-block-value">
                    <span>{item.name}</span>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    )
  }
}

TestDetails.propTypes = {
  test: PropTypes.object,
}

TestDetails.defaultProps = {
  test: {
    cycles: [],
  },
}

export default withTranslation()(TestDetails)
