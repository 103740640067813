import { combineReducers } from 'redux'

import { reducer as Auth } from 'store/modules/Auth'
import { reducer as Account } from 'store/modules/Account'
import { reducer as Modal } from 'store/modules/Modal'
import { reducer as Exercise } from 'store/modules/Exercise'
import { reducer as User } from 'store/modules/User'
import { reducer as Resource } from 'store/modules/Resource'
import { reducer as Session } from 'store/modules/Session'
import { reducer as Challenge } from 'store/modules/Challenge'
import { reducer as Program } from 'store/modules/Program'
import { reducer as Prescriber } from 'store/modules/Prescriber'
import { reducer as Status } from 'store/modules/Status'
import { reducer as PhysicalTest } from 'store/modules/PhysicalTest'
import { reducer as Folder } from 'store/modules/Folder'
import { reducer as Equipment } from 'store/modules/Equipment'
import { reducer as BodyParts } from 'store/modules/BodyParts'
import { reducer as Assessment } from 'store/modules/Assessment'
import { reducer as Email } from 'store/modules/Email'
import { reducer as Notification } from 'store/modules/Notification'

export default combineReducers({
  Auth,
  Account,
  Modal,
  Exercise,
  User,
  Resource,
  Session,
  Challenge,
  Program,
  Prescriber,
  Status,
  PhysicalTest,
  Folder,
  Equipment,
  BodyParts,
  Assessment,
  Email,
  Notification,
})
