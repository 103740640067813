import { getToken } from 'services/token'
import * as modalActions from 'store/modules/Modal/actions.js'

const checkRoute = () => {
  const pathnameArray = window.location.pathname.split('/')

  return pathnameArray.length > 1 ? pathnameArray[1] : ''
}

const getInitialModalValue = () => {
  if (getToken()) return null
  if (checkRoute() !== 'password-reset' && checkRoute() !== 'confirm-email') return 'login'
  return null
}

const initialState = {
  activeModal: getInitialModalValue(),
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case modalActions.SET_ACTIVE_MODAL:
      return {
        ...state,
        activeModal: action.payload,
        modalData: action.data,
      }

    case modalActions.CLOSE_MODALS:
      return {
        ...state,
        activeModal: null,
      }

    default:
      return state
  }
}
