import React, { Component } from 'react'

import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import AdminTableRowDraggable from './components/AdminTableRowDraggable'

import './style.css'

class TBody extends Component {
  render() {
    return (
      <Droppable droppableId="droppable-1">
        {(provided) => (
          // eslint-disable-next-line
          <tbody innerRef={provided.innerRef} ref={provided.innerRef} {...provided.droppableProps}>
            {this.props.rows &&
              this.props.rows.map((row, index) => (
                <AdminTableRowDraggable
                  row={row}
                  key={`table_row_${index}`}
                  index={index}
                  draggableId={`table_row_${index}`}
                />
              ))}

            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    )
  }
}

class AdminTableDraggable extends Component {
  onBeforeDragStart = () => {
    /*...*/
  }

  onDragStart = () => {
    /*...*/
  }
  onDragUpdate = () => {
    /*...*/
  }
  onDragEnd = (result) => {
    // the only one that is required
    this.props.handleDragEnd(result)
  }

  // tableRows = () => {
  //   return <
  // }

  render() {
    return (
      <table className={`admin-table-draggable ${this.props.className}`}>
        <thead>
          {(this.props.headerRow.columns || []).length > 0 && (
            <tr>
              <th className={'drag-handle'}></th>
              {this.props.headerRow.columns.map((item, index) => {
                return <th key={`adminTableDraggable_${index}`}>{item.value}</th>
              })}
            </tr>
          )}
        </thead>

        <DragDropContext
          onBeforeDragStart={this.onBeforeDragStart}
          onDragStart={this.onDragStart}
          onDragUpdate={this.onDragUpdate}
          onDragEnd={this.onDragEnd}
        >
          <TBody rows={this.props.rows} />
        </DragDropContext>
      </table>
    )
  }
}

AdminTableDraggable.defaultProps = {
  headerRow: {},
  rows: [],
}

export default AdminTableDraggable
