import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import isEqual from 'lodash/isEqual'

import Button from 'components/Button'
import AdminTableDraggable from 'components/AdminTableDraggable'
import PostureSelector from './components/PostureSelector'
import QuestionSelector from './components/QuestionSelector'
import CardPicker from 'components/CardPicker'

import { exerciseSearchCreator } from 'store/modules/Exercise/actions.js'
import { FETCH_EXERCISES_RESOURCES_REQUEST } from 'store/modules/Resource/actions.js'

import './style.css'

const baseClass = 'form-challenge-create-exercises'

const statusRefSearch = 'FormTestCreate-Exercises-search'

class Exercises extends Component {
  constructor() {
    super()
    this.state = {
      test_exercises: [],
      searchData: {},
      showCardModal: false,
    }
  }

  componentDidMount() {
    this.props.dispatch({ type: FETCH_EXERCISES_RESOURCES_REQUEST })
    this.setInitialData()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.test_exercises &&
      !isEqual(prevProps.test_exercises, this.props.test_exercises)
    ) {
      this.setInitialData()
    }
  }

  setInitialData = () => {
    this.setState({
      test_exercises: this.props.test_exercises,
    })
  }

  handleAddExerciseClick = (newExercises) => {
    const { test_exercises: exercises } = this.state

    newExercises.map((exercise) => {
      exercises.push({
        exercise,
        exercise_id: exercise.id,
        series: 1,
        repetitions: 1,
        duration: 30,
        rest_time: 30,
        is_posture: (exercise.objective || {}).id === 1,
      })
    })

    const test_exercises = exercises.map((exercise, position) => ({ ...exercise, position }))

    this.setState({
      test_exercises,
    })
    this.props.handleExercisesUpdate(test_exercises)
  }

  handleSearchExercisesClick = () => {
    this.setState(
      {
        showCardModal: true,
      },
      () => this.props.dispatch(exerciseSearchCreator({ public: 1, statusRef: statusRefSearch }))
    )
  }

  handleCloseCardModal = () => {
    this.setState({
      showCardModal: false,
    })
  }

  fetchExercises = (data) => {
    if (!data) return

    this.setState(
      {
        searchData: data,
      },
      () => {
        this.props.dispatch(
          exerciseSearchCreator({
            ...data,
            ...data.multiSelect,
            search: data.search_text,
            public: true,
            statusRef: statusRefSearch,
          })
        )
      }
    )
  }

  exerciseDurationChange = (ev, item) => {
    const { value } = ev.target
    const { test_exercises } = this.state
    const index = test_exercises.indexOf(item)

    if (index < 0) return

    test_exercises[index] = { ...test_exercises[index], duration: value }

    this.setState({
      test_exercises,
    })
  }

  handleRemoveExerciseLine = (index) => {
    const { test_exercises } = this.state

    test_exercises.splice(index, 1)

    this.setState(
      {
        test_exercises,
      },
      () => {
        this.props.handleExercisesUpdate(this.state.test_exercises)
      }
    )
  }

  renderExercisesTable = () => {
    const { t } = this.props

    const headers = {
      columns: [
        { value: t('FormTestCreate.Exercises.subtitle-1') },
        { value: t('FormTestCreate.Exercises.subtitle-2') },
        { value: t('FormTestCreate.Exercises.subtitle-3') },
        { value: t('FormTestCreate.Exercises.subtitle-4') },
      ],
    }

    let tableRows = []

    if (this.state.test_exercises) {
      this.state.test_exercises.map((item, index) => {
        if (typeof item.question == 'string') {
          item.question = { fr: item.question }
        }

        let columns = [
          { value: item.exercise.id },
          { value: item.exercise.name },
          {
            value: (
              <input
                type="number"
                value={item.duration}
                onChange={(e) => this.exerciseDurationChange(e, item)}
              />
            ),
          },
        ]

        if ((item.exercise.objective || {}).id === 1 || item.is_posture === 1) {
          //If flexibility then use postures
          columns.push({
            value: (
              <div>
                <Button onClick={() => this.postureClick(item)}>
                  {t('FormTestCreate.Exercises.button-1')}
                </Button>

                {(item.postures || []).map((posture, idx) => {
                  return (
                    <div key={idx}>
                      <div>{posture.to_reach ? '✔' : ''}</div>
                      <div>{this.postureFromId(posture.id).name}</div>
                    </div>
                  )
                })}
              </div>
            ),
          })
        } else {
          columns.push({
            value: (
              <div>
                <Button onClick={() => this.questionClick(item)}>
                  {t('FormTestCreate.Exercises.button-2')}
                </Button>
              </div>
            ),
          })
        }

        columns.push({
          value: (
            <Button onClick={() => this.handleRemoveExerciseLine(index)}>
              {' '}
              {t('GLOBAL.delete')}{' '}
            </Button>
          ),
        })

        tableRows.push({ columns })
        return null
      })
    }

    return (
      <AdminTableDraggable
        headerRow={headers}
        rows={tableRows}
        handleDragEnd={this.handleRowDragEnd}
      />
    )
  }

  handleRowDragEnd = (data) => {
    const { destination, source } = data

    if (!destination) return

    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    let { test_exercises } = this.state
    let temp = test_exercises[source.index]
    test_exercises.splice(source.index, 1)
    test_exercises.splice(destination.index, 0, temp)

    test_exercises = this.setPositionParams(test_exercises)

    this.setState(
      {
        test_exercises,
      },
      () => {
        this.props.handleExercisesUpdate(this.state.test_exercises)
      }
    )
  }

  setPositionParams = (test_exercises) => {
    if (!test_exercises) return []

    return test_exercises.map((item, index) => {
      return { ...item, position: index + 1 }
    })
  }

  handleTableInputChange = (event, index) => {
    const { target } = event

    let value = target.type === 'checkbox' ? target.checked : target.value
    if (value === 'true' || value === 'false') {
      value = value === 'true'
    }

    const { name } = target

    if (index < 0) return

    const { test_exercises } = this.state
    test_exercises[index] = {
      ...this.state.test_exercises[index],
      [name]: parseInt(value, 10),
    }

    this.setState(
      {
        test_exercises,
      },
      () => {
        this.props.handleExercisesUpdate(this.state.test_exercises)
      }
    )
  }

  postureClick = (postureSelector) => {
    this.setState({
      postureSelector,
    })
  }

  handlePostureSelectorClose = () => {
    this.setState(
      {
        postureSelector: null,
      },
      () => this.props.handleExercisesUpdate(this.state.test_exercises)
    )
  }

  questionClick = (item) => {
    this.setState({
      questionSelector: item,
    })
  }

  handleQuestionSelectorClose = () => {
    this.setState(
      {
        questionSelector: null,
      },
      () => this.props.handleExercisesUpdate(this.state.test_exercises)
    )
  }

  handlePosturesSubmit = (test_answers, question) => {
    const { test_exercises, postureSelector } = this.state
    const index = test_exercises.indexOf(postureSelector)

    if (index < 0) return

    test_exercises[index] = { ...test_exercises[index], test_answers, question }

    this.setState(
      {
        test_exercises,
      },
      this.handlePostureSelectorClose
    )
  }

  handleQuestionSubmit = (question, test_answers) => {
    const { test_exercises, questionSelector } = this.state
    const testExerciseIdx = test_exercises.findIndex(
      ({ exercise_id }) => exercise_id === questionSelector.exercise_id
    )
    if (testExerciseIdx < 0) return

    test_exercises[testExerciseIdx] = { ...test_exercises[testExerciseIdx], question, test_answers }

    this.setState(
      {
        test_exercises,
      },
      this.handleQuestionSelectorClose
    )
  }

  postureFromId = (id) => {
    let toReturn = {}

    let postures = (this.props.resources || {}).postures || []

    postures.map((item) => {
      if (item.id === id) toReturn = item

      return null
    })

    return toReturn
  }

  searchFilteroptions = () => {
    const { t } = this.props

    const resources = this.props.resources
    let toReturn = []

    if (resources.equipment) {
      toReturn.push({
        label: t('PageExercises.equipment'),
        name: 'equipment',
        value: 'equipment',
        options: resources.equipment.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.muscles) {
      toReturn.push({
        label: t('PageExercises.muscles'),
        name: 'muscles',
        value: 'muscles',
        options: resources.muscles.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.objectives) {
      toReturn.push({
        label: t('PageExercises.objectives'),
        name: 'objectives',
        value: 'objectives',
        options: resources.objectives.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.positions) {
      toReturn.push({
        label: t('PageExercises.positions'),
        name: 'positions',
        value: 'positions',
        options: resources.positions.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    return toReturn
  }

  render() {
    const { t } = this.props

    return (
      <div className={`${baseClass}`}>
        <div>{this.renderExercisesTable()}</div>

        <div>
          <Button onClick={this.handleSearchExercisesClick}>
            {t('FormTestCreate.Exercises.button-3')}
          </Button>
        </div>

        {this.state.showCardModal && (
          <CardPicker
            type={'exercise'}
            handleClose={this.handleCloseCardModal}
            searchResults={this.props.searchResults.exercises}
            limit={this.props.searchResults.limit}
            offset={this.props.searchResults.offset}
            total={this.props.searchResults.total_count}
            searchData={this.state.searchData}
            searchFilteroptions={this.searchFilteroptions()}
            searchFilterType={'multi-select'}
            filterSubmit={this.fetchExercises}
            handleSubmit={this.handleAddExerciseClick}
            status={this.props.statuses[statusRefSearch]}
            resources={this.props.resourcesExercises}
          />
        )}

        {this.state.postureSelector && (
          <PostureSelector
            exercise={this.state.postureSelector}
            postures={this.props.resources.postures}
            handleClose={this.handlePostureSelectorClose}
            onSubmit={this.handlePosturesSubmit}
          />
        )}

        {this.state.questionSelector && (
          <QuestionSelector
            exercise={this.state.questionSelector}
            handleClose={this.handleQuestionSelectorClose}
            onSubmit={this.handleQuestionSubmit}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  searchResults: state.Exercise.searchResults,
  resources: state.Resource.challenges,
  resourcesExercises: state.Resource.exercises,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Exercises))
