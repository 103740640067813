export const USER_FETCH_ALL = 'user/USER_FETCH_ALL'
export const USER_ADMIN_SEARCH = 'user/USER_ADMIN_SEARCH'
export const USER_ADMIN_SEARCH_RESULTS = 'user/USER_ADMIN_SEARCH_RESULTS'

export const GET_USER_BY_ID = 'user/GET_USER_BY_ID'
export const GET_USER_COACH = 'user/GET_USER_COACH'

export const SET_USER_AVATAR_REQUEST = 'user/SET_USER_AVATAR_REQUEST'

export const UPDATE_CACHE = 'user/UPDATE_CACHE'
export const UPDATE_CACHE_SET = 'user/UPDATE_CACHE_SET'

export function adminUserSearchCreator(payload) {
  return { type: USER_ADMIN_SEARCH, payload: payload }
}

export function getUserByIdCreator(payload) {
  return { type: GET_USER_BY_ID, payload: payload }
}

export function getUserCoachCreator(payload) {
  return { type: GET_USER_COACH, payload: payload }
}

export function setUserAvatarCreator(payload) {
  return { type: USER_ADMIN_SEARCH, payload: payload }
}
