import React, { Component } from 'react'
import { connect } from 'react-redux'

import './style.css'

class PageIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return <div className="page-index" />
  }
}

const mapStateToProps = (state) => ({
  theState: state,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PageIndex)
