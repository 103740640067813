import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Button from 'components/Button'
import ActionButtons from 'components/ActionButtons'
import ExerciseDetails from 'components/ExerciseDetails'
import ExerciseSteps from 'components/ExerciseSteps'
import ExerciseAdditionalInfo from 'components/ExerciseAdditionalInfo'
import ErrorMessage from 'components/ErrorMessage'

import ModalPreviewGif from './components/ModalPreviewGif'

import {
  EXERCISE_FETCH_SUBMIT,
  EXERCISE_MAKE_PUBLIC_REQUEST,
  EXERCISE_DELETE_REQUEST,
  deleteExercisePermanently,
} from 'store/modules/Exercise/actions.js'
import { clearStatus } from 'store/modules/Status/actions.js'

import './style.css'
import _ from 'lodash'
import ActionConfirmation from '../../components/ActionConfirmation'
import { ADMIN_ROLE } from '../../constants/constants'

const statusRefPublish = 'PageExercise-publish'
const statusRefRefusePublish = 'PageExercise-refusePublish'
const statusRefDeleteExercisePermanently = 'PageExercise-deleteExercisePermanently'

class PageExercise extends Component {
  constructor() {
    super()
    this.state = {
      exercise: {},
      actionConfirmationActive: false,
      openModalPreviewGif: false,
      isForEdit: false,
    }
  }

  setExerciseResources = (exercise) => {
    const { objectives, positions, counts } = this.props.resources
    const { objective_id, position_id, count_id, ...rest } = exercise

    const objective = objectives.find((obj) => obj.id === objective_id) || {}
    const position = positions.find((position) => position.id === position_id) || {}
    const count = counts.find((count) => count.id === count_id) || {}

    return { ...rest, objective, position, count }
  }

  componentDidMount() {
    this.setState({ exerciseId: Number(this.props.match.params.exerciseId) }, () => {
      const { exerciseId } = this.state
      const { exercise } = this.props
      if (exercise.id !== exerciseId) {
        this.props.dispatch({ type: EXERCISE_FETCH_SUBMIT, payload: exerciseId })
      }
    })
  }

  handleClearDeleteStatus = () => {
    setTimeout(() => {
      this.handleCancelDeletingConfirmationModal()
      this.props.dispatch(clearStatus({ statusRef: statusRefDeleteExercisePermanently }))
      window.history.go(-1)
    }, 200)
  }

  handleAcceptPublishClick = () => {
    this.props.dispatch({
      type: EXERCISE_MAKE_PUBLIC_REQUEST,
      payload: { id: this.state.exerciseId, statusRef: statusRefPublish },
    })
  }

  handleRefusePublishClick = () => {
    //TODO: Confirmation popup
    this.props.dispatch({
      type: EXERCISE_DELETE_REQUEST,
      payload: {
        id: this.state.exerciseId,
        statusRef: statusRefRefusePublish,
        callback: {
          success: () => this.handleClearDeleteStatus(),
        },
      },
    })
  }

  handleDeleteExercise = () => {
    this.props.dispatch(
      deleteExercisePermanently({
        exercise_id: this.state.exerciseId,
        statusRef: statusRefDeleteExercisePermanently,
        callback: {
          success: () => this.handleClearDeleteStatus(),
        },
      })
    )
  }

  handleEditClick = () => {
    const { exercise, history } = this.props

    if (_.isEmpty(exercise)) return
    if (exercise.error) {
      this.setState({
        actionConfirmationActive: true,
        isForEdit: true,
      })
    } else {
      history.push(`/exercise/${this.state.exerciseId}/edit`)
    }
  }

  handleDeleteClick = () => {
    this.props.dispatch(clearStatus({ statusRef: statusRefDeleteExercisePermanently }))

    this.setState({
      actionConfirmationActive: true,
      isForEdit: false,
    })
  }

  handleCancelDeletingConfirmationModal = () => {
    this.setState({
      actionConfirmationActive: false,
      isForEdit: false,
    })
  }

  handleOpenModalPreviewGif = () => {
    this.setState({
      openModalPreviewGif: true,
    })
  }

  handleCloseModalPreviewGif = () => {
    this.setState({
      openModalPreviewGif: false,
    })
  }

  getExerciseImages = (exercise) => {
    if (!exercise) return

    let imagesToReturn = []

    ;(exercise.steps || []).map((step) => {
      if (step.in_gif) {
        imagesToReturn.push(step.photo)
      }

      return null
    })

    return imagesToReturn
  }

  render() {
    const { t, exercise, role, statuses } = this.props
    const { openModalPreviewGif, actionConfirmationActive, isForEdit } = this.state
    return (
      <div className="page-exercise">
        <div className="page-exercise-content">
          <ExerciseDetails exercise={this.setExerciseResources(exercise)} />

          <ActionButtons
            role={role}
            handleEditClick={this.handleEditClick}
            handleDeleteClick={this.handleDeleteClick}
            objectName="exercise"
            canDelete={role === ADMIN_ROLE}
          />

          <ActionConfirmation
            isOpen={actionConfirmationActive}
            confirmationTitle={t('PageExercise.delete-confirmation-title')}
            handleCancel={this.handleCancelDeletingConfirmationModal}
            handleConfirm={this.handleDeleteExercise}
            status={statuses[statusRefDeleteExercisePermanently]}
            isForEdit={isForEdit}
            data={exercise.data}
          />

          <Button
            onClick={this.handleOpenModalPreviewGif}
            disabled={!this.getExerciseImages(exercise)?.length}
          >
            {t('PageExercise.button-preview-gif')}
          </Button>

          <ModalPreviewGif
            isOpen={openModalPreviewGif}
            images={this.getExerciseImages(exercise)}
            handleCloseModal={this.handleCloseModalPreviewGif}
          />

          {role === ADMIN_ROLE && exercise.public === 0 && (
            <div className="page-exercise-pending">
              <div className="page-exercise-pending-title">{t('PageExercise.title')}</div>

              <Button
                onClick={this.handleAcceptPublishClick}
                status={statuses[statusRefPublish]}
                data-cy="publish-exercise"
              >
                {t('PageExercise.button')}
              </Button>

              <Button
                onClick={this.handleRefusePublishClick}
                status={statuses[statusRefRefusePublish]}
                data-cy="refuse-exercise"
              >
                {t('GLOBAL.delete')}
              </Button>

              <ErrorMessage status={statuses[statusRefPublish]} />
              <ErrorMessage status={statuses[statusRefRefusePublish]} />
            </div>
          )}

          <ExerciseSteps exercise={exercise} />

          <ExerciseAdditionalInfo exercise={exercise} />
        </div>
      </div>
    )
  }
}

PageExercise.defaultProps = {
  exercise: {},
  role: '',
  statuses: {},
  resources: {},
}

const mapStateToProps = (state) => ({
  exercise: state.Exercise.exercise,
  role: state.Account.role,
  statuses: state.Status.statuses,
  resources: state.Resource.exercises,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PageExercise))
)
