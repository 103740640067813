import React, { useState } from 'react'
import PropTypes from 'prop-types'

import './style.css'
import { withTranslation } from 'react-i18next'

const imageUrl = process.env.REACT_APP_STORAGE_URL

const ExerciseCard = (props) => {
  const [isSelected, setIsSelected] = useState(false)
  const { className, item, i18n } = props

  const getStepsImages = () => {
    return item.steps.map((item, index) => (
      <div
        style={{ backgroundImage: `url(${imageUrl}${item.photo})` }}
        className="exercise-card-step-image"
        key={`stepsImages_${index}`}
      />
    ))
  }

  return (
    <div
      className={`exercise-card ${className || ''} ${isSelected ? 'card-selected' : ''}`}
      onClick={() => setIsSelected(!isSelected)}
    >
      <div className="exercise-card-steps-images-container">{getStepsImages()}</div>

      <div className="exercise-card-text-container">
        <div className="exercise-card-text-title" title={item.name}>
          {item.name}
        </div>

        <div className="objective">{item.objective?.translations[i18n.language].name}</div>
      </div>
    </div>
  )
}

ExerciseCard.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
}

ExerciseCard.defaultProps = {
  item: {
    name: '',
    objective: {},
    steps: [],
  },
  onClick: () => {},
}
export default withTranslation()(ExerciseCard)
