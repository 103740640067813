import { takeEvery, put, select } from 'redux-saga/effects'

import * as ApiService from 'services/api'

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions.js'
import { ProtectedCall } from 'services/protected.api'

import * as assessmentActions from 'store/modules/Assessment/actions'

/*************************************************/
/** Selectors **/
const allSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Assessment.assessments))
}

/*************************************************/
/** Sagas **/
export default function* sagaWatcher() {
  yield takeEvery(assessmentActions.GET_ASSESSMENTS, getAssessmentsSaga)
  yield takeEvery(assessmentActions.CREATE_ASSESSMENT, createAssessmentSaga)
  yield takeEvery(assessmentActions.EDIT_ASSESSMENT, editAssessmentSaga)
  yield takeEvery(assessmentActions.DELETE_ASSESSMENT, deleteAssessmentSaga)

  yield takeEvery(assessmentActions.GET_LEVELS, getLevelsSaga)
  yield takeEvery(assessmentActions.GET_OBJECTIVES, getProgramObjectivesSaga)

  yield takeEvery(assessmentActions.UPDATE_ASSESSMENTS_CACHE, updateCacheSaga)
}

function* getAssessmentsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get assessments start',
      })
    )

    let result = yield ProtectedCall(ApiService.AssessmentsGet, data.payload)

    yield put({
      type: assessmentActions.UPDATE_ASSESSMENTS_CACHE,
      payload: result.data.data.assessments,
    })

    yield put({
      type: assessmentActions.UPDATE_ASSESSMENT_SEARCH_RESULTS,
      payload: result.data.data,
    })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get assessments finsihed',
      })
    )
  } catch (e) {
    console.log('*** Error caught in getAssessmentsSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get assessments start error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* createAssessmentSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Create assessments start',
      })
    )

    yield ProtectedCall(ApiService.AssessmentCreate, data.payload)

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Create assessments finished',
      })
    )
  } catch (e) {
    console.log('*** Error caught in createAssessmentSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Create assessments error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* editAssessmentSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Edit assessments start',
      })
    )

    yield ProtectedCall(ApiService.AssessmentEdit, data.payload, data.payload.id)

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Edit assessments finished',
      })
    )
  } catch (e) {
    console.log('*** Error caught in editAssessmentSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Edit assessments error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* deleteAssessmentSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Delete assessments start',
      })
    )

    yield ProtectedCall(ApiService.AssessmentDelete, data.payload.id)

    yield put({
      type: assessmentActions.UPDATE_ASSESSMENTS_CACHE,
      payload: [data.payload.id],
      delete: true,
    })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Delete assessments finished',
      })
    )
  } catch (e) {
    console.log('*** Error caught in deleteAssessmentSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Delete assessments error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* getLevelsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Get levels start' })
    )

    let result = yield ProtectedCall(ApiService.LevelsGet, data.payload)

    yield put({
      type: assessmentActions.UPDATE_RESSOURCES_CACHE,
      payload: {
        levels: result.data.data.levels,
      },
    })

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Get levels finished' })
    )
  } catch (e) {
    console.log('*** Error caught in getLevelsSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get levels error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* getProgramObjectivesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Get objectives start' })
    )

    let result = yield ProtectedCall(ApiService.ProgramObjectivesGet)

    yield put({
      type: assessmentActions.UPDATE_RESSOURCES_CACHE,
      payload: {
        program_objectives: result.data.data.program_objectives,
      },
    })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get objectives finished',
      })
    )
  } catch (e) {
    console.log('*** Error caught in getProgramObjectivesSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get objectives error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* updateCacheSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return
  }

  let assessments = yield select(allSelector) || []

  if (data.delete) {
    data.payload.map((item) => {
      let id = item
      if (typeof id === 'object') {
        id = id.id
      }

      delete assessments[id]

      return null
    })
  } else {
    data.payload.map((item) => {
      if (!item.id) return

      assessments[item.id] = item
      return null
    })
  }

  yield put({ type: assessmentActions.UPDATE_ASSESSMENTS_CACHE_SET, payload: assessments })
}
