import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './style.css'

class FormGeneric extends Component {
  constructor() {
    super()

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(ev) {
    ev.preventDefault()
    this.props.onSubmit(ev)
  }

  render() {
    return (
      <form
        id={this.props.id}
        className={`form ${this.props.className}`}
        onSubmit={this.handleSubmit}
        action={this.props.action}
        method={this.props.method}
      >
        {this.props.children}
      </form>
    )
  }
}

FormGeneric.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  action: PropTypes.string,
  method: PropTypes.string,
}

FormGeneric.defaultProps = {
  action: '#',
  method: 'POST',
}

export default FormGeneric
