import { takeLatest, call, put } from 'redux-saga/effects'

import * as ApiService from 'services/api'
import * as RolesService from 'services/roles'

import { getUserByIdCreator } from 'store/modules/User/actions.js'
import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions.js'

import { getToken, setToken, clearToken } from 'services/token'

import { setLocale, getLocale } from 'services/localisation'
import { ProtectedCall } from 'services/protected.api'

import * as accountActions from 'store/modules/Account/actions'

export default function* sagaWatcher() {
  yield takeLatest(accountActions.APP_INIT, initSaga)

  yield takeLatest(accountActions.LOGIN_REQUEST, loginSaga)
  yield takeLatest(accountActions.SET_LOGGEDIN_STATE, setLoggedInState)

  yield takeLatest(accountActions.SET_CURRENT_ROLE, setCurrentRoleSaga)
  yield takeLatest(accountActions.SET_LOGGEDOUT_STATE, logoutSaga)

  yield takeLatest(accountActions.GET_LANGUAGES, getLanguagesSaga)

  yield takeLatest(accountActions.REGISTER_REQUEST, registrationSaga)
  yield takeLatest(accountActions.EMAIL_CONFIRM_SUBMIT_REQUEST, submitConfirmEmailSaga)
  yield takeLatest(accountActions.UPDATE_PROFILE_REQUEST, updateProfileSaga)

  yield takeLatest(
    accountActions.SUBMIT_FORGOTTEN_PASSWORD_REQUEST,
    submitForgottenPasswordRequestSaga
  )
  yield takeLatest(accountActions.SUBMIT_NEW_PASSWORD, submitNewPasswordSaga)

  yield takeLatest(accountActions.UPDATE_USER_LANGUAGE, updateUserLanguageSaga)
}

function* initSaga() {
  try {
    let token = yield call(getToken)

    if (!token) {
      yield put({
        type: accountActions.SET_LOGGEDOUT_STATE,
      })
      return
    }
    // yield put(setLoggedInStateCreator(response.data.data));

    // yield put({
    //   type: SET_LOGIN_REQUEST_STATUS,
    //   payload: {
    //     error: null,
    //     status: 'success'
    //   }});

    const me = yield ProtectedCall(ApiService.GetUser, 'me')
    yield put(accountActions.setLoggedInStateCreator(me.data.data))
  } catch (e) {
    console.log('*** Error caught in initSaga ***')
  }
}

function* loginSaga(data) {
  const { callback = { onSuccess: () => null } } = data.payload
  try {
    // yield put({ type: SET_LOGIN_REQUEST_STATUS, payload: { error:null, status:'sending' }});
    yield put(incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Start login' }))

    const response = yield call(ApiService.Login, data.payload.email, data.payload.password)

    yield call(setToken, response.data.data)
    yield put(accountActions.setLoggedInStateCreator(response.data.data))

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Start login success' })
    )

    // yield put({
    //   type: SET_LOGIN_REQUEST_STATUS,
    //   payload: {
    //     error: null,
    //     status: 'success'
    //   }});
    callback.onSuccess()
  } catch (error) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Login error',
        state: 'error',
        data: error.response.data,
      })
    )

    yield put({
      type: accountActions.SET_LOGGEDOUT_STATE,
    })
  }
}

function* setLoggedInState(data) {
  try {
    if (!data || !data.payload) return

    if (!data.payload.user) return

    yield put(accountActions.getLanguages({}))
    if (data.payload.user.language && data.payload.user.language.code) {
      setLocale(data.payload.user.language.code)
    }

    // yield put ( {type: user_UPDATE_CACHE, payload: [data.payload.user]});

    yield put(accountActions.setCurrentRole(RolesService.getDefaultRole(data.payload.user.roles)))

    yield put({
      type: accountActions.SET_LOGIN_REQUEST_STATUS,
      payload: {
        error: null,
        status: 'success',
      },
    })

    yield put({
      type: accountActions.SET_LOGGEDIN_STATE_SET,
      payload: {
        user: data.payload.user,
      },
    })

    yield put(getUserByIdCreator('me'))

    //   socketConnect(
    //     data.payload.user,
    //    (message) => yield put(messageReceived({data: message}))
    //  );
  } catch (error) {
    console.log('*** Error caught in setLoggedInState ***', error)
  }
}

function* setCurrentRoleSaga(data) {
  try {
    if (!data || !data.payload) {
      data = { payload: null }
    }

    yield call(RolesService.saveToLocalStorage, data.payload)
    yield put({ type: accountActions.SET_CURRENT_ROLE_SET, payload: data.payload })
  } catch (error) {
    console.log('*** Error caught in setCurrentRoleSaga ***', error)
  }
}

function* logoutSaga() {
  try {
    yield put({
      type: accountActions.SET_LOGGEDOUT_STATE_SET,
    })

    setLocale(getLocale())

    let token = yield call(getToken)

    if (token) {
      yield ProtectedCall(ApiService.Logout)
    }

    yield call(clearToken)
    yield call(RolesService.clearRole)
  } catch (error) {
    console.log('*** Error caught in logoutSaga ***', error)
  }
}

function* registrationSaga(data) {
  try {
    yield put({
      type: accountActions.SET_REGISTER_REQUEST_STATUS,
      payload: {
        error: null,
        status: 'sending',
      },
    })

    yield call(ApiService.Register, data.payload)

    yield put({
      type: accountActions.SET_REGISTER_REQUEST_STATUS,
      payload: {
        error: null,
        status: 'success',
      },
    })
  } catch (error) {
    let errorString = ''

    if (error.response) {
      errorString = {
        message: error.response.data.message,
        errors: error.response.data.errors,
      }
    } else if (error.request) {
      errorString = error.request
    } else {
      errorString = error.message
    }

    yield put({
      type: accountActions.SET_REGISTER_REQUEST_STATUS,
      payload: {
        error: errorString,
        status: '',
      },
    })
  }
}

function* updateUserLanguageSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'start updating user language',
      })
    )

    let response = yield call(ApiService.UpdateUserLanguage, data.payload)

    yield put(accountActions.setLoggedInStateCreator(response.data.data))

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'finish updating user language',
      })
    )
  } catch (error) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'update user language error',
        state: 'error',
        data: error.response.data,
      })
    )
  }
}

function* getLanguagesSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Getting languages' })
    )

    let result = yield call(ApiService.GetLanguages, data.payload)
    yield put({ type: accountActions.SET_LANGUAGES, payload: result.data.data.languages })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting languages success',
      })
    )
  } catch (error) {
    console.log('*** Error caught in getLanguagesSaga ***', error)
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Getting languages error',
        state: 'error',
        data: error.response.data.error,
      })
    )
  }
}

function* submitConfirmEmailSaga(data) {
  try {
    yield call(ApiService.ConfirmEmail, data.payload)

    yield put({
      type: accountActions.SET_REGISTER_CONFIRM_EMAIL_STATUS,
      payload: {
        error: null,
        status: 'success',
      },
    })
  } catch (error) {
    let errorString = ''

    if (error.response) {
      console.log('error +++> ', JSON.stringify(error.response))
      errorString = {
        message: error.response.data.message,
        errors: error.response.data.errors || error.response.data.error,
      }
    } else if (error.request) {
      errorString = error.request
    } else {
      errorString = error.message
    }

    yield put({
      type: accountActions.SET_REGISTER_CONFIRM_EMAIL_STATUS,
      payload: {
        error: errorString,
        status: '',
      },
    })
  }
}

function* submitForgottenPasswordRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting forgotten password request',
      })
    )

    yield call(ApiService.ForgotPasswordRequest, data.payload)

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting forgotten password requestfinished',
      })
    )
  } catch (e) {
    console.log('*** Error caught in submitForgottenPasswordRequestSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting forgotten password request error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* submitNewPasswordSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting new password request',
      })
    )

    yield call(ApiService.SubmitNewPassword, data.payload)

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting new password requestfinished',
      })
    )
  } catch (e) {
    console.log('*** Error caught in submitNewPasswordSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Submitting new password request error',
        state: 'error',
      })
    )
  }
}

function* updateProfileSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.payload.statusRef,
        message: 'update profile',
      })
    )

    let response = yield ProtectedCall(ApiService.UpdateProfile, data.payload.payload)

    yield put(accountActions.setLoggedInStateCreator(response.data.data))

    yield put({
      type: accountActions.SET_PROFILE_UDPDATE_STATUS,
      payload: {
        error: null,
        status: 'success',
      },
    })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.payload.statusRef,
        message: 'update profile finished',
      })
    )
  } catch (error) {
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.payload.statusRef,
        message: 'update profile error',
        state: 'error',
      })
    )
    let errorString = ''

    if (error.response) {
      errorString = {
        message: error.response.data.message,
        errors: error.response.data.errors || error.response.data.error,
      }
    } else if (error.request) {
      errorString = error.request
    } else {
      errorString = error.message
    }

    yield put({
      type: accountActions.SET_PROFILE_UDPDATE_STATUS,
      payload: {
        error: errorString,
        status: '',
      },
    })

    yield call(alert(errorString))
  }
}
