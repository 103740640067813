export const GET_TOP_LEVEL_FOLDERS = 'folder/GET_TOP_LEVEL_FOLDERS'
export const SET_TOP_LEVEL_FOLDERS = 'folder/SET_TOP_LEVEL_FOLDERS'

export const GET_FOLDER = 'folder/GET_FOLDER'
export const CREATE_TAB_FOLDER = 'folder/CREATE_TAB_FOLDER'

export const SET_FOLDER = 'folder/SET_FOLDER'

export const UPDATE_FOLDER = 'folder/UPDATE_FOLDER'
export const UPDATE_FOLDER_CACHE = 'folder/UPDATE_FOLDER_CACHE'
export const DELETE_CONTENT_FROM_FOLDER = 'folder/DELETE_CONTENT_FROM_FOLDER'

export function getTopLevelFolders(payload) {
  return { type: GET_TOP_LEVEL_FOLDERS, payload: payload }
}

export function getFolder(payload) {
  return { type: GET_FOLDER, payload: payload }
}

export function createTabFolder(payload) {
  return { type: CREATE_TAB_FOLDER, payload: payload }
}

export function deleteContentFromFolder(payload) {
  return { type: DELETE_CONTENT_FROM_FOLDER, payload: payload }
}
