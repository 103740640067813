import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import getTranslations from 'helpers/get-translations'

import FormGeneric from 'components/FormGeneric'
import Button from 'components/Button'
import Exercises from './containers/Exercises'
import Input from 'containers/Input'
import ErrorMessage from 'components/ErrorMessage'

import './style.css'

const baseClass = 'form-challenge-create'

class FormChallengeCreate extends Component {
  constructor() {
    super()

    this.state = {
      form: {
        public: false,
        name: {},
        cycle: 1,
      },
      exercises: [],
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleExercisesUpdate = this.handleExercisesUpdate.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setInitialData = this.setInitialData.bind(this)
    this.handleBack = this.handleBack.bind(this)
  }

  componentDidMount() {
    this.setInitialData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialData) {
      if (JSON.stringify(this.props.initialData) !== JSON.stringify(prevProps.initialData)) {
        this.setInitialData()
      }
    }
  }

  setInitialData() {
    if (!this.props.initialData) return

    const {
      translations,
      challenge_type,
      cycle,
      level,
      public: publicProp,
      challenge_exercises,
    } = this.props.initialData

    this.setState({
      form: {
        ...this.props.initialData,
        public: publicProp || false,
        name: getTranslations(translations, 'name'),
        challenge_type_id: (challenge_type || {}).id,
        cycle: cycle || 1,
        level_id: (level || {}).id,
      },
      exercises: challenge_exercises || [],
    })
  }

  handleExercisesUpdate(exercises) {
    this.setState({
      exercises: exercises,
    })
  }

  handleInputChange(event) {
    const target = event.target

    let value = target.type === 'checkbox' ? target.checked : target.value
    if (value === 'true' || value === 'false') {
      value = value === 'true'
    }

    const name = target.name

    if (name.includes('.')) {
      //TODO: ALlows language to be specified in the input name.
      //Look at a more scalable way of doing this.
      let splitName = name.split('.')
      this.setState({
        form: {
          ...this.state.form,
          [splitName[0]]: {
            ...this.state.form[splitName[0]],
            [splitName[1]]: value,
          },
        },
      })
    } else {
      this.setState({
        form: {
          ...this.state.form,
          [name]: value,
        },
      })
    }
  }

  handleInputTranslationChange = (event, values) => {
    const { target } = event
    const { name } = target

    if (name.includes('.')) {
      let splitName = name.split('.')
      this.setState({
        form: {
          ...this.state.form,
          [splitName[0]]: values,
        },
      })
    }
  }

  handleSubmit() {
    this.props.handleSubmit(this.state.form, this.state.exercises)
  }

  handleBack() {
    window.history.go(-1)
  }

  render() {
    const { t } = this.props

    return (
      <div className={`${baseClass}`}>
        <FormGeneric className="modal-create-challenge-form" onSubmit={this.handleSubmit}>
          <h1>{t('FormChallengeCreate.title')}</h1>

          <div className="row-container">
            {this.props.role === 'admin_referensiel' && (
              <div>
                <label>{t('FormChallengeCreate.label-1')}</label>

                <div className="left-indent">
                  <input
                    type="radio"
                    name="public"
                    value={true}
                    onChange={this.handleInputChange}
                    checked={this.state.form.public === true}
                  />

                  <label>{t('FormChallengeCreate.label-2')}</label>
                </div>
              </div>
            )}
          </div>

          <div className="row-container">
            <label>{t('FormChallengeCreate.label-3')}</label>
            <Input
              type="text"
              name="name"
              className="name"
              value={this.state.form.name || {}}
              onChange={this.handleInputTranslationChange}
              placeholder={t('FormChallengeCreate.label-3')}
              autoComplete="false"
              required={true}
              maxLength={191}
              isTranslatable={true}
            />
          </div>

          <div className="row-container">
            <div className="inline-block width-50">
              <label>{t('FormChallengeCreate.label-4')}</label>
              <div className="left-indent">
                <select
                  name="challenge_type_id"
                  autoComplete="off"
                  onChange={this.handleInputChange}
                  value={this.state.form.challenge_type_id || ''}
                  required={true}
                >
                  <option value="" disabled="disabled">
                    -------
                  </option>
                  {this.props.resources.challenge_types.map((item, index) => {
                    return (
                      <option value={item.id} key={`challenge_types_${index}`}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>

            <div className="inline-block width-50">
              <label>{t('FormChallengeCreate.label-5')}</label>
              <div className="left-indent">
                <select
                  name="level_id"
                  autoComplete="off"
                  onChange={this.handleInputChange}
                  value={this.state.form.level_id || ''}
                  required={true}
                >
                  <option value="" disabled="disabled">
                    -------
                  </option>
                  {this.props.resourcesSessions.levels.map((item, index) => {
                    return (
                      <option value={item.id} key={`levelsB_${index}`}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>

          <div>
            <label>{t('FormChallengeCreate.label-6')}</label>

            <Exercises
              exercises={this.state.exercises}
              handleExercisesUpdate={this.handleExercisesUpdate}
              challengeType={this.state.form.challenge_type_id}
            />
          </div>

          <div className="row-container">
            <Button className="cancel-button" onClick={this.handleBack}>
              {t('GLOBAL.cancel')}
            </Button>

            <Button
              type="submit"
              className="signup-button"
              onClick={() => {}}
              status={this.props.statusSubmit}
            >
              {t('GLOBAL.submit')}
            </Button>

            <ErrorMessage status={this.props.statusSubmit} />
          </div>
        </FormGeneric>
      </div>
    )
  }
}

FormChallengeCreate.propTypes = {
  resources: PropTypes.object,
  resourcesSessions: PropTypes.object,
  statusSubmit: PropTypes.object,
}

FormChallengeCreate.defaultProps = {}

export default withTranslation()(FormChallengeCreate)
