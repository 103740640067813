import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import ReactQuill from 'react-quill' // ES6
import 'react-quill/dist/quill.snow.css' // ES6

import { getLocale } from 'services/localisation'

import TranslationTabs from 'components/TranslationTabs'

import './style.css'

const baseClass = 'texteditor'

class Texteditor extends Component {
  constructor() {
    super()

    this.state = {
      lang: getLocale() || 'fr',
    }

    this.containerRef = React.createRef()

    this.handleLanguageChange = this.handleLanguageChange.bind(this)
  }

  handleLanguageChange(lang) {
    this.setState({
      lang,
    })
  }

  getHeight() {
    if (!this.containerRef.current) return
    const children = this.containerRef.current.children

    let height = 300

    for (var i = 0; i < children.length; i++) {
      const item = children[i]
      if (item.classList.contains(`${baseClass}-textarea-element`)) {
        if (item.offsetHeight > height) {
          height = item.offsetHeight
        }
      }
    }
    return height
  }

  getValue(values, currentlang) {
    let toReturn = null

    if (!this.props.isTranslatable) {
      return values
    }

    Object.keys(values).map((lang) => {
      const value = values[lang]

      if (currentlang == lang) {
        if (typeof value === 'object') {
          toReturn = (value || {}).value
        } else {
          toReturn = value
        }
      }

      return null
    })

    return toReturn
  }

  onChange(inputValue, item, isTranslatable) {
    const { languages, handleTextAreaChange, name, value } = this.props
    const inputValues = {}

    if (isTranslatable) {
      languages.forEach((language) => {
        if (item.code === language.code) {
          inputValues[language.code] = inputValue !== '<p><br></p>' ? inputValue : null
        } else if (Object.prototype.hasOwnProperty.call(value, language.code)) {
          inputValues[language.code] = value[language.code]
        } else {
          inputValues[language.code] = null
        }
      })

      handleTextAreaChange(inputValues, name, isTranslatable)
    } else {
      handleTextAreaChange(inputValue !== '<p><br></p>' ? inputValue : null, name, isTranslatable)
    }
  }

  render() {
    const { isTranslatable } = this.props
    return (
      <div className={`${baseClass}`} ref={this.containerRef}>
        {this.props.isTranslatable && (
          <TranslationTabs
            currentLanguage={this.state.lang}
            handleLanguageChange={this.handleLanguageChange}
            languages={this.props.languages}
          />
        )}

        {this.props.languages.map((item, idx) => {
          this.getValue(this.props.value, item.code)
          return (
            <ReactQuill
              key={idx}
              value={this.getValue(this.props.value, item.code) || ''}
              onChange={(val) => this.onChange(val, item, isTranslatable)}
              theme="snow"
              className={`${this.props.className} ${baseClass}-textarea-element ${
                item.code === this.state.lang ? 'active-element' : ''
              } ${this.props.isTranslatable ? 'isTranslatable' : 'notTranslatable'}}`}
              style={{ minHeight: this.getHeight() }}
            />
          )
        })}

        {/*{this.props.withCharsCount && this.props.maxLength && (*/}
        {/*  <div>*/}
        {/*    <p>*/}
        {/*      {t('GLOBAL.chars-left')}:{' '}*/}
        {/*      {this.props.maxLength -*/}
        {/*        parseInt((this.props.value[this.state.lang] || '').length, 10)}*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    )
  }
}

Texteditor.propTypes = {
  isTranslatable: PropTypes.bool,
  withCharsCount: PropTypes.bool,
}

Texteditor.defaultProps = {
  isTranslatable: false,
  withCharsCount: false,
}

const mapStateToProps = (state) => ({
  theState: state,
  languages: state.Account.languages,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Texteditor))
