import { takeEvery, put, select } from 'redux-saga/effects'

import * as ApiService from 'services/api'

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions.js'
import { ProtectedCall } from 'services/protected.api'

import * as bodyPartsActions from 'store/modules/BodyParts/actions.js'

/*************************************************/
/** Selectors **/
const allSelector = (state) => {
  return JSON.parse(JSON.stringify(state.BodyParts.body_parts))
}

/*************************************************/
/** Sagas **/
export default function* sagaWatcher() {
  yield takeEvery(bodyPartsActions.BODY_PARTS_GET_ALL, bodyPartsGetAllSaga)
  yield takeEvery(bodyPartsActions.CREATE_BODY_PARTS_REQUEST, createBodyPartsRequestSaga)
  yield takeEvery(bodyPartsActions.UPDATE_BODY_PARTS, updateBodyPartsSaga)
  yield takeEvery(bodyPartsActions.UPDATE_BODY_PARTS_CACHE, updateCacheSaga)

  yield takeEvery(bodyPartsActions.BODY_PARTS_DELETE_REQUEST, deleteBodyPartsRequestSaga)
}

function* createBodyPartsRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Creating BodyParts' })
    )

    let result = yield ProtectedCall(ApiService.CreateBodyParts, data.payload)

    yield put({ type: bodyPartsActions.UPDATE_BODY_PARTS_CACHE, payload: [result.data.data] })

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'BodyParts created' })
    )
  } catch (e) {
    console.log('*** Error caught in createBodyPartsRequestSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'BodyParts creation error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* updateBodyPartsSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Update BodyParts' })
    )

    let result = yield ProtectedCall(ApiService.UpdateBodyParts, data.payload.id, data.payload)

    yield put({ type: bodyPartsActions.UPDATE_BODY_PARTS_CACHE, payload: [result.data.data] })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Update body_parts finished',
      })
    )
  } catch (e) {
    console.log('*** Error caught in updateBodyPartsSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Update body_parts error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* bodyPartsGetAllSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Fetching BodyParts' })
    )

    let result = yield ProtectedCall(ApiService.BodyPartsGetAll, data.payload)

    yield put({
      type: bodyPartsActions.UPDATE_BODY_PARTS_CACHE,
      payload: result.data.data.body_parts,
    })

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'BodyParts fetched' })
    )
  } catch (e) {
    console.log('*** Error caught in body_partFetchSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'BodyParts fetch error',
        state: 'error',
      })
    )
  }
}

function* deleteBodyPartsRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Deleting body_part' })
    )

    yield ProtectedCall(ApiService.BodyPartsDelete, data.payload.id)

    yield put({
      type: bodyPartsActions.UPDATE_BODY_PARTS_CACHE,
      payload: [data.payload],
      delete: true,
    })

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'BodyParts deleted' })
    )
  } catch (e) {
    console.log('*** Error caught in body_part.deleteBodyPartsRequestSaga ***')

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'BodyParts deletion error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* updateCacheSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return
  }

  let body_parts = yield select(allSelector) || []

  if (data.delete) {
    data.payload.map((item) => {
      let id = item
      if (typeof id === 'object') {
        id = id.id
      }

      delete body_parts[id]

      return null
    })
  } else {
    data.payload.map((item) => {
      if (!item.id) return

      body_parts[item.id] = item
      return null
    })
  }

  yield put({ type: bodyPartsActions.UPDATE_BODY_PARTS_CACHE_SET, payload: body_parts })
}
