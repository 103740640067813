import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { getQueryParam } from 'services/location'

import { submitConfirmEmailCreator } from 'store/modules/Account/actions.js'
import FormModal from 'components/FormModal'
import Button from 'components/Button'

import { SET_ACTIVE_MODAL } from 'store/modules/Modal/actions.js'

import './style.css'

class ModalConfirmEmail extends Component {
  constructor() {
    super()
    this.state = {}

    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleResendConfirmEmail = this.handleResendConfirmEmail.bind(this)
  }

  handleCloseModal() {
    this.props.handleOnClose()
  }

  handleResendConfirmEmail() {
    this.props.dispatch({ type: SET_ACTIVE_MODAL, payload: 'resend_confirm_email' })
  }

  componentDidMount() {
    let confirmCode = getQueryParam('code')
    let email = getQueryParam('email')

    this.setState({
      confirmCode,
    })

    if (confirmCode && email) {
      this.props.dispatch(
        submitConfirmEmailCreator({
          code: confirmCode,
          email: email,
        })
      )
    }
  }

  render() {
    const { t } = this.props

    return (
      <ReactModal
        className="modal-register-success modal-content"
        isOpen={this.props.isActive}
        onRequestClose={this.handleCloseModal}
        shouldCloseOnOverlayClick={false}
        overlayClassName="modal-overlay"
      >
        <FormModal id="login-form" className="login-form transparent" onSubmit={() => {}}>
          {!this.state.confirmCode && (
            <div>
              <h1>{t('ModalConfirmEmail.message_error')}</h1>

              <div>
                <Button onClick={this.handleResendConfirmEmail}>
                  {t('ModalConfirmEmail.resend')}
                </Button>

                <Button onClick={this.handleCloseModal}>{t('ModalConfirmEmail.close')}</Button>
              </div>
            </div>
          )}

          {this.props.confirmEmailError && (
            <div>
              <h1>{t('ModalConfirmEmail.message_invalide')}</h1>

              <div>
                <Button onClick={this.handleResendConfirmEmail}>
                  {t('ModalConfirmEmail.resend')}
                </Button>

                <Button onClick={this.handleCloseModal}>{t('GLOBAL.close')}</Button>
              </div>
            </div>
          )}

          {this.props.confirmEmailRequestStatus && (
            <div>
              <h1>{t('ModalConfirmEmail.message_confirmed')}</h1>

              <div>
                <Button onClick={this.handleCloseModal}>
                  {t('ModalConfirmEmail.close_loggin')}
                </Button>
              </div>
            </div>
          )}
        </FormModal>
      </ReactModal>
    )
  }
}

const mapStateToProps = (state) => ({
  activeModal: state.Modal.activeModal,
  confirmEmailRequestStatus: state.Account.confirmEmailRequestStatus,
  confirmEmailError: state.Account.confirmEmailError,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalConfirmEmail))
