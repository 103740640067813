import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import Button from 'components/Button'
import SessionCard from 'components/SessionCard'
import Pagination from 'components/Pagination'
import SearchFilterBar from 'components/SearchFilterBar'
import { Link } from 'react-router-dom'

import { SET_ACTIVE_MODAL } from 'store/modules/Modal/actions.js'
import { sessionSearchCreator, updateSessionSearchFilters } from 'store/modules/Session/actions.js'
import { FETCH_SESSIONS_RESOURCES_REQUEST } from 'store/modules/Resource/actions.js'
import { getListPrescribers } from 'store/modules/Prescriber/actions.js'
import { clearStatus } from 'store/modules/Status/actions.js'

import './style.css'
import { ADMIN_ROLE } from '../../constants/constants'
import debounce from 'lodash/debounce'

const statusRefSearch = 'PageSessions-search'

class PageSessions extends Component {
  constructor() {
    super()
    this.state = {
      searchData: {},
      isSessionDataFetching: false,
    }
    this.fetchSessions = debounce(this.fetchSessions.bind(this), 400)
  }

  componentDidMount() {
    const { searchFilters } = this.props
    this.props.dispatch(clearStatus({ statusRef: statusRefSearch }))
    this.props.dispatch({ type: FETCH_SESSIONS_RESOURCES_REQUEST })

    this.setState({ isSessionDataFetching: true })
    this.props.dispatch(
      sessionSearchCreator({
        projection: 'simple_list',
        ...searchFilters,
        ...searchFilters.multiSelect,
        search: searchFilters.search_text,
        statusRef: statusRefSearch,
        callback: {
          onSuccess: this.handleSessionsFetched,
        },
      })
    )
    this.props.dispatch(getListPrescribers({}))
  }

  fetchSessions(data) {
    if (!data) return

    this.setState(
      {
        searchData: data,
        isSessionDataFetching: true,
      },
      () => {
        this.props.dispatch(
          sessionSearchCreator({
            projection: 'simple_list',
            ...data,
            ...data.multiSelect,
            search: data.search_text,
            statusRef: statusRefSearch,
            callback: {
              onSuccess: this.handleSessionsFetched,
            },
          })
        )
      }
    )
  }

  handleSessionsFetched = () => {
    this.setState({ isSessionDataFetching: false })
  }

  handleCreateSessionClick = () => {
    this.props.dispatch({ type: SET_ACTIVE_MODAL, payload: 'create-session' })
  }

  searchFilteroptions = () => {
    const { t, resources, prescribers } = this.props
    const { levels, objectives, target_audiences } = resources
    let toReturn = []

    if (levels) {
      toReturn.push({
        label: t('PageSessions.SearchFilters.levels'),
        name: 'levels',
        value: 'levels',
        options: levels.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (objectives) {
      toReturn.push({
        label: t('PageSessions.SearchFilters.objectives'),
        name: 'objectives',
        value: 'objectives',
        options: objectives.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (target_audiences) {
      toReturn.push({
        label: t('PageSessions.SearchFilters.target_audiences'),
        name: 'target_audiences',
        value: 'target_audiences',
        options: target_audiences.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (prescribers) {
      toReturn.push({
        label: t('PageSessions.SearchFilters.prescribers'),
        name: 'prescribers',
        value: 'prescribers',
        options: prescribers?.map((item) => {
          return { value: item.id, label: item.full_name }
        }),
      })
    }

    return toReturn
  }

  updateStoredFilters = (filters) => {
    this.props.dispatch(updateSessionSearchFilters({ ...filters }))
  }

  setSessionResources = (session) => {
    const { objectives, levels, target_audiences } = this.props.resources

    let { objective_id, level_id, target_audience_id, exercises_count, ...rest } = session

    const objective = objectives.find((obj) => obj.id === objective_id) || {}
    const level = levels.find((level) => level.id === level_id) || {}
    const target_audience =
      target_audiences.find((target) => target.id === target_audience_id) || {}

    return { ...rest, objective, level, target_audience, nb_exercises: exercises_count }
  }

  render() {
    const { t, role, searchFilters, searchResults } = this.props
    const { searchData, isSessionDataFetching } = this.state

    return (
      <div className="page-sessions">
        <div className="page-sessions-title">{t('PageSessions.title')}</div>

        <div>
          <Link to="/sessions/create">
            <Button onClick={() => {}}>{t('PageSessions.button-1')}</Button>
          </Link>

          {role === ADMIN_ROLE && (
            <Link to="/sessions/requests">
              <Button onClick={() => {}}>{t('PageSessions.button-2')}</Button>
            </Link>
          )}
        </div>

        <SearchFilterBar
          filterTitle={''}
          filterOptions={this.searchFilteroptions()}
          handleSearchFormSubmit={this.fetchSessions}
          onfilterChange={this.fetchSessions}
          filterType={'multi-select'}
          showLoadingIndicator={isSessionDataFetching}
          storedFilters={searchFilters}
          updateStoredFilters={this.updateStoredFilters}
        />

        {searchResults.sessions &&
          searchResults.sessions.map((item, idx) => {
            const session = this.setSessionResources(item)
            return (
              <Link key={idx} to={`/session/${session.id}`}>
                <SessionCard item={session} />
              </Link>
            )
          })}

        <Pagination
          searchData={searchData}
          limit={searchResults.limit}
          offset={searchResults.offset}
          total={searchResults.total_count}
          fetchData={this.fetchSessions}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  searchFilters: state.Session.searchFilters,
  searchResults: state.Session.searchResults,
  role: state.Account.role,
  resources: state.Resource.sessions,
  prescribers: state.Prescriber.prescribersList,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PageSessions))
