import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import FormTestCreate from 'components/FormTestCreate'

import {
  FETCH_CHALLENGES_RESOURCES_REQUEST,
  FETCH_SESSIONS_RESOURCES_REQUEST,
} from 'store/modules/Resource/actions.js'
import { updatePhysicalTest, TEST_FETCH_SUBMIT } from 'store/modules/PhysicalTest/actions.js'
import { clearStatus } from 'store/modules/Status/actions.js'

import './style.css'

const statusRef = 'PageTestEdit'

class PageTestEdit extends Component {
  constructor() {
    super()
    this.state = {}
  }

  componentDidMount() {
    this.props.dispatch(clearStatus({ statusRef }))

    this.setState(
      {
        testId: this.props.match.params.testId,
      },
      () => {
        this.props.dispatch({ type: TEST_FETCH_SUBMIT, payload: this.state.testId })
      }
    )

    this.props.dispatch({ type: FETCH_CHALLENGES_RESOURCES_REQUEST })
    this.props.dispatch({ type: FETCH_SESSIONS_RESOURCES_REQUEST })
  }

  handlePhysicalTestUpdated = () => {
    const { testId } = this.state
    setTimeout(() => {
      this.props.history.push(`/test/${testId}`)
    }, 700)
  }

  handleSubmit = (form, test_exercises) => {
    this.props.dispatch(
      updatePhysicalTest({
        ...form,
        test_exercises,
        statusRef,
        callback: {
          success: this.handlePhysicalTestUpdated,
        },
      })
    )
  }

  render() {
    const { physicalTest, resources, resourcesSessions = {}, statuses } = this.props
    const { testId } = this.state
    return (
      <div className="page-test-create">
        <FormTestCreate
          initialData={physicalTest[testId]}
          resources={resources}
          levels={resourcesSessions.levels}
          handleSubmit={this.handleSubmit}
          status={statuses[statusRef]}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  resources: state.Resource.programs,
  resourcesSessions: state.Resource.sessions,
  statuses: state.Status.statuses,
  physicalTest: state.PhysicalTest.physicalTests,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageTestEdit))
