import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import FormModal from 'components/FormModal'
import Button from 'components/Button'
import CloseButton from 'components/CloseButton'
import ErrorMessage from 'components/ErrorMessage'

import { clearStatus } from 'store/modules/Status/actions.js'
import { submitForgottenPasswordRequest } from 'store/modules/Account/actions.js'

import './style.css'

const statusRef = 'ModalForgotPassword'

ReactModal.setAppElement('#root')

class ModalForgotPassword extends Component {
  constructor() {
    super()

    this.state = {
      email: '',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleSubmit() {
    this.props.dispatch(
      submitForgottenPasswordRequest({ email: this.state.email, statusRef: statusRef })
    )
  }

  componentWillUnmount() {
    this.props.handleCloseForgotPassword()
  }

  componentDidMount() {
    this.props.dispatch(clearStatus({ statusRef }))
  }

  render() {
    const { t } = this.props

    return (
      <ReactModal
        isOpen={true}
        onRequestClose={this.props.handleOnClose}
        shouldCloseOnOverlayClick={true}
        className="modal-content modal-forgot-password"
        overlayClassName="modal-overlay"
      >
        <CloseButton onClick={this.props.handleOnClose} />
        <FormModal className="forgot-password-form transparent" onSubmit={this.handleSubmit}>
          <h1>{t('ModalForgotPassword.title')}</h1>

          <div className="row-container">
            <input
              type="email"
              name="email"
              value={this.state.email}
              placeholder="Email"
              autoComplete="false"
              required={true}
              onChange={this.handleChange}
              onBlur={this.handleChange}
            />
          </div>

          <div className="row-container">
            <Button type="submit" name="button" status={this.props.statuses[statusRef]}>
              {t('GLOBAL.submit')}
            </Button>

            <ErrorMessage status={this.props.statuses[statusRef]} />
          </div>
        </FormModal>
      </ReactModal>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

ModalForgotPassword.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
}

ModalForgotPassword.defaultProps = {}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalForgotPassword))
