import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { hasLanguageChanged } from 'helpers/props-watcher'

import { getListPrescribers } from 'store/modules/Prescriber/actions.js'
import {
  editAssessment,
  createAssessment,
  getAssessments,
  deleteAssessment,
  getLevels,
  getProgramObjectives,
} from 'store/modules/Assessment/actions.js'
import {
  FETCH_EXERCISES_RESOURCES_REQUEST,
  FETCH_PROGRAMS_RESOURCES_REQUEST,
  getTasksResources,
} from 'store/modules/Resource/actions.js'
import { clearStatus } from 'store/modules/Status/actions.js'
import { programSearchCreator } from 'store/modules/Program/actions.js'
import { searchPhysicalTests } from 'store/modules/PhysicalTest/actions.js'
import { getEmailsTemplate } from 'store/modules/Email/actions.js'

import Button from 'components/Button'
import Icon from 'components/Icon'
import ModalCreateAssessment from './components/ModalCreateAssessment'
import AdminTable from 'components/AdminTable'
import Pagination from 'components/Pagination'
import ConfirmDeleteMessage from 'components/ConfirmDeleteMessage'

import { TYPE_EMAIL_INACTIVITY } from '../../constants/constants'

import './style.css'

const baseClass = 'page-assessments'

const statusRefAssessment = 'PageAssessments-createEditAssessment'
const statusRefDeleteAssessment = 'PageAssessments-deleteAssessment'
const statusRefGetPrescriberLevels = 'PageAssessments-getPrescriberLevels'

class PageAssessments extends Component {
  constructor() {
    super()

    this.state = {
      openModalAssessment: false,
      openModalAssessmentTime: Date.now(),
      openModalConfirmationDelete: false,
      searchData: {},
      validationError: {},
    }

    this.fetchPrescriber = this.fetchPrescriber.bind(this)
    this.fetchAssessments = this.fetchAssessments.bind(this)
    this.fetchAssessmentsRessources = this.fetchAssessmentsRessources.bind(this)
    this.handleSubmitAssessment = this.handleSubmitAssessment.bind(this)
    this.handleDeleteAssessement = this.handleDeleteAssessement.bind(this)

    this.getHeaderRow = this.getHeaderRow.bind(this)
    this.buildTableRows = this.buildTableRows.bind(this)

    this.handleOpenModalAssessment = this.handleOpenModalAssessment.bind(this)
    this.handleCloseModalAssessment = this.handleCloseModalAssessment.bind(this)

    this.handleOpenModalConfirmationDelete = this.handleOpenModalConfirmationDelete.bind(this)
    this.handleCloseModalConfirmationDelete = this.handleCloseModalConfirmationDelete.bind(this)

    this.handleElementClick = this.handleElementClick.bind(this)
  }

  componentDidMount() {
    this.props.dispatch({ type: FETCH_PROGRAMS_RESOURCES_REQUEST })
    this.fetchPrescriber()
    this.props.dispatch(
      programSearchCreator({
        public: true,
        limit: 500,
      })
    )
    this.props.dispatch(searchPhysicalTests({}))
    this.fetchAssessments({})
    this.props.dispatch(
      getEmailsTemplate({
        type: TYPE_EMAIL_INACTIVITY,
      })
    )
    this.fetchTasksResources()
    if (!this.props.isExerciseResourceDataFetched()) {
      this.props.dispatch({ type: FETCH_EXERCISES_RESOURCES_REQUEST })
    }
    this.fetchAssessmentsRessources(null, true)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.statuses[statusRefAssessment] &&
      (this.props.statuses[statusRefAssessment] || {}).state === 'finished'
    ) {
      if (
        !prevProps.statuses[statusRefAssessment] ||
        (prevProps.statuses[statusRefAssessment] || {}).state !== 'finished'
      ) {
        this.fetchAssessments()

        setTimeout(() => {
          this.handleCloseModalAssessment()
        }, 500)
      }
    }

    if (
      this.props.statuses[statusRefDeleteAssessment] &&
      (this.props.statuses[statusRefDeleteAssessment] || {}).state === 'finished'
    ) {
      if (
        !prevProps.statuses[statusRefDeleteAssessment] ||
        (prevProps.statuses[statusRefDeleteAssessment] || {}).state !== 'finished'
      ) {
        this.fetchAssessments()

        setTimeout(() => {
          this.handleCloseModalConfirmationDelete()
        }, 500)
      }
    }

    if (hasLanguageChanged(this.props.account, prevProps.account)) {
      this.fetchAssessments({})
    }
  }

  fetchAssessments(data) {
    this.setState(
      {
        searchData: data,
      },
      () => {
        this.props.dispatch(getAssessments({ ...data, prescriber_id: data?.prescriber_id }))
      }
    )
  }

  fetchAssessmentsRessources(prescriber_id, isPrescriberChanged) {
    if (!prescriber_id) {
      this.props.dispatch(
        getLevels({
          statusRef: isPrescriberChanged ? statusRefGetPrescriberLevels : null,
        })
      )
    } else {
      this.props.dispatch(
        getLevels({
          prescriber_id: prescriber_id,
          statusRef: isPrescriberChanged ? statusRefGetPrescriberLevels : null,
        })
      )
    }

    this.props.dispatch(getProgramObjectives({}))
  }

  fetchPrescriber() {
    this.props.dispatch(getListPrescribers({}))
  }

  fetchTasksResources = () => {
    this.props.dispatch(getTasksResources({}))
  }

  handleOpenModalAssessment(item) {
    this.setState({
      openModalAssessment: item || true,
      openModalAssessmentTime: Date.now(),
    })
  }

  handleCloseModalAssessment() {
    this.props.dispatch(
      clearStatus({
        statusRef: statusRefAssessment,
      })
    )

    this.setState({
      openModalAssessment: null,
      openModalAssessmentTime: null,
    })
  }

  handleOpenModalConfirmationDelete(item) {
    this.setState({
      openModalConfirmationDelete: item,
    })
  }

  handleCloseModalConfirmationDelete() {
    this.setState({
      openModalConfirmationDelete: null,
    })
  }

  handleSubmitAssessment(data) {
    if (!data) return
    const { t, assessmentRessources, levelObjectives } = this.props
    const { levels } = assessmentRessources

    if (!data.prescriber || data.prescriber_id != data.prescriber.id) {
      data.prescriber = this.props.prescribers.find(
        (prescriber) => prescriber.id === data.prescriber_id
      )
    }

    let errors = []
    if (!data.physical_tests.length && data.force_physical_test) {
      errors.push([
        `${errors.length + 1 + ' ' + t('GLOBAL.error')} : ${t(
          'PageAssessments.ModalCreateAssessment.physical-tests-required-error'
        )}`,
      ])
    } else if (data.physical_tests.length && data.force_physical_test && data.prescriber) {
      let missingLevels = []
      let foundLevels = this.props.assessmentRessources?.levels.filter((level) => {
        let foundLevelInPT =
          data.physical_tests.filter((physical_test) => {
            return physical_test.levels.map((pt_level) => pt_level.id).indexOf(level.level_id) > -1
          }).length > 0

        if (!foundLevelInPT) {
          missingLevels.push(level.name)
        }
        return foundLevelInPT
      })

      if (foundLevels.length != this.props.assessmentRessources.levels.length) {
        errors.push([
          `${errors.length + 1 + ' ' + t('GLOBAL.error')} : ${t(
            'PageAssessments.ModalCreateAssessment.physical-tests-required-for-levels-error'
          )} ${missingLevels.join(', ')}`,
        ])
      }
    }

    if (!data.programs.length) {
      errors.push([
        `${errors.length + 1 + ' ' + t('GLOBAL.error')} : ${t(
          'PageAssessments.ModalCreateAssessment.programs-required-error'
        )}`,
      ])
    }

    if (
      data.prescriber &&
      !data.prescriber.is_specific &&
      data.level_objectives.length != levels.length
    ) {
      errors.push([
        `${errors.length + 1 + ' ' + t('GLOBAL.error')} : ${t(
          'PageAssessments.ModalCreateAssessment.objectives-required-error'
        )}`,
      ])
    } else if (!data.prescriber && data.level_objectives.length != levelObjectives.length) {
      errors.push([
        `${errors.length + 1 + ' ' + t('GLOBAL.error')} : ${t(
          'PageAssessments.ModalCreateAssessment.objectives-required-error'
        )}`,
      ])
    }

    if (
      (data.prescriber || {}).is_specific &&
      data.levels.length !== this.props.assessmentRessources.levels.length
    ) {
      let missingLevels = this.props.assessmentRessources.levels.filter((level) => {
        return data.levels.indexOf(level.level_id) === -1
      })
      let missingLevelsString = missingLevels
        .map((level) => {
          return level.name
        })
        .join(', ')

      errors.push([
        `${
          errors.length + 1 + ' ' + t('PageAssessments.ModalCreateAssessment.level-errors')
        } : ${missingLevelsString}`,
      ])
    }

    if (errors.length) {
      this.setState({
        validationError: {
          data: {
            errors: errors,
          },
          state: 'error',
        },
      })
      return
    } else {
      this.setState({
        validationError: {},
      })
    }

    if ((data.assessment_tasks || []).length > 0) {
      data.configurations.is_combined = true
    } else {
      data.configurations.is_combined = false

      if (data.id && data.nomenclature) {
        data.assessment_tasks = []
      }
    }

    delete data['task']
    delete data['combined_assessment']

    if (data.id) {
      this.props.dispatch(
        editAssessment({
          ...data,
          programs: [
            ...data.programs.map((program) => {
              return program.id
            }),
          ],
          physical_tests: [
            ...data.physical_tests.map((physical_test) => {
              return physical_test.id
            }),
          ],
          level_objectives: data.level_objectives,
          custom_programs: {
            ...data.custom_programs,
            programs: data.custom_programs.programs.map((program) => {
              return {
                program_objective_id:
                  typeof (program.program_objective || {}).id === 'number'
                    ? (program.program_objective || {}).id
                    : null,
                position: program.position,
                type: program.type || 'objective',
              }
            }),
          },
          configurations: data.configurations,
          statusRef: statusRefAssessment,
        })
      )
    } else {
      this.props.dispatch(
        createAssessment({
          ...data,
          programs: [
            ...data.programs.map((program) => {
              return program.id
            }),
          ],
          physical_tests: [
            ...data.physical_tests.map((physical_test) => {
              return physical_test.id
            }),
          ],
          configurations: data.configurations,
          level_objectives: data.level_objectives,
          custom_programs: {
            ...data.custom_programs,
            programs: (data.custom_programs.programs || []).map((program) => {
              return {
                program_objective_id:
                  typeof (program.program_objective || {}).id === 'number'
                    ? (program.program_objective || {}).id
                    : null,
                position: program.position,
                type: program.type || 'objective',
              }
            }),
          },
          statusRef: statusRefAssessment,
        })
      )
    }
  }

  handleDeleteAssessement(item) {
    if (!item) return

    this.props.dispatch(
      deleteAssessment({
        id: item.id,
        statusRef: statusRefDeleteAssessment,
      })
    )
  }

  handleElementClick(target, target_id) {
    this.props.history.push(`/${target}/${target_id}`)
  }

  getHeaderRow() {
    const { t } = this.props

    return {
      columns: [
        { value: t('PageAssessments.subtitle-1') },
        { value: t('PageAssessments.subtitle-2') },
        { value: t('PageAssessments.subtitle-3') },
        { value: t('PageAssessments.subtitle-4') },
        { value: t('PageAssessments.subtitle-5') },
        { value: t('PageAssessments.subtitle-6') },
        { value: t('PageAssessments.subtitle-7') },
      ],
    }
  }

  buildTableRows() {
    return ((this.props.searchResults || {}).assessments || []).map((item) => {
      return {
        columns: [
          { value: item.name },
          { value: (item.prescriber || {}).full_name },
          {
            value:
              item.force_primary_questionnaire === 1 || item.force_primary_questionnaire === true
                ? '√'
                : '',
          },
          {
            value: (
              <div className="list-details">
                {(item.physical_tests || []).map((physical_test, idx) => (
                  <div
                    key={idx}
                    className="list-details-element"
                    onClick={() => this.handleElementClick('test', physical_test.id)}
                  >
                    {physical_test.label}
                  </div>
                ))}
              </div>
            ),
          },
          {
            value: (
              <div className="list-details">
                {(item.programs || []).map((program, idx) => (
                  <div
                    key={idx}
                    className="list-details-element"
                    onClick={() => this.handleElementClick('program', program.id)}
                  >
                    {program.name}
                  </div>
                ))}
              </div>
            ),
          },
          {
            value: (
              <Button className="narrow" onClick={() => this.handleOpenModalAssessment(item)}>
                <Icon className="small" src={'pen.svg'} />
              </Button>
            ),
          },
          {
            value: (
              <Button
                className="narrow"
                onClick={() => this.handleOpenModalConfirmationDelete(item)}
              >
                <Icon className="small" src={'trash.svg'} />
              </Button>
            ),
          },
        ],
      }
    })
  }

  render() {
    const { t, levelObjectives } = this.props

    return (
      <div className={`${baseClass} page`}>
        <div className={`${baseClass}-title`}>{t('PageAssessments.title')}</div>

        <Button onClick={() => this.handleOpenModalAssessment({})}>
          {t('PageAssessments.create')}
        </Button>

        <AdminTable headerRow={this.getHeaderRow()} rows={this.buildTableRows()} />

        <Pagination
          searchData={this.state.searchData}
          limit={this.props.searchResults.limit}
          offset={this.props.searchResults.offset}
          total={this.props.searchResults.total_count}
          fetchData={this.fetchAssessments}
        />

        {this.state.openModalAssessment && (
          <ModalCreateAssessment
            key={this.state.openModalAssessmentTime}
            account={this.props.account}
            programs={this.props.programs}
            physicalTests={this.props.physicalTests}
            assessments={(this.props.searchResults || {}).assessments}
            assessmentRessources={this.props.assessmentRessources}
            emails={this.props.emails}
            prescribers={this.props.prescribers}
            initialData={this.state.openModalAssessment}
            tasks={this.props.tasks}
            handleClose={this.handleCloseModalAssessment}
            handleSubmit={this.handleSubmitAssessment}
            fetchPrescriber={this.fetchPrescriber}
            fetchAssessmentsRessources={this.fetchAssessmentsRessources}
            status={this.props.statuses[statusRefAssessment]}
            statusGetLevels={this.props.statuses[statusRefGetPrescriberLevels]}
            validationError={this.state.validationError}
            resources={this.props.resources}
            defaultLevelObjectives={levelObjectives}
          />
        )}

        {this.state.openModalConfirmationDelete && (
          <ConfirmDeleteMessage
            data={this.state.openModalConfirmationDelete}
            handleCancel={this.handleCloseModalConfirmationDelete}
            handleConfirm={this.handleDeleteAssessement}
            statusRef={this.props.statuses[statusRefDeleteAssessment]}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  statuses: state.Status.statuses,
  prescribers: state.Prescriber.prescribersList,
  searchResults: state.Assessment.searchResults,
  physicalTests: state.PhysicalTest.physicalTests,
  programs: state.Program.programs,
  emails: state.Email.emails,
  assessmentRessources: state.Assessment.ressources,
  account: state.Account.user,
  tasks: state.Resource.tasks,
  resources: state.Resource.exercises,
  levelObjectives: state.Resource.programs.levels,
  isExerciseResourceDataFetched: () =>
    Object.values(state.Resource.exercises).some((c) => c.length > 0),
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PageAssessments))
)
