import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { getResponseErrors } from 'services/errors'

import './style.css'

const baseClass = 'error-message'

class ErrorMessage extends Component {
  constructor() {
    super()

    this.state = {}
  }

  hasError() {
    return this.props.status && this.props.status.state === 'error'
  }

  render() {
    return (
      <div>
        {this.hasError() && this.props.status.data && (
          <div className={`${baseClass} ${this.hasError() ? 'has-error' : ''}`}>
            <div className={`${baseClass}-error-text`}>
              {getResponseErrors(this.props.status.data)}
            </div>
          </div>
        )}
      </div>
    )
  }
}

ErrorMessage.propTypes = {
  status: PropTypes.object,
}

ErrorMessage.defaultProps = {
  status: {},
}

export default ErrorMessage
