import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './style.css'

import FormGeneric from 'components/FormGeneric'

class FormModal extends Component {
  render() {
    return (
      <FormGeneric
        id={this.props.id}
        className={`modal-form ${this.props.className}`}
        onSubmit={this.props.onSubmit}
        action={this.props.action}
        method={this.props.method}
      >
        {this.props.children}
      </FormGeneric>
    )
  }
}

FormModal.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  action: PropTypes.string,
  method: PropTypes.string,
}

export default FormModal
