import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import './style.css'

class PaymentsInfo extends Component {
  render() {
    const { t } = this.props

    return (
      <div className="profile-modal-payment-info form-profile">
        {t('ModalProfile.PaymentsInfo.title')}
        <div className="data-row">
          <table>
            <thead>
              <tr>
                <th>{t('ModalProfile.PaymentsInfo.subtitle-1')}</th>
                <th>{t('ModalProfile.PaymentsInfo.subtitle-2')}</th>
                <th>{t('ModalProfile.PaymentsInfo.subtitle-3')}</th>
                <th>{t('ModalProfile.PaymentsInfo.subtitle-4')}</th>
                <th>{t('ModalProfile.PaymentsInfo.subtitle-5')}</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td data-label="Reçu de paiement pour la commande N°">REFG10002623</td>
                <td data-label="Cours Co : ">
                  <a href="http://v2.getphy.com/communities/55f72cb08a1a3a42178b45b7">
                    Aquagym | Barcelona
                  </a>
                </td>

                <td data-label="Date transaction : "> 28 03 2018 </td>
                <td data-label="Tarif :"> 25 Euro(s) </td>
                <td data-label="Télécharger">
                  {' '}
                  <a
                    href="http://v2.getphy.com/download-payment/REFG10002623"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Télécharger{' '}
                  </a>{' '}
                </td>
              </tr>
              <tr>
                <td data-label="Reçu de paiement pour la commande N°">REFG10002645</td>
                <td data-label="Cours Co : ">
                  <a href="http://v2.getphy.com/communities/55f72cb08a1a3a42178b45b7">
                    Aquagym | Barcelona
                  </a>
                </td>

                <td data-label="Date transaction : "> 05 04 2018 </td>
                <td data-label="Tarif :"> 1 Euro(s) </td>
                <td data-label="Télécharger">
                  {' '}
                  <a
                    href="http://v2.getphy.com/download-payment/REFG10002645"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Télécharger{' '}
                  </a>{' '}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default withTranslation()(PaymentsInfo)
