export const CREATE_SESSION_REQUEST = 'session/CREATE_SESSION_REQUEST'
export const UPDATE_SESSION = 'session/UPDATE_SESSION'
export const DUPLICATE_SESSION = 'session/DUPLICATE_SESSION'

export const UPDATE_SESSION_EXERCISE = 'session/UPDATE_SESSION_EXERCISE'

export const SESSION_MAKE_PUBLIC_REQUEST = 'session/SESSION_MAKE_PUBLIC_REQUEST'
export const SESSION_DELETE_REQUEST = 'session/SESSION_DELETE_REQUEST'
export const SESSION_DELETE_PERMANENTLY = 'session/SESSION_DELETE_PERMANENTLY'
export const SESSION_SEARCH_SUBMIT = 'session/SESSION_SEARCH_SUBMIT'
export const SET_SESSIONS_SEARCH_RESULTS = 'session/SET_SESSIONS_SEARCH_RESULTS'
export const SET_SESSION_REQUESTS_SEARCH_RESULTS = 'session/SET_SESSION_REQUESTS_SEARCH_RESULTS'
export const UPDATE_SESSION_CACHE = 'session/UPDATE_SESSION_CACHE'
export const UPDATE_SESSION_CACHE_SET = 'session/UPDATE_SESSION_CACHE_SET'
export const UPDATE_SESSIONS_CACHE = 'session/UPDATE_SESSIONS_CACHE'
export const UPDATE_SESSIONS_CACHE_SET = 'session/UPDATE_SESSIONS_CACHE_SET'
export const SESSION_FETCH_SUBMIT = 'session/SESSION_FETCH_SUBMIT'
export const SESSION_ADD_EXERCISE_REQUEST = 'session/SESSION_ADD_EXERCISE_REQUEST'
export const SESSION_REMOVE_EXERCISE_REQUEST = 'session/SESSION_REMOVE_EXERCISE_REQUEST'
// export const EXERCISE_STEP_SET_IMAGE_REQUEST = 'EXERCISE_STEP_SET_IMAGE_REQUEST';
export const UPDATE_SEARCH_SESSION_FILTERS = 'session/UPDATE_SEARCH_SESSION_FILTERS'

export function createSessionCreator(payload) {
  return { type: CREATE_SESSION_REQUEST, payload: payload }
}

export function updateSession(payload) {
  return { type: UPDATE_SESSION, payload: payload }
}

export function duplicateSession(payload) {
  return { type: DUPLICATE_SESSION, payload: payload }
}

export function updateSessionExercise(payload) {
  return { type: UPDATE_SESSION_EXERCISE, payload: payload }
}

export function addExercisetoSessionCreator(payload) {
  return { type: SESSION_ADD_EXERCISE_REQUEST, payload: payload }
}

export function removeExercisetoSessionCreator(payload) {
  return { type: SESSION_REMOVE_EXERCISE_REQUEST, payload: payload }
}

export function sessionSearchCreator(payload) {
  return { type: SESSION_SEARCH_SUBMIT, payload: payload }
}

export function getSessionCreator(payload) {
  return { type: SESSION_FETCH_SUBMIT, payload: payload }
}

export function deleteSessionPermanently(payload) {
  return { type: SESSION_DELETE_PERMANENTLY, payload: payload }
}

export function updateSessionSearchFilters(payload) {
  return { type: UPDATE_SEARCH_SESSION_FILTERS, payload }
}

export function makeSessionPublic(payload) {
  return { type: SESSION_MAKE_PUBLIC_REQUEST, payload: payload }
}

export function deleteSession(payload) {
  return { type: SESSION_DELETE_REQUEST, payload: payload }
}
