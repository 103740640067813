import React, { Component } from 'react'
import { connect } from 'react-redux'

import FormChallengeCreate from 'components/FormChallengeCreate'

import { clearStatus } from 'store/modules/Status/actions.js'

import { CREATE_CHALLENGE_REQUEST } from 'store/modules/Challenge/actions.js'
import {
  FETCH_CHALLENGES_RESOURCES_REQUEST,
  FETCH_SESSIONS_RESOURCES_REQUEST,
} from 'store/modules/Resource/actions.js'

import './style.css'

const statusRef = 'PageChallengesCreate'

class PageChallengesCreate extends Component {
  constructor() {
    super()
    this.state = {}

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  //TODO: Getting called on app initialisation. Why? Should only get called on modal open.
  componentDidMount() {
    this.props.dispatch(clearStatus({ statusRef: statusRef }))

    this.props.dispatch({ type: FETCH_CHALLENGES_RESOURCES_REQUEST })
    this.props.dispatch({ type: FETCH_SESSIONS_RESOURCES_REQUEST })
  }

  componentDidUpdate(prevProps) {
    if (this.props.statuses[statusRef]) {
      if (
        this.props.statuses[statusRef].state === 'finished' &&
        prevProps.statuses[statusRef].state !== 'finshed'
      ) {
        const id = this.props.statuses[statusRef].data.newId

        setTimeout(() => {
          this.props.history.push(`/challenge/${id}`)
        }, 700)
      }
    }
  }

  handleSubmit(challengeForm, exercises) {
    let exercisesFormatted = exercises.map((item) => {
      return {
        duration: item.duration,
        repetitions: item.repetitions,
        postures: item.postures,
        exercise_id: item.exercise.id,
      }
    })

    this.props.dispatch({
      type: CREATE_CHALLENGE_REQUEST,
      payload: {
        ...challengeForm,
        exercises: exercisesFormatted,
        statusRef: statusRef,
      },
    })
  }

  render() {
    return (
      <div className="page-challenges-create">
        TODO - A challenge shoudl always ahve exactly 3 exercises ?
        <FormChallengeCreate
          resources={this.props.resources}
          resourcesSessions={this.props.resourcesSessions}
          statusSubmit={this.props.statuses[statusRef]}
          handleSubmit={this.handleSubmit}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  activeModal: state.Modal.activeModal,
  role: state.Account.role,
  resources: state.Resource.challenges,
  resourcesSessions: state.Resource.sessions,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PageChallengesCreate)
