import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import './style.css'

const imageUrl = process.env.REACT_APP_STORAGE_URL

const SessionCard = ({ t, item, className }) => {
  const [isSelected, setIsSelected] = useState(false)

  const stepsImages = () =>
    item.images.map((item, index) => (
      <div
        style={{ backgroundImage: `url(${imageUrl}${item})` }}
        className="session-card-step-image"
        key={`stepsImages_${index}`}
      />
    ))

  return (
    <div
      className={`session-card ${className || ''} ${isSelected ? 'card-selected' : ''}`}
      onClick={() => setIsSelected(!isSelected)}
    >
      <div className="session-card-steps-images-container">{stepsImages()}</div>

      <div className="session-card-text-container">
        <div className="session-card-text-title" title={item.name}>
          {item.name}
        </div>

        <div className="objective">{item.objective.name}</div>

        <div className="level">{item.level.name}</div>

        <div className="muscles">
          {(item.muscles || [])
            .filter((item) => item.percentage > 0)
            .map((item, index, array) => (
              <span key={`muscle_${index}`}>
                {`${item.name}${index < array.length - 1 ? ', ' : ''}`}
              </span>
            ))}
        </div>

        <div className="level">
          {item.nb_exercises} {t('SessionCard.text')}
        </div>
      </div>
    </div>
  )
}

SessionCard.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
}

export default withTranslation()(SessionCard)
