import { takeEvery, takeLatest, put, select, all, cancelled } from 'redux-saga/effects'

import * as ApiService from 'services/api'

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions.js'
import { ProtectedCall } from 'services/protected.api'

import * as challengeActions from 'store/modules/Challenge/actions.js'

/*************************************************/
/** Selectors **/
const allSelector = (state) => {
  return state.Challenge.challenges
}

/*************************************************/
/** Sagas **/
export default function* sagaWatcher() {
  yield takeEvery(challengeActions.CREATE_CHALLENGE_REQUEST, createChallengeRequestSaga)
  yield takeEvery(challengeActions.UPDATE_CHALLENGE, updateChallengeSaga)
  yield takeEvery(challengeActions.UPDATE_CHALLENGE_EXERCISE, updateChallengeExerciseSaga)

  yield takeEvery(challengeActions.CHALLENGE_MAKE_PUBLIC_REQUEST, makePublicRequestSaga)
  yield takeEvery(challengeActions.CHALLENGE_DELETE_REQUEST, deleteChallengeRequestSaga)
  yield takeLatest(challengeActions.CHALLENGE_SEARCH_SUBMIT, challengeSearchSaga)
  yield takeEvery(challengeActions.UPDATE_CHALLENGE_CACHE, updateCacheSaga)
  yield takeEvery(challengeActions.CHALLENGE_FETCH_SUBMIT, challengeFetchSaga)
  yield takeEvery(challengeActions.CHALLENGE_ADD_EXERCISE_REQUEST, challengeAddExercise)
  yield takeEvery(challengeActions.CHALLENGE_REMOVE_EXERCISE_REQUEST, challengeRemoveExercise)
}

function* createChallengeRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Creating Challenge' })
    )

    let result = yield ProtectedCall(ApiService.CreateChallenge, data.payload)

    if (data.payload.exercises) {
      yield all(
        data.payload.exercises.map((item) => {
          return put(
            challengeActions.addExercisetoChallengeCreator({
              challengeId: result.data.data.challenge.id,
              exercise: item,
              statusRef: data.payload.statusRef,
              onFinishData: { newId: result.data.data.challenge.id },
            })
          )
        })
      )
    }

    yield put({
      type: challengeActions.UPDATE_CHALLENGE_CACHE,
      payload: [result.data.data.challenge],
    })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Challenge created',
        data: { newId: result.data.data.challenge.id },
      })
    )
  } catch (e) {
    console.log('*** Error caught in createChallengeRequestSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Challenge creation error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* updateChallengeSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Update Challenge' })
    )

    let result = yield ProtectedCall(ApiService.UpdateChallenge, data.payload.id, data.payload)

    if (data.payload.exercises) {
      const oldExercises = result.data.data.challenge.challenge_exercises
      const newExercises = data.payload.exercises

      let exercisesToSet = []
      let exercisesToRemove = []
      let exercisesToUpdate = []

      oldExercises.map((item) => {
        let remove = true

        newExercises.map((item2) => {
          if (item2.id && parseInt(item.id, 10) === parseInt(item2.id, 10)) {
            remove = false

            delete item2.exercise_id

            if (JSON.stringify(item) !== JSON.stringify(item2)) {
              exercisesToUpdate.push(item2)
            }
          }

          return null
        })

        if (remove) {
          exercisesToRemove.push(item.id)
        }

        return null
      })

      newExercises.map((item) => {
        let add = true

        oldExercises.map((item2) => {
          if (parseInt(item.id, 10) === parseInt(item2.id, 10)) {
            add = false
          }

          return null
        })

        if (add) {
          exercisesToSet.push(item)
        }

        return null
      })

      yield all(
        exercisesToRemove.map((item) => {
          return put(
            challengeActions.removeExercisetoChallengeCreator({
              challengeId: result.data.data.challenge.id,
              exerciseId: item,
              statusRef: data.payload.statusRef,
            })
          )
        })
      )

      yield all(
        exercisesToSet.map((item) => {
          return put(
            challengeActions.addExercisetoChallengeCreator({
              challengeId: result.data.data.challenge.id,
              exercise: item,
              statusRef: data.payload.statusRef,
            })
          )
        })
      )

      yield all(
        exercisesToUpdate.map((item) => {
          return put(
            challengeActions.updateChallengeExercise({
              challengeId: result.data.data.challenge.id,
              exercise: item,
              statusRef: data.payload.statusRef,
            })
          )
        })
      )
    }

    yield put({ type: challengeActions.UPDATE_CHALLENGE_CACHE, payload: [result.data.data] })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Update challenge finished',
      })
    )
  } catch (e) {
    console.log('*** Error caught in updateChallengeSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Update challenge error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* challengeSearchSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Searching Challenges' })
    )

    let result = yield ProtectedCall(ApiService.ChallengeSearch, data.payload)

    if ((data.payload || {}).pending === 1) {
      yield put({
        type: challengeActions.SET_CHALLENGE_REQUESTS_SEARCH_RESULTS,
        payload: result.data.data,
      })
    } else {
      yield put({ type: challengeActions.SET_CHALLENGES_SEARCH_RESULTS, payload: result.data.data })
    }

    yield put({
      type: challengeActions.UPDATE_CHALLENGE_CACHE,
      payload: result.data.data.challenges,
    })
  } catch (e) {
    console.log('*** Error caught in challengeSearchSaga ***')
    yield put({ type: challengeActions.SET_CHALLENGES_SEARCH_RESULTS, payload: [] })
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Challenges search failed',
        state: 'error',
      })
    )
  } finally {
    if (yield cancelled()) {
      console.log('cancelled')
      yield put(
        decrementStatusCreator({
          statusRef: data.payload.statusRef,
          message: 'Challenges search cancelled',
        })
      )
    } else {
      yield put(
        decrementStatusCreator({
          statusRef: data.payload.statusRef,
          message: 'Challenges search finished',
        })
      )
    }
  }
}

function* challengeFetchSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Fetching Challenge' })
    )

    let result = yield ProtectedCall(ApiService.ChallengeFetch, data.payload)

    yield put({
      type: challengeActions.UPDATE_CHALLENGE_CACHE,
      payload: [result.data.data.challenge],
    })

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Challenge fetched' })
    )
  } catch (e) {
    console.log('*** Error caught in challengeFetchSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Challenge fetch error',
        state: 'error',
      })
    )
  }
}

function* makePublicRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Make challenge public',
      })
    )

    let result = yield ProtectedCall(ApiService.ChallengeUpdate, data.payload.id, { public: 1 })

    yield put({ type: challengeActions.UPDATE_CHALLENGE_CACHE, payload: [result.data.data] })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Making challenge public complete',
      })
    )
  } catch (e) {
    console.log('*** Error caught in challenge.makePublicRequestSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Making challenge public error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* deleteChallengeRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Deleting challenge' })
    )

    yield ProtectedCall(ApiService.ChallengeDelete, data.payload.id)

    yield put({
      type: challengeActions.UPDATE_CHALLENGE_CACHE,
      payload: [data.payload],
      delete: true,
    })

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Challenge deleted' })
    )
  } catch (e) {
    console.log('*** Error caught in challenge.deleteChallengeRequestSaga ***')

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Challenge deletion error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* challengeAddExercise(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Adding exercise to challenge',
      })
    )

    const { challengeId } = data.payload

    const exercise = data.payload.exercise

    yield ProtectedCall(ApiService.ChallengeAddExercise, challengeId, exercise)

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Adding exercise to challenge complete',
        data: data.payload.onFinishData,
      })
    )
  } catch (e) {
    console.log('*** Error caught in challengeAddExercise ***')

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Adding exercise to challenge error',
        state: 'error',
      })
    )
  }
}

function* challengeRemoveExercise(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Removing exercise to challenge',
      })
    )

    const { challengeId, exerciseId } = data.payload

    yield ProtectedCall(ApiService.ChallengeRemoveExercise, challengeId, exerciseId, data)

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Removing exercise to challenge complete',
      })
    )
  } catch (e) {
    console.log('*** Error caught in challengeRemoveExercise ***')

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Removing exercise to challenge error',
        state: 'error',
      })
    )
  }
}

function* updateChallengeExerciseSaga(data) {
  try {
    yield put(
      incrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating challenge exercise',
      })
    )

    const { challengeId } = data.payload

    const exercise = data.payload.exercise

    yield ProtectedCall(ApiService.UpdateChallengeExercise, challengeId, exercise.id, exercise)

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating challenge exercise complete',
      })
    )
  } catch (e) {
    console.log('*** Error caught in updateChallengeExerciseSaga ***')

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Updating challenge exercise error',
        state: 'error',
      })
    )
  }
}

function* updateCacheSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return
  }

  let challenges = yield select(allSelector) || []

  if (data.delete) {
    data.payload.map((item) => {
      let id = item
      if (typeof id === 'object') {
        id = id.id
      }

      // id = parseInt(id);
      let challenge = challenges[id]
      let index = challenges.indexOf(challenge)
      if (index > -1) {
        challenges.splice(index, 1)
      }

      return null
    })
  } else {
    data.payload.map((item) => {
      challenges[item.id] = item

      return null
    })
  }

  yield put({ type: challengeActions.UPDATE_CHALLENGE_CACHE_SET, payload: challenges })
}
