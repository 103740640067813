import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import FormModal from 'components/FormModal'
import Button from 'components/Button'

import { CLOSE_MODALS } from 'store/modules/Modal/actions.js'

import './style.css'

class ModalRegisterSuccess extends Component {
  constructor() {
    super()
    this.state = {}

    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  handleCloseModal() {
    this.props.dispatch({ type: CLOSE_MODALS })
  }

  render() {
    const { t } = this.props

    return (
      <ReactModal
        className="modal-register-success modal-content"
        isOpen={this.props.activeModal === 'register_success'}
        onRequestClose={this.handleCloseModal}
        shouldCloseOnOverlayClick={true}
        overlayClassName="modal-overlay"
      >
        <FormModal
          id="login-form"
          className="login-form transparent"
          onSubmit={this.handleCloseModal}
        >
          <h1>{t('ModalRegisterSuccess.title')}</h1>
          <div className="register-success-text">{t('ModalRegisterSuccess.text')}</div>

          <div>
            <Button onClick={this.handleCloseModal}>{t('GLOBAL.close')}</Button>
          </div>
        </FormModal>
      </ReactModal>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  activeModal: state.Modal.activeModal,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalRegisterSuccess))
