import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import Button from 'components/Button'
import './style.css'

class NotificationInfo extends Component {
  render() {
    const { t } = this.props

    return (
      <div className="profile-modal-personal-info form-profile">
        <div className="data-row">
          <label>{t('ModalProfile.NotificationInfo.label-1')}</label>
        </div>

        <div className="data-row data-row">
          <label>
            <input
              className="notifications"
              name="notifications"
              type="checkbox"
              value={this.props.userData.notifications || ''}
              onChange={this.props.inputChange}
            />
            {t('ModalProfile.NotificationInfo.label-2')}
          </label>

          <br />

          <label>
            <input
              className="notifications"
              name="notifications"
              type="checkbox"
              value={this.props.userData.notifications || ''}
              onChange={this.props.inputChange}
            />

            {t('ModalProfile.NotificationInfo.label-3')}
          </label>

          <br />

          <label>
            <input
              className="notifications"
              checked="checked"
              name="notifications"
              type="checkbox"
              value={this.props.userData.notifications || ''}
              onChange={this.props.inputChange}
            />
            <span />
            {t('ModalProfile.NotificationInfo.label-4')}
          </label>

          <br />
        </div>

        <div>
          <Button onClick={this.props.cancelClick}>{t('GLOBAL.cancel')}</Button>

          <Button onClick={this.props.submitClick}>{t('GLOBAL.submit')}</Button>
        </div>
      </div>
    )
  }
}

export default withTranslation()(NotificationInfo)
