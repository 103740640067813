import React, { Component } from 'react'

import './style.css'

class DropdownMenu extends Component {
  render() {
    return (
      <div className={`header-avatar-dropdown ${this.props.isActive ? 'active' : ''}`}>
        {this.props.dropdownButtons.map((item) => {
          return (
            <div className="dropdown-item" onClick={item.onClick} key={item.text}>
              {item.text}
            </div>
          )
        })}
      </div>
    )
  }
}

export default DropdownMenu
