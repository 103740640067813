import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import FormModal from 'components/FormModal'
import Button from 'components/Button'
import CloseButton from 'components/CloseButton'

import { CLOSE_MODALS, SET_ACTIVE_MODAL } from 'store/modules/Modal/actions.js'
import { REGISTER_REQUEST } from 'store/modules/Account/actions.js'

import './style.css'

class ModalRegister extends Component {
  constructor() {
    super()
    this.state = {
      showModal: false,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        cgu: false,
        authorize_data: true,
      },
    }

    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  handleCloseModal() {
    this.props.dispatch({ type: CLOSE_MODALS })
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    })
  }

  handleSubmit() {
    this.props.dispatch({ type: REGISTER_REQUEST, payload: this.state.form })
  }

  componentDidUpdate(prevProps) {
    const { registerRequestStatus } = this.props
    //After succesful login close login modal
    if (prevProps.registerRequestStatus !== 'success' && registerRequestStatus === 'success') {
      setTimeout(() => {
        this.props.dispatch({ type: SET_ACTIVE_MODAL, payload: 'register_success' })
      }, 900)
    }
  }

  render() {
    const { t } = this.props

    return (
      <ReactModal
        className="modal-content modal-register"
        overlayClassName="modal-overlay"
        isOpen={this.props.activeModal === 'register'}
        onRequestClose={this.handleCloseModal}
        shouldCloseOnOverlayClick={true}
      >
        <CloseButton onClick={this.handleCloseModal} />
        <FormModal
          id="register-form"
          className="register-form transparent"
          onSubmit={this.handleSubmit}
        >
          <h1>{t('ModalRegister.title')}</h1>

          <div className="row-container">
            <input
              type="text"
              name="first_name"
              value={this.state.form.first_name}
              onChange={this.handleInputChange}
              placeholder={t('ModalRegister.placeholder-1')}
              autoComplete="given-name"
              required="true"
              maxLength={191}
            />
          </div>

          <div className="row-container">
            <input
              type="text"
              name="last_name"
              value={this.state.form.last_name}
              onChange={this.handleInputChange}
              placeholder={t('ModalRegister.placeholder-2')}
              autoComplete="family-name"
              required="true"
              maxLength={191}
            />
          </div>

          <div className="row-container">
            <input
              id="email"
              type="email"
              name="email"
              value={this.state.form.email}
              onChange={this.handleInputChange}
              placeholder={t('ModalRegister.placeholder-3')}
              autoComplete="email"
              required="true"
            />
          </div>

          <div className="row-container">
            <input
              type="password"
              name="password"
              value={this.state.form.password}
              onChange={this.handleInputChange}
              placeholder={t('ModalRegister.placeholder-4')}
              autoComplete="new-password"
              required="true"
            />
          </div>

          <div className="row-container">
            <input
              type="password"
              name="password_confirmation"
              value={this.state.form.password_confirmation}
              onChange={this.handleInputChange}
              placeholder={t('ModalRegister.placeholder-5')}
              autoComplete="new-password"
              required="true"
            />
          </div>

          <div className="row-container check-row">
            <input
              type="checkbox"
              name="cgu"
              value={this.state.form.cgu}
              onChange={this.handleInputChange}
              required="true"
            />

            <label htmlFor="accept-terms">
              {t('ModalRegister.text-1.part1')}
              <a href="/terms">
                <strong>{t('ModalRegister.text-1.part2')}</strong>
              </a>
              {t('ModalRegister.text-1.part3')}
            </label>
          </div>

          <div className="row-container check-row">
            <input
              type="checkbox"
              name="authorize_data"
              value={this.state.form.authorize_data}
              onChange={this.handleInputChange}
              required="true"
            />

            <label htmlFor="collect-data">
              {t('ModalRegister.text-2.part1')}
              <strong>{t('ModalRegister.text-2.part2')}</strong>
              {t('ModalRegister.text-2.part3')}
              <strong>{t('ModalRegister.text-2.part4')}</strong>
              {t('ModalRegister.text-2.part5')}
              <strong>{t('ModalRegister.text-2.part6')}</strong>
              {t('ModalRegister.text-2.part7')}
              <strong>{t('ModalRegister.text-2.part8')}</strong>
              {t('ModalRegister.text-2.part9')}
            </label>
          </div>

          <div className="register-status"></div>

          {this.props.registerError && (
            <div className="danger alert">
              <p className="error-msg">{t('ModalRegister.submission-error')}</p>
            </div>
          )}

          <div className="row-container">
            <Button className="cancel-button" onClick={this.handleCloseModal}>
              {t('GLOBAL.cancel')}
            </Button>

            <Button type="submit" className="signup-button" onClick={() => {}}>
              {t('ModalRegister.submission')}
            </Button>
          </div>
        </FormModal>
      </ReactModal>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  activeModal: state.Modal.activeModal,
  registerError: state.Account.registerError,
  registerRequestStatus: state.Account.registerRequestStatus,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalRegister))
