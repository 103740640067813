import * as resourceActions from 'store/modules/Resource/actions.js'

const initialState = {
  exercises: {
    objectives: [],
    counts: [],
    positions: [],
    levels: [],
    muscles: [],
    equipment: [],
    interests: [],
  },
  sessions: {
    target_audiences: [],
    levels: [],
    objectives: [],
    prescribers: [],
  },
  challenges: {
    levels: [],
    challenge_types: [],
    postures: [],
  },
  programs: {
    levels: [],
  },
  tasks: [],
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case resourceActions.UPDATE_RESOURCE_CACHE_SET:
      return {
        ...state,
        [action.resource]: action.payload,
      }

    default:
      return state
  }
}
