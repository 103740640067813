import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './style.css'

const imageUrl = process.env.REACT_APP_STORAGE_URL

class ExerciseSteps extends Component {
  constructor() {
    super()

    this.state = {}

    // this.handleMonthChange = this.handleMonthChange.bind(this);
  }

  render() {
    return (
      <div className={`exercise-steps ${this.props.className || ''}`}>
        {this.props.exercise.steps &&
          this.props.exercise.steps.map((item, index) => {
            return (
              <div
                className={
                  item.in_gif === 1 ? 'page-exercise-step' : 'page-exercise-step-deactivated'
                }
                key={`steps_${index}`}
              >
                <div
                  className="page-exercise-step-photo"
                  style={{ backgroundImage: `url(${imageUrl}${item.photo})` }}
                />

                <div className="page-exercise-step-description">
                  <div className="page-exercise-step-description-number">{index + 1}</div>

                  <div className="page-exercise-step-description-text">{item.description}</div>
                </div>
              </div>
            )
          })}
      </div>
    )
  }
}

ExerciseSteps.propTypes = {
  exercise: PropTypes.object,
}

ExerciseSteps.defaultProps = {
  exercise: {},
}

export default ExerciseSteps
