import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import './style.css'

class ExerciseAdditionalInfo extends Component {
  constructor() {
    super()

    this.state = {}
  }

  render() {
    const { t } = this.props

    return (
      <div className={`exercise-additional-info ${this.props.className || ''}`}>
        <div className="page-exercise-objective-details">
          <div className="page-exercise-objective-details-title">
            {t('ExerciseAdditionalInfo.title-4')}
          </div>

          <div className="page-exercise-objective-details-list">
            {this.props.exercise.objective_details}
          </div>
        </div>

        <div className="page-exercise-recommendations">
          <div className="page-exercise-recommendations-title">
            {t('ExerciseAdditionalInfo.title-2')}
          </div>

          <div className="page-exercise-recommendations-list">
            {this.props.exercise.recommendations}
          </div>
        </div>

        <div className="page-exercise-equipment">
          <div className="page-exercise-equipment-title">{t('ExerciseAdditionalInfo.title-3')}</div>

          <div className="page-exercise-equipment-list">
            {this.props.exercise.equipment &&
              this.props.exercise.equipment.map((item, index, array) => {
                return (
                  <div key={`equipment_${index}`}>
                    {`${item.name}${index < array.length - 1 ? ', ' : ''}`}
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    )
  }
}

ExerciseAdditionalInfo.propTypes = {}

ExerciseAdditionalInfo.defaultProps = {}

export default withTranslation()(ExerciseAdditionalInfo)
