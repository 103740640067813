import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next'

import FormModal from 'components/FormModal'
import CloseButton from 'components/CloseButton'

import LanguagePicker from 'containers/LanguagePicker'

import { updateUserLanguage } from 'store/modules/Account/actions.js'

import './style.css'

const statusRefChangeLanguage = 'ModalLanguage-changeLanguage'

class ModalLanguage extends Component {
  constructor() {
    super()

    this.state = {}

    this.inputChange = this.inputChange.bind(this)
  }

  inputChange(value) {
    this.props.dispatch(
      updateUserLanguage({
        language_id: value,
        statusRef: statusRefChangeLanguage,
      })
    )
  }

  render() {
    const { t } = this.props

    return (
      <ReactModal
        isOpen={true}
        className="modal-content modal-language"
        overlayClassName="modal-overlay"
        onRequestClose={this.props.handleCloseModal}
        shouldCloseOnOverlayClick={true}
      >
        <CloseButton onClick={this.props.handleCloseModal} />

        <FormModal
          id="language-form"
          className="language-form transparent"
          onSubmit={this.handleSubmit}
        >
          <h1>{t('ModalLanguage.title')}</h1>

          <LanguagePicker handleOnChange={(value) => this.inputChange(value)} />
        </FormModal>
      </ReactModal>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalLanguage))
