import { takeEvery, put, select } from 'redux-saga/effects'

import * as ApiService from 'services/api'

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions.js'
import { ProtectedCall } from 'services/protected.api'

import * as equipmentActions from 'store/modules/Equipment/actions.js'

/*************************************************/
/** Selectors **/
const allSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Equipment.equipments))
}

/*************************************************/
/** Sagas **/
export default function* sagaWatcher() {
  yield takeEvery(equipmentActions.EQUIPMENT_GET_ALL, equipmentGetAllSaga)
  yield takeEvery(equipmentActions.CREATE_EQUIPMENT_REQUEST, createEquipmentRequestSaga)
  yield takeEvery(equipmentActions.UPDATE_EQUIPMENT, updateEquipmentSaga)
  yield takeEvery(equipmentActions.UPDATE_EQUIPMENT_CACHE, updateCacheSaga)

  yield takeEvery(equipmentActions.EQUIPMENT_DELETE_REQUEST, deleteEquipmentRequestSaga)
}

function* createEquipmentRequestSaga(data) {
  try {
    console.log('here')
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Creating Equipment' })
    )

    let result = yield ProtectedCall(ApiService.CreateEquipment, data.payload)
    console.log('here')

    yield put({
      type: equipmentActions.UPDATE_EQUIPMENT_CACHE,
      payload: [result.data.data.equipment],
    })

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Equipment created' })
    )
  } catch (e) {
    console.log('*** Error caught in createEquipmentRequestSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Equipment creation error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* updateEquipmentSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Update Equipment' })
    )

    let result = yield ProtectedCall(ApiService.UpdateEquipment, data.payload.id, data.payload)

    yield put({
      type: equipmentActions.UPDATE_EQUIPMENT_CACHE,
      payload: [result.data.data.equipment],
    })

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Update equipment finished',
      })
    )
  } catch (e) {
    console.log('*** Error caught in updateEquipmentSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Update equipment error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* equipmentGetAllSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Fetching Equipment' })
    )

    let result = yield ProtectedCall(ApiService.EquipmentGetAll, data.payload)

    yield put({
      type: equipmentActions.UPDATE_EQUIPMENT_CACHE,
      payload: result.data.data.equipment,
    })

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Equipment fetched' })
    )
  } catch (e) {
    console.log('*** Error caught in equipmentFetchSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Equipment fetch error',
        state: 'error',
      })
    )
  }
}

function* deleteEquipmentRequestSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Deleting equipment' })
    )

    yield ProtectedCall(ApiService.EquipmentDelete, data.payload.id)

    yield put({
      type: equipmentActions.UPDATE_EQUIPMENT_CACHE,
      payload: [data.payload],
      delete: true,
    })

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Equipment deleted' })
    )
  } catch (e) {
    console.log('*** Error caught in equipment.deleteEquipmentRequestSaga ***')

    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Equipment deletion error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* updateCacheSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return
  }

  let equipments = yield select(allSelector) || []

  if (data.delete) {
    data.payload.map((item) => {
      let id = item
      if (typeof id === 'object') {
        id = id.id
      }

      delete equipments[id]

      return null
    })
  } else {
    data.payload.map((item) => {
      equipments[item.id] = item

      return null
    })
  }

  yield put({ type: equipmentActions.UPDATE_EQUIPMENT_CACHE_SET, payload: equipments })
}
