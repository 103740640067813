import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import ReactModal from 'react-modal'

import FormModal from 'components/FormModal'
import Button from 'components/Button'
import CloseButton from 'components/CloseButton'

import './style.css'

const baseClass = 'action-confirmation-modal'

class ActionConfirmation extends Component {
  constructor() {
    super()

    this.state = {}
  }

  render() {
    const {
      t,
      isOpen,
      status,
      handleCancel,
      confirmationTitle,
      actionTitle,
      handleConfirm,
      isForEdit,
    } = this.props

    return (
      <div className={`${baseClass}`}>
        <ReactModal
          className={`${baseClass} modal-content`}
          isOpen={isOpen}
          onRequestClose={handleCancel}
          shouldCloseOnOverlayClick={true}
          overlayClassName="modal-overlay"
        >
          <CloseButton onClick={handleCancel} />

          <FormModal className={`${baseClass}-form`} onSubmit={() => {}}>
            {!isForEdit && (
              <div>
                <h1>{status.state === 'error' ? status.data?.error : t(confirmationTitle)}</h1>

                {status.state === 'error' && (
                  <div className={`${baseClass}-form-list`}>
                    {status.data?.data?.sessions?.map((session, idx) => {
                      return (
                        <Link key={idx} to={`/session/${session.id}`}>
                          {session.name}
                        </Link>
                      )
                    })}

                    {status.data?.data?.challenges?.map((challenge, idx) => {
                      return (
                        <Link key={idx} to={`/challenge/${challenge.id}`}>
                          {challenge.name}
                        </Link>
                      )
                    })}

                    {status.data?.data?.programs?.map((program, idx) => {
                      return (
                        <Link key={idx} to={`/program/${program.id}`}>
                          {program.name}
                        </Link>
                      )
                    })}

                    {status.data?.data?.physical_tests?.map((physical_test, idx) => {
                      return (
                        <Link key={idx} to={`/test/${physical_test.id}`}>
                          {physical_test.label}
                        </Link>
                      )
                    })}
                  </div>
                )}
              </div>
            )}

            {this.props.isForEdit && (
              <div>
                <h1>{t('PageExercise.ActionConfirmation.no-edit-title')}</h1>
                <div className={`${baseClass}-form-list`}>
                  {this.props.data?.physical_tests?.map((physical_test, idx) => {
                    return (
                      <Link key={idx} to={`/test/${physical_test.id}`}>
                        {physical_test.label}
                      </Link>
                    )
                  })}
                </div>
              </div>
            )}

            <Button type="button" onClick={handleCancel}>
              {t('GLOBAL.cancel')}
            </Button>
            {!this.props.isForEdit && (
              <Button
                disabled={status.state === 'error'}
                type="button"
                className={`delete-button`}
                onClick={() => handleConfirm()}
                status={status}
              >
                {t(actionTitle)}
              </Button>
            )}

            {/* <ErrorMessage status={status} /> */}
          </FormModal>
        </ReactModal>
      </div>
    )
  }
}

ActionConfirmation.propTypes = {
  confirmationTitle: PropTypes.string,
  actionTitle: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  status: PropTypes.object,
}

ActionConfirmation.defaultProps = {
  confirmationTitle: 'GLOBAL.are-you-sure',
  actionTitle: 'GLOBAL.delete',
  status: {},
}

export default withTranslation()(ActionConfirmation)
