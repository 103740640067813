import React, { Component } from 'react'
import { connect } from 'react-redux'

import './style.css'

import ModalLogin from 'containers/modals/ModalLogin'
import ModalRegister from 'containers/modals/ModalRegister'
import ModalRegisterSuccess from 'containers/modals/ModalRegisterSuccess'
import ModalConfirmEmail from 'containers/modals/ModalConfirmEmail'
import ModalProfile from 'containers/modals/ModalProfile'
import ModalNotification from 'containers/modals/ModalNotification'

class ModalAllList extends Component {
  render() {
    return (
      <div className="modal-all-list">
        <ModalLogin />
        <ModalRegister />
        <ModalRegisterSuccess />
        <ModalConfirmEmail />
        <ModalNotification />

        {this.props.isLoggedIn && (
          <div>
            <ModalProfile />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  role: state.Account.role,
  isLoggedIn: state.Account.isLoggedIn,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalAllList)
