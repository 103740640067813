export const CREATE_EXERCISE_REQUEST = 'exercise/CREATE_EXERCISE_REQUEST'
export const UPDATE_EXERCISE = 'exercise/UPDATE_EXERCISE'
export const UPDATE_EXERCISE_STEP = 'exercise/UPDATE_EXERCISE_STEP'

export const EXERCISE_MAKE_PUBLIC_REQUEST = 'exercise/EXERCISE_MAKE_PUBLIC_REQUEST'
export const EXERCISE_DELETE_REQUEST = 'exercise/EXERCISE_DELETE_REQUEST'
export const EXERCISE_DELETE_PERMANENTLY = 'exercise/EXERCISE_DELETE_PERMANENTLY'
export const EXERCISE_SEARCH_SUBMIT = 'exercise/COMMUNITY_SEARCH_SUBMIT'
export const SET_EXERCISES_SEARCH_RESULTS = 'exercise/SET_EXERCISES_SEARCH_RESULTS'
export const SET_EXERCISE_REQUESTS_SEARCH_RESULTS = 'exercise/SET_EXERCISE_REQUESTS_SEARCH_RESULTS'
export const UPDATE_EXERCISE_CACHE = 'exercise/UPDATE_EXERCISE_CACHE'
export const UPDATE_EXERCISE_CACHE_SET = 'exercise/UPDATE_EXERCISE_CACHE_SET'
export const UPDATE_EXERCISES_CACHE = 'exercise/UPDATE_EXERCISES_CACHE'
export const UPDATE_EXERCISES_CACHE_SET = 'exercise/UPDATE_EXERCISES_CACHE_SET'
export const EXERCISE_FETCH_SUBMIT = 'exercise/EXERCISE_FETCH_SUBMIT'
export const UPDATE_SEARCH_EXERCISE_FILTERS = 'exercise/UPDATE_SEARCH_EXERCISE_FILTERS'

export function createExerciseCreator(payload) {
  return { type: CREATE_EXERCISE_REQUEST, payload: payload }
}

export function updateExercise(payload) {
  return { type: UPDATE_EXERCISE, payload: payload }
}

export function exerciseSearchCreator(payload) {
  return { type: EXERCISE_SEARCH_SUBMIT, payload: payload }
}

export function getExerciseCreator(payload) {
  return { type: EXERCISE_FETCH_SUBMIT, payload: payload }
}

export function deleteExercisePermanently(payload) {
  return { type: EXERCISE_DELETE_PERMANENTLY, payload: payload }
}

export function updateExerciseSearchFilters(payload) {
  return { type: UPDATE_SEARCH_EXERCISE_FILTERS, payload }
}
