import React, { Component } from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import { getResponseErrors } from 'services/errors'

import { isEqual } from 'lodash'

import { hasLanguageChanged } from 'helpers/props-watcher'

import AdminTable from 'components/AdminTable'
import FormGeneric from 'components/FormGeneric'
import FormModal from 'components/FormModal'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import CloseButton from 'components/CloseButton'
import Accordion from 'components/Accordion'

import Configurations from './components/Configurations/index'
import Programs from './components/Programs/index'
import PhysicalTests from './components/PhysicalTests/index'
import Objectives from './components/Objectives/index'

import './style.css'
import Texteditor from 'containers/Texteditor/index'

const baseClass = 'modal-assessment'

class ModalCreateAssessment extends Component {
  constructor() {
    super()

    this.state = {
      form: {
        assessment_tasks: [],
        end_assessment_message: {},
        configurations: {
          inactivity_periods: [],
        },
      },
      touchedQuestionaryOrTest: false,
      task: {},
      currentErrors: {},
      assessmentsRelatedToCurrentPrescriber: [],
      prescriberLevels: [],
    }

    this.setInitialData = this.setInitialData.bind(this)
    this.getPrescriberOptions = this.getPrescriberOptions.bind(this)
    this.getPhaseOptions = this.getPhaseOptions.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleInputChangeConfiguration = this.handleInputChangeConfiguration.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleChangeInactivityPeriods = this.handleChangeInactivityPeriods.bind(this)
    this.headerRow = this.headerRow.bind(this)
    this.buildTableRows = this.buildTableRows.bind(this)
    this.phaseChange = this.phaseChange.bind(this)
    this.startAfterChange = this.startAfterChange.bind(this)
    this.additionalInfoChange = this.additionalInfoChange.bind(this)
    this.handleAddProgramsIntoAssessments = this.handleAddProgramsIntoAssessments.bind(this)
    this.handleRemoveProgramsFromAssessments = this.handleRemoveProgramsFromAssessments.bind(this)
    this.handleAddPhysicalTestIntoAssessments = this.handleAddPhysicalTestIntoAssessments.bind(this)
    this.handleRemovePhysicalTestFromAssessments = this.handleRemovePhysicalTestFromAssessments.bind(
      this
    )
    this.handleAddObjectiveIntoAssessments = this.handleAddObjectiveIntoAssessments.bind(this)
    this.handleRemoveObjectiveFromAssessments = this.handleRemoveObjectiveFromAssessments.bind(this)
    this.handleAddCustomProgramObjectivesIntoAssessments = this.handleAddCustomProgramObjectivesIntoAssessments.bind(
      this
    )
    this.handleRemoveCustomProgramObjectiveFromAssessments = this.handleRemoveCustomProgramObjectiveFromAssessments.bind(
      this
    )
    this.handleCustomProgramsDragEnd = this.handleCustomProgramsDragEnd.bind(this)
    this.canAssignCustomPrograms = this.canAssignCustomPrograms.bind(this)
    this.getAssessmentsRelatedToCurrentPrescriber = this.getAssessmentsRelatedToCurrentPrescriber.bind(
      this
    )
    this.getLevelObjectives = this.getLevelObjectives.bind(this)
  }

  componentDidMount() {
    this.setInitialData()
  }

  componentDidUpdate(prevProps) {
    const { assessmentRessources, defaultLevelObjectives } = this.props

    if (this.props.initialData) {
      if (!isEqual(this.props.initialData, prevProps.initialData)) {
        this.setInitialData()
      }
    }

    if (
      prevProps.status !== this.props.status ||
      prevProps.validationError !== this.props.validationError
    ) {
      let newResponseStatus = this.props.status || {}
      let currentErrors = {
        data: {
          errors: [],
        },
        state:
          newResponseStatus.state === 'error' || this.props.validationError?.state === 'error'
            ? 'error'
            : newResponseStatus.state,
      }
      if (currentErrors.state === 'error') {
        currentErrors.data.errors = [
          ...(getResponseErrors(newResponseStatus.data) || []),
          ...(getResponseErrors(this.props.validationError.data) || []),
        ]
        this.setState({
          currentErrors,
        })
      } else {
        this.setState({
          currentErrors: this.props.status,
        })
      }
    }

    if (!isEqual(prevProps.assessmentRessources, assessmentRessources)) {
      const prescriberLevels = defaultLevelObjectives.filter((levelObjective) => {
        assessmentRessources.levels.map((level) => level.level_id === levelObjective.id)
      })

      this.setState({
        prescriberLevels,
      })
    }

    if (hasLanguageChanged(this.props.account, prevProps.account)) {
      this.setInitialData()
    }
  }

  getInitialLevelObjectives(levelObjectives) {
    const { defaultLevelObjectives } = this.props
    const initialLevelObjectives = []

    levelObjectives.forEach((levelObjective) => {
      const initialLevelObjective = {
        level_id: levelObjective.id,
        program_objectives: [],
      }

      levelObjective.program_objectives.forEach((objective) => {
        defaultLevelObjectives.forEach((defaultLevelObjective) => {
          if (defaultLevelObjective.id === levelObjective.id) {
            defaultLevelObjective.program_objectives.forEach((programObjective) => {
              if (programObjective.id === objective.id) {
                initialLevelObjective.program_objectives.push(objective.id)
              } else {
                const programObjectiveChildrenFoundIndex = programObjective.children.findIndex(
                  (child) => child.id === objective.id
                )

                if (programObjectiveChildrenFoundIndex !== -1) {
                  initialLevelObjective.program_objectives.push(objective.id)
                }
              }
            })
          }
        })
      })
      if (initialLevelObjective.program_objectives.length > 0)
        initialLevelObjectives.push(initialLevelObjective)
    })

    return initialLevelObjectives
  }

  setInitialData() {
    if (!this.props.initialData) return

    let toSet = { ...this.props.initialData }

    toSet.id = this.props.initialData.id || null
    toSet.name = this.props.initialData.name || ''
    toSet.force_primary_questionnaire = this.props.initialData.force_primary_questionnaire || false
    toSet.force_physical_test = this.props.initialData.force_physical_test || false
    toSet.prescriber_id = (this.props.initialData.prescriber || {}).id || null
    toSet.next_assessment_id = this.props.initialData.next_assessment_id || null
    toSet.programs = this.props.initialData.programs || []
    toSet.levels = []
    toSet.physical_tests = this.props.initialData.physical_tests || []
    toSet.configurations = this.props.initialData.configurations || {}
    toSet.level_objectives = this.props.initialData.level_objectives
      ? this.getInitialLevelObjectives(this.props.initialData.level_objectives)
      : []
    toSet.configurations.custom_programs_assignment =
      this.props.initialData.custom_programs_assignment || false
    toSet.custom_programs = this.props.initialData.custom_programs || {}

    if ((this.props.initialData.configurations || {}).is_combined === '1') {
      toSet.combined_assessment = true
    } else {
      toSet.combined_assessment = false
    }
    let tasks = []
    if (this.props.initialData.tasks) {
      toSet.tasks &&
        toSet.tasks.forEach((assessment_task) => {
          tasks.push({
            id: assessment_task.id,
            task_id: assessment_task.task.id,
            start_after: assessment_task.start_after,
            additional_info: assessment_task.additional_info,
          })
        })
    }
    toSet.assessment_tasks = this.props.initialData.tasks || []
    ;((toSet.custom_programs || {}).programs || []).map((program) => {
      if (!program.program_objective) {
        program.program_objective = this.getConstants()[program.type]
      }

      return null
    })

    Object.keys(
      (this.props.initialData.configurations || {}).physical_test_welcome_message || []
    ).map((key) => {
      const item = this.props.initialData.configurations.physical_test_welcome_message[key] || {}

      toSet.configurations.physical_test_welcome_message = {
        ...toSet.configurations.physical_test_welcome_message,
        [key]: item.value,
      }

      return null
    })

    Object.keys((this.props.initialData.configurations || {}).end_assessment_message || []).map(
      (key) => {
        const item = this.props.initialData.configurations.end_assessment_message[key] || {}

        toSet.configurations.end_assessment_message = {
          ...toSet.configurations.end_assessment_message,
          [key]: item.value,
        }

        return null
      }
    )
    Object.keys(
      (this.props.initialData.configurations || {}).physical_test_expired_message || []
    ).map((key) => {
      const item = this.props.initialData.configurations.physical_test_expired_message[key] || {}

      toSet.configurations.physical_test_expired_message = {
        ...toSet.configurations.physical_test_expired_message,
        [key]: item.value,
      }

      return null
    })

    Object.keys((this.props.initialData.configurations || {}).session_intro_message || []).map(
      (key) => {
        const item = this.props.initialData.configurations.session_intro_message[key] || {}

        toSet.configurations.session_intro_message = {
          ...toSet.configurations.session_intro_message,
          [key]: item.value,
        }

        return null
      }
    )

    toSet.programs.map((program) => {
      if (toSet.levels.indexOf(program.level.id) === -1) {
        toSet.levels.push(program.level.id)
      }

      return null
    })

    if (toSet.prescriber_id) {
      this.props.fetchAssessmentsRessources(toSet.prescriber_id, false)
    }
    toSet.end_assessment_message = {}

    let assessments = this.props.assessments.filter((assessment) => {
      return (assessment.prescriber || {}).id === toSet.prescriber_id
    })

    this.setState({
      form: toSet,
      assessmentsRelatedToCurrentPrescriber: assessments,
    })
  }

  getConstants() {
    const { t } = this.props

    return {
      // constant define in Backend
      user_selected_objective: {
        id: 'user_selected_objective',
        name: t('PageAssessments.ModalCreateAssessment.constant_user_selected'), //User selected objective
        type: 'user_selected_objective',
        parent_id: null,
      },
      personal: {
        id: 'personal',
        name: t('PageAssessments.ModalCreateAssessment.constant_personal'), //"Personal"
        type: 'personal',
        parent_id: null,
      },
      complimentary: {
        id: 'complimentary',
        name: t('PageAssessments.ModalCreateAssessment.constant_complimentary'), // "Complimentary",
        type: 'complimentary',
        parent_id: null,
      },
    }
  }

  getProgramObjectiveWithId(programObjectives, objective_id) {
    if ((programObjectives || []).length === 0 || !objective_id) return
    let toReturn = null

    programObjectives.forEach((programObjective) => {
      if (programObjective.id === objective_id) {
        toReturn = programObjective
      } else {
        programObjective.children.forEach((programObjectiveChild) => {
          if (programObjectiveChild.id === objective_id) {
            toReturn = programObjectiveChild
          }
        })
      }
    })

    return toReturn
  }

  handleInputChangeConfiguration(event, values = null) {
    const target = event.target
    const name = target.name
    let value = target.type === 'checkbox' ? target.checked : target.value
    if (value === 'true' || value === 'false') {
      value = value === 'true'
    }

    let configurations = JSON.parse(JSON.stringify(this.state.form.configurations))

    if (name.includes('.')) {
      let splitName = name.split('.')

      if (values) {
        configurations = {
          ...configurations,
          [splitName[0]]: values,
        }
      } else {
        configurations = {
          ...configurations,
          [splitName[0]]: {
            ...configurations[splitName[0]],
            [splitName[1]]: value,
          },
        }
      }
    } else {
      configurations = {
        ...configurations,
        [name]: value,
      }
    }

    let newEvent = { ...event, target: { ...event.target } }
    newEvent.target.name = 'configurations'
    newEvent.target.value = configurations

    this.handleInputChange(newEvent)
  }

  handleTextEditorChange = (_html, name, isTranslatable, collection, field = null) => {
    if (field) {
      this.setState({
        [collection]: {
          ...this.state[collection],
          [field]: {
            ...this.state[collection][field],
            [name]: _html,
          },
        },
      })
    } else {
      this.setState({
        [collection]: {
          ...this.state[collection],
          [name]: _html,
        },
      })
    }
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? Boolean(target.checked) : target.value
    const name = target.name

    let form = {}

    if (name.includes('.')) {
      let splitName = name.split('.')
      form = {
        ...this.state.form,
        [splitName[0]]: {
          ...this.state.form[splitName[0]],
          [splitName[1]]: value,
        },
      }
    } else {
      form = {
        ...this.state.form,
        [name]: value,
      }
    }

    if (name === 'combined_assessment' && value === true) {
      form.configurations.force_pq_program_assign = false
    }

    this.setState({
      form,
    })
  }

  handleChangeInactivityPeriods(inactivityPeriods) {
    if (!inactivityPeriods) return

    this.setState({
      form: {
        ...this.state.form,
        configurations: {
          ...this.state.form.configurations,
          inactivity_periods: inactivityPeriods,
        },
      },
    })
  }

  headerRow() {
    const { t } = this.props

    return {
      columns: [
        { value: t('PageAssessments.ModalCreateAssessment.phase.label-step') },
        { value: t('PageAssessments.ModalCreateAssessment.phase.label-start-after') },
        { value: t('PageAssessments.ModalCreateAssessment.phase.label-additional-info') },
        { value: t('GLOBAL.empty') },
      ],
    }
  }

  buildTableRows() {
    const { t } = this.props

    const assessment_tasks = this.state.form.assessment_tasks

    if (!assessment_tasks) {
      return []
    }

    return (assessment_tasks || []).map((item, index) => {
      const id = item.task ? item.task.id : item.task_id

      return {
        columns: [
          { value: this.getPhaseById(id).name },
          { value: item.start_after },
          { value: <div dangerouslySetInnerHTML={{ __html: item.additional_info }} /> },
          {
            value: (
              <Button onClick={() => this.handleDeletePhase(index)}>{t('GLOBAL.delete')}</Button>
            ),
          },
        ],
      }
    })
  }

  getPhaseById = (id) => {
    if (!id) return

    let toReturn = {}

    this.props.tasks.map((item) => {
      if (parseInt(id, 10) === parseInt(item.id, 10)) {
        toReturn = item
      }

      return null
    })

    return toReturn
  }

  phaseChange(value) {
    this.setState({
      task: {
        ...this.state.task,
        task_id: value.value,
      },
    })
  }

  startAfterChange(ev) {
    const { value } = ev.target

    this.setState({
      task: {
        ...this.state.task,
        start_after: value,
      },
    })
  }

  additionalInfoChange(ev) {
    const { value } = ev.target

    this.setState({
      task: {
        ...this.state.task,
        additional_info: value,
      },
    })
  }

  handleAddPhase = () => {
    const task = { ...this.state.task }
    let assessment_tasks = [...this.state.form.assessment_tasks]

    assessment_tasks.push(task)

    const sortAssessmentTask = assessment_tasks.sort((a, b) => a.start_after - b.start_after)

    this.setState({
      form: {
        ...this.state.form,
        assessment_tasks: sortAssessmentTask,
      },
      task: {},
    })
  }

  handleDeletePhase(index) {
    let assessment_tasks = this.state.form.assessment_tasks

    assessment_tasks.splice(index, 1)

    this.setState({
      form: {
        ...this.state.form,
        assessment_tasks,
      },
    })
  }

  handleSelectChange(name, value) {
    if (name === 'prescriber_id') {
      this.props.fetchAssessmentsRessources(value.value, true)
    }

    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: value.value,
        },
      },
      () => {
        if (name === 'prescriber_id') {
          this.getAssessmentsRelatedToCurrentPrescriber(value.value)
          this.handleSelectChange('next_assessment_id', { value: null })
          this.setState({
            form: {
              ...this.state.form,
              level_objectives: [],
            },
          })
        }
      }
    )
  }

  handleAddProgramsIntoAssessments() {
    let programs = [...this.state.form.programs]
    let all_programs = [...this.props.programs]
    let program_id = this.state.form.program_id
    let levels = [...this.state.form.levels]

    if (!program_id) return

    programs.push(all_programs[program_id])
    levels.push(all_programs[program_id].level.id)

    this.setState({
      form: {
        ...this.state.form,
        programs,
        levels,
        program_id: null,
      },
    })
  }

  handleRemoveProgramsFromAssessments(item) {
    if (!item) return

    let programs = [...this.state.form.programs]
    let levels = [...this.state.form.levels]

    programs.splice(item.index, 1)
    levels.splice(item.index, 1)

    this.setState({
      form: {
        ...this.state.form,
        programs,
        levels,
      },
    })
  }

  handleAddPhysicalTestIntoAssessments() {
    let physical_tests = [...this.state.form.physical_tests]
    let all_physical_tests = [...this.props.physicalTests]
    let physical_test_id = this.state.form.physical_test_id

    if (!physical_test_id) return

    physical_tests.push(all_physical_tests[physical_test_id])

    this.setState({
      form: {
        ...this.state.form,
        physical_tests,
        physical_test_id: null,
      },
    })
  }

  handleRemovePhysicalTestFromAssessments(item) {
    if (!item) return

    let physical_tests = [...this.state.form.physical_tests]

    physical_tests.splice(item.index, 1)

    this.setState({
      form: {
        ...this.state.form,
        physical_tests,
      },
    })
  }

  getObjectivesChildren(objectives) {
    let toReturn = []

    if (objectives && objectives.length > 0) {
      objectives.map((item) => {
        if (item.children && item.children.length > 0) {
          toReturn = [...toReturn, ...item.children]
        }
      })
    }

    return toReturn
  }

  handleAddObjectiveIntoAssessments(level, objective_id) {
    const { form } = this.state
    const { level_objectives } = form
    const foundObjectiveIndex = level_objectives.findIndex(
      (levelObjective) => levelObjective.level_id === level.id
    )
    if (foundObjectiveIndex === -1) {
      level_objectives.push({
        level_id: level.id,
        program_objectives: [objective_id],
      })
    } else {
      level_objectives[foundObjectiveIndex].program_objectives.push(objective_id)
    }

    this.setState({
      form: {
        ...this.state.form,
        level_objectives,
      },
    })
  }

  handleRemoveObjectiveFromAssessments(item, level) {
    let levelObjectiveToDelete = null
    let level_objectives = [...this.state.form.level_objectives]
    level_objectives.map((level_objective, index) => {
      if (level_objective.level_id === level.id) {
        const relatedObjective = level_objective.program_objectives.findIndex(
          (objective) => objective === item.id
        )
        if (relatedObjective !== -1) {
          level_objective.program_objectives.splice(relatedObjective, 1)
          if (level_objective.program_objectives.length === 0) levelObjectiveToDelete = index
        }
      }
    })

    if (levelObjectiveToDelete !== null) level_objectives.splice(levelObjectiveToDelete, 1)
    this.setState({
      form: {
        ...this.state.form,
        level_objectives,
      },
    })
  }

  getPrescriberOptions() {
    let prescribers = (Object.keys(this.props.prescribers) || []).map((key) => {
      const item = this.props.prescribers[key]
      return { value: item.id, label: item.full_name }
    })

    prescribers.unshift({
      value: null,
      label: '-----------',
    })

    return prescribers
  }

  getPhaseOptions() {
    let tasks = (Object.keys(this.props.tasks || []) || []).map((key) => {
      const item = this.props.tasks[key]
      return { value: item.id, label: item.name }
    })

    tasks.unshift({
      value: null,
      label: '-----------',
    })

    return tasks
  }

  canAssignCustomPrograms() {
    if (!this.state.form.prescriber_id) {
      return false
    } else {
      const prescriber = this.props.prescribers.find(
        (prescriber) => prescriber.id === this.state.form.prescriber_id
      )

      if (prescriber && prescriber.is_specific) {
        return false
      }

      return true
    }
  }

  handleAddCustomProgramObjectivesIntoAssessments() {
    let programs = [...((this.state.form.custom_programs || {}).programs || [])]
    let custom_program_objective_id = this.state.form.custom_program_objective_id
    let all_program_objectives = (this.props.assessmentRessources || {}).program_objectives || []

    if (!custom_program_objective_id) return

    let index = programs.length
    let custom_program_objective =
      typeof custom_program_objective_id === 'number'
        ? this.getProgramObjectiveWithId(all_program_objectives, custom_program_objective_id)
        : this.getConstants()[custom_program_objective_id]

    programs.push({
      id: null,
      program_objective: custom_program_objective,
      position: index,
      type: custom_program_objective.type,
    })

    this.setState({
      form: {
        ...this.state.form,
        custom_programs: {
          ...this.state.form.custom_programs,
          programs,
        },
        custom_program_objective_id: null,
      },
    })
  }

  handleRemoveCustomProgramObjectiveFromAssessments(item) {
    if (!item) return

    let programs = [...this.state.form.custom_programs.programs]

    programs.splice(item.index, 1)

    this.setState({
      form: {
        ...this.state.form,
        custom_programs: {
          ...this.state.form.custom_programs,
          programs,
        },
      },
    })
  }

  handleCustomProgramsDragEnd(data) {
    const { destination, source } = data

    if (!destination) return

    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    let programs = [...((this.state.form.custom_programs || {}).programs || [])]
    let temp = {
      ...programs[source.index],
      position: destination.index,
    }
    programs.splice(source.index, 1)
    programs.splice(destination.index, 0, temp)

    programs = this.setPositionParams(programs)

    this.setState({
      form: {
        ...this.state.form,
        custom_programs: {
          ...this.state.form.custom_programs,
          programs,
        },
      },
    })
  }

  setPositionParams(programs) {
    if (!programs) return []

    return programs.map((item, index) => {
      return { ...item, position: index + 1 }
    })
  }

  getAssessmentsRelatedToCurrentPrescriber(prescriberId) {
    let assessments = this.props.assessments.filter((assessment) => {
      return (assessment.prescriber || {}).id === prescriberId
    })

    this.setState({
      assessmentsRelatedToCurrentPrescriber: assessments,
    })
  }
  setTouchedQuestionaryOrTest = (checked) => {
    if (!this.state.touchedQuestionaryOrTest) {
      this.setState({
        touchedQuestionaryOrTest: checked,
      })
    }
  }
  getLevelObjectives = () => {
    const { defaultLevelObjectives, assessmentRessources } = this.props
    const { form } = this.state
    const { prescriber_id } = form
    const { levels } = assessmentRessources

    if (prescriber_id) {
      return defaultLevelObjectives.filter((level) => {
        const index = levels.findIndex((prescriberLevel) => prescriberLevel.level_id === level.id)

        return index !== -1
      })
    }

    return defaultLevelObjectives
  }
  render() {
    const { t, assessmentRessources } = this.props
    const { touchedQuestionaryOrTest, form } = this.state
    const { level_objectives } = form
    const { program_objectives } = assessmentRessources
    const prescriber = this.props.prescribers.find(
      (prescriber) => prescriber.id === this.state.form.prescriber_id
    )
    const next_assessment = this.props.assessments.find(
      (assessment) => assessment.id === this.state.form.next_assessment_id
    )
    const disableSave =
      touchedQuestionaryOrTest &&
      !this.state.form.force_primary_questionnaire &&
      !this.state.form.force_physical_test

    return (
      <ReactModal
        isOpen={true}
        className="modal-content modal-assessment"
        overlayClassName="modal-overlay"
        onRequestClose={this.props.handleClose}
        shouldCloseOnOverlayClick={true}
      >
        <CloseButton onClick={this.props.handleClose} />

        <FormModal
          className="assessment-form transparent"
          onSubmit={() => {
            this.setTouchedQuestionaryOrTest(true)
            this.props.handleSubmit(this.state.form)
          }}
        >
          <h1>{t('PageAssessments.ModalCreateAssessment.title')}</h1>

          <div className="row-container">
            <label>{t('PageAssessments.ModalCreateAssessment.label-1')}</label>

            <div className="left-indent">
              <input
                type="text"
                name="name"
                value={this.state.form.name}
                onChange={this.handleInputChange}
                required="true"
                maxLength={191}
              />
            </div>
          </div>

          <div className="row-container">
            <label>{t('PageAssessments.ModalCreateAssessment.label-2')}</label>

            <div className="left-indent">
              <Select
                onChange={(values) => this.handleSelectChange('prescriber_id', values)}
                isMulti={false}
                value={{
                  value: (prescriber || {}).id,
                  label: (prescriber || {}).full_name,
                }}
                name={'prescriber_id'}
                options={this.getPrescriberOptions()}
                className="search-filter-bar-multi-select search-program"
                classNamePrefix={'react-select'}
                placeholder={t('GLOBAL.search')}
              />
            </div>
          </div>

          <div className="row-container">
            <label>{t('PageAssessments.ModalCreateAssessment.subtitle-next-assessment')} *</label>

            <div className="left-indent">
              <Select
                onChange={(values) => this.handleSelectChange('next_assessment_id', values)}
                value={{
                  value: (next_assessment || {}).id,
                  label: (next_assessment || {}).name,
                }}
                isMulti={false}
                name={'next_assessment_id'}
                options={(this.state.assessmentsRelatedToCurrentPrescriber || []).map(
                  (assessment) => {
                    return { value: assessment.id, label: assessment.name }
                  }
                )}
                className="search-filter-bar-multi-select"
                classNamePrefix={'react-select'}
                placeholder={t('GLOBAL.search')}
              />
            </div>
          </div>

          <div className="row-container">
            <label>{t('PageAssessments.ModalCreateAssessment.label-3')}</label>

            <div className="left-indent">
              <input
                type="checkbox"
                name="force_primary_questionnaire"
                value={this.state.form.force_primary_questionnaire}
                onChange={this.handleInputChange}
                onClick={() => this.setTouchedQuestionaryOrTest(true)}
                checked={this.state.form.force_primary_questionnaire}
              />
            </div>
          </div>

          <div className="row-container">
            <label>{t('PageAssessments.ModalCreateAssessment.label-4')}</label>

            <div className="left-indent">
              <input
                type="checkbox"
                name="force_physical_test"
                value={this.state.form.force_physical_test}
                onChange={this.handleInputChange}
                onClick={() => this.setTouchedQuestionaryOrTest(true)}
                checked={Boolean(this.state.form.force_physical_test)}
              />
            </div>
          </div>

          <div className="row-container">
            <label>{t('PageAssessments.ModalCreateAssessment.label-combined-assessment')}</label>

            <div className="left-indent">
              <input
                type="checkbox"
                name="combined_assessment"
                value={this.state.form.combined_assessment}
                onChange={this.handleInputChange}
                // maxLength={191}
                checked={Boolean(this.state.form.combined_assessment)}
              />
            </div>
          </div>

          {this.state.form.combined_assessment && (
            <div className={`${baseClass}-table`}>
              {this.state.form.assessment_tasks.length > 0 && (
                <AdminTable headerRow={this.headerRow()} rows={this.buildTableRows()} />
              )}

              <FormGeneric className={`${baseClass}-phase-form`} onSubmit={this.handlePhaseAdd}>
                <div className={`${baseClass}-phase-form-container`}>
                  <div className={`${baseClass}-phase-form-firstLine`}>
                    <div>
                      <label>{t('PageAssessments.ModalCreateAssessment.phase.label-step')}</label>
                      <div className="left-indent">
                        <Select
                          onChange={(values) => this.phaseChange(values)}
                          isMulti={false}
                          value={{
                            value: this.state.task.task_id || '',
                            label: (
                              (this.props.tasks || []).find(
                                (obj) => obj.id === this.state.task.task_id
                              ) || []
                            ).name,
                          }}
                          name={'step'}
                          options={this.getPhaseOptions()}
                          className={`${baseClass}-phase-form-input-step search-filter-bar-multi-select search-program`}
                          classNamePrefix={'react-select'}
                          placeholder={t('GLOBAL.search')}
                        />
                      </div>
                    </div>

                    <div className={`${baseClass}-phase-form-input-start-after-wrapper`}>
                      <label>
                        {t('PageAssessments.ModalCreateAssessment.phase.label-start-after')}
                      </label>
                      <input
                        className={`${baseClass}-phase-form-input-start-after`}
                        type="number"
                        step="1"
                        min="0"
                        value={this.state.task.start_after || ''}
                        onChange={this.startAfterChange}
                        required={true}
                      />
                    </div>
                  </div>
                  <div>
                    <label>
                      {t('PageAssessments.ModalCreateAssessment.phase.label-additional-info')}
                    </label>
                    <Texteditor
                      name="additional_info"
                      value={this.state.task.additional_info || ''}
                      handleTextAreaChange={(html, name, isTranslatable) => {
                        this.handleTextEditorChange(html, name, isTranslatable, 'task')
                      }}
                    />
                  </div>
                </div>
                <div className={`${baseClass}-phase-form-button`}>
                  <Button
                    type="button"
                    onClick={this.handleAddPhase}
                    disabled={this.state.task.task_id && this.state.task.start_after ? false : true}
                  >
                    {t('PageAssessments.ModalCreateAssessment.phase.submission')}
                  </Button>
                </div>
              </FormGeneric>
            </div>
          )}

          <Accordion
            className={`${baseClass}-section-content-accordion`}
            sections={[
              {
                title: t('PageAssessments.ModalCreateAssessment.section-title-1'),
                content: (
                  <Configurations
                    emails={this.props.emails}
                    configData={this.state.form.configurations}
                    disableForcePqProgramAssign={Boolean(this.state.form.combined_assessment)}
                    handleInputChange={this.handleInputChangeConfiguration}
                    handleTextEditorChange={(html, name, isTranslatable) => {
                      this.handleTextEditorChange(
                        html,
                        name,
                        isTranslatable,
                        'form',
                        'configurations'
                      )
                    }}
                    handleChangeInactivityPeriods={this.handleChangeInactivityPeriods}
                  />
                ),
              },
              {
                title: t('PageAssessments.ModalCreateAssessment.section-title-3'),
                content: (
                  <PhysicalTests
                    resources={this.props.resources}
                    physical_test_id={this.state.form.physical_test_id}
                    allPhysicalTests={this.props.physicalTests}
                    assessmentPhysicalTests={this.state.form.physical_tests}
                    handleSelectChange={this.handleSelectChange}
                    handleAddPhysicalTestIntoAssessments={this.handleAddPhysicalTestIntoAssessments}
                    handleRemovePhysicalTestFromAssessments={
                      this.handleRemovePhysicalTestFromAssessments
                    }
                  />
                ),
              },
              {
                title: t('PageAssessments.ModalCreateAssessment.section-title-4'),
                content: (
                  <Objectives
                    prescriberSelected={prescriber || null}
                    formLevelObjectives={level_objectives}
                    levelObjectives={this.getLevelObjectives()}
                    handleAddObjectiveIntoAssessments={this.handleAddObjectiveIntoAssessments}
                    handleRemoveObjectiveFromAssessments={this.handleRemoveObjectiveFromAssessments}
                  />
                ),
              },
              {
                title: t('PageAssessments.ModalCreateAssessment.section-title-2'),
                content: (
                  <Programs
                    staticProgramObjectives={this.getConstants()}
                    programObjectives={program_objectives}
                    formLevelObjectives={level_objectives}
                    canAssignCustomPrograms={this.canAssignCustomPrograms()}
                    custom_programs={this.state.form.custom_programs}
                    custom_programs_assignment={
                      (this.state.form.configurations || {}).custom_programs_assignment
                    }
                    custom_program_objective_id={this.state.form.custom_program_objective_id}
                    program_id={this.state.form.program_id}
                    allPrograms={this.props.programs}
                    assessmentPrograms={this.state.form.programs}
                    assessments={this.props.assessments}
                    handleInputChangeConfiguration={this.handleInputChangeConfiguration}
                    handleInputChange={this.handleInputChange}
                    handleSelectChange={this.handleSelectChange}
                    handleAddProgramsIntoAssessments={this.handleAddProgramsIntoAssessments}
                    handleRemoveProgramsFromAssessments={this.handleRemoveProgramsFromAssessments}
                    handleAddCustomProgramObjectivesIntoAssessments={
                      this.handleAddCustomProgramObjectivesIntoAssessments
                    }
                    handleRemoveCustomProgramObjectiveFromAssessments={
                      this.handleRemoveCustomProgramObjectiveFromAssessments
                    }
                    handleCustomProgramsDragEnd={this.handleCustomProgramsDragEnd}
                  />
                ),
              },
            ]}
          />

          <div className="explanations">
            <sup>*</sup> {t('PageAssessments.ModalCreateAssessment.assessment_explanation')}
          </div>

          <div className="row-container button-container">
            {disableSave && (
              <div className={`error-message`}>
                {t('PageAssessments.ModalCreateAssessment.error_force_pq_pt')}
              </div>
            )}

            <Button className="cancel-button" onClick={this.props.handleClose}>
              {t('GLOBAL.cancel')}
            </Button>

            <Button
              type="submit"
              className="signup-button"
              onClick={() => {}}
              status={this.props.status}
              disabled={disableSave}
            >
              {t('GLOBAL.submit')}
            </Button>
            {this.state.currentErrors && !!Object.entries(this.state.currentErrors).length && (
              <ErrorMessage status={this.state.currentErrors} />
            )}
          </div>
        </FormModal>
      </ReactModal>
    )
  }
}

ModalCreateAssessment.propTypes = {
  resources: PropTypes.object,
  prescribers: PropTypes.object,
  status: PropTypes.object,
  defaultLevelObjectives: PropTypes.object,
}

ModalCreateAssessment.defaultProps = {
  defaultLevelObjectives: [],
  prescribers: [],
}

export default withTranslation()(ModalCreateAssessment)
