import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactModal from 'react-modal'
import { withTranslation } from 'react-i18next'

import AdminTable from 'components/AdminTable'
import Button from 'components/Button'
import CloseButton from 'components/CloseButton'
import ErrorMessage from 'components/ErrorMessage'

import PageBodyPartsNew from 'containers/PageBodyPartsNew'

import {
  getAllBodyParts,
  createBodyParts,
  updateBodyParts,
  deleteBodyParts,
} from 'store/modules/BodyParts/actions.js'

import './style.css'

const statusRefDelete = 'PageBodyParts-delete'

class PageBodyParts extends Component {
  constructor() {
    super()

    this.state = {
      hasDoneInitialFetch: false,
    }

    this.buildSearchResultTableRow = this.buildSearchResultTableRow.bind(this)
    this.handleNewBodyPartsClick = this.handleNewBodyPartsClick.bind(this)
    this.handleCloseNewBodyPartsModal = this.handleCloseNewBodyPartsModal.bind(this)
    this.handleNewBodyPartsSuccess = this.handleNewBodyPartsSuccess.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(getAllBodyParts({}))
  }

  buildSearchResultTableRow() {
    const { t } = this.props

    const results = this.props.body_parts

    if (!results) {
      return []
    }

    return Object.keys(results).map((key) => {
      const item = results[key]
      return {
        columns: [
          { value: item.name },
          { value: <Button onClick={() => this.handleEditClick(item)}>{t('GLOBAL.edit')}</Button> },
          {
            value: (
              <Button onClick={() => this.handleDeleteClick(item.id)}>{t('GLOBAL.delete')}</Button>
            ),
          },
        ],
      }
    })
  }

  getSearchResultsHeaderRow() {
    const { t } = this.props

    return {
      columns: [
        { value: t('PageBodyParts.subtitle-1') },
        { value: t('PageBodyParts.subtitle-2') },
        { value: t('PageBodyParts.subtitle-2') },
      ],
    }
  }

  handleNewBodyPartsClick() {
    this.setState({
      newBodyPartsActive: true,
    })
  }

  handleCloseNewBodyPartsModal() {
    this.setState({
      newBodyPartsActive: false,
      editBodyPartsActive: null,
    })

    this.props.dispatch(getAllBodyParts({}))
  }

  handleNewBodyPartsSuccess() {
    setTimeout(() => {
      this.handleCloseNewBodyPartsModal()
    }, 500)
  }

  handleEditClick(item) {
    this.setState({
      editBodyPartsActive: item,
    })
  }

  handleDeleteClick(id) {
    this.props.dispatch(
      deleteBodyParts({
        id,
        statusRef: statusRefDelete,
      })
    )
  }

  handleSubmit(values) {
    if (this.state.newBodyPartsActive) {
      this.props.dispatch(createBodyParts({ ...values }))
    } else {
      this.props.dispatch(updateBodyParts({ ...values }))
    }
  }

  render() {
    const { t } = this.props

    return (
      <div className="page-body-parts">
        {(this.props.role === 'manager' || this.props.role === 'admin_referensiel') && (
          <div>
            <div>
              <Button onClick={this.handleNewBodyPartsClick}>
                + {t('PageBodyParts.add-new-body-parts')}
              </Button>
            </div>

            <ErrorMessage status={this.props.statuses[statusRefDelete]} />

            <AdminTable
              headerRow={this.getSearchResultsHeaderRow()}
              rows={this.buildSearchResultTableRow()}
            />

            {(this.state.newBodyPartsActive || this.state.editBodyPartsActive) && (
              <ReactModal
                isOpen={true}
                onRequestClose={this.handleCloseNewBodyPartsModal}
                shouldCloseOnOverlayClick={true}
                className="modal-content modal-bilans-forms"
                overlayClassName="modal-overlay"
              >
                <CloseButton onClick={this.handleCloseNewBodyPartsModal} />
                <PageBodyPartsNew
                  formTitle={t('PageBodyParts.new-edit')}
                  body_parts={this.props.body_parts}
                  handleSubmit={this.handleSubmit}
                  initialData={this.state.editBodyPartsActive}
                  onFormSubmitSuccess={this.handleNewBodyPartsSuccess}
                />
              </ReactModal>
            )}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.Account.user || null,
  role: state.Account.role || null,
  body_parts: state.BodyParts.body_parts,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PageBodyParts))
