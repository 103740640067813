import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import FormSessionCreate from 'components/FormSessionCreate'

import { SESSION_FETCH_SUBMIT, updateSession } from 'store/modules/Session/actions.js'
import { FETCH_SESSIONS_RESOURCES_REQUEST } from 'store/modules/Resource/actions.js'
import { clearStatus } from 'store/modules/Status/actions.js'

import './style.css'
import isEqual from 'lodash/isEqual'
import getTranslations from '../../helpers/get-translations'

const statusRef = 'PageSessionsEdit'

class PageSessionsEdit extends Component {
  constructor() {
    super()
    this.state = {
      form: {
        name: {},
        description: {},
        recommendations: {},
        rest_time: 30,
        public_request: false,
      },
      exercises: [],
    }
  }

  //TODO: Getting called on app initialisation. Why? Should only get called on modal open.
  componentDidMount() {
    this.props.dispatch(clearStatus({ statusRef }))

    this.setState({ sessionId: this.props.match.params.sessionId }, () => {
      this.props.dispatch({
        type: SESSION_FETCH_SUBMIT,
        payload: this.state.sessionId,
      })
    })

    this.props.dispatch({ type: FETCH_SESSIONS_RESOURCES_REQUEST })

    this.setInitialData()
  }

  componentDidUpdate(prevProps) {
    const { statuses, history, session } = this.props
    if (session && !isEqual(session, prevProps.session)) {
      this.setInitialData()
    }
    if (statuses[statusRef] && statuses[statusRef].state === 'finished') {
      if (prevProps.statuses[statusRef].state !== 'finished') {
        setTimeout(() => {
          history.push(`/session/${this.state.sessionId}`)
        }, 700)
      }
    }
  }

  setInitialData = () => {
    if (!this.props.session) return
    const {
      public: _public,
      translations,
      target_audience_id,
      level_id,
      objective_id,
      rest_time,
      session_exercises: exercises,
    } = this.props.session
    const name = getTranslations(translations, 'name')
    const description = getTranslations(translations, 'description')
    const recommendations = getTranslations(translations, 'recommendations')

    this.setState({
      form: {
        ...this.props.session,
        public: _public || false,
        name,
        description,
        target_audience_id,
        level_id,
        objective_id,
        recommendations,
        rest_time: rest_time || 30,
      },
      exercises,
    })
  }

  handleSubmit = () => {
    const { sessionId, exercises, form } = this.state
    const session_exercises = exercises.map((item) => {
      return {
        ...item,
        exercise_id: item.exercise.id,
      }
    })

    delete form.public

    this.props.dispatch(
      updateSession({
        ...form,
        sessionId,
        session_exercises,
        statusRef,
      })
    )
  }

  handleExercisesUpdate = (exercises) => {
    this.setState({
      exercises,
    })
  }

  handleCheckboxGroupChange = (event) => {
    const target = event.target
    const checked = target.checked
    const name = target.name

    if (name.includes('[')) {
      let openBracketIndex = name.indexOf('[')
      let closeBracketIndex = name.indexOf(']')
      let bracketLength = closeBracketIndex - openBracketIndex - 1

      let beforeBracketValue = name.substr(0, openBracketIndex)
      let bracketValue = name.substr(openBracketIndex + 1, bracketLength)

      let currentStateArray = this.state.form[beforeBracketValue] || []

      let newArray = [...currentStateArray]

      if (checked) {
        if (newArray.indexOf(bracketValue) < 0) {
          newArray.push(bracketValue)
        }
      } else {
        let index = newArray.indexOf(bracketValue)
        newArray.splice(index, 1)
      }

      this.setState({
        form: {
          ...this.state.form,
          [beforeBracketValue]: newArray,
        },
      })
    }
  }

  handleInputChange = (event) => {
    const target = event.target

    let value = target.type === 'checkbox' ? target.checked : target.value
    if (value === 'true' || value === 'false') {
      value = value === 'true'
    }

    const name = target.name

    if (name.includes('.')) {
      //TODO: ALlows language to be specified in the input name.
      //Look at a more scalable way of doing this.
      let splitName = name.split('.')
      this.setState({
        form: {
          ...this.state.form,
          [splitName[0]]: {
            ...this.state.form[splitName[0]],
            [splitName[1]]: value,
          },
        },
      })
    } else {
      this.setState({
        form: {
          ...this.state.form,
          [name]: value,
        },
      })
    }
  }

  handleInputTranslationChange = (event, values) => {
    const { target } = event
    const { name } = target

    if (name.includes('.')) {
      let splitName = name.split('.')
      this.setState({
        form: {
          ...this.state.form,
          [splitName[0]]: values,
        },
      })
    }
  }

  setSessionResources = (session) => {
    const { objectives, levels, target_audiences } = this.props.resources

    const { objective_id, level_id, target_audience_id, session_exercises = [], ...rest } = session

    const objective = objectives.find((obj) => obj.id === objective_id) || {}
    const level = levels.find((level) => level.id === level_id) || {}
    const target_audience =
      target_audiences.find((target) => target.id === target_audience_id) || {}
    const nb_exercises = session_exercises.length

    return { ...rest, objective, level, target_audience, nb_exercises }
  }

  render() {
    const { resources, statuses } = this.props
    const { form, exercises } = this.state

    return (
      <div className="page-session-edit">
        <FormSessionCreate
          form={form}
          exercises={exercises}
          resources={resources}
          handleSubmit={this.handleSubmit}
          submitStatus={statuses[statusRef]}
          handleExercisesUpdate={this.handleExercisesUpdate}
          handleCheckboxGroupChange={this.handleCheckboxGroupChange}
          handleInputChange={this.handleInputChange}
          handleInputTranslationChange={this.handleInputTranslationChange}
        />
      </div>
    )
  }
}

PageSessionsEdit.defaultProps = {
  session: {},
}

const mapStateToProps = (state) => ({
  resources: state.Resource.sessions,
  statuses: state.Status.statuses,
  session: state.Session.session,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageSessionsEdit))
