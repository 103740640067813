import * as exerciseActions from 'store/modules/Exercise/actions.js'

const initialState = {
  exercise: {},
  searchResults: [],
  requestsSearchResults: [],
  searchFilters: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case exerciseActions.SET_EXERCISES_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      }

    case exerciseActions.SET_EXERCISE_REQUESTS_SEARCH_RESULTS:
      return {
        ...state,
        requestsSearchResults: action.payload,
      }

    case exerciseActions.UPDATE_EXERCISE_CACHE_SET:
      return {
        ...state,
        exercise: action.payload,
      }
    case exerciseActions.UPDATE_SEARCH_EXERCISE_FILTERS:
      return {
        ...state,
        searchFilters: action.payload,
      }

    default:
      return state
  }
}
