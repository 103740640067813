import React, { Component } from 'react'
import { connect } from 'react-redux'

import ModalConfirmEmail from 'containers/modals/ModalConfirmEmail'

import { SET_ACTIVE_MODAL } from 'store/modules/Modal/actions.js'

import './style.css'

class PageConfirmEmail extends Component {
  constructor() {
    super()

    this.state = {
      modalActive: false,
    }

    this.handleOnClose = this.handleOnClose.bind(this)
  }

  componentDidMount() {
    this.setState({
      modalActive: true,
    })
  }

  handleOnClose() {
    this.setState(
      {
        modalActive: false,
      },
      () => this.props.dispatch({ type: SET_ACTIVE_MODAL, payload: 'login' })
    )
  }

  render() {
    return (
      <div className="page-confirm-email">
        {this.state.modalActive && (
          <ModalConfirmEmail isActive={this.state.modalActive} handleOnClose={this.handleOnClose} />
        )}
      </div>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PageConfirmEmail)
