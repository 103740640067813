import React, { Component } from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import FormModal from 'components/FormModal'
import Button from 'components/Button'
import CloseButton from 'components/CloseButton'
import AdminTable from 'components/AdminTable'

import './style.css'

const baseClass = 'form-challenge-create-exercises-posture-selector'

class PostureSelector extends Component {
  constructor() {
    super()

    this.state = {
      postures: [],
      selectedPosture: null,
    }

    this.postureSelectChange = this.postureSelectChange.bind(this)
    this.onAddPostureClick = this.onAddPostureClick.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.postureFromId = this.postureFromId.bind(this)
    this.renderTable = this.renderTable.bind(this)
    this.toReachClick = this.toReachClick.bind(this)
  }

  componentDidMount() {
    this.setState({
      postures: this.props.exercise.postures || [],
    })
  }

  postureSelectChange(ev) {
    let value = ev.target.value
    this.setState({ selectedPosture: parseInt(value, 10) })
  }

  onAddPostureClick() {
    if (this.state.selectedPosture === null) return
    let postures = [...this.state.postures]

    postures.push({ id: this.state.selectedPosture })

    this.setState({
      ...this.state,
      postures,
    })
  }

  onSubmit(ev) {
    ev.preventDefault()
    ev.stopPropagation()

    this.props.onSubmit(this.state.postures)
  }

  postureFromId(id) {
    let toReturn = {}

    this.props.postures.map((item) => {
      if (item.id === id) toReturn = item
    })

    return toReturn
  }

  renderTable() {
    const { t } = this.props

    const headers = {
      columns: [
        { value: t('FormChallengeCreate.Exercises.PostureSelector.text-1') },
        { value: t('FormChallengeCreate.Exercises.PostureSelector.text-2') },
      ],
    }

    let tableRows = []

    ;(this.state.postures || []).map((item, index) => {
      let columns = [
        { value: <div onClick={() => this.toReachClick(index)}>{item.to_reach ? '✔' : 'X'}</div> },
        { value: this.postureFromId(item.id).name },
      ]

      tableRows.push({ columns })

      return null
    })

    return <AdminTable headerRow={headers} rows={tableRows} />
  }

  toReachClick(_index) {
    let postures = [...this.state.postures]

    postures.map((item, index) => {
      if (index === _index) {
        postures[index].to_reach = !item.to_reach
        return
      }

      postures[index].to_reach = false

      return null
    })

    this.setState({ postures })
  }

  reachExists() {
    let postures = [...this.state.postures]
    let isSomethingReach = false

    postures.map((item) => {
      if (item.to_reach) {
        isSomethingReach = true
      }

      return null
    })

    return isSomethingReach
  }

  isDisabled() {
    if ((this.state.postures || []).length > 0 && this.reachExists()) {
      return false
    }

    return true
  }

  render() {
    const { t } = this.props

    return (
      <ReactModal
        className={`${baseClass}`}
        isOpen={true}
        onRequestClose={this.props.handleClose}
        shouldCloseOnOverlayClick={true}
        // className="modal-content"
        overlayClassName="modal-overlay"
      >
        <FormModal id="profile-form" className="profile-form" onSubmit={this.onSubmit}>
          <CloseButton onClick={this.props.handleClose} />

          <div>{this.renderTable()}</div>

          <select onChange={this.postureSelectChange}>
            {this.props.postures.map((item, index) => {
              return (
                <option value={item.id} key={`postures_${index}`}>
                  {item.name}
                </option>
              )
            })}
          </select>

          <Button type="button" onClick={this.onAddPostureClick}>
            {' '}
            {t('FormChallengeCreate.Exercises.PostureSelector.button')}{' '}
          </Button>

          <p>{t('FormChallengeCreate.Exercises.PostureSelector.text-3')} </p>

          <Button type="submit" disabled={this.isDisabled()} onClick={() => {}}>
            {' '}
            {t('GLOBAL.save')}{' '}
          </Button>
        </FormModal>
      </ReactModal>
    )
  }
}

PostureSelector.propTypes = {
  postures: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.isRequired,
}

PostureSelector.defaultProps = {
  postures: [],
}

export default withTranslation()(PostureSelector)
