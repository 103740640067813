import * as sessionActions from 'store/modules/Session/actions.js'

const initialState = {
  session: {},
  searchResults: [],
  requestSearchResults: [],
  searchFilters: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case sessionActions.SET_SESSIONS_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      }

    case sessionActions.SET_SESSION_REQUESTS_SEARCH_RESULTS:
      return {
        ...state,
        requestSearchResults: action.payload,
      }

    case sessionActions.UPDATE_SESSION_CACHE_SET:
      return {
        ...state,
        session: action.payload,
      }
    case sessionActions.UPDATE_SEARCH_SESSION_FILTERS:
      return {
        ...state,
        searchFilters: action.payload,
      }
    default:
      return state
  }
}
