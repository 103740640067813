import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import isEqual from 'lodash/isEqual'

import { normalizeTime } from 'services/time'

import './style.css'

class ExerciseDetails extends Component {
  constructor() {
    super()

    this.state = {}
  }

  render() {
    const { t, i18n } = this.props

    return (
      <div className={`exercise-details ${this.props.className || ''}`}>
        <div className="page-exercise-title-container">
          <h1 className="page-exercise-title">{this.props.exercise.name}</h1>
        </div>

        <div className="page-exercise-info-container">
          <div className="page-exercise-info-block">
            <div className="page-exercise-info-block-key">{t('ExerciseDetails.title-2')}</div>

            <div className="page-exercise-info-block-value">
              {this.props.exercise.objective && (
                <span>
                  {((this.props.exercise.objective || {}).translations || [])[i18n.language]?.name}
                </span>
              )}
            </div>
          </div>

          <div className="page-exercise-info-block">
            <div className="page-exercise-info-block-key">{t('ExerciseDetails.title-3')}</div>

            <div className="page-exercise-info-block-value">
              {this.props.exercise.muscles &&
                this.props.exercise.muscles.map((item, index, array) => {
                  return (
                    <span key={`muscles_${index}`}>
                      {`${item.name}${index < array.length - 1 ? ', ' : ''}`}
                    </span>
                  )
                })}
            </div>
          </div>

          <div className="page-exercise-info-block">
            <div className="page-exercise-info-block-key">{t('ExerciseDetails.title-4')}</div>

            <div className="page-exercise-info-block-value">
              <span>
                {((this.props.exercise.position || {}).translations || [])[i18n.language]?.name}
              </span>
            </div>
          </div>

          <div className="page-exercise-info-block">
            <div className="page-exercise-info-block-key">{t('ExerciseDetails.title-5')}</div>

            <div className="page-exercise-info-block-value">
              <span>
                {((this.props.exercise.count || {}).translations || [])[i18n.language]?.name}
              </span>
            </div>
          </div>

          <div className="page-exercise-info-block">
            <div className="page-exercise-info-block-key">{t('ExerciseDetails.title-6')}</div>

            <div className="page-exercise-info-block-value">
              {this.props.exercise.asymmetric ? t('GLOBAL.yes') : t('GLOBAL.no')}
            </div>
          </div>

          {this.props.isSessionExercise && (
            <div>
              <div className="page-exercise-info-block">
                <div className="page-exercise-info-block-key">{t('ExerciseDetails.title-7')}</div>
                <div className="page-exercise-info-block-value">
                  <span>{this.props.sessionExercise.series}</span>
                </div>
              </div>

              <div className="page-exercise-info-block">
                <div className="page-exercise-info-block-key">{t('ExerciseDetails.title-8')}</div>
                <div className="page-exercise-info-block-value">
                  <span>
                    {isEqual(this.props.sessionExercise.repetitions, 9999)
                      ? t('ExerciseDetails.max-repetitions')
                      : this.props.sessionExercise.repetitions}
                  </span>
                </div>
              </div>

              <div className="page-exercise-info-block">
                <div className="page-exercise-info-block-key">{t('ExerciseDetails.title-9')}</div>
                <div className="page-exercise-info-block-value">
                  <span>{`${normalizeTime(this.props.sessionExercise.duration, 'seconds')}`}</span>
                </div>
              </div>

              <div className="page-exercise-info-block">
                <div className="page-exercise-info-block-key">{t('ExerciseDetails.title-10')}</div>
                <div className="page-exercise-info-block-value">
                  <span>{`${normalizeTime(this.props.sessionExercise.rest_time, 'seconds')}`}</span>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="page-exercise-proposed">
          {t('ExerciseDetails.text')}{' '}
          <span className="page-exercise-title-proposed-name">
            {' '}
            {this.props.exercise.suggested_by}{' '}
          </span>
        </div>
      </div>
    )
  }
}

ExerciseDetails.propTypes = {
  exercise: PropTypes.object,
}

ExerciseDetails.defaultProps = {
  exercise: {
    level: {},
    objective: {},
    muscles: [],
    position: {},
    count: {},
  },
}

export default withTranslation()(ExerciseDetails)
