import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import FormGeneric from 'components/FormGeneric'
import Button from 'components/Button'
import Textarea from 'containers/Textarea'
import Input from 'containers/Input'
import ErrorMessage from 'components/ErrorMessage'

import Exercises from './containers/Exercises'
import ModalExerciseEdit from 'containers/modals/ModalExerciseEdit'

import './style.css'

const baseClass = 'form-session-create'

const FormSessionCreate = ({
  t,
  resources,
  submitStatus,
  handleInputChange,
  handleInputTranslationChange,
  handleSubmit,
  handleExercisesUpdate,
  form,
  exercises,
}) => {
  const [showModalExerciseEdit, setShowModalExerciseEdit] = useState(false)
  const [exerciseId, setExerciseId] = useState('')

  const handleOpenEditExercise = (exerciseId) => {
    setShowModalExerciseEdit(true)
    setExerciseId(exerciseId)
  }

  const handleCloseEditExercise = () => {
    setShowModalExerciseEdit(false)
  }

  const handleBack = () => {
    window.history.go(-1)
  }

  return (
    <div className={`${baseClass}`}>
      <FormGeneric className="modal-create-session-form" onSubmit={handleSubmit}>
        <h1>{t('FormSessionCreate.title')}</h1>
        <div className="row-container">
          <label>{t('FormSessionCreate.label-1')}</label>

          <div className="left-indent">
            <Input
              type="text"
              name="name"
              className="name"
              value={form.name || {}}
              onChange={handleInputTranslationChange}
              placeholder={t('FormSessionCreate.label-1')}
              autoComplete="false"
              required={true}
              maxLength={191}
              isTranslatable={true}
            />
          </div>
        </div>

        <div className="row-container">
          <label>{t('FormSessionCreate.label-2')}</label>
          <div className="left-indent">
            <Textarea
              type="text"
              name="description"
              value={form.description}
              onChange={handleInputTranslationChange}
              placeholder={t('FormSessionCreate.label-2')}
              autoComplete="false"
              maxLength={1000}
              isTranslatable={true}
              withCharsCount={true}
            />
          </div>
        </div>

        <div className="row-container">
          <div className="inline-block width-50">
            <label>{t('FormSessionCreate.label-3')}</label>
            <div className="left-indent">
              <select
                name="target_audience_id"
                autoComplete="off"
                onChange={handleInputChange}
                value={form.target_audience_id}
                required={true}
              >
                <option value="" disabled="disabled">
                  -------
                </option>
                {resources.target_audiences.map((item, index) => {
                  return (
                    <option value={item.id} key={`audiences_${index}`}>
                      {item.name}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>

          <div className="inline-block width-50">
            <label>{t('FormSessionCreate.label-4')}</label>
            <div className="left-indent">
              <select
                name="level_id"
                autoComplete="off"
                onChange={handleInputChange}
                value={form.level_id}
                required={true}
              >
                <option value="" disabled="disabled">
                  -------
                </option>
                {resources.levels.map((item, index) => {
                  return (
                    <option value={item.id} key={`levelsA_${index}`}>
                      {item.name}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="row-container">
          <div className="inline-block width-50">
            <label>{t('FormSessionCreate.label-5')}</label>
            <div className="left-indent">
              <select
                name="objective_id"
                autoComplete="off"
                onChange={handleInputChange}
                value={form.objective_id}
                required={true}
              >
                <option value="" disabled="disabled">
                  -------
                </option>
                {resources.objectives.map((item, index) => {
                  return (
                    <option value={item.id} key={`resources_${index}`}>
                      {item.name}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="row-container">
          <label>{t('FormSessionCreate.label-7')}</label>
          <div className="left-indent">
            <Textarea
              name="recommendations"
              value={form.recommendations || {}}
              placeholder={t('FormSessionCreate.label-7')}
              onChange={handleInputTranslationChange}
              maxLength={200}
              isTranslatable={true}
              withCharsCount={true}
            />
          </div>
        </div>

        <div>
          <label>{t('FormSessionCreate.label-8')}</label>

          <Exercises
            exercises={exercises}
            handleExercisesUpdate={handleExercisesUpdate}
            handleOpenEditExercise={handleOpenEditExercise}
          />
        </div>

        {!form.public && (
          <div className="left-indent">
            <input
              type="checkbox"
              name="public_request"
              value={true}
              onChange={handleInputChange}
              checked={form.public_request === 1 || form.public_request === true}
            />

            <label>{t('FormSessionCreate.label-10')}</label>
          </div>
        )}

        {form.public && (
          <div className="row-container">
            <label>{t('FormSessionCreate.text')}</label>
          </div>
        )}

        <div className="row-container">
          <Button className="cancel-button" onClick={handleBack}>
            {t('GLOBAL.cancel')}
          </Button>

          <Button type="submit" className="signup-button" onClick={() => {}} status={submitStatus}>
            {t('GLOBAL.submit')}
          </Button>

          <ErrorMessage status={submitStatus} />
        </div>
      </FormGeneric>

      <ModalExerciseEdit
        isOpen={showModalExerciseEdit}
        exerciseId={exerciseId}
        handleCloseModal={handleCloseEditExercise}
      />
    </div>
  )
}

FormSessionCreate.propTypes = {
  resources: PropTypes.object,
  submitStatus: PropTypes.object,
}

FormSessionCreate.defaultProps = {
  form: {
    name: {},
    description: {},
  },
  exercises: [],
  resources: {
    objectives: [],
    levels: [],
    target_audiences: [],
  },
}

export default withTranslation()(FormSessionCreate)
