import React, { Component } from 'react'
import PropTypes from 'prop-types'

import AccordionSection from './components/AccordionSection'
import './style.css'

class Accordion extends Component {
  constructor() {
    super()

    this.state = {}

    this.handleSectionTitleClick = this.handleSectionTitleClick.bind(this)
  }

  handleSectionTitleClick(key) {
    this.setState((prevState) => ({
      [key]: !prevState[key],
    }))
  }

  render() {
    return (
      <div className={`accordion ${this.props.className}`}>
        {this.props.sections.map((item, index) => {
          return (
            <AccordionSection
              title={item.title}
              content={item.content}
              titleClick={this.handleSectionTitleClick}
              key={`accordion_${index}`}
              isOpen={this.state[item.title]}
            />
          )
        })}
      </div>
    )
  }
}

Accordion.propTypes = {
  sections: PropTypes.array,
}

Accordion.defaultProps = {
  sections: [],
}

export default Accordion
