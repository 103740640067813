import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './style.css'

const imageUrl = process.env.REACT_APP_IMAGE_URL

class ChallengeCard extends Component {
  constructor() {
    super()

    this.state = {}

    this.stepsImages = this.stepsImages.bind(this)
  }

  stepsImages() {
    let toReturn = []

    ;(this.props.item.challenge_exercises || []).map((item, index) => {
      const exercise = item.exercise

      if (exercise.steps.length) {
        toReturn.push(
          <div
            style={{ backgroundImage: `url(${imageUrl}${exercise.steps[0].photo})` }}
            className="challenge-card-step-image"
            key={`stepsImages_${index}`}
          ></div>
        )
      }

      return null
    })

    return toReturn
  }

  render() {
    return (
      <div className={`challenge-card ${this.props.className || ''}`} onClick={this.props.onClick}>
        <div className="challenge-card-steps-images-container">{this.stepsImages()}</div>

        <div className="challenge-card-text-container">
          <div className="challenge-card-text-title">{(this.props.item || {}).name}</div>

          <div className="objective">{(this.props.item.objective || {}).name}</div>

          <div className="level">{(this.props.item.level || {}).name}</div>

          <div className="muscles">
            {(this.props.item.muscles || []).map((item, index) => {
              return (
                <span key={`muscle_${index}`}>
                  {`${item.name}${index < (this.props.item || {}).muscles.length - 1 ? ', ' : ''}`}
                </span>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

ChallengeCard.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
}

ChallengeCard.defaultProps = {
  item: {
    name: '',
    level: {},
    objective: {},
    muscles: [],
    steps: [],
  },
  onClick: () => {},
}

export default ChallengeCard
