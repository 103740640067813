import React, { Component } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import Icon from 'components/Icon'

import './style.css'

class AdminTableRowDraggable extends Component {
  render() {
    return (
      <Draggable draggableId={this.props.draggableId} index={this.props.index}>
        {(provided, snapshot) => (
          <tr
            className={`admin-table-row-draggable ${this.props.className}`}
            {...provided.draggableProps}
            // eslint-disable-next-line
            innerRef={provided.innerRef}
            ref={provided.innerRef}
            // eslint-disable-next-line
            isDragging={snapshot.isDragging}
          >
            <td
              className={'admin-table-row-draggable--drag-handle-icon'}
              {...provided.dragHandleProps}
            >
              {' '}
              <Icon src="drag-handle-up-down.png" />
            </td>

            {this.props.row.columns.map((column, index) => {
              return (
                <td key={`adminTableRowDraggable_${index}`} className={`${column.className || ''}`}>
                  {column.value}
                </td>
              )
            })}
          </tr>
        )}
      </Draggable>
    )
  }
}

AdminTableRowDraggable.defaultProps = {
  row: {
    columns: [],
  },
}

export default AdminTableRowDraggable
