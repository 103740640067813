import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import Button from 'components/Button'
import ProgramDetails from 'components/ProgramDetails'
import CycleDetails from 'components/CycleDetails'
import ActionButtons from 'components/ActionButtons'
import ErrorMessage from 'components/ErrorMessage'

import {
  PROGRAM_FETCH_SUBMIT,
  PROGRAM_MAKE_PUBLIC_REQUEST,
  PROGRAM_DELETE_REQUEST,
} from 'store/modules/Program/actions.js'
import { clearStatus } from 'store/modules/Status/actions.js'

import './style.css'

const statusRefPublish = 'PageProgram-publish'
const statusRefRefusePublish = 'PageProgram-refusePublish'

class PageProgram extends Component {
  constructor() {
    super()
    this.state = {
      program: {},
    }

    this.handleAcceptPublishClick = this.handleAcceptPublishClick.bind(this)
    this.handleRefusePublishClick = this.handleRefusePublishClick.bind(this)
    this.handleEditClick = this.handleEditClick.bind(this)
  }

  componentDidMount() {
    this.setState({ programId: this.props.match.params.programId }, () => {
      this.props.dispatch({ type: PROGRAM_FETCH_SUBMIT, payload: this.state.programId })
    })
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.statuses[statusRefPublish] &&
      this.props.statuses[statusRefPublish].state === 'finished'
    ) {
      if (prevProps.statuses[statusRefPublish].state !== 'finished') {
        setTimeout(() => {
          this.props.dispatch({ type: PROGRAM_FETCH_SUBMIT, payload: this.state.programId })
          this.props.dispatch(clearStatus({ statusRef: statusRefPublish }))
        }, 200)
      }
    }

    if (
      this.props.statuses[statusRefRefusePublish] &&
      this.props.statuses[statusRefRefusePublish].state === 'finished'
    ) {
      if (prevProps.statuses[statusRefRefusePublish].state !== 'finished') {
        setTimeout(() => {
          this.props.dispatch(clearStatus({ statusRef: statusRefRefusePublish }))
          window.history.go(-1)
        }, 200)
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.programId) return null

    if (nextProps.programs[prevState.programId] !== prevState.program) {
      return {
        program: nextProps.programs[prevState.programId] || {},
      }
    }

    return null
  }

  handleAcceptPublishClick() {
    this.props.dispatch({
      type: PROGRAM_MAKE_PUBLIC_REQUEST,
      payload: { id: this.state.programId, statusRef: statusRefPublish },
    })
  }

  handleRefusePublishClick() {
    //TODO: Confirmation popup
    this.props.dispatch({
      type: PROGRAM_DELETE_REQUEST,
      payload: { id: this.state.programId, statusRef: statusRefRefusePublish },
    })
  }

  handleEditClick() {
    this.props.history.push(`/program/${this.state.programId}/edit`)
  }

  render() {
    const { t } = this.props

    return (
      <div className="page-program">
        <div className="page-program-content">
          <ProgramDetails program={this.state.program} />

          <ActionButtons handleEditClick={this.handleEditClick} objectName="program" />

          {this.props.role === 'admin_referensiel' &&
            this.state.program.enabled === 1 &&
            this.state.program.public === 0 &&
            this.state.program.getphy === 0 && (
              <div>
                {t('PageProgram.title')}
                <Button
                  onClick={this.handleAcceptPublishClick}
                  status={this.props.statuses[statusRefPublish]}
                >
                  {t('PageProgram.button')}
                </Button>
                <Button
                  onClick={this.handleRefusePublishClick}
                  status={this.props.statuses[statusRefRefusePublish]}
                >
                  {t('GLOBAL.delete')}
                </Button>

                <ErrorMessage status={this.props.statuses[statusRefPublish]} />
                <ErrorMessage status={this.props.statuses[statusRefRefusePublish]} />
              </div>
            )}

          {this.state.program.cycles &&
            this.state.program.cycles.map((item, index) => {
              return <CycleDetails cycle={item} key={`cycle-details-${index}`} />
            })}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  programs: state.Program.programs || {},
  activeModal: state.Modal.activeModal,
  role: state.Account.role,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PageProgram))
)
