export const GET_ASSESSMENTS = 'assessment/GET_ASSESSMENTS'
export const CREATE_ASSESSMENT = 'assessment/CREATE_ASSESSMENT'
export const EDIT_ASSESSMENT = 'assessment/EDIT_ASSESSMENT'
export const DELETE_ASSESSMENT = 'assessment/DELETE_ASSESSMENT'

export const GET_LEVELS = 'assessment/GET_LEVELS'
export const GET_OBJECTIVES = 'assessment/GET_OBJECTIVES'

export const UPDATE_ASSESSMENTS_CACHE = 'assessment/UPDATE_ASSESSMENTS_CACHE'
export const UPDATE_ASSESSMENTS_CACHE_SET = 'assessment/UPDATE_ASSESSMENTS_CACHE_SET'
export const UPDATE_ASSESSMENT_SEARCH_RESULTS = 'assessment/UPDATE_ASSESSMENT_SEARCH_RESULTS'
export const UPDATE_RESSOURCES_CACHE = 'assessment/UPDATE_RESSOURCES_CACHE'

export function getAssessments(payload) {
  return { type: GET_ASSESSMENTS, payload: payload }
}

export function createAssessment(payload) {
  return { type: CREATE_ASSESSMENT, payload: payload }
}

export function editAssessment(payload) {
  return { type: EDIT_ASSESSMENT, payload: payload }
}

export function deleteAssessment(payload) {
  return { type: DELETE_ASSESSMENT, payload: payload }
}

export function getLevels(payload) {
  return { type: GET_LEVELS, payload: payload }
}

export function getProgramObjectives(payload) {
  return { type: GET_OBJECTIVES, payload: payload }
}
