import * as challengeActions from 'store/modules/Challenge/actions.js'

const initialState = {
  searchResults: [],
  requestssearchResults: [],
  challenges: [],
  searchFilters: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case challengeActions.SET_CHALLENGES_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      }

    case challengeActions.SET_CHALLENGE_REQUESTS_SEARCH_RESULTS:
      return {
        ...state,
        requestssearchResults: action.payload,
      }

    case challengeActions.UPDATE_CHALLENGE_CACHE_SET:
      return {
        ...state,
        challenges: action.payload,
      }

    case challengeActions.UPDATE_SEARCH_CHALLENGE_FILTERS:
      return {
        ...state,
        searchFilters: action.payload,
      }
    default:
      return state
  }
}
