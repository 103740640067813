import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import SessionDetails from 'components/SessionDetails'
import ChallengeDetails from 'components/ChallengeDetails'

import './style.css'

class CycleDetails extends Component {
  constructor() {
    super()

    this.state = {}
  }

  render() {
    const { t } = this.props

    return (
      <div className={`cycle-details ${this.props.className || ''}`}>
        <div className="cycle-details-title-container">
          <h1 className="cycle-details-title">
            {t('CycleDetails.title')} {this.props.cycle.position}
          </h1>
        </div>

        <h1>{t('CycleDetails.subtitle-1')}</h1>
        {this.props.cycle.cycle_sessions &&
          this.props.cycle.cycle_sessions.map((item, index) => {
            return (
              <div className="cycle-details-session-row" key={`cycle-details-row-${index}`}>
                <div className="cycle-details-session-row-title">
                  <div className="cycle-details-session-row-title-position">{item.position}.</div>

                  <div className="cycle-details-session-row-title-position-start-after">
                    {t('CycleDetails.text-1')} {item.start_after} {t('CycleDetails.text-2')}
                  </div>
                </div>

                <SessionDetails
                  session={item.session}
                  images={item.session.images}
                  showSessionImages={true}
                />
              </div>
            )
          })}

        <h1>{t('CycleDetails.subtitle-2')}</h1>

        {this.props.cycle.challenges && (
          <div className="cycle-details-session-row">
            {this.props.cycle.challenges.map((challenge, idx) => {
              return <ChallengeDetails key={idx} challenge={challenge} />
            })}
          </div>
        )}
      </div>
    )
  }
}

CycleDetails.propTypes = {
  cycle: PropTypes.object,
}

CycleDetails.defaultProps = {
  cycle: {},
}

export default withTranslation()(CycleDetails)
