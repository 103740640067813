import { takeLatest, call, put, take, cancelled } from 'redux-saga/effects'

import * as ApiService from 'services/api'
import { eventChannel } from 'redux-saga'
import { APP_INIT } from 'store/modules/Account/actions.js'

import * as notificationActions from 'store/modules/Notification/actions.js'

/*************************************************/
/** Sagas **/
export default function* sagaWatcher() {
  yield takeLatest(APP_INIT, infiniteLoop)
}

function countdown(secs) {
  return eventChannel((emitter) => {
    const iv = setInterval(() => {
      emitter(secs)
    }, 1000 * 60 * 15)
    return () => {
      clearInterval(iv)
    }
  })
}

function* infiniteLoop() {
  const chan = yield call(countdown, 50)

  try {
    while (true) {
      yield take(chan)
      try {
        let version = window._build_hash || ''
        let { data } = yield call(ApiService.GetAppVersion)
        data = data.toString()

        if (data && version && data.trim() !== version.trim()) {
          yield put(notificationActions.openNewVersionPopup())
          chan.close()
          break
        }
      } catch (e) {
        console.log(e, 'e')
      }
    }
  } finally {
    if (yield cancelled()) {
      chan.close()
    }
  }
}
