import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import Button from 'components/Button'
import ActionButtons from 'components/ActionButtons'
import ChallengeDetails from 'components/ChallengeDetails'
import ExerciseDetails from 'components/ExerciseDetails'
import ExerciseSteps from 'components/ExerciseSteps'
import ExerciseAdditionalInfo from 'components/ExerciseAdditionalInfo'
import ErrorMessage from 'components/ErrorMessage'

import {
  CHALLENGE_FETCH_SUBMIT,
  CHALLENGE_MAKE_PUBLIC_REQUEST,
  CHALLENGE_DELETE_REQUEST,
} from 'store/modules/Challenge/actions.js'
import { clearStatus } from 'store/modules/Status/actions.js'

import './style.css'

const statusRefPublish = 'PageChallenge-publish'
const statusRefRefusePublish = 'PageChallenge-refusePublish'

class PageChallenge extends Component {
  constructor() {
    super()
    this.state = {
      challenge: {},
    }

    this.handleAcceptPublishClick = this.handleAcceptPublishClick.bind(this)
    this.handleRefusePublishClick = this.handleRefusePublishClick.bind(this)
    this.exercises = this.exercises.bind(this)
    this.handleEditClick = this.handleEditClick.bind(this)
  }

  componentDidMount() {
    this.setState({ challengeId: this.props.match.params.challengeId }, () => {
      this.props.dispatch({
        type: CHALLENGE_FETCH_SUBMIT,
        payload: this.state.challengeId,
      })
    })
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.statuses[statusRefPublish] &&
      this.props.statuses[statusRefPublish].state === 'finished'
    ) {
      if (prevProps.statuses[statusRefPublish].state !== 'finished') {
        setTimeout(() => {
          this.props.dispatch({ type: CHALLENGE_FETCH_SUBMIT, payload: this.state.challengeId })
          this.props.dispatch(clearStatus({ statusRef: statusRefPublish }))
        }, 200)
      }
    }

    if (
      this.props.statuses[statusRefRefusePublish] &&
      this.props.statuses[statusRefRefusePublish].state === 'finished'
    ) {
      if (prevProps.statuses[statusRefRefusePublish].state !== 'finished') {
        setTimeout(() => {
          this.props.dispatch(clearStatus({ statusRef: statusRefRefusePublish }))
          window.history.go(-1)
        }, 200)
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.challengeId) return null

    if (nextProps.challenges[prevState.challengeId] !== prevState.challenge) {
      return {
        challenge: nextProps.challenges[prevState.challengeId] || {},
      }
    }

    return null
  }

  handleAcceptPublishClick() {
    this.props.dispatch({
      type: CHALLENGE_MAKE_PUBLIC_REQUEST,
      payload: {
        id: this.state.challengeId,
        statusRef: statusRefPublish,
      },
    })
  }

  handleRefusePublishClick() {
    //TODO: Confirmation popup
    this.props.dispatch({
      type: CHALLENGE_DELETE_REQUEST,
      payload: {
        id: this.state.challengeId,
        statusRef: statusRefRefusePublish,
      },
    })
  }

  handleEditClick() {
    this.props.history.push(`/challenge/${this.state.challengeId}/edit`)
  }

  exercises() {
    let toReturn = []
    this.state.challenge.challenge_exercises &&
      this.state.challenge.challenge_exercises.map((item) => {
        toReturn.push(
          <div className="page-challenge-exercises-row">
            <ExerciseDetails className={'exercise-details--small'} exercise={item.exercise} />

            <ExerciseSteps className={'exercise-steps--small'} exercise={item.exercise} />
            <ExerciseAdditionalInfo
              className={'exercise-additional-info--small'}
              exercise={item.exercise}
            />
          </div>
        )
      })

    return toReturn
  }

  render() {
    const { t } = this.props

    return (
      <div className="page-challenge">
        <div className="page-challenge-content">
          <ChallengeDetails challenge={this.state.challenge} />

          <ActionButtons handleEditClick={this.handleEditClick} />

          {this.props.role === 'admin_referensiel' && this.state.challenge.status === 'pending' && (
            <div className="page-challenge-pending">
              <div className="page-challenge-pending-title">{t('PageChallenge.title-1')}</div>

              <Button
                onClick={this.handleAcceptPublishClick}
                status={this.props.statuses[statusRefPublish]}
                data-cy="publish-challenge"
              >
                {t('PageChallenge.button')}
              </Button>

              <Button
                onClick={this.handleRefusePublishClick}
                status={this.props.statuses[statusRefRefusePublish]}
                data-cy="refuse-challenge"
              >
                {t('GLOBAL.delete')}
              </Button>

              <ErrorMessage status={this.props.statuses[statusRefPublish]} />
              <ErrorMessage status={this.props.statuses[statusRefRefusePublish]} />
            </div>
          )}

          <div className="challenge-details-exercises-container">
            <div className="challenge-details-exercises-container-title">
              {t('PageChallenge.title-2')}
            </div>

            {this.exercises()}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  challenges: state.Challenge.challenges || {},
  activeModal: state.Modal.activeModal,
  role: state.Account.role,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PageChallenge))
)
