import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import Button from 'components/Button'
import AdminTableDraggable from 'components/AdminTableDraggable'
import PostureSelector from './components/PostureSelector'
import CardPicker from 'components/CardPicker'

import { exerciseSearchCreator } from 'store/modules/Exercise/actions.js'
import { FETCH_EXERCISES_RESOURCES_REQUEST } from 'store/modules/Resource/actions.js'

import './style.css'

const baseClass = 'form-challenge-create-exercises'

const statusRefSearch = 'FormChallengeCreate-Exercises-search'

class Exercises extends Component {
  constructor() {
    super()
    this.state = {
      exercises: [],
      searchData: {},
      showCardModal: false,
    }

    this.handleAddExerciseClick = this.handleAddExerciseClick.bind(this)
    this.handleSearchExercisesClick = this.handleSearchExercisesClick.bind(this)
    this.handleTableInputChange = this.handleTableInputChange.bind(this)
    this.setInitialData = this.setInitialData.bind(this)
    this.postureClick = this.postureClick.bind(this)
    this.handlePostureSelectorClose = this.handlePostureSelectorClose.bind(this)
    this.handlePosturesSubmit = this.handlePosturesSubmit.bind(this)
    this.postureFromId = this.postureFromId.bind(this)
    this.fetchExercises = this.fetchExercises.bind(this)
    this.handleCloseCardModal = this.handleCloseCardModal.bind(this)
    this.handleRemoveExerciseLine = this.handleRemoveExerciseLine.bind(this)
    this.searchFilteroptions = this.searchFilteroptions.bind(this)
    this.handleRowDragEnd = this.handleRowDragEnd.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(exerciseSearchCreator({ statusRef: statusRefSearch }))
    this.props.dispatch({ type: FETCH_EXERCISES_RESOURCES_REQUEST })
    this.setInitialData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.exercises) {
      if (JSON.stringify(this.props.exercises) !== JSON.stringify(prevProps.exercises)) {
        this.setInitialData()
      }
    }
  }

  setInitialData() {
    this.setState({
      exercises: this.props.exercises,
    })
  }

  handleAddExerciseClick(exercises) {
    let exercisesFormated = []

    exercises.map((exercise) => {
      exercisesFormated.push({
        exercise,
        series: 1,
        repetitions: 1,
        duration: 30,
        rest_time: 30,
      })
    })

    this.setState(
      {
        exercises: [...this.state.exercises, ...exercisesFormated],
      },
      () => {
        this.props.handleExercisesUpdate(this.state.exercises)
      }
    )
  }

  handleSearchExercisesClick() {
    this.setState(
      {
        showCardModal: true,
      },
      () => this.props.dispatch(exerciseSearchCreator({ public: 1, statusRef: statusRefSearch }))
    )
  }

  handleRemoveExerciseLine(index) {
    let { exercises } = this.state

    exercises.splice(index, 1)

    this.setState(
      {
        exercises,
      },
      () => {
        this.props.handleExercisesUpdate(this.state.exercises)
      }
    )
  }

  handleCloseCardModal() {
    this.setState({
      showCardModal: false,
    })
  }

  fetchExercises(data) {
    if (!data) return

    this.setState(
      {
        searchData: data,
      },
      () => {
        this.props.dispatch(
          exerciseSearchCreator({
            ...data,
            ...data.multiSelect,
            search: data.search_text,
            public: true,
            statusRef: statusRefSearch,
          })
        )
      }
    )
  }

  renderExercisesTable() {
    const { t } = this.props

    const headers = {
      columns: [
        { value: t('FormChallengeCreate.Exercises.text-1') },
        { value: t('FormChallengeCreate.Exercises.text-2') },
      ],
    }

    if (parseInt(this.props.challengeType, 10) === 1) {
      headers.columns.push({ value: t('FormChallengeCreate.Exercises.text-3') })
    }

    if (parseInt(this.props.challengeType, 10) === 2) {
      headers.columns.push({ value: t('FormChallengeCreate.Exercises.text-4') })
    }

    if (parseInt(this.props.challengeType, 10) === 3) {
      headers.columns.push({ value: t('FormChallengeCreate.Exercises.text-5') })
    }

    if (parseInt(this.props.challengeType, 10) === 4) {
      headers.columns.push({ value: t('FormChallengeCreate.Exercises.text-6') })
      headers.columns.push({ value: t('FormChallengeCreate.Exercises.text-7') })
    }

    let tableRows = []

    if (this.state.exercises) {
      this.state.exercises.map((item, index) => {
        let columns = [{ value: item.exercise.id }, { value: item.exercise.name }]

        if (parseInt(this.props.challengeType, 10) === 1) {
          columns.push({
            value: (
              <input
                name="duration"
                type="number"
                onChange={(e) => this.handleTableInputChange(e, index)}
                value={item.duration}
              />
            ),
          })
        }

        if (parseInt(this.props.challengeType, 10) === 2) {
          columns.push({
            value: (
              <input
                name="repetitions"
                type="number"
                onChange={(e) => this.handleTableInputChange(e, index)}
                value={item.repetitions}
              />
            ),
          })
        }

        if (parseInt(this.props.challengeType, 10) === 3) {
          columns.push({
            value: (
              <div>
                <Button onClick={() => this.postureClick(item)}>{t('GLOBAL.edit')}</Button>

                {(item.postures || []).map((posture, idx) => {
                  return (
                    <div key={idx}>
                      <div>{posture.to_reach ? '✔' : ''}</div>
                      <div>{this.postureFromId(posture.id).name}</div>
                    </div>
                  )
                })}
              </div>
            ),
          })
        }

        if (parseInt(this.props.challengeType, 10) === 4) {
          columns.push({
            value: (
              <input
                name="duration"
                onChange={(e) => this.handleTableInputChange(e, index)}
                value={item.duration}
              />
            ),
          })
          columns.push({
            value: (
              <input
                name="repetitions"
                onChange={(e) => this.handleTableInputChange(e, index)}
                value={item.repetitions}
              />
            ),
          })
        }

        columns.push({
          value: (
            <Button onClick={() => this.handleRemoveExerciseLine(index)}>
              {' '}
              {t('GLOBAL.delete')}{' '}
            </Button>
          ),
        })

        tableRows.push({ columns })
        return null
      })
    }

    return (
      <AdminTableDraggable
        headerRow={headers}
        rows={tableRows}
        handleDragEnd={this.handleRowDragEnd}
      />
    )
  }

  handleRowDragEnd(data) {
    const { destination, source } = data

    if (!destination) return

    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    let { exercises } = this.state
    let temp = exercises[source.index]
    exercises.splice(source.index, 1)
    exercises.splice(destination.index, 0, temp)

    exercises = this.setPositionParams(exercises)

    this.setState(
      {
        exercises,
      },
      () => {
        this.props.handleExercisesUpdate(this.state.exercises)
      }
    )
  }

  setPositionParams(exercises) {
    if (!exercises) return []

    return exercises.map((item, index) => {
      return { ...item, evolution: index + 1 }
    })
  }

  handleTableInputChange(event, index) {
    const target = event.target

    let value = target.type === 'checkbox' ? target.checked : target.value
    if (value === 'true' || value === 'false') {
      value = value === 'true'
    }

    const name = target.name

    if (index > -1) {
      let exercises = this.state.exercises
      exercises[index] = {
        ...this.state.exercises[index],
        [name]: parseInt(value, 10),
      }

      this.setState(
        {
          exercises: exercises,
        },
        () => {
          this.props.handleExercisesUpdate(this.state.exercises)
        }
      )
    }
  }

  postureClick(item) {
    this.setState({
      postureSelector: item,
    })
  }

  handlePostureSelectorClose() {
    this.setState({
      postureSelector: null,
    })
  }

  handlePosturesSubmit(postures) {
    let index = this.state.exercises.indexOf(this.state.postureSelector)

    if (index < 0) return

    let exercises = [...this.state.exercises]
    exercises[index].postures = postures

    this.setState(
      {
        ...this.state,
        exercises,
      },
      () => this.handlePostureSelectorClose()
    )
  }

  postureFromId(id) {
    let toReturn = {}

    let postures = (this.props.resources || {}).postures || []

    postures.map((item) => {
      if (item.id === id) toReturn = item

      return null
    })

    return toReturn
  }

  searchFilteroptions() {
    const { t } = this.props

    const resources = this.props.resourcesExercises
    let toReturn = []

    if (resources.equipment) {
      toReturn.push({
        label: t('FormChallengeCreate.Search.equipment'),
        name: 'equipment',
        value: 'equipment',
        options: resources.equipment.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.muscles) {
      toReturn.push({
        label: t('FormChallengeCreate.Search.muscles'),
        name: 'muscles',
        value: 'muscles',
        options: resources.muscles.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.objectives) {
      toReturn.push({
        label: t('FormChallengeCreate.Search.objectives'),
        name: 'objectives',
        value: 'objectives',
        options: resources.objectives.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.positions) {
      toReturn.push({
        label: t('FormChallengeCreate.Search.positions'),
        name: 'positions',
        value: 'positions',
        options: resources.positions.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    return toReturn
  }

  render() {
    const { t } = this.props

    return (
      <div className={`${baseClass}`}>
        <div>{this.renderExercisesTable()}</div>

        <div>
          <Button onClick={this.handleSearchExercisesClick}>
            {t('FormChallengeCreate.Exercises.button')}
          </Button>
        </div>

        {this.state.showCardModal && (
          <CardPicker
            type={'exercise'}
            handleClose={this.handleCloseCardModal}
            searchResults={this.props.searchResults.exercises}
            limit={this.props.searchResults.limit}
            offset={this.props.searchResults.offset}
            total={this.props.searchResults.total_count}
            searchData={this.state.searchData}
            searchFilteroptions={this.searchFilteroptions()}
            searchFilterType={'multi-select'}
            filterSubmit={this.fetchExercises}
            handleSubmit={this.handleAddExerciseClick}
            status={this.props.statuses[statusRefSearch]}
            resources={this.props.resourcesExercises}
          />
        )}

        {this.state.postureSelector && (
          <PostureSelector
            exercise={this.state.postureSelector}
            postures={this.props.resources.postures}
            handleClose={this.handlePostureSelectorClose}
            onSubmit={this.handlePosturesSubmit}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  searchResults: state.Exercise.searchResults,
  resources: state.Resource.challenges,
  resourcesExercises: state.Resource.exercises,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Exercises))
