import React, { Component } from 'react'
import Select from 'react-select'
import { withTranslation } from 'react-i18next'

import ConfirmDeleteMessage from 'components/ConfirmDeleteMessage'
import AdminTable from 'components/AdminTable'
import Icon from 'components/Icon'
import Button from 'components/Button'

import './style.css'
import { getLocale } from 'services/localisation'

class PhysicalTests extends Component {
  constructor() {
    super()

    this.state = {
      openModalConfirmationDeletePhysicalTest: null,
    }

    this.getPhysicalTestsHeaderRow = this.getPhysicalTestsHeaderRow.bind(this)
    this.buildPhysicalTestsTableRows = this.buildPhysicalTestsTableRows.bind(this)
    this.getPhysicalTestsNotInAssessments = this.getPhysicalTestsNotInAssessments.bind(this)
    this.handleRemovePhysicalTestFromAssessments = this.handleRemovePhysicalTestFromAssessments.bind(
      this
    )
    this.handleOpenModalConfirmationDeletePhysicalTest = this.handleOpenModalConfirmationDeletePhysicalTest.bind(
      this
    )
    this.handleCloseModalConfirmationDeletePhysicalTest = this.handleCloseModalConfirmationDeletePhysicalTest.bind(
      this
    )
  }

  getPhysicalTestsNotInAssessments() {
    let difference = (this.props.allPhysicalTests || []).filter((x) => {
      const found = (this.props.assessmentPhysicalTests || []).filter((y) => x.id === y.id)
      return found.length <= 0
    })

    return (difference || []).map((item) => {
      return {
        value: item.id,
        label: `${item.translations[getLocale()].label} || ${this.setLevelsResources(
          item.levels || []
        ).map((level) => `${level.name}`)} `,
      }
    })
  }

  getPhysicalTestsHeaderRow() {
    const { t } = this.props

    return {
      columns: [
        { value: t('PageAssessments.ModalCreateAssessment.subtitle-name') },
        { value: t('PageAssessments.ModalCreateAssessment.subtitle-levels') },
        { value: t('GLOBAL.empty') },
      ],
    }
  }

  buildPhysicalTestsTableRows() {
    return (this.props.assessmentPhysicalTests || []).map((item, index) => {
      return {
        columns: [
          { value: item.translations ? item.translations[getLocale()].label : item.label },
          { value: this.setLevelsResources(item.levels || []).map((level) => `${level.name}, `) },
          {
            value: (
              <Button
                className="narrow"
                onClick={() => this.handleOpenModalConfirmationDeletePhysicalTest(item, index)}
              >
                <Icon className="small" src={'trash.svg'} />
              </Button>
            ),
          },
        ],
      }
    })
  }

  handleOpenModalConfirmationDeletePhysicalTest(item, index) {
    this.setState({
      openModalConfirmationDeletePhysicalTest: {
        ...item,
        index,
      },
    })
  }

  handleCloseModalConfirmationDeletePhysicalTest() {
    this.setState({
      openModalConfirmationDeletePhysicalTest: null,
    })
  }

  handleRemovePhysicalTestFromAssessments(item) {
    this.props.handleRemovePhysicalTestFromAssessments(item)
    this.handleCloseModalConfirmationDeletePhysicalTest()
  }

  setLevelsResources = (level_ids) => {
    const { levels: levelResources } = this.props.resources
    if (!levelResources.length) {
      return []
    }
    return level_ids.map((level) =>
      levelResources.find((levelResource) => levelResource.id === level.id)
    )
  }

  render() {
    const { t } = this.props

    return (
      <div className="row-container">
        <Select
          onChange={(values) => this.props.handleSelectChange('physical_test_id', values)}
          isMulti={false}
          // value={this.state.form.physical_test_id}
          name={'physical_test_id'}
          options={this.getPhysicalTestsNotInAssessments()}
          className="search-filter-bar-multi-select search-program"
          classNamePrefix={'react-select'}
          placeholder={t('GLOBAL.search')}
        />

        <Button
          className="button-plus"
          onClick={this.props.handleAddPhysicalTestIntoAssessments}
          disabled={this.props.physical_test_id ? false : true}
        >
          +
        </Button>

        <AdminTable
          headerRow={this.getPhysicalTestsHeaderRow()}
          rows={this.buildPhysicalTestsTableRows()}
        />

        {this.state.openModalConfirmationDeletePhysicalTest && (
          <ConfirmDeleteMessage
            data={this.state.openModalConfirmationDeletePhysicalTest}
            handleCancel={this.handleCloseModalConfirmationDeletePhysicalTest}
            handleConfirm={this.handleRemovePhysicalTestFromAssessments}
          />
        )}
      </div>
    )
  }
}

export default withTranslation()(PhysicalTests)
