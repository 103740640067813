export const APP_INIT = 'APP_INIT'

export const LOGIN_REQUEST = 'account/LOGIN_REQUEST'
export const SET_LOGIN_REQUEST_STATUS = 'account/SET_LOGIN_REQUEST_STATUS'

export const SET_LOGGEDIN_STATE = 'account/SET_LOGGEDIN_STATE'
export const SET_LOGGEDIN_STATE_SET = 'account/SET_LOGGEDIN_STATE_SET'

export const SET_CURRENT_ROLE = 'account/SET_CURRENT_ROLE'
export const SET_CURRENT_ROLE_SET = 'account/SET_CURRENT_ROLE_SET'

export const SET_LOGGEDOUT_STATE = 'account/SET_LOGGEDOUT_STATE'
export const SET_LOGGEDOUT_STATE_SET = 'account/SET_LOGGEDOUT_STATE_SET'

export const UPDATE_USER_LANGUAGE = 'account/UPDATE_USER_LANGUAGE'

export const GET_LANGUAGES = 'account/GET_LANGUAGES'
export const SET_LANGUAGES = 'account/SET_LANGUAGES'

export const REGISTER_REQUEST = 'account/REGISTER_REQUEST'
export const SET_REGISTER_REQUEST_STATUS = 'account/SET_REGISTER_REQUEST_STATUS'
export const SET_REGISTERED_STATE = 'account/SET_REGISTERED_STATE'

export const EMAIL_CONFIRM_SUBMIT_REQUEST = 'account/EMAIL_CONFIRM_SUBMIT_REQUEST'
export const SET_REGISTER_CONFIRM_EMAIL_STATUS = 'account/SET_REGISTER_CONFIRM_EMAIL_STATUS'

export const UPDATE_PROFILE_REQUEST = 'account/UPDATE_PROFILE_REQUEST'
export const SET_PROFILE_UDPDATE_STATUS = 'account/SET_PROFILE_UDPDATE_STATUS'

export const SUBMIT_FORGOTTEN_PASSWORD_REQUEST = 'account/SUBMIT_FORGOTTEN_PASSWORD_REQUEST'
export const SUBMIT_NEW_PASSWORD = 'account/SUBMIT_NEW_PASSWORD'

export function loginRequestCreator(data) {
  return { type: LOGIN_REQUEST, payload: data }
}

export function setLoginRequestStatusCreator(status = null, error = null) {
  return { type: SET_LOGIN_REQUEST_STATUS, payload: { status, error } }
}

export function setLoggedInStateCreator(payload) {
  return {
    type: SET_LOGGEDIN_STATE,
    payload: {
      user: payload.user,
    },
  }
}

export function setCurrentRole(role) {
  return { type: SET_CURRENT_ROLE, payload: role }
}

export function submitConfirmEmailCreator(payload) {
  return {
    type: EMAIL_CONFIRM_SUBMIT_REQUEST,
    payload: {
      payload: payload,
    },
  }
}

export function submitProfileUpdate(payload) {
  return {
    type: UPDATE_PROFILE_REQUEST,
    payload: {
      payload: payload,
    },
  }
}

export function submitNewPassword(data) {
  return { type: SUBMIT_NEW_PASSWORD, payload: data }
}

export function submitForgottenPasswordRequest(data) {
  return { type: SUBMIT_FORGOTTEN_PASSWORD_REQUEST, payload: data }
}

export function getLanguages(data) {
  return { type: GET_LANGUAGES, payload: data }
}

export function updateUserLanguage(payload) {
  return { type: UPDATE_USER_LANGUAGE, payload: payload }
}
