import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import Button from 'components/Button'

import './style.css'

const baseClass = 'ActionButtons'

class ActionButtons extends Component {
  constructor() {
    super()

    this.state = {}

    this.handleButtonClick = this.handleButtonClick.bind(this)
  }

  handleButtonClick(type) {
    switch (type) {
      case 'edit': {
        this.props.handleEditClick()
        break
      }
      case 'delete': {
        this.props.handleDeleteClick()
        break
      }

      default:
        break
    }
  }

  render() {
    const { t, objectName } = this.props

    return (
      <div className={`${baseClass}`}>
        <Button
          onClick={() => this.handleButtonClick('edit')}
          className={`${baseClass}-edit-button`}
        >
          {t('GLOBAL.edit')}
        </Button>
        {this.props.canDelete && (
          <Button
            className={`${baseClass}-delete-button`}
            onClick={() => this.handleButtonClick('delete')}
          >
            {t('ActionButtons.permanent-delete-' + objectName)}
          </Button>
        )}
      </div>
    )
  }
}

ActionButtons.propTypes = {
  handleEditClick: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  canDelete: PropTypes.bool,
}

ActionButtons.defaultProps = {
  canDelete: false,
}

export default withTranslation()(ActionButtons)
