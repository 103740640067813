import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import Button from 'components/Button'
import SessionDetails from 'components/SessionDetails'
import ActionButtons from 'components/ActionButtons'
import ExerciseDetails from 'components/ExerciseDetails'
import ExerciseSteps from 'components/ExerciseSteps'
import ExerciseAdditionalInfo from 'components/ExerciseAdditionalInfo'
import ErrorMessage from 'components/ErrorMessage'
import SessionPrinter from './components/SessionPrinter'
import ActionConfirmation from 'components/ActionConfirmation'

import {
  duplicateSession,
  deleteSessionPermanently,
  updateSession,
} from 'store/modules/Session/actions.js'

import './style.css'
import { clearStatus } from 'store/modules/Status/actions.js'
import { ADMIN_ROLE } from '../../constants/constants'
import { getSessionCreator, makeSessionPublic } from '../../store/modules/Session/actions'
import {
  FETCH_EXERCISES_RESOURCES_REQUEST,
  FETCH_SESSIONS_RESOURCES_REQUEST,
} from '../../store/modules/Resource/actions'
import { getLocale } from 'services/localisation'
import SessionPrinterFr from 'containers/PageSession/components/SessionPrinterFr/index'
import getTranslations from 'helpers/get-translations'

const statusRefDuplicate = 'PageSession-duplicate'
const statusRefUnpublish = 'PageSession-unpublish'
const statusRefPublish = 'PageSession-publish'
const statusRefDeleteSessionPermanently = 'PageSession-deleteSessionPermanently'

class PageSession extends Component {
  constructor() {
    super()

    this.state = {
      actionConfirmationActive: false,
      confirmUnpublishActive: false,
    }
  }

  componentDidMount() {
    this.props.dispatch({ type: FETCH_EXERCISES_RESOURCES_REQUEST })
    const { resources } = this.props
    if (!Object.values(resources)[0].length) {
      this.props.dispatch({ type: FETCH_SESSIONS_RESOURCES_REQUEST })
    }
    this.props.dispatch(clearStatus({ statusRef: statusRefDuplicate }))
    this.setState({ sessionId: this.props.match.params.sessionId }, () => {
      this.props.dispatch(getSessionCreator(this.state.sessionId))
    })
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.sessionId &&
      this.props.match.params.sessionId !== prevProps.match.params.sessionId
    ) {
      this.setState({ sessionId: this.props.match.params.sessionId }, () => {
        this.props.dispatch(getSessionCreator(this.state.sessionId))
      })
    }

    if (
      this.props.statuses[statusRefUnpublish] &&
      this.props.statuses[statusRefUnpublish].state === 'error'
    ) {
      if (prevProps.statuses[statusRefUnpublish].state !== 'error') {
        this.setState({
          confirmUnpublishActive: true,
        })
      }
    }
  }

  handleDuplicateStatus = (data) => {
    const { duplicateId: id } = data
    setTimeout(() => {
      this.props.history.push(`/session/${id}`)
    }, 700)
  }

  handlePublishStatus = () => {
    setTimeout(() => {
      this.props.dispatch(getSessionCreator(this.state.sessionId))
      this.props.dispatch(clearStatus({ statusRef: statusRefPublish }))
    }, 200)
  }

  handleClearDeleteStatus = () => {
    setTimeout(() => {
      this.handleCancelDeletingConfirmationModal()
      this.props.dispatch(clearStatus({ statusRef: statusRefDeleteSessionPermanently }))
      window.history.go(-1)
    }, 200)
  }

  handleClearUnpublishStatus = () => {
    setTimeout(() => {
      this.handleCancelUnpublishConfirmationModal()
      this.props.dispatch(clearStatus({ statusRef: statusRefUnpublish }))
      window.history.go(-1)
    }, 200)
  }

  handleDeleteSession = () => {
    this.props.dispatch(
      deleteSessionPermanently({
        session_id: this.state.sessionId,
        statusRef: statusRefDeleteSessionPermanently,
        callback: {
          success: this.handleClearDeleteStatus,
        },
      })
    )
  }

  handleDeleteClick = () => {
    this.props.dispatch(clearStatus({ statusRef: statusRefDeleteSessionPermanently }))

    this.setState({
      actionConfirmationActive: true,
    })
  }

  handleCancelDeletingConfirmationModal = () => {
    this.setState({
      actionConfirmationActive: false,
    })
  }

  handleAcceptPublishClick = () => {
    this.props.dispatch(
      makeSessionPublic({
        id: this.state.sessionId,
        statusRef: statusRefPublish,
        callback: {
          success: this.handlePublishStatus,
        },
      })
    )
  }

  handleUnpublishClick = () => {
    this.props.dispatch(clearStatus({ statusRef: statusRefUnpublish }))

    this.setState({
      confirmUnpublishActive: true,
    })
  }

  handleDuplicateClick = () => {
    this.props.dispatch(
      duplicateSession({
        session_id: this.state.sessionId,
        statusRef: statusRefDuplicate,
        callback: {
          success: this.handleUnpublishStatus,
        },
      })
    )
  }

  handleUnpublish = () => {
    const { sessionId } = this.state

    this.props.dispatch(
      updateSession({
        sessionId,
        statusRef: statusRefUnpublish,
        public: false,
        callback: {
          success: this.handleClearUnpublishStatus,
        },
      })
    )
  }

  handleCancelUnpublishConfirmationModal = () => {
    this.setState({
      confirmUnpublishActive: false,
    })
  }

  getExercises = () => {
    const { session_exercises } = this.props.session
    let toReturn = []
    session_exercises &&
      session_exercises.map((item, index) => {
        const exercise = this.setExerciseResources(item.exercise)
        toReturn.push(
          <div className="page-session-exercises-row" key={`sessionExercises-${index}`}>
            <ExerciseDetails
              className={'exercise-details--small'}
              exercise={exercise}
              isSessionExercise={true}
              sessionExercise={item}
            />

            <ExerciseSteps className={'exercise-steps--small'} exercise={exercise} />
            <ExerciseAdditionalInfo
              className={'exercise-additional-info--small'}
              exercise={exercise}
            />
          </div>
        )
      })

    return toReturn
  }

  handleEditClick = () => {
    this.props.history.push(`/session/${this.state.sessionId}/edit`)
  }

  setExerciseResources = (exercise) => {
    const { objectives, positions, counts } = this.props.exerciseResources
    const { objective_id, position_id, count_id, ...rest } = exercise

    const objective = objectives.find((obj) => obj.id === objective_id) || {}
    const position = positions.find((position) => position.id === position_id) || {}
    const count = counts.find((count) => count.id === count_id) || {}

    return { ...rest, objective, position, count }
  }

  setSessionResources = (session) => {
    const { objectives, levels, target_audiences } = this.props.resources

    const { objective_id, level_id, target_audience_id, session_exercises = [], ...rest } = session

    const objective = objectives.find((obj) => obj.id === objective_id) || {}
    const level = levels.find((level) => level.id === level_id) || {}
    const target_audience =
      target_audiences.find((target) => target.id === target_audience_id) || {}
    const nb_exercises = session_exercises.length

    const muscles = this.getMusclesFromExercises(session_exercises)
    const objs = this.getObjectivesFromExercises(session_exercises, objectives)

    return {
      ...rest,
      objective,
      level,
      target_audience,
      nb_exercises,
      muscles,
      session_exercises,
      objectives: objs,
    }
  }

  getMusclesFromExercises = (exercises) => {
    return exercises
      .reduce((prev, curr) => {
        const currRes = [...prev]
        curr.exercise.muscles.map((m) => {
          if (currRes.find((i) => i.id === m.id)) {
            const itemIndex = currRes.findIndex((i) => i.id === m.id)
            currRes[itemIndex].usage = currRes[itemIndex].usage + 1
          } else {
            currRes.push({ ...m, usage: 1 })
          }
        })
        return [...currRes]
      }, [])
      .sort((prev, curr) => curr.usage - prev.usage)
  }

  getObjectivesFromExercises = (exercises, objectives) => {
    return exercises
      .reduce((prev, curr) => {
        const currRes = [...prev]
        const objective = objectives.find((obj) => obj.id === curr.exercise.objective_id)
        if (currRes.find((i) => i.id === objective?.id)) {
          const itemIndex = currRes.findIndex((i) => i.id === objective?.id)
          currRes[itemIndex].usage = currRes[itemIndex].usage + 1
        } else {
          currRes.push({ ...objective, usage: 1 })
        }
        return [...currRes]
      }, [])
      .sort((prev, curr) => curr.usage - prev.usage)
  }

  render() {
    const { t, match, session, role, statuses } = this.props
    const { actionConfirmationActive, confirmUnpublishActive } = this.state
    const locale = getLocale()
    const description = getTranslations(session.translations, 'description')?.[locale]
    const recommendations = getTranslations(session.translations, 'recommendations')?.[locale]

    return (
      <div className="page-session" key={match.params.sessionId}>
        <div className="page-session-content">
          {getLocale() === 'fr' ? (
            <SessionPrinterFr content={this.setSessionResources(session)} />
          ) : (
            <SessionPrinter content={this.setSessionResources(session)} />
          )}

          <SessionDetails session={this.setSessionResources(session)} />

          <div>
            <ActionButtons
              role={role}
              handleEditClick={this.handleEditClick}
              handleDeleteClick={this.handleDeleteClick}
              objectName="session"
              canDelete={session.creator && !session.public}
            />

            <Button
              onClick={this.handleDuplicateClick}
              status={statuses[statusRefDuplicate]}
              data-cy="duplicate-session"
            >
              {t('PageSession.duplicate-button')}
            </Button>

            {!!session.public && role === ADMIN_ROLE && (
              <Button
                onClick={this.handleUnpublishClick}
                status={statuses[statusRefUnpublish]}
                data-cy="unpublish-session"
              >
                {t('PageSession.unpublish-session')}
              </Button>
            )}

            <ActionConfirmation
              isOpen={confirmUnpublishActive}
              actionTitle={'PageSession.unpublish-session'}
              handleCancel={this.handleCancelUnpublishConfirmationModal}
              handleConfirm={this.handleUnpublish}
              status={statuses[statusRefUnpublish]}
            />

            <ActionConfirmation
              isOpen={actionConfirmationActive}
              confirmationTitle={t('PageSession.delete-confirmation-title')}
              handleCancel={this.handleCancelDeletingConfirmationModal}
              handleConfirm={this.handleDeleteSession}
              status={statuses[statusRefDeleteSessionPermanently]}
            />

            <ErrorMessage status={statuses[statusRefDuplicate]} />
          </div>

          {role === ADMIN_ROLE && session.public_request === 1 && (
            <div className="page-session-pending">
              {t('PageSession.title-1')}
              <Button
                onClick={this.handleAcceptPublishClick}
                status={statuses[statusRefPublish]}
                data-cy="publish-session"
              >
                {t('PageSession.button')}
              </Button>

              <Button
                onClick={this.handleUnpublishClick}
                status={statuses[statusRefUnpublish]}
                data-cy="refuse-session"
              >
                {t('PageSession.unpublish-session')}
              </Button>

              <ErrorMessage status={statuses[statusRefPublish]} />
            </div>
          )}

          <div className="session-details-exercises-container">
            <div className={`session-details-exercises-container-informations`}>
              <div className={`session-details-exercises-container-title`}>
                {t('FormSessionCreate.label-2')}
              </div>
              <div>{description}</div>
            </div>

            <div className={`session-details-exercises-container-informations`}>
              <div className={`session-details-exercises-container-title`}>
                {t('FormSessionCreate.label-7')}
              </div>
              <div>{recommendations}</div>
            </div>

            <div className="session-details-exercises-container-title">
              {t('PageSession.title-2')}
            </div>

            {this.getExercises()}
          </div>
        </div>
      </div>
    )
  }
}

PageSession.defaultProps = {
  session: {},
  role: '',
  statuses: {},
}

const mapStateToProps = (state) => ({
  resources: state.Resource.sessions,
  exerciseResources: state.Resource.exercises,
  session: state.Session.session,
  role: state.Account.role,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PageSession))
)
