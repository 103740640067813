import React, { Component } from 'react'
import './style.css'

class Img extends Component {
  render() {
    const imgSrc = require('./assets/' + this.props.src)
    return <img src={imgSrc} className={this.props.class} alt={this.props.src} />
  }
}

export default Img
