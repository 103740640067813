import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { withTranslation } from 'react-i18next'

import SessionCard from 'components/SessionCard'
import Pagination from 'components/Pagination'
import SearchFilterBar from 'components/SearchFilterBar'

import { sessionSearchCreator, updateSessionSearchFilters } from 'store/modules/Session/actions.js'
import { FETCH_SESSIONS_RESOURCES_REQUEST } from 'store/modules/Resource/actions.js'
import { getListPrescribers } from 'store/modules/Prescriber/actions.js'

import './style.css'

const statusRefSearch = 'page-sessions-requests_search'

class PageSessionsrrequests extends Component {
  constructor() {
    super()
    this.state = {
      searchData: {},
    }

    this.fetchSessions = this.fetchSessions.bind(this)
    this.searchFilteroptions = this.searchFilteroptions.bind(this)
    this.updateStoredFilters = this.updateStoredFilters.bind(this)
  }

  componentDidMount() {
    const { searchFilters } = this.props.theState.Session
    this.props.dispatch({ type: FETCH_SESSIONS_RESOURCES_REQUEST })
    this.props.dispatch(
      sessionSearchCreator({
        ...searchFilters,
        ...searchFilters.multiSelect,
        search: searchFilters.search_text,
        statusRef: statusRefSearch,
        public_request: true,
      })
    )
    this.props.dispatch(getListPrescribers({}))
  }

  fetchSessions(data) {
    if (!data) return

    this.setState(
      {
        searchData: data,
      },
      () => {
        this.props.dispatch(
          sessionSearchCreator({
            ...data,
            ...data.multiSelect,
            search: data.search_text,
            public_request: true,
            statusRef: statusRefSearch,
          })
        )
      }
    )
  }

  searchFilteroptions() {
    const { t } = this.props

    const resources = this.props.resources
    const prescribers = this.props.prescribers
    let toReturn = []

    if (resources.levels) {
      toReturn.push({
        label: 'Levels',
        name: 'levels',
        value: 'levels',
        options: resources.levels.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.objectives) {
      toReturn.push({
        label: 'Objectives',
        name: 'objectives',
        value: 'objectives',
        options: resources.objectives.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.target_audiences) {
      toReturn.push({
        label: 'Target Audiences',
        name: 'target_audiences',
        value: 'target_audiences',
        options: resources.target_audiences.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (prescribers) {
      toReturn.push({
        label: t('PageSessions.SearchFilters.prescribers'),
        name: 'prescribers',
        value: 'prescribers',
        options: (prescribers || []).map((item) => {
          return { value: item.id, label: item.full_name }
        }),
      })
    }

    return toReturn
  }

  updateStoredFilters(filters) {
    this.props.dispatch(updateSessionSearchFilters({ ...filters }))
  }

  setSessionResources = (session) => {
    const { objectives, levels, target_audiences } = this.props.resources

    const { objective_id, level_id, target_audience_id, exercises_count, ...rest } = session

    const objective = objectives.find((obj) => obj.id === objective_id) || {}
    const level = levels.find((level) => level.id === level_id) || {}
    const target_audience =
      target_audiences.find((target) => target.id === target_audience_id) || {}

    return { ...rest, objective, level, target_audience, nb_exercises: exercises_count }
  }

  render() {
    const { t } = this.props

    return (
      <div className="page-sessions-requests">
        <div className="page-sessions-requests-title">{t('PageSessionsrrequests.title')}</div>

        <SearchFilterBar
          filterTitle={''}
          filterOptions={this.searchFilteroptions()}
          handleSearchFormSubmit={this.fetchSessions}
          onfilterChange={this.fetchSessions}
          filterType={'multi-select'}
          showLoadingIndicator={(this.props.statuses[statusRefSearch] || {}).state === 'busy'}
          storedFilters={this.props.theState.Session.searchFilters}
          updateStoredFilters={this.updateStoredFilters}
        />

        {this.props.searchResults.sessions &&
          this.props.searchResults.sessions.map((item, idx) => {
            const session = this.setSessionResources(item)
            return (
              <Link key={idx} to={`/session/${item.id}`}>
                <SessionCard item={session} />
              </Link>
            )
          })}

        <Pagination
          searchData={this.state.searchData}
          limit={this.props.searchResults.limit}
          offset={this.props.searchResults.offset}
          total={this.props.searchResults.total_count}
          fetchData={this.fetchSessions}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  activeModal: state.Modal.activeModal,
  searchResults: state.Session.requestSearchResults,
  resources: state.Resource.sessions,
  prescribers: state.Prescriber.prescribersList,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(PageSessionsrrequests)
)
