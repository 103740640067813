import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './style.css'

const baseClass = 'translations-tabs'

class TranslationTabs extends Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    return (
      <div className={`${baseClass} ${this.props.className}`}>
        <div className={`${baseClass}-containers`}>
          {this.props.languages.map((lang, idx) => {
            return (
              <button
                key={idx}
                type="button"
                className={`${baseClass}-containers-tab ${
                  this.props.currentLanguage === lang.code ? 'selected' : ''
                }`}
                onClick={() => this.props.handleLanguageChange(lang.code)}
              >
                {lang.code}
              </button>
            )
          })}
        </div>
      </div>
    )
  }
}

TranslationTabs.propTypes = {
  languages: PropTypes.array,
}

TranslationTabs.defaultProps = {
  languages: [],
}

export default TranslationTabs
