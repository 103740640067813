export const CREATE_CHALLENGE_REQUEST = 'challenge/CREATE_CHALLENGE_REQUEST'
export const UPDATE_CHALLENGE = 'challenge/UPDATE_CHALLENGE'
export const UPDATE_CHALLENGE_EXERCISE = 'challenge/UPDATE_CHALLENGE_EXERCISE'

export const CHALLENGE_SEARCH_SUBMIT = 'challenge/CHALLENGE_SEARCH_SUBMIT'
export const CHALLENGE_FETCH_SUBMIT = 'challenge/CHALLENGE_FETCH_SUBMIT'
export const UPDATE_CHALLENGE_CACHE = 'challenge/UPDATE_CHALLENGE_CACHE'
export const UPDATE_CHALLENGE_CACHE_SET = 'challenge/UPDATE_CHALLENGE_CACHE_SET'
export const SET_CHALLENGES_SEARCH_RESULTS = 'challenge/SET_CHALLENGES_SEARCH_RESULTS'
export const SET_CHALLENGE_REQUESTS_SEARCH_RESULTS =
  'challenge/SET_CHALLENGE_REQUESTS_SEARCH_RESULTS'
export const CHALLENGE_MAKE_PUBLIC_REQUEST = 'challenge/CHALLENGE_MAKE_PUBLIC_REQUEST'
export const CHALLENGE_DELETE_REQUEST = 'challenge/CHALLENGE_DELETE_REQUEST'
export const CHALLENGE_ADD_EXERCISE_REQUEST = 'challenge/CHALLENGE_ADD_EXERCISE_REQUEST'
export const CHALLENGE_REMOVE_EXERCISE_REQUEST = 'challenge/CHALLENGE_REMOVE_EXERCISE_REQUEST'
export const UPDATE_SEARCH_CHALLENGE_FILTERS = 'challenge/UPDATE_SEARCH_CHALLENGE_FILTERS'

export function createChallengeCreator(payload) {
  return { type: CREATE_CHALLENGE_REQUEST, payload: payload }
}

export function updateChallenge(payload) {
  return { type: UPDATE_CHALLENGE, payload: payload }
}

export function updateChallengeExercise(payload) {
  return { type: UPDATE_CHALLENGE_EXERCISE, payload: payload }
}

export function challengeSearchCreator(payload) {
  return { type: CHALLENGE_SEARCH_SUBMIT, payload: payload }
}

export function addExercisetoChallengeCreator(payload) {
  return { type: CHALLENGE_ADD_EXERCISE_REQUEST, payload: payload }
}

export function removeExercisetoChallengeCreator(payload) {
  return { type: CHALLENGE_REMOVE_EXERCISE_REQUEST, payload: payload }
}

export function updateChallengeSearchFilters(payload) {
  return { type: UPDATE_SEARCH_CHALLENGE_FILTERS, payload }
}
