import React, { Component } from 'react'

import Icon from 'components/Icon'
import DropdownMenu from './DropdownMenu'

import './style.css'

// TODO: handle file paths
const imagePath = process.env.REACT_APP_IMAGE_URL

class AvatarButton extends Component {
  constructor() {
    super()

    this.handleDropdownClick = this.handleDropdownClick.bind(this)

    this.state = {
      dropdownActive: false,
    }
  }

  handleDropdownClick(e) {
    e.stopPropagation()

    this.setState((prevState) => ({
      dropdownActive: !prevState.dropdownActive,
    }))
  }

  render() {
    return (
      <div className="header-avatar-button" onClick={this.handleDropdownClick}>
        {this.props.avatarUrl && <img src={imagePath + this.props.avatarUrl} alt="avatar" />}
        {!this.props.avatarUrl && <Icon src="default-avatar.png" />}

        <DropdownMenu
          dropdownButtons={this.props.dropdownButtons}
          isActive={this.state.dropdownActive}
        />
      </div>
    )
  }
}

export default AvatarButton
