/**
 * Given a translations object it will return the translations for the key send
 *
 * @param {Object} translations object to parse
 * @param {String} key to extract from the translations object
 * @returns {Object} object of translations for the key
 */

const getTranslations = (translations, key) => {
  if (!translations || translations.length === 0) return

  let toReturn = {}

  ;(Object.keys(translations) || []).map((lang) => {
    const translation = translations[lang] || {}

    toReturn[lang] = translation[key]

    return null
  })

  return toReturn
}

export default getTranslations
