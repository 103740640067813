export const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL'
export const CLOSE_MODALS = 'CLOSE_MODALS'

export const actionCreators = {
  openModal: function ({ modal, data = {} }) {
    return {
      type: SET_ACTIVE_MODAL,
      payload: modal,
      data: data,
    }
  },
}
