import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

import { withTranslation } from 'react-i18next'

import FormGeneric from 'components/FormGeneric'
import Button from 'components/Button'
import './style.css'

class SearchFilterBar extends Component {
  selectDefaultValues = {}
  constructor() {
    super()

    this.state = {
      form: {
        checkboxes: [],
        multiSelect: {},
      },
    }
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this)
  }

  componentDidUpdate() {
    const { storedFilters, filterOptions } = this.props

    if (
      storedFilters.multiSelect &&
      Object.keys(storedFilters.multiSelect).length > 0 &&
      filterOptions[0].options.length > 0
    ) {
      filterOptions.forEach((item) => {
        this.selectDefaultValues[item.name] = item.options.filter(
          (object) =>
            storedFilters.multiSelect[item.name] &&
            storedFilters.multiSelect[item.name].includes(object.value)
        )
      })
    }
  }
  componentDidMount() {
    if (Object.keys(this.props.storedFilters).length > 0) {
      this.setState({
        ...this.state,
        form: this.props.storedFilters,
      })
    } else {
      this.selectDefaultValues = {}
    }
  }

  handleFormSubmit(ev) {
    ev.preventDefault()
    ev.stopPropagation()

    this.props.handleSearchFormSubmit({
      ...this.state.form,
      offset: 0,
    })
  }

  handleInputChange(e) {
    let { name, value, type, checked } = e.target

    if (type === 'checkbox') {
      value = checked
    }

    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: value,
        },
      },
      () => {
        this.props.onfilterChange({
          ...this.state.form,
          offset: 0,
        })
        if (this.props.updateStoredFilters) {
          this.props.updateStoredFilters(this.state.form)
        }
      }
    )
  }

  handleCheckboxChange(e) {
    const { value, checked } = e.target
    let checkboxes = this.state.form.checkboxes

    if (checked && checkboxes.indexOf(value) < 0) {
      checkboxes.push(value)
    }

    if (!checked && checkboxes.indexOf(value) >= 0) {
      checkboxes.splice(checkboxes.indexOf(value), 1)
    }

    this.setState(
      {
        form: {
          ...this.state.form,
          checkboxes: checkboxes,
        },
      },
      () => {
        this.props.onfilterChange({
          ...this.state.form,
          offset: 0,
        })
      }
    )
  }

  handleMultiSelectChange(name, values) {
    this.setState(
      {
        form: {
          ...this.state.form,
          multiSelect: {
            ...this.state.form.multiSelect,
            [name]: values ? values.map((item) => item.value) : [],
          },
        },
      },
      () => {
        this.props.onfilterChange({
          ...this.state.form,
          offset: 0,
        })
        this.selectDefaultValues[name] = values
        if (this.props.updateStoredFilters) {
          this.props.updateStoredFilters(this.state.form)
        }
      }
    )
  }

  render() {
    const { t } = this.props
    return (
      <FormGeneric
        id={this.props.id}
        className={`search-filter-bar ${this.props.className}`}
        onSubmit={this.handleFormSubmit}
        action={this.props.action}
        method={this.props.method}
      >
        {this.props.filterType && (
          <div className={`search-filter-bar-filters ${this.props.filterType}`}>
            <div className="search-filter-bar-filters-title">{this.props.filterTitle}</div>

            <div className={`search-filter-bar-filters-options ${this.props.filterType}`}>
              <div className="search-filter-bar-filters-options-inner">
                {this.props.filterOptions.map((item, idx) => {
                  return (
                    <label key={idx} className="width-auto">
                      {this.props.filterType === 'radio' && (
                        <span>
                          <input
                            type={this.props.filterType}
                            name={item.name}
                            onChange={this.handleInputChange}
                            // onInput={this.handleInputChange}
                            checked={
                              item.checked || this.state.form[item.name] === item.name || false
                            }
                            value={item.value}
                          />

                          {item.label}
                        </span>
                      )}

                      {this.props.filterType === 'checkbox' && (
                        <span>
                          <input
                            type={this.props.filterType}
                            name={item.name}
                            onChange={this.handleCheckboxChange}
                            // onInput={this.handleCheckboxChange}
                            checked={
                              this.state.form.checkboxes.indexOf((item.value || '').toString()) >= 0
                            }
                            value={item.value}
                          />

                          {item.label}
                        </span>
                      )}

                      {this.props.filterType === 'multi-select' && (
                        <span>
                          {item.label}

                          <Select
                            onChange={(values) => {
                              this.handleMultiSelectChange(item.name, values)
                            }}
                            defaultValue={item.defaultValue || []}
                            isMulti={item.isMulti || true}
                            name={item.name || ''}
                            options={item.options}
                            className="search-filter-bar-multi-select"
                            classNamePrefix={item.classNamePrefix || 'react-select'}
                            placeholder={t('GLOBAL.search')}
                            value={this.selectDefaultValues[item.name] || []}
                          />
                        </span>
                      )}
                    </label>
                  )
                })}
              </div>
            </div>
          </div>
        )}

        {!this.props.hideSearch && (
          <div className="search-filter-bar-text-search">
            <div className="search-filter-bar-text-search-title">{t('SearchFilterBar.search')}</div>

            <input
              type="text"
              name="search_text"
              value={this.state.form.search_text || ''}
              onChange={this.handleInputChange}
              maxLength={191}
            />

            <Button type="submit" onClick={() => {}} status={this.props.status}>
              {t('SearchFilterBar.filter')}
            </Button>
          </div>
        )}

        {this.props.showLoadingIndicator && <div className=" isLoading" />}
      </FormGeneric>
    )
  }
}

SearchFilterBar.propTypes = {
  filterOptions: PropTypes.array,
  updateStoredFilters: PropTypes.func,
  storedFilters: PropTypes.object,
}

SearchFilterBar.defaultProps = {
  filterOptions: [],
  storedFilters: {},
}

export default withTranslation()(SearchFilterBar)
