import CookieConsent from 'react-cookie-consent'
import Button from '../../components/Button'
import { withTranslation } from 'react-i18next'
import React, { Component } from 'react'
class CookieConsentModal extends Component {
  render() {
    const { t, onAccept, onDecline } = this.props
    const hostName = window.location.origin.replace('referensiel', 'getphy')
    const termsUrl = hostName + '/terms#personalTerms'
    return (
      <CookieConsent
        onAccept={onAccept}
        onDecline={onDecline}
        location="bottom"
        buttonText={t('CookieConsent.button-accept')}
        declineButtonText={t('CookieConsent.button-decline')}
        flipButtons
        cookieName="ReferensielCookieConsentPolicy"
        style={{
          background: 'rgba(0, 21, 136, 0.9)',
          fontSize: '1.3rem',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
        contentStyle={{
          maxWidth: '980px',
        }}
        expires={395}
        enableDeclineButton
        ButtonComponent={Button}
      >
        <p className="cookieConsentParagraph">{t('CookieConsent.firstParagraph')}</p>
        <p>
          <br />
        </p>
        <p>
          {t('CookieConsent.secondParagraph')}
          <a rel="noreferrer" target="_blank" href={termsUrl}>
            {t('CookieConsent.link')}
          </a>
          {t('CookieConsent.thirdParagraph')}
        </p>
      </CookieConsent>
    )
  }
}
export default withTranslation()(CookieConsentModal)
