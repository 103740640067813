import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import AdminTable from 'components/AdminTable'
import Button from 'components/Button'

import { Link } from 'react-router-dom'

import './style.css'

// const baseClass = 'pages-stats-general-stats'

class MyPrograms extends Component {
  constructor() {
    super()

    this.state = {}

    this.buildTableRows = this.buildTableRows.bind(this)
  }

  buildTableRows() {
    return Object.keys(this.props.programs).map((key) => {
      const item = this.props.programs[key]
      return {
        columns: [
          { value: item.getphy ? '✔' : '' },
          { value: item.name },
          { value: (item.level || {}).name },
          { value: item.sessions_count },
          { value: item.challenges_count },
          { value: item.cycles_count },
        ],
        onClick: () => this.props.history.push(`/program/${item.id}`),
      }
    })
  }

  getHeaderRow() {
    const { t } = this.props

    return {
      columns: [
        { value: t('PagePrograms.subtitle-1') },
        { value: t('PagePrograms.subtitle-2') },
        { value: t('PagePrograms.subtitle-3') },
        { value: t('PagePrograms.subtitle-4') },
        { value: t('PagePrograms.subtitle-5') },
        { value: t('PagePrograms.subtitle-6') },
      ],
    }
  }

  render() {
    const { t } = this.props

    return (
      <div>
        <div>
          <Link to="/programs/create">
            <Button onClick={() => {}}>{t('PageBag.MyPrograms.new-program')}</Button>
          </Link>
        </div>
        <div>
          <AdminTable headerRow={this.getHeaderRow()} rows={this.buildTableRows()} />
        </div>
      </div>
    )
  }
}

export default withTranslation()(withRouter(MyPrograms))
