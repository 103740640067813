import React, { Component } from 'react'
import { connect } from 'react-redux'

import FormProgramCreate from 'components/FormProgramCreate'

import { clearStatus } from 'store/modules/Status/actions.js'
import { CREATE_PROGRAM_REQUEST } from 'store/modules/Program/actions.js'
import { FETCH_PROGRAMS_RESOURCES_REQUEST } from 'store/modules/Resource/actions.js'

import './style.css'

const statusRef = 'PageProgramsCreate'

class PageProgramsCreate extends Component {
  constructor() {
    super()
    this.state = {}

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(clearStatus({ statusRef: statusRef }))
    this.props.dispatch({ type: FETCH_PROGRAMS_RESOURCES_REQUEST })
  }

  componentDidUpdate(prevProps) {
    if (this.props.statuses[statusRef]) {
      if (
        this.props.statuses[statusRef].state === 'finished' &&
        prevProps.statuses[statusRef].state !== 'finshed'
      ) {
        const id = this.props.statuses[statusRef].data.newId
        setTimeout(() => {
          this.props.history.push(`/program/${id}`)
        })
      }
    }
  }

  handleSubmit(form, cycles) {
    this.props.dispatch({
      type: CREATE_PROGRAM_REQUEST,
      payload: {
        ...form,
        cycles: cycles,
        statusRef: statusRef,
      },
    })
  }

  render() {
    return (
      <div className="page-programs-create page">
        <FormProgramCreate
          resources={this.props.resources}
          statusSubmit={this.props.statuses[statusRef]}
          handleSubmit={this.handleSubmit}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  activeModal: state.Modal.activeModal,
  role: state.Account.role,
  resources: state.Resource.programs,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PageProgramsCreate)
