import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import getTranslations from 'helpers/get-translations'

import FormGeneric from 'components/FormGeneric'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'

import Input from 'containers/Input'
import CyclesContainer from './containers/CyclesContainer'
import ReactModal from 'react-modal'
import CloseButton from 'components/CloseButton'
import isEqual from 'lodash/isEqual'
import './style.css'

const baseClass = 'form-program-create'

class FormProgramCreate extends Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {
        getphy: false,
        enabled: false,
      },
      cycles: [],
      programObjectives: [],
      programObjectivesChildren: [],
      openDisableModal: false,
      enabledModalErrorMessage: null,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleLevelChange = this.handleLevelChange.bind(this)
    this.handleProgramObjectiveChange = this.handleProgramObjectiveChange.bind(this)
    this.callBackAfterInputChange = this.callBackAfterInputChange.bind(this)
    this.handleCheckboxGroupChange = this.handleCheckboxGroupChange.bind(this)
    this.handleCyclesUpdate = this.handleCyclesUpdate.bind(this)
    this.handleGetphyChange = this.handleGetphyChange.bind(this)
    this.handleEnabledChange = this.handleEnabledChange.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleValidateDisable = this.handleValidateDisable.bind(this)

    this.handleSubmit = this.handleSubmit.bind(this)

    this.getProgramObjectives = this.getProgramObjectives.bind(this)
    this.getProgramObjectivesChildren = this.getProgramObjectivesChildren.bind(this)

    this.handleBack = this.handleBack.bind(this)
  }

  componentDidMount() {
    if (this.props.initialData && this.props.resources) {
      this.setInitialData()
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.resources && !isEqual(this.props.resources, prevProps.resources)) ||
      (this.props.initialData &&
        !isEqual(this.props.initialData, prevProps.initialData) &&
        !prevProps.isError)
    ) {
      this.setInitialData()
    }
    if (!prevProps.isError && this.props.isError) {
      this.setInitialData()
    }
  }

  setInitialData = () => {
    if (!this.props.initialData) return
    const {
      id,
      translations,
      enabled,
      public: isPublic,
      getphy,
      program_objective,
      level,
      cycles,
    } = this.props.initialData
    const name = getTranslations(translations, 'name')
    let program_objective_id = program_objective?.id || ''
    let program_subobjective_id = null

    if (program_objective?.parent_id) {
      program_objective_id = program_objective?.parent_id || ''
      program_subobjective_id = program_objective?.id || ''

      this.getProgramObjectivesChildren(program_objective_id)
    }

    const level_id = level?.id || ''

    if (level_id) {
      this.getProgramObjectives(level_id)
    }

    this.setState({
      form: {
        id: id || null,
        public: isPublic || false,
        enabled: !!enabled,
        getphy: getphy || false,
        name,
        program_objective_id,
        program_subobjective_id,
        level_id,
      },
      cycles: [...(cycles || [])],
    })
  }

  handleCheckboxGroupChange(event) {
    const target = event.target
    const checked = target.checked
    const name = target.name

    if (name.includes('[')) {
      let openBracketIndex = name.indexOf('[')
      let closeBracketIndex = name.indexOf(']')
      let bracketLength = closeBracketIndex - openBracketIndex - 1

      let beforeBracketValue = name.substr(0, openBracketIndex)
      let bracketValue = name.substr(openBracketIndex + 1, bracketLength)

      let currentStateArray = this.state.form[beforeBracketValue] || []

      let newArray = [...currentStateArray]

      if (checked) {
        if (newArray.indexOf(bracketValue) < 0) {
          newArray.push(bracketValue)
        }
      } else {
        let index = newArray.indexOf(bracketValue)
        newArray.splice(index, 1)
      }

      this.setState({
        form: {
          ...this.state.form,
          [beforeBracketValue]: newArray,
        },
      })
    }
  }

  handleInputChange(event) {
    const target = event.target

    let value = target.type === 'checkbox' ? target.checked : target.value
    if (value === 'true' || value === 'false') {
      value = value === 'true'
    }

    const name = target.name

    if (name.includes('.')) {
      //TODO: ALlows language to be specified in the input name.
      //Look at a more scalable way of doing this.
      let splitName = name.split('.')
      this.setState({
        form: {
          ...this.state.form,
          [splitName[0]]: {
            ...this.state.form[splitName[0]],
            [splitName[1]]: value,
          },
        },
      })
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value,
          },
        },
        () => {
          this.callBackAfterInputChange(name, value)
        }
      )
    }
  }

  handleInputTranslationChange = (event, values) => {
    const { target } = event
    const { name } = target

    if (name.includes('.')) {
      let splitName = name.split('.')
      this.setState({
        form: {
          ...this.state.form,
          [splitName[0]]: values,
        },
      })
    }
  }

  handleLevelChange(event) {
    const target = event.target
    const { name, value } = target

    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: value,
          program_objective_id: null,
          program_subobjective_id: undefined,
        },
        programObjectivesChildren: [],
      },
      () => {
        this.callBackAfterInputChange(name, value)
      }
    )
  }

  handleProgramObjectiveChange(event) {
    const target = event.target
    const { name, value } = target

    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: value,
          program_subobjective_id: null,
        },
      },
      () => {
        this.callBackAfterInputChange(name, value)
      }
    )
  }

  callBackAfterInputChange(name, value) {
    if (name === 'program_objective_id') {
      this.getProgramObjectivesChildren(value)
    }

    if (name === 'level_id') {
      this.getProgramObjectives(value)
    }
  }

  getProgramObjectives(levelId) {
    let programObjectives = []

    ;((this.props.resources || {}).levels || []).forEach((level) => {
      if (level.id === parseInt(levelId) && level.program_objectives.length > 0) {
        programObjectives = level.program_objectives
      }
    })

    this.setState({
      programObjectives,
    })
  }

  getProgramObjectivesChildren(programObjectiveId) {
    let programObjectivesChildren = []

    ;((this.props.resources || {}).levels || []).map((level) => {
      ;(level.program_objectives || []).forEach((program_objective) => {
        if (
          program_objective.id === parseInt(programObjectiveId) &&
          program_objective.children.length > 0
        ) {
          programObjectivesChildren = program_objective.children
        }
      })
    })

    this.setState({
      programObjectivesChildren,
    })
  }

  handleCyclesUpdate(cycles) {
    this.setState({
      cycles,
    })
  }

  handleSubmit() {
    if (!this.state.form) return
    if (this.state.form.program_subobjective_id) {
      this.setState(
        {
          form: {
            ...this.state.form,
            program_objective_id: this.state.form.program_subobjective_id,
          },
        },
        () => {
          this.props.handleSubmit(this.state.form, this.state.cycles)
        }
      )
    } else {
      this.props.handleSubmit(this.state.form, this.state.cycles)
    }
  }

  handleGetphyChange(ev) {
    let { checked } = ev.target

    this.setState({
      form: {
        ...this.state.form,
        getphy: checked,
        enabled: true,
      },
    })
  }

  handleEnabledChange(ev) {
    let { checked } = ev.target
    const { initialData, t } = this.props
    const { assessments, linked_users } = initialData || {}

    let errorMessage, openModal
    //If the program has been associated to some assessments or users
    //then recheck it (Need to dissociate the program from the assessments the manually).

    if (!checked) {
      if (initialData) {
        if ((assessments || []).length > 0 || (linked_users || []).length > 0) {
          if ((assessments || []).length > 0 && (!linked_users || linked_users.length === 0))
            errorMessage = t('FormProgramCreate.modal-disable-title.only-assessments', {
              count: this.props.initialData.assessments.length,
            })
          else if ((linked_users || []).length > 0 && (!assessments || assessments.length === 0))
            errorMessage = t('FormProgramCreate.modal-disable-title.only-users')
          else if (assessments.length > 0 && linked_users.length > 0)
            errorMessage = t('FormProgramCreate.modal-disable-title.users-assessments', {
              count: this.props.initialData.assessments.length,
            })
        } else {
          errorMessage = t('FormProgramCreate.modal-disable-title.confirmation')
        }
        checked = !checked
        openModal = true
      }
    }

    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        enabled: checked,
        getphy: checked === false ? false : this.state.form.getphy,
      },
      openDisableModal: openModal,
      enabledModalErrorMessage: errorMessage,
    })
  }

  handleBack() {
    window.history.go(-1)
  }

  handleCloseModal() {
    this.setState({
      ...this.state,
      openDisableModal: false,
    })
  }

  handleValidateDisable() {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        enabled: false,
        getphy: false,
      },
      openDisableModal: false,
    })
  }

  render() {
    const { initialData, t } = this.props
    const { assessments, linked_users } = initialData || {}

    return (
      <div className={`${baseClass}`}>
        <FormGeneric className="modal-create-program-form">
          <h1>{t('FormProgramCreate.title')}</h1>

          <div>
            <input
              type="checkbox"
              name="enabled"
              checked={this.state.form.enabled}
              onChange={this.handleEnabledChange}
            />

            <label>{t('FormProgramCreate.enabled')}</label>
          </div>
          <br />
          <div>
            <input
              type="checkbox"
              name="getphy"
              checked={this.state.form.getphy}
              onChange={this.handleGetphyChange}
            />

            <label>{t('FormProgramCreate.label-1')}</label>
          </div>

          <div className="row-container">
            <label>{t('FormProgramCreate.label-2')}</label>
            <div className="left-indent">
              <Input
                type="text"
                name="name"
                value={this.state.form.name || {}}
                onChange={this.handleInputTranslationChange}
                placeholder={t('FormProgramCreate.label-2')}
                autoComplete="false"
                required={true}
                maxLength={191}
                isTranslatable={true}
              />
            </div>
          </div>

          <div className="row-container">
            <div className="inline-block width-50">
              <label>{t('FormProgramCreate.label-3')}</label>
              <div className="left-indent">
                <select
                  name="level_id"
                  autoComplete="off"
                  onChange={this.handleLevelChange}
                  value={this.state.form.level_id || ''}
                  required={true}
                >
                  <option value="" disabled="disabled">
                    -------
                  </option>
                  {this.props.resources.levels.map((item, index) => {
                    return (
                      <option value={item.id} key={`levels_${index}`}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            {(this.state.programObjectives || []).length > 0 && (
              <div className="inline-block width-50">
                <label>{t('FormProgramCreate.label-4')}</label>
                <div className="left-indent">
                  <select
                    name="program_objective_id"
                    autoComplete="off"
                    onChange={this.handleProgramObjectiveChange}
                    required={true}
                  >
                    <option value="">-------</option>
                    {(this.state.programObjectives || []).map((item, index) => {
                      return (
                        <option
                          value={item.id}
                          key={`levels_${index}`}
                          selected={Number(this.state.form.program_objective_id) === item.id}
                        >
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                  {(this.state.programObjectivesChildren || []).length > 0 && (
                    <select
                      name="program_subobjective_id"
                      autoComplete="off"
                      onChange={this.handleInputChange}
                    >
                      <option value="">-------</option>
                      {(this.state.programObjectivesChildren || []).map((item, index) => {
                        return (
                          <option
                            value={item.id}
                            key={`levels_${index}`}
                            selected={Number(this.state.form.program_subobjective_id) === item.id}
                          >
                            {item.name}
                          </option>
                        )
                      })}
                    </select>
                  )}
                </div>
              </div>
            )}
          </div>

          <div>
            <label>{t('FormProgramCreate.label-7')}</label>

            <CyclesContainer
              handleCyclesUpdate={this.handleCyclesUpdate}
              initialData={this.state.cycles}
            />
          </div>

          <div className="row-container">
            <Button className="cancel-button" onClick={this.handleBack}>
              {t('GLOBAL.cancel')}
            </Button>

            <Button
              className="signup-button"
              onClick={this.handleSubmit}
              status={this.props.statusSubmit}
            >
              {t('FormProgramCreate.button')}
            </Button>

            <ErrorMessage status={this.props.statusSubmit} />
          </div>
        </FormGeneric>

        <ReactModal
          isOpen={this.state.openDisableModal}
          className="modal-content modal-language"
          overlayClassName="modal-overlay"
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseButton onClick={this.handleCloseModal} />
          {this.props.initialData && (
            <div id="form-assessments" className="form transparent">
              <h2>{this.state.enabledModalErrorMessage}</h2>

              {(assessments || []).length > 0 && (
                <ul>
                  {assessments.map((value, index) => {
                    return <li key={index}>{value.name}</li>
                  })}
                </ul>
              )}

              {(!assessments || (assessments || []).length === 0) &&
                (!linked_users || (linked_users || []).length === 0) && (
                  <div className="modal-row-container">
                    <Button
                      className="modal-validate-button"
                      name="button"
                      onClick={this.handleValidateDisable}
                    >
                      {t('GLOBAL.submit')}
                    </Button>

                    <Button
                      className="modal-cancel-button"
                      name="button"
                      onClick={this.handleCloseModal}
                    >
                      {t('GLOBAL.cancel')}
                    </Button>
                  </div>
                )}
            </div>
          )}
        </ReactModal>
      </div>
    )
  }
}

FormProgramCreate.propTypes = {
  resources: PropTypes.object,
  statusSubmit: PropTypes.object,
}

FormProgramCreate.defaultProps = {
  resources: {
    levels: [],
  },
}

export default withTranslation()(FormProgramCreate)
