import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { connect } from 'react-redux'

import Logo from './components/Logo'
import Hamburger from './components/Hamburger'
import HeaderLink from './components/HeaderLink'
import AvatarButton from './components/AvatarButton'

import ModalLanguage from 'containers/modals/ModalLanguage'

import { SET_ACTIVE_MODAL } from 'store/modules/Modal/actions.js'
import { SET_LOGGEDOUT_STATE } from 'store/modules/Account/actions.js'

import './style.css'

class HeaderContainer extends Component {
  constructor() {
    super()

    this.state = {
      navOptions: [],
      openModalLanguage: false,
    }

    this.handleConnectClick = this.handleConnectClick.bind(this)
    this.handleMessagesClick = this.handleMessagesClick.bind(this)
    this.handleMyCoachClick = this.handleMyCoachClick.bind(this)
    this.handleLogoutClick = this.handleLogoutClick.bind(this)

    this.getDropdownButtons = this.getDropdownButtons.bind(this)

    this.handleLanguageClick = this.handleLanguageClick.bind(this)
    this.handleCloseLanguage = this.handleCloseLanguage.bind(this)
  }

  getDropdownButtons() {
    const { t } = this.props

    return [
      { onClick: this.handleLanguageClick, text: t('HeaderContainer.subtitle-1') },
      { onClick: this.handleLogoutClick, text: t('HeaderContainer.subtitle-2') },
    ]
  }

  handleConnectClick() {
    this.props.dispatch({ type: SET_ACTIVE_MODAL, payload: 'login' })
  }

  handleLanguageClick() {
    this.setState({
      openModalLanguage: true,
    })
  }

  handleCloseLanguage() {
    this.setState({
      openModalLanguage: false,
    })
  }

  handleMessagesClick() {
    console.log('handleMessagesClick')
  }

  handleMyCoachClick() {
    console.log('handleMyCoachClick')
  }

  handleLogoutClick() {
    this.props.dispatch({ type: SET_LOGGEDOUT_STATE })
    this.props.history.push(`/`)
    this.props.dispatch({ type: SET_ACTIVE_MODAL, payload: 'login' })
  }

  getLinks() {
    switch (this.props.role) {
      case 'admin_referensiel': {
        return this.adminOptions()
      }

      case 'manager':
      case 'coach': {
        return this.managerCoachOptions()
      }

      default: {
        return this.userOptions()
      }
    }
  }

  userOptions() {
    return []
  }

  managerCoachOptions() {
    const { t } = this.props

    return [
      {
        text: t('HeaderContainer.options.subtitle-1'),
        icon: '',
        link: '/exercises',
        dropdown: [],
      },
      {
        text: t('HeaderContainer.options.subtitle-2'),
        icon: '',
        link: '/challenges',
        dropdown: [],
      },
      {
        text: t('HeaderContainer.options.subtitle-3'),
        icon: '',
        link: '/sessions',
        dropdown: [],
      },
      {
        text: t('HeaderContainer.options.subtitle-4'),
        icon: '',
        link: '/programs',
        dropdown: [],
      },
      {
        text: t('HeaderContainer.options.subtitle-7'),
        icon: '',
        link: '/sac',
        dropdown: [],
      },
    ]
  }

  adminOptions() {
    const { t } = this.props

    return [
      {
        text: t('HeaderContainer.options.subtitle-1'),
        icon: '',
        link: '/exercises',
        dropdown: [],
      },
      {
        text: t('HeaderContainer.options.subtitle-2'),
        icon: '',
        link: '/challenges',
        dropdown: [],
      },
      {
        text: t('HeaderContainer.options.subtitle-3'),
        icon: '',
        link: '/sessions',
        dropdown: [],
      },
      {
        text: t('HeaderContainer.options.subtitle-4'),
        icon: '',
        link: '/programs',
        dropdown: [],
      },
      {
        text: t('HeaderContainer.options.subtitle-5'),
        icon: '',
        link: '/tests',
        dropdown: [],
      },
      {
        text: t('HeaderContainer.options.subtitle-10'),
        icon: '',
        link: '/assessments',
        dropdown: [],
      },
      {
        text: t('HeaderContainer.options.subtitle-7'),
        icon: '',
        link: '/sac',
        dropdown: [],
      },
      {
        text: t('HeaderContainer.options.subtitle-8'),
        icon: '',
        link: null,
        dropdown: [
          { text: t('HeaderContainer.options.adminOptions.subtitle-2'), to: '/exercises/create' },
          { text: t('HeaderContainer.options.adminOptions.subtitle-3'), to: '/challenges/create' },
          { text: t('HeaderContainer.options.adminOptions.subtitle-5'), to: '/exercises/requests' },
          { text: t('HeaderContainer.options.adminOptions.subtitle-6'), to: '/sessions/requests' },
          { text: t('HeaderContainer.options.adminOptions.subtitle-7'), to: '/equipment' },
          { text: t('HeaderContainer.options.adminOptions.subtitle-8'), to: '/body-parts' },
        ],
      },
    ]
  }

  links() {
    const { t } = this.props

    return (
      <ul>
        {this.getLinks().map((item) => {
          return (
            <HeaderLink
              text={item.text}
              icon={item.icon}
              to={item.link}
              dropdown={item.dropdown}
              key={item.link}
            />
          )
        })}

        {!this.props.isLoggedIn && (
          <HeaderLink
            type="connectButton"
            text={t('HeaderContainer.subtitle-3')}
            onClick={this.handleConnectClick}
          />
        )}

        {this.props.isLoggedIn && (
          <AvatarButton
            dropdownButtons={this.getDropdownButtons()}
            avatarUrl={this.props.userData ? this.props.userData.photo : null}
          />
        )}
      </ul>
    )
  }

  render() {
    return (
      <div className="header-container">
        <div className="header-container-inner">
          <Logo linkRoute={this.props.homeRoute || '/'} />

          <Hamburger>{this.links()}</Hamburger>

          <div className="desktop-header">{this.links()}</div>

          {this.state.openModalLanguage && (
            <ModalLanguage handleCloseModal={this.handleCloseLanguage} />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.Account.isLoggedIn,
  roles: state.Account.user.roles,
  role: state.Account.role,
  userData: state.Account.user,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderContainer))
)
