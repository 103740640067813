import ReactGA from 'react-ga'
import Cookies from 'js-cookie'

export const GOOGLE_TRACKING_ID = 'UA-159253739-2'

function shouldInit() {
  const cookieConsentPolicy = Cookies.get('ReferensielCookieConsentPolicy')
  const cookieConsentPolicyLegacy = Cookies.get('ReferensielCookieConsentPolicy-legacy')
  return (
    process.env.NODE_ENV !== 'production' ||
    !(cookieConsentPolicy === 'true' || cookieConsentPolicyLegacy === 'true')
  )
}

export function InitGoogle(location) {
  if (shouldInit()) return
  ReactGA.initialize(GOOGLE_TRACKING_ID)
  if (location) {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
  }
}

export function PageView(pathname) {
  if (shouldInit()) return
  ReactGA.set({ page: pathname })
  ReactGA.pageview(pathname)
}
