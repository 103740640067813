import React, { Component } from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import getTranslations from 'helpers/get-translations'

import FormModal from 'components/FormModal'
import Input from 'containers/Input'
import Button from 'components/Button'
import CloseButton from 'components/CloseButton'
import AdminTable from 'components/AdminTable'
import Icon from 'components/Icon'
import isEqual from 'lodash/isEqual'

import './style.css'

const baseClass = 'form-challenge-create-exercises-question-selector'

class QuestionSelector extends Component {
  constructor() {
    super()

    this.state = {
      form: {
        question: {},
        nb_to_reach: null,
        points: null,
      },
      test_answers: [],
    }
  }

  componentDidMount() {
    this.setInitialData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.exercise && !isEqual(prevProps.exercise, this.props.exercise)) {
      this.setInitialData()
    }
  }

  setInitialData = () => {
    const translations =
      Object.keys(this.props.exercise.question || []).length > 1
        ? this.props.exercise.question
        : getTranslations(this.props.exercise.translations, 'question')
    this.setState({
      test_answers: this.props.exercise.test_answers || [],
      form: {
        question: translations,
      },
    })
  }

  handleInputChange = (event) => {
    const target = event.target

    let value = target.type === 'checkbox' ? target.checked : target.value
    if (value === 'true' || value === 'false') {
      value = value === 'true'
    }

    const name = target.name

    if (name.includes('.')) {
      //TODO: ALlows language to be specified in the input name.
      //Look at a more scalable way of doing this.
      let splitName = name.split('.')
      this.setState({
        form: {
          ...this.state.form,
          [splitName[0]]: {
            ...this.state.form[splitName[0]],
            [splitName[1]]: value,
          },
        },
      })
    } else {
      this.setState({
        form: {
          ...this.state.form,
          [name]: value,
        },
      })
    }
  }

  handleInputTranslationChange = (event, values) => {
    const { target } = event
    const { name } = target

    if (name.includes('.')) {
      let splitName = name.split('.')
      this.setState({
        form: {
          ...this.state.form,
          [splitName[0]]: values,
        },
      })
    }
  }

  onAddAnswerClick = () => {
    if (this.state.form.nb_to_reach === null) return
    if (this.state.form.points === null) return

    const { test_answers } = this.state

    test_answers.push({ nb_to_reach: this.state.form.nb_to_reach, points: this.state.form.points })

    this.setState({
      ...this.state,
      test_answers,
      form: {
        ...this.state.form,
        nb_to_reach: '',
        points: '',
      },
    })
  }

  onEditAnswerClick = (event, index) => {
    const { target } = event
    const { name, value } = target

    const { test_answers } = this.state

    test_answers[index][name] = value

    this.setState({
      ...this.state,
      test_answers,
    })
  }

  onRemoveAnswerClick = (index) => {
    const { test_answers } = this.state

    test_answers.splice(index, 1)

    this.setState({
      test_answers,
    })
  }

  onSubmit = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
    this.props.onSubmit(this.state.form.question, this.state.test_answers)
  }

  renderTable = () => {
    const { t } = this.props

    const headers = {
      columns: [
        { value: t('FormTestCreate.Exercises.QuestionSelector.subtitle-1') },
        { value: t('FormTestCreate.Exercises.QuestionSelector.subtitle-2') },
        { value: ' ' },
      ],
    }

    let tableRows = []

    ;(this.state.test_answers || []).map((item, index) => {
      let columns = [
        {
          value: (
            <input
              type="text"
              name="nb_to_reach"
              value={item.nb_to_reach}
              onChange={(ev) => this.onEditAnswerClick(ev, index)}
            />
          ),
        },
        {
          value: (
            <input
              type="text"
              name="points"
              value={item.points}
              onChange={(ev) => this.onEditAnswerClick(ev, index)}
            />
          ),
        },
        {
          value: (
            <Button
              type="button"
              onClick={() => this.onRemoveAnswerClick(index)}
              className={`narrow`}
            >
              <Icon src="trash.svg" className="trash-icon" />
            </Button>
          ),
        },
      ]

      tableRows.push({ columns })

      return null
    })

    return <AdminTable headerRow={headers} rows={tableRows} />
  }

  isDisabled = () => {
    if ((this.state.test_answers || []).length) {
      if (!this.state.form.nb_to_reach || !this.state.form.min_points) {
        return false
      }
      const question = (this.state.form.question || {}).fr || ''
      if (question.length) {
        return false
      }
    }

    return true
  }

  isAddBtnDisabled = () => {
    if (!this.state.form.nb_to_reach || !this.state.form.points) {
      return true
    }
    return false
  }

  render() {
    const { t, handleClose } = this.props
    const { form } = this.state

    return (
      <ReactModal
        className={`${baseClass} modal-content`}
        isOpen={true}
        onRequestClose={handleClose}
        shouldCloseOnOverlayClick={true}
        overlayClassName="modal-overlay"
      >
        <FormModal id="profile-form" className="profile-form posture-form" onSubmit={this.onSubmit}>
          <CloseButton onClick={handleClose} />

          <div className="row-container">
            <label>{t('FormTestCreate.Exercises.QuestionSelector.label-1')}</label>
            <Input
              type="text"
              name="question"
              value={form.question || {}}
              onChange={this.handleInputTranslationChange}
              placeholder="Titre"
              autoComplete="false"
              required={true}
              maxLength={191}
              isTranslatable={true}
            />
          </div>

          <div>{this.renderTable()}</div>

          <div className="row-container">
            <fieldset className="inline-block width-20">
              <label>{t('FormTestCreate.Exercises.QuestionSelector.label-2')}</label>
              <input
                type="number"
                name="nb_to_reach"
                value={form.nb_to_reach || ''}
                onChange={this.handleInputChange}
                placeholder="Answer"
                autoComplete="false"
              />
            </fieldset>

            <fieldset className="inline-block width-20">
              <label>{t('FormTestCreate.Exercises.QuestionSelector.label-3')}</label>
              <input
                type="number"
                name="points"
                value={form.points || ''}
                onChange={this.handleInputChange}
                placeholder="Points"
                autoComplete="false"
              />
            </fieldset>

            <fieldset className="inline-block width-20">
              <Button
                type="button"
                disabled={this.isAddBtnDisabled()}
                onClick={this.onAddAnswerClick}
              >
                {' '}
                {t('FormTestCreate.Exercises.QuestionSelector.button')}{' '}
              </Button>
            </fieldset>
          </div>

          <p>{t('FormTestCreate.Exercises.QuestionSelector.text')}</p>

          <Button type="submit" disabled={this.isDisabled()} onClick={() => {}}>
            {' '}
            {t('GLOBAL.save')}{' '}
          </Button>
        </FormModal>
      </ReactModal>
    )
  }
}

QuestionSelector.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.isRequired,
}

QuestionSelector.defaultProps = {}

export default withTranslation()(QuestionSelector)
