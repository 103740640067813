import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import Accordion from 'components/Accordion/index'
import ObjectivesSelector from './components/ObjectivesSelector/index'

import './style.css'

const baseClass = 'ModalCreateAssessment-objectives'

class Objectives extends Component {
  constructor() {
    super()

    this.state = {}
  }

  getProgramObjectivesSelected(level) {
    const { formLevelObjectives } = this.props

    return formLevelObjectives.find((formLevel) => formLevel.level_id === level.id)
  }

  getFlatProgramObjectives(level) {
    let flatProgramObjectives = []
    level.program_objectives.map((objective) => {
      flatProgramObjectives.push(objective)
      if (objective.children.length > 0) {
        objective.children.map((child) => {
          flatProgramObjectives.push(child)
        })
      }
    })
    return flatProgramObjectives
  }

  getPrescriberLevels() {
    return this.props.levelObjectives.map((level, index) => {
      return {
        title: level.name,
        content: (
          <ObjectivesSelector
            key={`level_objective_${index}`}
            level={level}
            flatProgramObjectives={this.getFlatProgramObjectives(level)}
            programObjectivesSelected={this.getProgramObjectivesSelected(level)}
            handleAddObjectiveIntoAssessments={(objective_id) =>
              this.props.handleAddObjectiveIntoAssessments(level, objective_id)
            }
            handleRemoveObjectiveFromAssessments={(item) =>
              this.props.handleRemoveObjectiveFromAssessments(item, level)
            }
          />
        ),
      }
    })
  }

  render() {
    const { t } = this.props

    return (
      <div className={`${baseClass}`}>
        {this.props.prescriberSelected && !!(this.props.prescriberSelected || {}).is_specific && (
          <div className={`error-message`}>
            {t(
              'PageAssessments.ModalCreateAssessment.Objectives.not_allow_to_define_objectives_specific'
            )}
          </div>
        )}

        {!(this.props.prescriberSelected || {}).is_specific && (
          <Accordion sections={this.getPrescriberLevels()} />
        )}
      </div>
    )
  }
}

Objectives.defaultProps = {
  prescriberSelected: null,
  formLevelObjectives: [],
  levelObjectives: [],
  handleAddObjectiveIntoAssessments: () => {},
  handleRemoveObjectiveFromAssessments: () => {},
}

export default withTranslation()(Objectives)
