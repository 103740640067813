import React, { Component } from 'react'
import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next'
import { getQueryParam } from 'services/location'

import FormModal from 'components/FormModal'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'

import { submitNewPassword } from 'store/modules/Account/actions.js'
import { clearStatus } from 'store/modules/Status/actions.js'
import { SET_ACTIVE_MODAL } from 'store/modules/Modal/actions.js'

import './style.css'

const statusRef = 'PagePasswordReset'
const baseClass = 'page-password-reset'

class PagePasswordReset extends Component {
  constructor() {
    super()

    this.state = {
      password: '',
      password_confirm: '',
      validationError: null,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(clearStatus({ statusRef }))
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.statuses[statusRef] &&
      this.props.statuses[statusRef].state === 'finished' &&
      (prevProps.statuses[statusRef] || {}).state !== 'finished'
    ) {
      this.props.dispatch({ type: SET_ACTIVE_MODAL, payload: 'login' })
    }
  }

  handleChange(e) {
    const { name, value } = e.target

    this.setState({
      [name]: value,
    })
  }

  handleSubmit() {
    const { t } = this.props

    let validationError

    if (this.state.password !== this.state.password_confirm) {
      validationError = t('PagePasswordReset.error')
    } else if (this.state.password.length < 6) {
      validationError = t('PagePasswordReset.error-password-too-short')
    } else {
      validationError = null
    }

    this.setState(
      {
        validationError,
      },
      () => {
        if (!validationError) {
          this.props.dispatch(
            submitNewPassword({
              email: getQueryParam('email'),
              password: this.state.password,
              statusRef: statusRef,
              resetToken: getQueryParam('token'),
            })
          )
        }
      }
    )
  }

  render() {
    const { t } = this.props

    return (
      <div className={`${baseClass}`}>
        <FormModal className={`${baseClass}-form transparent`} onSubmit={this.handleSubmit}>
          <h1 className={`${baseClass}-form-title`}>{t('PagePasswordReset.title')}</h1>

          <div className={`${baseClass}-form-explanations`}>
            {t('PagePasswordReset.explanations')}
          </div>

          <div className="row-container">
            <input
              type="password"
              name="password"
              value={this.state.password}
              placeholder={t('PagePasswordReset.placeholders.password')}
              autoComplete="false"
              required={true}
              onChange={this.handleChange}
              onBlur={this.handleChange}
            />
          </div>

          <div className="row-container">
            <input
              type="password"
              name="password_confirm"
              value={this.state.password_confirm}
              placeholder={t('PagePasswordReset.placeholders.passwordConfirmation')}
              autoComplete="false"
              required={true}
              onChange={this.handleChange}
              onBlur={this.handleChange}
            />
          </div>

          {this.state.validationError && (
            <div className="validation-error alert danger">{this.state.validationError}</div>
          )}

          {this.props.statuses[statusRef] &&
            this.props.statuses[statusRef].state === 'error' &&
            this.props.statuses[statusRef].data && (
              <div className="danger alert">{this.props.statuses[statusRef].data}</div>
            )}

          {this.props.statuses[statusRef] &&
            this.props.statuses[statusRef].state === 'finished' &&
            this.props.statuses[statusRef].data && (
              <div className="success alert">{this.props.statuses[statusRef].data}</div>
            )}

          <ErrorMessage status={this.props.statuses[statusRef]} />

          <div className="row-container actions-container">
            <Button
              type="submit"
              name="button"
              onClick={() => {}}
              status={this.props.statuses[statusRef]}
            >
              {t('GLOBAL.submit')}
            </Button>
          </div>
        </FormModal>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PagePasswordReset))
