import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import Button from 'components/Button'
import './style.css'

class ConfidentialInfo extends Component {
  render() {
    const { t } = this.props

    return (
      <div className="profile-modal-personal-info form-profile">
        <div className="data-row">
          <label>{t('ModalProfile.ConfidentialInfo.label-1')}</label>
        </div>

        <div className="data-row data-row-half">
          <label>{t('ModalProfile.ConfidentialInfo.label-2')}</label>
          <input
            autoComplete="new-password"
            name="password"
            type="password"
            value={this.props.userData.password || ''}
            onChange={this.props.inputChange}
          />
        </div>

        <div className="data-row data-row-half">
          <label>{t('ModalProfile.ConfidentialInfo.label-3')}</label>
          <input
            autoComplete="new-password"
            name="password_confirmation"
            type="password"
            value={this.props.userData.password_confirmation || ''}
            onChange={this.props.inputChange}
          />
        </div>

        <div className="data-row data-row-half radio-container">
          <div className="data-row">
            <label>{t('ModalProfile.ConfidentialInfo.label-4')}</label>
          </div>

          <div className="data-row">
            <div className="data-row">
              <input
                name="privacy"
                type="radio"
                value={this.props.userData.privacy || ''}
                onChange={this.props.inputChange}
              />
              <label>{t('ModalProfile.ConfidentialInfo.label-5')}</label>
            </div>

            <div className="data-row">
              <input
                name="privacy"
                type="radio"
                value={this.props.userData.privacy || ''}
                onChange={this.props.inputChange}
              />
              <label>{t('ModalProfile.ConfidentialInfo.label-6')}</label>
            </div>

            <div className="data-row">
              <input
                checked="checked"
                name="privacy"
                type="radio"
                value={this.props.userData.privacy || ''}
                onChange={this.props.inputChange}
              />
              <label>{t('ModalProfile.ConfidentialInfo.label-7')}</label>
            </div>
          </div>
        </div>

        <div className="data-row data-row-half radio-container">
          <div className="data-row">
            <label>{t('ModalProfile.ConfidentialInfo.label-8')}</label>
          </div>

          <div className="data-row data-row-half radio-container">
            <div className="data-row">
              <input
                name="confidential"
                type="radio"
                value={this.props.userData.confidential || ''}
                onChange={this.props.inputChange}
              />
              <label>{t('ModalProfile.ConfidentialInfo.label-9')}</label>
            </div>

            <div className="data-row">
              <input
                checked="checked"
                name="confidential"
                type="radio"
                value={this.props.userData.confidential || ''}
                onChange={this.props.inputChange}
              />
              <label>{t('ModalProfile.ConfidentialInfo.label-10')}</label>
            </div>
          </div>
        </div>

        <div>
          <Button onClick={this.props.cancelClick}>{t('GLOBAL.cancel')}</Button>

          <Button onClick={this.props.submitClick}>{t('GLOBAL.submit')}</Button>
        </div>
      </div>
    )
  }
}

export default withTranslation()(ConfidentialInfo)
