import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import {
  exerciseSearchCreator,
  updateExerciseSearchFilters,
} from 'store/modules/Exercise/actions.js'
import ExerciseCard from 'components/ExerciseCard'
import Pagination from 'components/Pagination'
import SearchFilterBar from 'components/SearchFilterBar'

import { FETCH_EXERCISES_RESOURCES_REQUEST } from 'store/modules/Resource/actions.js'

import './style.css'

const statusRefSearch = 'PageExercisesRequests_search'

class PageExercisesRequests extends Component {
  constructor() {
    super()
    this.state = {
      searchData: {},
    }

    this.fetchExercises = this.fetchExercises.bind(this)
    this.searchFilteroptions = this.searchFilteroptions.bind(this)
    this.updateStoredFilters = this.updateStoredFilters.bind(this)
  }

  componentDidMount() {
    const { searchFilters } = this.props.theState.Exercise

    this.props.dispatch(
      exerciseSearchCreator({
        ...searchFilters,
        ...searchFilters.multiSelect,
        search: searchFilters.search_text,
        statusRef: statusRefSearch,
        pending: true,
      })
    )
    this.props.dispatch({ type: FETCH_EXERCISES_RESOURCES_REQUEST })
  }

  fetchExercises(data) {
    if (!data) return

    this.setState(
      {
        searchData: data,
      },
      () => {
        this.props.dispatch(
          exerciseSearchCreator({
            ...data,
            ...data.multiSelect,
            search: data.search_text,
            pending: true,
            statusRef: statusRefSearch,
          })
        )
      }
    )
  }

  searchFilteroptions() {
    const { t } = this.props

    const resources = this.props.resources
    let toReturn = []

    if (resources.equipment) {
      toReturn.push({
        label: t('PageExercises.equipment'),
        name: 'equipment',
        value: 'equipment',
        options: resources.equipment.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.muscles) {
      toReturn.push({
        label: t('PageExercises.muscles'),
        name: 'muscles',
        value: 'muscles',
        options: resources.muscles.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.objectives) {
      toReturn.push({
        label: t('PageExercises.objectives'),
        name: 'objectives',
        value: 'objectives',
        options: resources.objectives.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.positions) {
      toReturn.push({
        label: t('PageExercises.positions'),
        name: 'positions',
        value: 'positions',
        options: resources.positions.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    return toReturn
  }

  updateStoredFilters(filters) {
    this.props.dispatch(updateExerciseSearchFilters({ ...filters }))
  }

  render() {
    const { t } = this.props

    return (
      <div className="page-exercises-requests">
        <div className="page-exercises-requests-title">{t('PageExercisesRequests.title')}</div>

        <SearchFilterBar
          filterTitle={''}
          filterOptions={this.searchFilteroptions()}
          handleSearchFormSubmit={this.fetchExercises}
          onfilterChange={this.fetchExercises}
          filterType={'multi-select'}
          showLoadingIndicator={(this.props.statuses[statusRefSearch] || {}).state === 'busy'}
          storedFilters={this.props.theState.Exercise.searchFilters}
          updateStoredFilters={this.updateStoredFilters}
        />

        {this.props.searchResults.exercises &&
          this.props.searchResults.exercises.map((item, index) => {
            return (
              <Link to={`/exercise/${item.id}`} key={`exercises_results_${index}`}>
                <ExerciseCard item={item} />
              </Link>
            )
          })}

        <Pagination
          searchData={this.state.searchData}
          limit={this.props.searchResults.limit}
          offset={this.props.searchResults.offset}
          total={this.props.searchResults.total_count}
          fetchData={this.fetchExercises}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  activeModal: state.Modal.activeModal,
  searchResults: state.Exercise.requestsSearchResults,
  resources: state.Resource.exercises,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(PageExercisesRequests)
)
