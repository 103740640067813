import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import isEqual from 'lodash/isEqual'
import Button from 'components/Button'
import MyExercises from './components/MyExercises'
import MySessions from './components/MySessions'
import MyPrograms from './components/MyPrograms'

import { getTopLevelFolders, getFolder, createTabFolder } from 'store/modules/Folder/actions.js'

import './style.css'
import {
  FETCH_EXERCISES_RESOURCES_REQUEST,
  FETCH_SESSIONS_RESOURCES_REQUEST,
} from '../../store/modules/Resource/actions'

const statusRefGetFolders1 = 'PageBag-getFolders1'
const statusRefGetFolders2 = 'PageBag-getFolders2'
const statusRefGetFolders3 = 'PageBag-getFolders3'

const statusRefFetching = 'PageBag-fetching'

const EXERCISES_FOLDER = 'exercises'
const SESSIONS_FOLDER = 'sessions'
const PROGRAMS_FOLDER = 'programs'

class PageBag extends Component {
  constructor() {
    super()
    this.state = {}
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    dispatch(
      getTopLevelFolders({
        type: 1,
        statusRef: statusRefGetFolders1,
      })
    )
    dispatch(
      getTopLevelFolders({
        type: 2,
        statusRef: statusRefGetFolders2,
      })
    )
    dispatch(
      getTopLevelFolders({
        type: 3,
        statusRef: statusRefGetFolders3,
      })
    )
    if (match.params.folderType) {
      this.getFolderDetails()
    }
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props
    if (
      match.params.folderType !== prevProps.match.params.folderType ||
      !isEqual(prevProps.topLevelFolders, this.props.topLevelFolders)
    ) {
      this.getFolderDetails()
    }
  }

  getFolderDetails = () => {
    const { match, dispatch, topLevelFolders } = this.props
    const { params } = match
    if (params.folderType) {
      switch (params.folderType) {
        case EXERCISES_FOLDER:
          dispatch({ type: FETCH_EXERCISES_RESOURCES_REQUEST })
          topLevelFolders[EXERCISES_FOLDER].map(({ id: folder_id }) => {
            dispatch(getFolder({ folder_id, statusRef: statusRefFetching }))
          })
          break

        case SESSIONS_FOLDER:
          dispatch({ type: FETCH_SESSIONS_RESOURCES_REQUEST })
          topLevelFolders[SESSIONS_FOLDER].map(({ id: folder_id }) => {
            dispatch(getFolder({ folder_id, statusRef: statusRefFetching }))
          })
          break

        case PROGRAMS_FOLDER:
          topLevelFolders[PROGRAMS_FOLDER].map(({ id: folder_id }) => {
            dispatch(getFolder({ folder_id, statusRef: statusRefFetching }))
          })
          break

        default:
          break
      }
    }
  }

  handleInitiateFoldersClick = () => {
    this.props.dispatch(createTabFolder({ name: PROGRAMS_FOLDER, type: 1, visible_getphy: true }))
    this.props.dispatch(createTabFolder({ name: SESSIONS_FOLDER, type: 2, visible_getphy: true }))
    this.props.dispatch(createTabFolder({ name: EXERCISES_FOLDER, type: 3, visible_getphy: true }))
  }

  handleExercisesClick = () => {
    this.props.history.push('/sac/exercises')
  }

  handleSessionsClick = () => {
    this.props.history.push('/sac/sessions')
  }

  handleProgramsClick = () => {
    this.props.history.push('/sac/programs')
  }

  setExercisesResources = (exercises) => {
    const { objectives, positions, counts } = this.props.exerciseResources
    if (!objectives.length || !positions.length || !counts.length) {
      return []
    }
    exercises = Object.values(exercises).map((exercise) => exercise)
    return exercises.map(({ objective_id, position_id, count_id, ...rest }) => {
      const objective = objectives.find((obj) => obj.id === objective_id) || {}
      const position = positions.find((position) => position.id === position_id) || {}
      const count = counts.find((count) => count.id === count_id) || {}

      return { ...rest, objective, position, count }
    })
  }

  setSessionsResources = (sessions) => {
    const { objectives, levels, target_audiences } = this.props.sessionResources
    if (!objectives.length || !levels.length || !target_audiences.length) {
      return []
    }
    sessions = Object.values(sessions).map((session) => session)
    return sessions.map(({ objective_id, level_id, target_audience_id, ...rest }) => {
      const objective = objectives.find((obj) => obj.id === objective_id)
      const level = levels.find((level) => level.id === level_id)
      const target_audience = target_audiences.find((target) => target.id === target_audience_id)
      return { ...rest, objective, level, target_audience }
    })
  }

  isExercisesFolder = () => {
    return this.props.match.params.folderType === EXERCISES_FOLDER
  }

  isSessionsFolder = () => {
    return this.props.match.params.folderType === SESSIONS_FOLDER
  }

  isProgramsFolder = () => {
    return this.props.match.params.folderType === PROGRAMS_FOLDER
  }

  render() {
    const { t, topLevelFolders, folders, statuses } = this.props

    return (
      <div className="page-bag">
        {t('PageBag.text')}

        {(!topLevelFolders[EXERCISES_FOLDER].length ||
          !topLevelFolders[SESSIONS_FOLDER].length ||
          !topLevelFolders[PROGRAMS_FOLDER].length) && (
          <div>
            <Button onClick={this.handleInitiateFoldersClick}>
              {t('PageBag.button-initiate-folders')}
            </Button>
          </div>
        )}

        <div>
          {topLevelFolders[EXERCISES_FOLDER].length && (
            <Button onClick={this.handleExercisesClick}>{t('PageBag.exercises')}</Button>
          )}

          {topLevelFolders[SESSIONS_FOLDER].length && (
            <Button onClick={this.handleSessionsClick}>{t('PageBag.sessions')}</Button>
          )}

          {topLevelFolders[PROGRAMS_FOLDER].length && (
            <Button onClick={this.handleProgramsClick}>{t('PageBag.programs')}</Button>
          )}
        </div>

        {this.isExercisesFolder() && (
          <MyExercises
            exercises={this.setExercisesResources(folders.exercises)}
            status={statuses[statusRefFetching]}
          />
        )}

        {this.isSessionsFolder() && (
          <MySessions
            sessions={this.setSessionsResources(folders.sessions)}
            status={statuses[statusRefFetching]}
          />
        )}

        {this.isProgramsFolder() && (
          <MyPrograms programs={folders.programs} status={statuses[statusRefFetching]} />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  topLevelFolders: state.Folder.topLevelFolders,
  folders: state.Folder.folders,
  statuses: state.Status.statuses,
  exerciseResources: state.Resource.exercises,
  sessionResources: state.Resource.sessions,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PageBag))
