import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import FormProgramCreate from 'components/FormProgramCreate'

import { PROGRAM_FETCH_SUBMIT, updateProgram } from 'store/modules/Program/actions.js'
import { FETCH_PROGRAMS_RESOURCES_REQUEST } from 'store/modules/Resource/actions.js'
import { clearStatus } from 'store/modules/Status/actions.js'

import './style.css'

const statusRef = 'PageProgramsCreate'

class PageProgramEdit extends Component {
  constructor() {
    super()
    this.state = {
      isError: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(clearStatus({ statusRef }))
    const {
      programs,
      match: {
        params: { programId },
      },
    } = this.props
    this.setState(
      {
        programId,
      },
      () => {
        if (!programs[programId]) {
          this.props.dispatch({ type: PROGRAM_FETCH_SUBMIT, payload: programId })
        }
      }
    )

    this.props.dispatch({ type: FETCH_PROGRAMS_RESOURCES_REQUEST })
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.statuses[statusRef]?.state && this.props?.statuses[statusRef]?.state) {
      const { state } = this.props.statuses[statusRef]
      const { state: prevState } = prevProps.statuses[statusRef]
      if (prevState !== 'finished' && state === 'finished') {
        this.setState({ isError: false })
        setTimeout(() => {
          this.props.history.push(`/program/${this.state.programId}`)
        }, 700)
      }
      if (prevState !== 'error' && state === 'error') {
        this.setState({ isError: true })
      }
    }
  }

  handleSubmit(form, cycles) {
    this.setState({ isError: false })
    this.props.dispatch(
      updateProgram({
        ...form,
        programId: this.state.programId,
        cycles: cycles,
        statusRef,
      })
    )
  }

  render() {
    return (
      <div className="page-programs-create">
        <FormProgramCreate
          resources={this.props.resources}
          statusSubmit={this.props.statuses[statusRef]}
          handleSubmit={this.handleSubmit}
          initialData={cloneDeep(this.props.programs[this.state.programId])}
          isError={this.state.isError}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  resources: state.Resource.programs,
  statuses: state.Status.statuses,
  programs: state.Program.programs || {},
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageProgramEdit))
