import React, { Component } from 'react'
import './style.css'

class CloseButton extends Component {
  render() {
    return (
      <div className="close-button" onClick={this.props.onClick}>
        X
      </div>
    )
  }
}

export default CloseButton
