import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import getTranslations from 'helpers/get-translations'

import FormGeneric from 'components/FormGeneric'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'

import Input from 'containers/Input'

import './style.css'

const baseClass = 'new-body-parts-form'

const statusRef = 'PageBodyPartsNew'

class PageBodyPartsNew extends Component {
  constructor() {
    super()

    this.state = {
      form: {
        name: {},
        is_muscle: false,
      },
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.setInitialData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.statuses[statusRef]) {
      if (
        this.props.statuses[statusRef].state === 'finished' &&
        prevProps.statuses[statusRef].state !== 'finished'
      ) {
        this.props.onFormSubmitSuccess()
      }
    }

    if (prevProps.initialData !== this.props.initialData) {
      this.setInitialData()
    }
  }

  setInitialData() {
    if (!this.props.initialData) return

    let id = this.props.initialData.id || null
    let name = getTranslations(this.props.initialData.translations, 'name')
    let is_muscle = this.props.initialData.is_muscle || false

    this.setState({
      form: {
        name,
        id,
        is_muscle,
      },
    })
  }

  handleInputChange(e) {
    const target = e.target

    let value = target.type === 'checkbox' ? target.checked : target.value
    if (value === 'true' || value === 'false') {
      value = value === 'true'
    }

    const name = target.name

    if (name.includes('.')) {
      let splitName = name.split('.')
      this.setState({
        form: {
          ...this.state.form,
          [splitName[0]]: {
            ...this.state.form[splitName[0]],
            [splitName[1]]: value,
          },
        },
      })
    } else {
      this.setState({
        form: {
          ...this.state.form,
          [name]: value,
        },
      })
    }
  }

  handleInputTranslationChange = (event, values) => {
    const { target } = event
    const { name } = target

    if (name.includes('.')) {
      let splitName = name.split('.')
      this.setState({
        form: {
          ...this.state.form,
          [splitName[0]]: values,
        },
      })
    }
  }

  handleSubmit() {
    this.props.handleSubmit({
      ...this.state.form,
      statusRef,
    })
  }

  render() {
    const { t } = this.props

    return (
      <div className={`${baseClass}`}>
        <FormGeneric className="model-new-body-part" onSubmit={this.handleSubmit}>
          <div className={`${baseClass}-form-title`}>
            <h1>{t('PageBodyParts.new-edit')}</h1>
          </div>

          <div className="row-container">
            <label>{t('PageBodyParts.subtitle-1')}</label>
            <div className="left-indent">
              <Input
                type="text"
                name="name"
                value={this.state.form.name || {}}
                onChange={this.handleInputTranslationChange}
                placeholder={t('PageBodyParts.subtitle-1')}
                autoComplete="false"
                required={true}
                maxLength={191}
                isTranslatable={true}
              />
            </div>

            <div className="row-container">
              <label>{t('PageBodyParts.muscle-flag')}</label>
              <input
                type="checkbox"
                name="is_muscle"
                value={true}
                onChange={this.handleInputChange}
                checked={this.state.form.is_muscle === true || this.state.form.is_muscle === 1}
                autoComplete="false"
              />
            </div>
          </div>

          <Button type="submit" onClick={() => {}} status={this.props.statuses[statusRef]}>
            {t('GLOBAL.submit')}
          </Button>

          <ErrorMessage status={this.props.statuses[statusRef]} />
        </FormGeneric>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PageBodyPartsNew))
