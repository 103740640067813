import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { withTranslation } from 'react-i18next'

import Button from 'components/Button'
import Pagination from 'components/Pagination'
import SearchFilterBar from 'components/SearchFilterBar'

import { Link } from 'react-router-dom'

import { SET_ACTIVE_MODAL } from 'store/modules/Modal/actions.js'
import { programSearchCreator, updateProgramSearchFilters } from 'store/modules/Program/actions.js'
import AdminTable from 'components/AdminTable'

import { FETCH_PROGRAMS_RESOURCES_REQUEST } from 'store/modules/Resource/actions.js'

import './style.css'

const statusRefSearch = 'PagePrograms-search'

class PagePrograms extends Component {
  constructor() {
    super()
    this.state = {
      searchData: {},
    }

    this.handleCreateProgramClick = this.handleCreateProgramClick.bind(this)
    this.fetchPrograms = this.fetchPrograms.bind(this)
    this.searchFilteroptions = this.searchFilteroptions.bind(this)
    this.updateStoredFilters = this.updateStoredFilters.bind(this)
  }

  componentDidMount() {
    const { searchFilters } = this.props.theState.Program
    this.props.dispatch({ type: FETCH_PROGRAMS_RESOURCES_REQUEST })
    if (
      Object.keys(searchFilters).length > 0 &&
      (searchFilters.search_text ||
        (searchFilters.multiSelect && searchFilters.multiSelect.levels.length))
    ) {
      this.props.dispatch(
        programSearchCreator({
          ...searchFilters,
          ...searchFilters.multiSelect,
          search: searchFilters.search_text,
          statusRef: statusRefSearch,
          public: true,
          getphy: false,
        })
      )
    } else {
      this.props.dispatch(
        programSearchCreator({ getphy: false, public: true, statusRef: statusRefSearch })
      )
    }
  }

  fetchPrograms(data) {
    if (!data) return

    this.setState(
      {
        searchData: data,
      },
      () => {
        this.props.dispatch(
          programSearchCreator({
            ...data,
            ...data.multiSelect,
            search: data.search_text,
            statusRef: statusRefSearch,
            public: true,
            getphy: false,
          })
        )
      }
    )
  }

  handleCreateProgramClick() {
    this.props.dispatch({ type: SET_ACTIVE_MODAL, payload: 'create-program' })
  }

  buildTableRows() {
    const programs = this.props.searchResults.programs

    if (!programs) {
      return []
    }

    return (programs || []).map((item) => {
      return {
        columns: [
          // { value: item.getphy ? '✔' : ''},
          { value: item.name },
          { value: (item.level || {}).name },
          { value: (item.prescriber || {}).full_name },
          { value: item.sessions_count },
          { value: item.challenges_count },
          { value: item.cycles_count },
        ],
        onClick: () => this.props.history.push(`/program/${item.id}`),
      }
    })
  }

  getHeaderRow() {
    const { t } = this.props

    return {
      columns: [
        // { value: t('PagePrograms.subtitle-1') },
        { value: t('PagePrograms.subtitle-2') },
        { value: t('PagePrograms.subtitle-3') },
        { value: t('PagePrograms.subtitle-4') },
        { value: t('PagePrograms.subtitle-5') },
        { value: t('PagePrograms.subtitle-6') },
        { value: t('PagePrograms.subtitle-7') },
      ],
    }
  }

  searchFilteroptions() {
    const { t } = this.props

    const resources = this.props.resources
    let toReturn = []

    if (resources.levels) {
      toReturn.push({
        label: t('PagePrograms.SearchFilters.levels'),
        name: 'levels',
        value: 'levels',
        options: resources.levels.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    return toReturn
  }

  updateStoredFilters(filters) {
    this.props.dispatch(updateProgramSearchFilters({ ...filters }))
  }

  render() {
    const { t } = this.props

    return (
      <div className="page-programs">
        <div className="page-programs-title">{t('PagePrograms.title')}</div>

        <div>
          <Link to="/programs/create">
            <Button onClick={() => {}}>{t('PagePrograms.button-1')}</Button>
          </Link>

          {this.props.role === 'admin_referensiel' && (
            <Link to="/programs/requests">
              <Button onClick={() => {}}>{t('PagePrograms.button-2')}</Button>
            </Link>
          )}
        </div>

        <SearchFilterBar
          filterTitle={''}
          filterOptions={this.searchFilteroptions()}
          storedFilters={this.props.theState.Program.searchFilters}
          handleSearchFormSubmit={this.fetchPrograms}
          onfilterChange={this.fetchPrograms}
          filterType={'multi-select'}
          showLoadingIndicator={(this.props.statuses[statusRefSearch] || {}).state === 'busy'}
          updateStoredFilters={this.updateStoredFilters}
        />

        <AdminTable headerRow={this.getHeaderRow()} rows={this.buildTableRows()} />

        <Pagination
          searchData={this.state.searchData}
          limit={this.props.searchResults.limit}
          offset={this.props.searchResults.offset}
          total={this.props.searchResults.total_count}
          fetchData={this.fetchPrograms}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  activeModal: state.Modal.activeModal,
  searchResults: state.Program.searchResults,
  role: state.Account.role,
  resources: state.Resource.programs,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PagePrograms))
)
