import React, { Component } from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'

import CloseButton from 'components/CloseButton'

import './style.css'

const baseClass = 'modal-preview-gif'

const imageUrl = process.env.REACT_APP_STORAGE_URL

class ModalPreviewGif extends Component {
  constructor() {
    super()

    this.state = {
      currentImage: null,
    }

    this.gifLoop = null

    this.startGif = this.startGif.bind(this)
  }

  componentDidMount() {
    if (this.props.images) {
      this.startGif(0)
    }
  }

  startGif(index) {
    let nextIndex = index + 1
    let { images } = this.props

    if (images.length === nextIndex) {
      nextIndex = 0
    }

    this.setState({
      currentImage: images[index],
    })

    if (this.gifLoop) {
      clearTimeout(this.gifLoop)
    }

    this.gifLoop = setTimeout(() => {
      this.startGif(nextIndex)
    }, 1000)
  }

  render() {
    const { isOpen, handleCloseModal } = this.props
    const { currentImage } = this.state
    return (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick={true}
        className={`modal-content ${baseClass}-modal`}
        overlayClassName="modal-overlay"
      >
        <CloseButton onClick={handleCloseModal} />

        <div
          className={`${baseClass}-content`}
          style={{ backgroundImage: `url(${imageUrl}${currentImage})` }}
        ></div>
      </ReactModal>
    )
  }
}

ModalPreviewGif.propTypes = {
  sections: PropTypes.array,
}

ModalPreviewGif.defaultProps = {
  sections: [],
}

export default ModalPreviewGif
