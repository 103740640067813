import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import getBoolean from 'helpers/get-boolean'

import Textarea from 'containers/Textarea'
import Texteditor from 'containers/Texteditor'

import InactivityPeriodsSelector from './Components/InactivityPeriodsSelector'

import './style.css'

class Configurations extends Component {
  constructor() {
    super()

    this.state = {}
  }

  render() {
    const { t } = this.props

    return (
      <div>
        <div className="row-container">
          <label>{`Expiry Period (days)`}</label>
          <div className="left-indent width-20">
            <input
              type="number"
              name="expiration_period"
              value={this.props.configData.expiration_period || null}
              onChange={this.props.handleInputChange}
            />
          </div>
        </div>

        <div className="row-container">
          <label className="mb-40">{t('PageAssessments.ModalCreateAssessment.label-9')}</label>
          <div className="left-indent">
            <Textarea
              name="physical_test_expired_message"
              value={this.props.configData.physical_test_expired_message || {}}
              onChange={this.props.handleInputChange}
              maxLength={500}
              isTranslatable={true}
              withCharsCount={true}
            ></Textarea>
          </div>
        </div>

        <div className="row-container">
          <label>{`Drop out period (days)`}</label>
          <div className="left-indent width-20">
            <input
              type="number"
              name="drop_out_period"
              value={this.props.configData.drop_out_period || null}
              onChange={this.props.handleInputChange}
            />
          </div>
        </div>

        {!this.props.disableForcePqProgramAssign && (
          <div className="row-container">
            <label>{t('PageAssessments.ModalCreateAssessment.label-5')}</label>
            <div className="left-indent">
              <input
                type="checkbox"
                name="force_pq_program_assign"
                value={this.props.configData.force_pq_program_assign}
                onChange={this.props.handleInputChange}
                maxLength={191}
                checked={getBoolean(this.props.configData.force_pq_program_assign) === true}
              />
            </div>
          </div>
        )}

        <div className="row-container">
          <label className="mb-40">{t('PageAssessments.ModalCreateAssessment.label-6')}</label>

          <div className="left-indent">
            <Textarea
              name="physical_test_welcome_message"
              value={this.props.configData.physical_test_welcome_message || {}}
              onChange={this.props.handleInputChange}
              maxLength={500}
              isTranslatable={true}
              withCharsCount={true}
            ></Textarea>
          </div>
        </div>

        <div className="row-container mb-80">
          <label className="mb-40">{t('PageAssessments.ModalCreateAssessment.label-10')}</label>

          <div className="left-indent">
            <Texteditor
              value={this.props.configData.end_assessment_message || {}}
              handleTextAreaChange={this.props.handleTextEditorChange}
              name="end_assessment_message"
              isTranslatable={true}
            />
          </div>
        </div>

        <div className="row-container">
          <label>{t('PageAssessments.ModalCreateAssessment.label-7')}</label>
          <div className="left-indent">
            <input
              type="checkbox"
              name="session_intro_message_enabled"
              value={this.props.configData.session_intro_message_enabled}
              onChange={this.props.handleInputChange}
              maxLength={191}
              checked={getBoolean(this.props.configData.session_intro_message_enabled) === true}
            />
          </div>
        </div>

        {this.props.configData.session_intro_message_enabled == 1 && (
          <div className="row-container">
            <label>{t('PageAssessments.ModalCreateAssessment.label-8')}</label>

            <div className="left-indent">
              <Texteditor
                value={this.props.configData.session_intro_message || {}}
                handleTextAreaChange={this.props.handleTextEditorChange}
                name="session_intro_message"
                isTranslatable={true}
              />
            </div>
          </div>
        )}

        <InactivityPeriodsSelector
          inactivityPeriods={this.props.configData.inactivity_periods}
          emails={this.props.emails}
          handleChangeInactivityPeriods={this.props.handleChangeInactivityPeriods}
        />
      </div>
    )
  }
}

Configurations.propTypes = {
  configData: PropTypes.object,
}

Configurations.defaultProps = {
  configData: {},
}

export default withTranslation()(Configurations)
