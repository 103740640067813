import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import FormModal from 'components/FormModal'
import CloseButton from 'components/CloseButton'
import TopSection from './components/TopSection'
import Accordion from 'components/Accordion'

import ProfileInfo from './components/ProfileInfo'
import PersonalInfo from './components/PersonalInfo'
import ConfidentialInfo from './components/ConfidentialInfo'
import NotificationInfo from './components/NotificationInfo'
import InviteInfo from './components/InviteInfo'
import PaymentsInfo from './components/PaymentsInfo'
import UnsubscribeInfo from './components/UnsubscribeInfo'

import { CLOSE_MODALS } from 'store/modules/Modal/actions.js'
import { SET_USER_AVATAR_REQUEST } from 'store/modules/User/actions.js'
import { submitProfileUpdate } from 'store/modules/Account/actions.js'
import { getCountries } from 'store/modules/Resource/actions.js'
import './style.css'

ReactModal.setAppElement('#root')

// const statusRefPrescriptorCode = 'ModalProfile-PrescriptorCode'

class ModalProfile extends Component {
  constructor() {
    super()

    this.state = {
      userData: {},
      changedData: {},
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleLocationChange = this.handleLocationChange.bind(this)
    this.handleSubmitClick = this.handleSubmitClick.bind(this)
    this.handleCancelClick = this.handleCancelClick.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleAvatarChange = this.handleAvatarChange.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(getCountries({}))
  }

  handleChange(e) {
    const { name, value } = e.target
    this.setState({
      userData: {
        ...this.state.userData,
        [name]: value,
      },
    })

    this.setState({
      changedData: {
        ...this.state.changedData,
        [name]: value,
      },
    })
  }

  handleLocationChange(data) {
    this.setState({
      userData: {
        ...this.state.userData,
        address: data.address || '',
        address_details: data.address_details || '',
        city: data.city || '',
        zip_code: data.zip_code || '',
        latitude: data.latitude || '',
        longitude: data.longitude || '',
        country_id: data.country_id || '',
      },
    })

    this.setState({
      changedData: {
        ...this.state.changedData,
        address: data.address || '',
        address_details: data.address_details || '',
        city: data.city || '',
        zip_code: data.zip_code || '',
        latitude: data.latitude || '',
        longitude: data.longitude || '',
        country_id: data.country_id || '',
      },
    })
  }

  handleCloseModal() {
    this.props.dispatch({ type: CLOSE_MODALS })
  }

  handleSubmitClick() {
    this.props.dispatch(submitProfileUpdate(this.state.changedData))
  }

  handleCancelClick() {
    this.setState({
      userData: this.props.userInfo,
      changedData: {},
    })
  }

  handleAvatarChange(dataUrl) {
    this.props.dispatch({
      type: SET_USER_AVATAR_REQUEST,
      data: {
        userId: this.props.userInfo.id,
        dataUrl: dataUrl,
      },
    })
  }

  componentDidUpdate(prevProps) {
    const { userInfo } = this.props
    //After succesful login close login modal
    if (userInfo !== prevProps.userInfo) {
      this.setState({ userData: prevProps.userInfo })
    }
  }

  render() {
    const { t } = this.props

    return (
      <ReactModal
        isOpen={this.props.activeModal === 'profile'}
        onRequestClose={this.handleCloseModal}
        shouldCloseOnOverlayClick={true}
        className="modal-content modal-profile"
        overlayClassName="modal-overlay"
      >
        <FormModal id="profile-form" className="profile-form" onSubmit={this.handleSubmitClick}>
          <CloseButton onClick={this.handleCloseModal} />

          <TopSection userData={this.state.userData} handleAvatarChange={this.handleAvatarChange} />

          <Accordion
            sections={[
              {
                title: t('ModalProfile.title-1'),
                content: (
                  <ProfileInfo
                    userData={this.state.userData}
                    countries={this.props.countries}
                    role={this.props.role}
                    inputChange={this.handleChange}
                    handleLocationChange={this.handleLocationChange}
                    cancelClick={this.handleCancelClick}
                    submitClick={this.handleSubmitClick}
                  />
                ),
              },
              {
                title: t('ModalProfile.title-2'),
                content: (
                  <PersonalInfo
                    userData={this.state.userData}
                    inputChange={this.handleChange}
                    cancelClick={this.handleCancelClick}
                    submitClick={this.handleSubmitClick}
                  />
                ),
              },
              {
                title: t('ModalProfile.title-4'),
                content: (
                  <ConfidentialInfo
                    userData={this.state.userData}
                    inputChange={this.handleChange}
                    cancelClick={this.handleCancelClick}
                    submitClick={this.handleSubmitClick}
                  />
                ),
              },
              {
                title: t('ModalProfile.title-5'),
                content: (
                  <NotificationInfo
                    userData={this.state.userData}
                    inputChange={this.handleChange}
                    cancelClick={this.handleCancelClick}
                    submitClick={this.handleSubmitClick}
                  />
                ),
              },
              {
                title: t('ModalProfile.title-6'),
                content: (
                  <InviteInfo
                    userData={this.state.userData}
                    inputChange={this.handleChange}
                    cancelClick={this.handleCancelClick}
                    submitClick={this.handleSubmitClick}
                  />
                ),
              },
              {
                title: t('ModalProfile.title-7'),
                content: <PaymentsInfo userData={this.state.userData} />,
              },
              {
                title: t('ModalProfile.title-8'),
                content: (
                  <UnsubscribeInfo
                    userData={this.state.userData}
                    inputChange={this.handleChange}
                    cancelClick={this.handleCancelClick}
                    submitClick={this.handleSubmitClick}
                  />
                ),
              },
            ]}
          />

          <div>{this.props.updateRequestStatus}</div>
          <div>{this.props.updateError}</div>
        </FormModal>
      </ReactModal>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  activeModal: state.Modal.activeModal,
  updateRequestStatus: state.Modal.updateRequestStatus,
  updateError: state.Modal.updateError,
  userInfo: state.Account.user,
  role: state.Account.role,
  statuses: state.Status.statuses,
  countries: state.Resource.countries,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalProfile))
