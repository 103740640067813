import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import ExerciseCard from 'components/ExerciseCard'
import Button from 'components/Button'

import { Link } from 'react-router-dom'

import './style.css'

// const baseClass = 'pages-stats-general-stats'

const MyExercises = ({ t, exercises }) => {
  return (
    <div>
      <div>
        <Link to="/exercises/create">
          <Button onClick={() => {}}>{t('PageBag.MyExercises.new-exercise')}</Button>
        </Link>
      </div>

      <div>
        {exercises.map((exercise) => (
          <Link to={`/exercise/${exercise.id}`} key={`exercise_${exercise.id}`}>
            <ExerciseCard item={exercise} />
          </Link>
        ))}
      </div>
    </div>
  )
}

MyExercises.propTypes = {
  exercises: PropTypes.array,
}

MyExercises.defaultProps = {
  exercises: [],
}

export default withTranslation()(MyExercises)
