import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { normalizeTime } from 'services/time'

import './style.css'

const imageUrl = process.env.REACT_APP_STORAGE_URL

class SessionDetails extends Component {
  constructor() {
    super()

    this.state = {}

    // this.handleMonthChange = this.handleMonthChange.bind(this);
    this.sessionImages = this.sessionImages.bind(this)
  }

  muscles() {
    if (!this.props.session.challenge_exercises) return []
    let toReturn = []

    this.props.session.challenge_exercises.map((item) => {
      toReturn = [...toReturn, ...item.exercise.muscles]
    })

    return toReturn
  }

  sessionImages(images) {
    let toReturn = []

    ;(images || []).map((item, index) => {
      toReturn.push(
        <div
          style={{ backgroundImage: `url('${imageUrl}${item}')` }}
          className="session-image"
          key={`sessionImages_${index}`}
        ></div>
      )

      return null
    })

    return toReturn
  }

  render() {
    const { t } = this.props

    return (
      <div className={`session-details ${this.props.className || ''}`}>
        <div className="session-details-title-container">
          <h1 className="session-details-title">{this.props.session.name}</h1>
        </div>

        <div className="session-details-info-container">
          <div className="session-details-info-block">
            <div className="session-details-info-block-key">{t('SessionDetails.subtitle-1')}</div>

            <div className="session-details-info-block-value">
              {this.props.session.level && <span>{this.props.session.level.name}</span>}
            </div>
          </div>

          <div className="session-details-info-block">
            <div className="session-details-info-block-key">{t('SessionDetails.subtitle-2')}</div>

            <div className="session-details-info-block-value">
              {this.props.session.duration && (
                <span>{`${normalizeTime(this.props.session.duration, 'seconds')}`}</span>
              )}
            </div>
          </div>

          <div className="session-details-info-block">
            <div className="session-details-info-block-key">{t('SessionDetails.subtitle-3')}</div>

            <div className="session-details-info-block-value">
              {this.props.session.rest_time && (
                <span>{`${normalizeTime(this.props.session.rest_time, 'seconds')}`}</span>
              )}
            </div>
          </div>

          <div className="session-details-info-block">
            <div className="session-details-info-block-key">{t('SessionDetails.subtitle-4')}</div>

            <div className="session-details-info-block-value">
              {this.props.session.nb_exercises && <span>{this.props.session.nb_exercises}</span>}
            </div>
          </div>

          <div className="session-details-info-block">
            <div className="session-details-info-block-key">{t('SessionDetails.subtitle-5')}</div>

            <div className="session-details-info-block-value">
              {this.props.session.target_audience && (
                <span>{this.props.session.target_audience.name}</span>
              )}
            </div>
          </div>

          <div className="session-details-info-block">
            <div className="session-details-info-block-key">{t('SessionDetails.subtitle-6')}</div>

            <div className="session-details-info-block-value">
              <span>{(this.props.session.objective || {}).name}</span>
            </div>
          </div>

          <div className="session-details-info-block">
            <div className="session-details-info-block-key">{t('SessionDetails.subtitle-7')}</div>

            <div className="session-details-info-block-value">
              {this.props.session.objectives &&
                this.props.session.objectives.map((item, index, array) => {
                  return (
                    <span key={`objective_${index}`}>
                      {`${item.name}${index < array.length - 1 ? ', ' : ''}`}
                    </span>
                  )
                })}
            </div>
          </div>

          <div className="session-details-info-block">
            <div className="session-details-info-block-key">{t('SessionDetails.subtitle-8')}</div>

            <div className="session-details-info-block-value">
              {this.props.session.muscles &&
                this.props.session.muscles.map((item, index, array) => {
                  return (
                    <span key={`muscle_${index}`}>
                      {`${item.name}${index < array.length - 1 ? ', ' : ''}`}
                    </span>
                  )
                })}
            </div>
          </div>

          {this.props.showSessionImages && (
            <div className="session-images-container">{this.sessionImages(this.props.images)}</div>
          )}
        </div>
      </div>
    )
  }
}

SessionDetails.propTypes = {
  challenge: PropTypes.object,
}

SessionDetails.defaultProps = {
  challenge: {
    level: {},
    challenge_exercises: [],
  },
}

export default withTranslation()(SessionDetails)
