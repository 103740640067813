import { all, fork } from 'redux-saga/effects'
import Account from 'store/saga/Account'
import Exercise from 'store/saga/Exercise'
import User from 'store/saga/User'
import Resource from 'store/saga/Resource'
import Session from 'store/saga/Session'
import Challenge from 'store/saga/Challenge'
import Program from 'store/saga/Program'
import Prescriber from 'store/saga/Prescriber'
import Status from 'store/saga/Status'
import PhysicalTest from 'store/saga/PhysicalTest'
import Folder from 'store/saga/Folder'
import Equipment from 'store/saga/Equipment'
import BodyParts from 'store/saga/BodyParts'
import Auth from 'store/saga/Auth'
import Assessment from 'store/saga/Assessment'
import Email from 'store/saga/Email'
import Notification from 'store/saga/Notification'

const sagas = [
  Account,
  Exercise,
  User,
  Resource,
  Session,
  Challenge,
  Program,
  Prescriber,
  Status,
  PhysicalTest,
  Folder,
  Equipment,
  BodyParts,
  Assessment,
  Auth,
  Email,
  Notification,
]

export default function* rootSaga() {
  yield all(sagas.map((saga) => fork(saga)))
  // yield fork(Account)
  // yield fork(Exercise)
  // yield fork(User)
  // yield fork(Resource)
  // yield fork(Session)
  // yield fork(Challenge)
  // yield fork(Program)
  // yield fork(Prescriber)
  // yield fork(Status)
  // yield fork(PhysicalTest)
  // yield fork(Folder)
  // yield fork(Equipment)
  // yield fork(BodyParts)
}
