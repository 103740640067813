import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import { CONTACT_EMAIL_ADDRESS } from 'services/contact'

import Button from 'components/Button'
import './style.css'

class UnsubscribeInfo extends Component {
  constructor() {
    super()

    this.state = {}

    this.handleUnsubscribeClick = this.handleUnsubscribeClick.bind(this)
  }

  handleUnsubscribeClick() {
    this.setState({ showConfirmMessage: true })
  }

  render() {
    const { t } = this.props

    return (
      <div className="panel">
        <div className="data-row">
          <h1>{t('ModalProfile.UnsubscribeInfo.title')}</h1>

          <p>{t('ModalProfile.UnsubscribeInfo.question-1')}</p>

          <ul>
            <li>
              {t('ModalProfile.UnsubscribeInfo.question-2')} :{' '}
              <a href="/">{t('ModalProfile.UnsubscribeInfo.response-1')}</a>.
            </li>
            <li>
              {t('ModalProfile.UnsubscribeInfo.question-3')} :{' '}
              <a href="/">{t('ModalProfile.UnsubscribeInfo.response-2')}</a>.
            </li>
            <li>
              {t('ModalProfile.UnsubscribeInfo.question-4')} :{' '}
              <a href={'mailto:' + CONTACT_EMAIL_ADDRESS}>
                {t('ModalProfile.UnsubscribeInfo.response-3')}
              </a>
              .
            </li>
            <li>
              {t('ModalProfile.UnsubscribeInfo.question-5')} :{' '}
              <a href="/">{t('ModalProfile.UnsubscribeInfo.response-4')}</a>.
            </li>
            <li>{t('ModalProfile.UnsubscribeInfo.text-1')}</li>
          </ul>

          <p>{t('ModalProfile.UnsubscribeInfo.text-2')}</p>
        </div>

        <br />
        <hr />
        <br />

        <div className="data-row data-row-half">
          <label> {t('ModalProfile.UnsubscribeInfo.label-1')} </label>
          <select autoComplete="off" name="motif">
            <option value="0">{t('ModalProfile.UnsubscribeInfo.option-1')}</option>
            <option value="1">{t('ModalProfile.UnsubscribeInfo.option-2')}</option>
            <option value="2">{t('ModalProfile.UnsubscribeInfo.option-3')}</option>
            <option value="3">{t('ModalProfile.UnsubscribeInfo.option-4')}</option>
            <option value="4">{t('ModalProfile.UnsubscribeInfo.option-5')}</option>
            <option value="5">{t('ModalProfile.UnsubscribeInfo.option-6')}</option>
            <option value="6">{t('ModalProfile.UnsubscribeInfo.option-7')}</option>
          </select>
        </div>

        <div className="data-row data-row-half">
          <label> {t('ModalProfile.UnsubscribeInfo.label-2')} </label>
          <select name="yesno">
            <option value="yes">{t('GLOBAL.yes')}</option>
            <option value="no">{t('GLOBAL.no')}</option>
          </select>
        </div>

        <div className="data-row data-row-half">
          <label> {t('ModalProfile.UnsubscribeInfo.label-3')} </label>
          <textarea name="suggestion" cols="50" rows="10" maxLength={500} />
        </div>

        {this.state.showConfirmMessage && (
          <div className="">
            <div className="">{t('ModalProfile.UnsubscribeInfo.confirm')}</div>

            <div className="">
              <Button onClick={this.props.cancelClick}>{t('GLOBAL.cancel')}</Button>

              <Button onClick={this.props.submitClick}>
                {t('ModalProfile.UnsubscribeInfo.unsubscribe')}
              </Button>
            </div>
          </div>
        )}

        <div className="">
          <Button onClick={this.handleUnsubscribeClick}>
            {t('ModalProfile.UnsubscribeInfo.unsubscribe')}
          </Button>
        </div>
      </div>
    )
  }
}

export default withTranslation()(UnsubscribeInfo)
