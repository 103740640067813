import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import './style.css'

class ProgramDetails extends Component {
  constructor() {
    super()

    this.state = {}
  }

  muscles() {
    if (!this.props.program.challenge_exercises) return []
    let toReturn = []

    this.props.program.challenge_exercises.map((item) => {
      toReturn = [...toReturn, ...item.exercise.muscles]
    })

    return toReturn
  }

  render() {
    const { t } = this.props

    return (
      <div className={`program-details ${this.props.className || ''}`}>
        <div className="program-details-title-container">
          <h1 className="program-details-title">{this.props.program.name}</h1>
        </div>

        <div className="program-details-info-container">
          <div className="program-details-info-block">
            <div className="program-details-info-block-key">{t('ProgramDetails.subtitle-1')}</div>

            <div className="program-details-info-block-value">
              {this.props.program.level && <span>{this.props.program.level.name}</span>}
            </div>
          </div>

          <div className="program-details-info-block">
            <div className="program-details-info-block-key">{t('ProgramDetails.subtitle-2')}</div>

            <div className="program-details-info-block-value">
              {this.props.program.goals &&
                this.props.program.goals
                  .filter((item) => {
                    return item.percentage > 0
                  })
                  .map((item, index, array) => {
                    return (
                      <span key={`goal_${index}`}>
                        {`${item.name}${index < array.length - 1 ? ', ' : ''}`}
                      </span>
                    )
                  })}
            </div>
          </div>

          {this.props.program.session_goal && (
            <div className="program-details-info-block">
              <div className="program-details-info-block-key">{t('ProgramDetails.subtitle-3')}</div>

              <div className="program-details-info-block-value">
                <span>✔</span>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

ProgramDetails.propTypes = {
  program: PropTypes.object,
}

ProgramDetails.defaultProps = {
  program: {
    cycles: [],
  },
}

export default withTranslation()(ProgramDetails)
