import * as bodyPartsActions from 'store/modules/BodyParts/actions.js'

const initialState = {
  // searchResults: [],
  body_parts: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // case SET_BODY_PARTS_SEARCH_RESULTS:
    //   return {
    //     ...state,
    //     searchResults: action.payload,
    //   };

    case bodyPartsActions.UPDATE_BODY_PARTS_CACHE_SET:
      return {
        ...state,
        body_parts: action.payload,
      }

    default:
      return state
  }
}
