import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import './style.css'

const imageUrl = process.env.REACT_APP_IMAGE_URL

class ChallengeDetails extends Component {
  constructor() {
    super()

    this.state = {}

    // this.handleMonthChange = this.handleMonthChange.bind(this);
    this.challengeImages = this.challengeImages.bind(this)
  }

  muscles() {
    if (!this.props.challenge.challenge_exercises) return []
    let toReturn = []

    this.props.challenge.challenge_exercises.map((item) => {
      toReturn = [...toReturn, ...item.exercise.muscles]
    })

    return toReturn
  }

  challengeImages() {
    let toReturn = []

    ;(this.props.challenge.challenge_exercises || []).map((item, index) => {
      const exercise = item.exercise
      if (exercise.steps.length) {
        toReturn.push(
          <div
            style={{ backgroundImage: `url(${imageUrl}${exercise.steps[0].photo})` }}
            className="challenge-image"
            key={`stepsImages_${index}`}
          ></div>
        )
      }

      return null
    })

    return toReturn
  }

  render() {
    const { t } = this.props

    return (
      <div className={`challenge-details ${this.props.className || ''}`}>
        <div className="challenge-details-title-container">
          <h1 className="challenge-details-title">{this.props.challenge.name}</h1>
        </div>

        <div className="challenge-details-info-container">
          <div className="challenge-details-info-block">
            <div className="challenge-details-info-block-key">{t('ChallengeDetails.title-1')}</div>

            <div className="challenge-details-info-block-value">
              {this.props.challenge.level && <span>{this.props.challenge.level.name}</span>}
            </div>
          </div>

          <div className="challenge-details-info-block">
            <div className="challenge-details-info-block-key">{t('ChallengeDetails.title-2')}</div>

            <div className="challenge-details-info-block-value">
              {this.props.challenge.cycle && <span>{this.props.challenge.cycle}</span>}
            </div>
          </div>

          <div className="challenge-details-info-block">
            <div className="challenge-details-info-block-key">{t('ChallengeDetails.title-3')}</div>

            <div className="challenge-details-info-block-value">
              {this.muscles().map((item, index, array) => {
                return (
                  <span key={`muscles_${index}`}>
                    {`${item.name}${index < array.length - 1 ? ', ' : ''}`}
                  </span>
                )
              })}
            </div>
          </div>

          <div className="challenge-details-info-block">
            <div className="challenge-details-info-block-key">{t('ChallengeDetails.title-4')}</div>

            <div className="challenge-details-info-block-value">
              {this.props.challenge.challenge_type && (
                <span>{this.props.challenge.challenge_type.name}</span>
              )}
            </div>
          </div>

          <div className="challenge-images-container">{this.challengeImages()}</div>
        </div>
      </div>
    )
  }
}

ChallengeDetails.propTypes = {
  challenge: PropTypes.object,
}

ChallengeDetails.defaultProps = {
  challenge: {
    level: {},
    challenge_exercises: [],
  },
}

export default withTranslation()(ChallengeDetails)
