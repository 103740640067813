import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import AdminTable from 'components/AdminTable'
import Button from 'components/Button'
import Icon from 'components/Icon'

import './style.css'

const baseClass = 'pageAssessments-modalCreateAssessemnt-configurations-inactivityPeriods'

class InactivityPeriodsSelector extends Component {
  constructor() {
    super()

    this.state = {
      inactivityPeriods: [],
    }

    this.renderTable = this.renderTable.bind(this)

    this.handleInputChange = this.handleInputChange.bind(this)

    this.handleAddInactivityPeriods = this.handleAddInactivityPeriods.bind(this)
    this.handleDeleteInactivityPeriods = this.handleDeleteInactivityPeriods.bind(this)
  }

  renderTable() {
    const { t } = this.props

    const headers = {
      columns: [
        {
          value: t(
            'PageAssessments.ModalCreateAssessment.Configurations.InactivityPeriodsSelector.subtitle-1'
          ),
        },
        {
          value: t(
            'PageAssessments.ModalCreateAssessment.Configurations.InactivityPeriodsSelector.subtitle-2'
          ),
        },
        { value: t('GLOBAL.empty') },
      ],
    }

    let tableRows = []

    ;(this.props.inactivityPeriods || []).map((item, index) => {
      let columns = [
        {
          value: (
            <input
              type="number"
              name="days"
              value={item.days || ''}
              onChange={(e) => this.handleInputChange(e, index)}
            />
          ),
        },
        {
          value: (
            <select
              name="email_id"
              value={item.email_id || ''}
              onChange={(e) => this.handleInputChange(e, index)}
            >
              <option value="" disabled>
                {' '}
                -------{' '}
              </option>

              {(Object.keys(this.props.emails) || []).map((key, idx) => {
                const email = this.props.emails[key]
                return (
                  <option key={idx} value={email.id}>
                    {' '}
                    {email.name}{' '}
                  </option>
                )
              })}
            </select>
          ),
        },
        {
          value: (
            <Button
              className="small narrow"
              onClick={() => this.handleDeleteInactivityPeriods(index)}
            >
              {' '}
              <Icon className="small" src={'trash.svg'} />{' '}
            </Button>
          ),
        },
      ]

      tableRows.push({ columns })
      return null
    })

    return <AdminTable headerRow={headers} rows={tableRows} />
  }

  handleInputChange(ev, index) {
    const { value, name } = ev.target
    let inactivityPeriods = [...this.props.inactivityPeriods]

    inactivityPeriods[index][name] = parseInt(value)

    this.setState(
      {
        inactivityPeriods,
      },
      () => {
        this.props.handleChangeInactivityPeriods(inactivityPeriods)
      }
    )
  }

  handleAddInactivityPeriods() {
    let inactivityPeriods = [...this.props.inactivityPeriods]

    inactivityPeriods.push({
      id: null,
      email_id: null,
      days: null,
    })

    this.setState(
      {
        inactivityPeriods,
      },
      () => this.props.handleChangeInactivityPeriods(inactivityPeriods)
    )
  }

  handleDeleteInactivityPeriods(index) {
    let inactivityPeriods = [...this.props.inactivityPeriods]

    inactivityPeriods.splice(index, 1)

    this.setState(
      {
        inactivityPeriods,
      },
      () => {
        this.props.handleChangeInactivityPeriods(inactivityPeriods)
      }
    )
  }

  render() {
    const { t } = this.props

    return (
      <div className={`${baseClass}`}>
        <div className="row-container">
          <div className="">
            <label>
              {t(
                'PageAssessments.ModalCreateAssessment.Configurations.InactivityPeriodsSelector.title'
              )}
            </label>

            <div className="inline-block left-indent">
              <Button type="button" onClick={this.handleAddInactivityPeriods}>
                {t(
                  'PageAssessments.ModalCreateAssessment.Configurations.InactivityPeriodsSelector.button'
                )}
              </Button>
            </div>

            <div className={`width-66`}>{this.renderTable()}</div>
          </div>
        </div>
      </div>
    )
  }
}

InactivityPeriodsSelector.propTypes = {
  emails: PropTypes.array,
  inactivityPeriods: PropTypes.array,
}

InactivityPeriodsSelector.defaultProps = {
  emails: [],
  inactivityPeriods: [],
}

export default withTranslation()(InactivityPeriodsSelector)
