import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import isEqual from 'lodash/isEqual'

import getTranslations from 'helpers/get-translations'

import FormGeneric from 'components/FormGeneric'
import Button from 'components/Button'
import StepsContainer from './components/StepsContainer'
import ErrorMessage from 'components/ErrorMessage'

import Input from 'containers/Input'

import './style.css'

const baseClass = 'create-exercise-form'

class FormExerciseCreate extends Component {
  constructor() {
    super()

    this.state = {
      form: {
        name: {},
        objective_id: '',
        count_id: '',
        position_id: '',
        asymmetric: false,
        objective_details: [],
        recommendations: {},
        steps: [],
        muscles: [],
        equipment: [],
        suggested_by: '',
      },
    }
  }

  componentDidMount() {
    this.setInitialData()
  }

  componentDidUpdate(prevProps) {
    const { initialData } = this.props
    if (initialData && !isEqual(initialData, prevProps.initialData)) {
      this.setInitialData()
    }
  }

  setInitialData = () => {
    if (!this.props.initialData) return

    const {
      public: _public,
      translations,
      asymmetric,
      objective_id,
      count_id,
      position_id,
    } = this.props.initialData
    let { equipment, steps, muscles } = this.props.initialData

    const name = getTranslations(translations, 'name')
    const recommendations = getTranslations(translations, 'recommendations')
    const objective_details = getTranslations(translations, 'objective_details')

    equipment = equipment ? equipment.map((item) => String(item.id)) : []
    muscles = muscles ? muscles.map((item) => String(item.id)) : []
    steps = steps
      ? steps.map((item) => {
          return {
            ...item,
            description: getTranslations(item.translations, 'description'),
          }
        })
      : []

    this.setState({
      form: {
        ...this.props.initialData,
        public: _public || false,
        name,
        asymmetric: asymmetric || false,
        objective_id,
        count_id,
        position_id,
        muscles,
        equipment,
        recommendations,
        objective_details,
        steps,
      },
    })
  }

  handleCheckboxGroupChange = (event) => {
    const { target } = event
    const { checked, name } = target

    if (name.includes('[')) {
      let openBracketIndex = name.indexOf('[')
      let closeBracketIndex = name.indexOf(']')
      let bracketLength = closeBracketIndex - openBracketIndex - 1

      let beforeBracketValue = name.substr(0, openBracketIndex)
      let bracketValue = name.substr(openBracketIndex + 1, bracketLength)

      let currentStateArray = this.state.form[beforeBracketValue] || []

      let newArray = [...currentStateArray]

      if (checked) {
        if (newArray.indexOf(bracketValue) < 0) {
          newArray.push(bracketValue)
        }
        if (bracketValue === '19') {
          newArray = []
          newArray.push('19')
        }
        if (bracketValue !== '19') {
          let index = newArray.indexOf('19')
          newArray.splice(index, 1)
          newArray.push(bracketValue)
        }
      } else {
        let index = newArray.indexOf(bracketValue)
        newArray.splice(index, 1)
      }

      this.setState({
        form: {
          ...this.state.form,
          [beforeBracketValue]: newArray,
        },
      })
    }
  }

  handleInputChange = (event) => {
    const { target } = event
    const { name, type, checked, value: val } = target

    let value = type === 'checkbox' ? checked : val
    if (value === 'true' || value === 'false') {
      value = value === 'true'
    }

    if (name.includes('.')) {
      //TODO: ALlows language to be specified in the input name.
      //Look at a more scalable way of doing this.
      let splitName = name.split('.')
      this.setState({
        form: {
          ...this.state.form,
          [splitName[0]]: {
            ...this.state.form[splitName[0]],
            [splitName[1]]: value,
          },
        },
      })
    } else {
      this.setState({
        form: {
          ...this.state.form,
          [name]: value,
        },
      })
    }
  }

  handleInputTranslationChange = (event, values) => {
    const { target } = event
    const { name } = target

    if (name.includes('.')) {
      let splitName = name.split('.')
      this.setState({
        form: {
          ...this.state.form,
          [splitName[0]]: values,
        },
      })
    }
  }

  handleSubmit = () => {
    const { form } = this.state
    const { steps, recommendations, objective_details } = form

    form.steps = steps.map((item, index) => {
      return { ...item, position: index + 1 }
    })

    if (recommendations && !Object.keys(recommendations).length) delete form.recommendations
    if (objective_details && !Object.keys(objective_details).length) delete form.objective_details

    this.props.handleSubmit(form)
  }

  handleStepsUpdate = (steps) => {
    this.setState({
      form: {
        ...this.state.form,
        steps,
      },
    })
  }

  handleCancel = () => {
    if (this.props.isModal) {
      this.props.handleCancel()
    } else {
      window.history.go(-1)
    }
  }

  render() {
    const { t, submitStatus, resources, i18n } = this.props
    const { form } = this.state

    return (
      <div className={`${baseClass}`}>
        <FormGeneric className="modal-create-exercise-form" onSubmit={this.handleSubmit}>
          <h1>{t('FormExerciseCreate.title')}</h1>

          <div className="row-container">
            <label>{t('FormExerciseCreate.label-1')}</label>
            <Input
              type="text"
              name="name"
              value={form.name || {}}
              onChange={this.handleInputTranslationChange}
              placeholder={t('FormExerciseCreate.label-1')}
              autoComplete="false"
              required={true}
              maxLength={191}
              isTranslatable={true}
              className="name"
            />
          </div>

          <div className="row-container">
            <label>{t('FormExerciseCreate.label-2')}</label>
            <div className="left-indent">
              <input
                type="checkbox"
                name="asymmetric"
                value={true}
                onChange={this.handleInputChange}
                checked={form.asymmetric === true || form.asymmetric === 1}
                autoComplete="false"
              />
            </div>
          </div>

          <div className="row-container">
            <div className="inline-block width-50">
              <label>{t('FormExerciseCreate.label-3')}</label>
              <div className="left-indent">
                <select
                  className="left-indent"
                  name="objective_id"
                  autoComplete="off"
                  onChange={this.handleInputChange}
                  value={form.objective_id || ''}
                  required={true}
                >
                  <option value="" disabled="disabled">
                    -------
                  </option>
                  {resources.objectives.map((item, index) => {
                    return (
                      <option value={item.id} key={`objectives_${index}`}>
                        {item.translations[i18n.language].name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>

            <div className="inline-block width-50">
              <label>{t('FormExerciseCreate.label-4')}</label>
              <div className="left-indent">
                <select
                  name="count_id"
                  autoComplete="off"
                  onChange={this.handleInputChange}
                  value={form.count_id || ''}
                  required={true}
                >
                  <option value="" disabled="disabled">
                    -------
                  </option>
                  {resources.counts.map((item, index) => {
                    return (
                      <option value={item.id} key={`counts_${index}`}>
                        {item.translations[i18n.language].name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="row-container">
            <div className="inline-block width-50">
              <label>{t('FormExerciseCreate.label-5')}</label>
              <div className="left-indent">
                <select
                  name="position_id"
                  autoComplete="off"
                  onChange={this.handleInputChange}
                  value={form.position_id || ''}
                  required={true}
                >
                  <option value="" disabled="disabled">
                    -------
                  </option>
                  {resources.positions.map((item, index) => {
                    return (
                      <option value={item.id} key={`positions_${index}`}>
                        {item.translations[i18n.language].name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="row-container">
            <label>{t('FormExerciseCreate.label-7')}</label>

            <div className="radio-group transparent no-border">
              {resources.muscles.map((item, index) => {
                return (
                  <div className="radio-field" key={`muscles_${index}`}>
                    <input
                      name={`muscles[${item.id}]`}
                      type="checkbox"
                      onChange={this.handleCheckboxGroupChange}
                      checked={form['muscles']?.indexOf(item.id.toString()) >= 0 || false}
                    />
                    <label>{item.name}</label>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="row-container">
            <label>{t('FormExerciseCreate.label-8')}</label>

            <div className="radio-group transparent no-border">
              {resources.equipment.map((item, index) => {
                return (
                  <div className="radio-field" key={`equipment_${index}`}>
                    <input
                      name={`equipment[${item.id}]`}
                      type="checkbox"
                      onChange={this.handleCheckboxGroupChange}
                      checked={form['equipment']?.indexOf(item.id.toString()) >= 0 || false}
                    />
                    <label>{item.name}</label>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="row-container">
            <label>{t('FormExerciseCreate.label-11')}</label>

            <div className="left-indent">
              <Input
                type="text"
                name="objective_details"
                value={form.objective_details || []}
                onChange={this.handleInputTranslationChange}
                placeholder={t('FormExerciseCreate.label-11')}
                autoComplete="false"
                maxLength={191}
                isTranslatable={true}
              />
            </div>
          </div>

          <div className="row-container">
            <label>{t('FormExerciseCreate.label-9')}</label>

            <div className="left-indent">
              <Input
                type="text"
                name="recommendations"
                value={form.recommendations || {}}
                onChange={this.handleInputTranslationChange}
                placeholder={t('FormExerciseCreate.label-9')}
                autoComplete="false"
                maxLength={191}
                isTranslatable={true}
              />
            </div>
          </div>

          <div className="row-container">
            <StepsContainer steps={form.steps} handleStepsUpdate={this.handleStepsUpdate} />
          </div>

          <div className="row-container">
            <label>{t('FormExerciseCreate.label-10')}</label>

            <div className="left-indent">
              <input
                type="text"
                name="suggested_by"
                value={form.suggested_by}
                onChange={this.handleInputChange}
                maxLength={191}
              />
            </div>
          </div>

          <div className="row-container">
            <Button className="cancel-button" onClick={this.handleCancel}>
              {t('GLOBAL.cancel')}
            </Button>

            <Button type="submit" onClick={() => {}} status={submitStatus}>
              {t('GLOBAL.submit')}
            </Button>

            <ErrorMessage status={submitStatus} />
          </div>
        </FormGeneric>
      </div>
    )
  }
}

FormExerciseCreate.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.object,
  submitStatus: PropTypes.object,
}

FormExerciseCreate.defaultProps = {
  initialData: {
    objective: {},
    count: {},
    level: {},
    position: {},
  },
  submitStatus: {},
}

export default withTranslation()(FormExerciseCreate)
