import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import Button from 'components/Button'
import ExerciseCard from 'components/ExerciseCard'
import Pagination from 'components/Pagination'
import SearchFilterBar from 'components/SearchFilterBar'

import { SET_ACTIVE_MODAL } from 'store/modules/Modal/actions.js'
import {
  exerciseSearchCreator,
  updateExerciseSearchFilters,
} from 'store/modules/Exercise/actions.js'
import { FETCH_EXERCISES_RESOURCES_REQUEST } from 'store/modules/Resource/actions.js'

import './style.css'
import { ADMIN_ROLE } from '../../constants/constants'

const statusRefSearch = 'PageExercises_search'

class PageExercises extends Component {
  constructor() {
    super()
    this.state = {
      searchData: {},
    }
    this.fetchExercises = debounce(this.fetchExercises.bind(this), 400)
  }

  componentDidMount() {
    const { searchFilters } = this.props
    this.props.dispatch(
      exerciseSearchCreator({
        ...searchFilters,
        ...searchFilters.multiSelect,
        search: searchFilters.search_text,
        statusRef: statusRefSearch,
      })
    )

    this.props.dispatch({ type: FETCH_EXERCISES_RESOURCES_REQUEST })
  }

  fetchExercises(data) {
    if (!data) return

    this.setState(
      {
        searchData: data,
      },
      () => {
        this.props.dispatch(
          exerciseSearchCreator({
            ...data,
            ...data.multiSelect,
            search: data.search_text,
            statusRef: statusRefSearch,
          })
        )
      }
    )
  }

  handleCreateExerciseClick = () => {
    this.props.dispatch({ type: SET_ACTIVE_MODAL, payload: 'create-exercise' })
  }

  searchFilterOptions = () => {
    const { t } = this.props

    const resources = this.props.resources || {}
    let toReturn = []

    if (resources.equipment) {
      toReturn.push({
        label: t('PageExercises.equipment'),
        name: 'equipment',
        value: 'equipment',
        options: resources.equipment.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }
    if (resources.muscles) {
      toReturn.push({
        label: t('PageExercises.muscles'),
        name: 'muscles',
        value: 'muscles',
        options: resources.muscles.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.objectives) {
      toReturn.push({
        label: t('PageExercises.objectives'),
        name: 'objectives',
        value: 'objectives',
        options: resources.objectives.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.positions) {
      toReturn.push({
        label: t('PageExercises.positions'),
        name: 'positions',
        value: 'positions',
        options: resources.positions.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    return toReturn
  }
  updateStoredFilters = (filters) => {
    this.props.dispatch(updateExerciseSearchFilters({ ...filters }))
  }

  setExerciseResources = (exercise) => {
    const { objectives, positions, counts } = this.props.resources
    const { objective_id, position_id, count_id, ...rest } = exercise

    const objective = objectives.find((obj) => obj.id === objective_id) || {}
    const position = positions.find((position) => position.id === position_id) || {}
    const count = counts.find((count) => count.id === count_id) || {}

    return { ...rest, objective, position, count }
  }

  render() {
    const { t, searchFilters, role, statuses, searchResults } = this.props
    const { searchData } = this.state

    return (
      <div className="page page-exercises">
        <div className="page-exercises-title">{t('PageExercises.title')}</div>

        <div>
          <Link to="/exercises/create">
            <Button onClick={() => {}}>{t('PageExercises.button-1')}</Button>
          </Link>

          {role === ADMIN_ROLE && (
            <Link to="/exercises/requests">
              <Button onClick={() => {}}>{t('PageExercises.button-2')}</Button>
            </Link>
          )}
        </div>

        <SearchFilterBar
          filterTitle={''}
          filterOptions={this.searchFilterOptions()}
          handleSearchFormSubmit={this.fetchExercises}
          onfilterChange={this.fetchExercises}
          filterType={'multi-select'}
          showLoadingIndicator={(statuses[statusRefSearch] || {}).state === 'busy'}
          storedFilters={searchFilters}
          updateStoredFilters={this.updateStoredFilters}
        />

        {searchResults.exercises?.map((item, index) => {
          const exercise = this.setExerciseResources(item)
          return (
            <Link to={`/exercise/${item.id}`} key={`exercise_${index}`}>
              <ExerciseCard item={exercise} />
            </Link>
          )
        })}

        <Pagination
          searchData={searchData}
          limit={searchResults.limit}
          offset={searchResults.offset}
          total={searchResults.total_count}
          fetchData={this.fetchExercises}
        />
      </div>
    )
  }
}

PageExercises.defaultProps = {
  searchFilters: {},
  searchResults: [],
  role: '',
  resources: {},
  statuses: {},
}

const mapStateToProps = (state) => ({
  searchFilters: state.Exercise.searchFilters,
  searchResults: state.Exercise.searchResults,
  role: state.Account.role,
  resources: state.Resource.exercises,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PageExercises))
