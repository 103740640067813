import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getLocale, setLocale } from 'services/localisation'

import userHasStaffRole from 'helpers/user-has-staff-role'

import { staffLanguagesCode } from 'constants/constants'

import './style.css'

const baseClass = 'language-picker'

class LanguagePicker extends Component {
  constructor() {
    super()

    this.state = {}

    this.handleChange = this.handleChange.bind(this)
    this.getLanguagesFromRole = this.getLanguagesFromRole.bind(this)
  }

  currentLanguage() {
    return getLocale()
  }

  handleChange(e) {
    let { value } = e.target

    value = JSON.parse(value)

    setLocale(value.code)
    this.props.handleOnChange(value.id)
  }

  getLanguagesFromRole() {
    const { roles } = this.props.account
    const isStaff = userHasStaffRole(roles)

    if (isStaff) {
      const filteredLanguages = this.props.languages.filter((el) => {
        return staffLanguagesCode.indexOf(el.code) !== -1
      })
      return filteredLanguages
    }
    return this.props.languages
  }

  render() {
    return (
      <div className={`${baseClass}`}>
        <select onChange={this.handleChange}>
          {(this.getLanguagesFromRole() || []).map((item, index) => {
            return (
              <option
                value={JSON.stringify(item)}
                key={`language_${index}`}
                selected={item.code === this.currentLanguage()}
              >
                {item.name}
              </option>
            )
          })}
        </select>
      </div>
    )
  }
}

LanguagePicker.propTypes = {
  handleOnChange: PropTypes.func,
}

LanguagePicker.defaultProps = {
  handleOnChange: () => {},
}

const mapStateToProps = (state) => ({
  account: state.Account.user,
  statuses: state.Status.statuses,
  languages: state.Account.languages,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePicker)
