import * as equipmentActions from 'store/modules/Equipment/actions.js'

const initialState = {
  // searchResults: [],
  equipments: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // case SET_EQUIPMENTS_SEARCH_RESULTS:
    //   return {
    //     ...state,
    //     searchResults: action.payload,
    //   };

    case equipmentActions.UPDATE_EQUIPMENT_CACHE_SET:
      return {
        ...state,
        equipments: action.payload,
      }

    default:
      return state
  }
}
