import { takeEvery, put, select } from 'redux-saga/effects'

import * as ApiService from 'services/api'

import { incrementStatusCreator, decrementStatusCreator } from 'store/modules/Status/actions.js'
import { ProtectedCall } from 'services/protected.api'

import * as emailActions from 'store/modules/Email/actions.js'

/*************************************************/
/** Selectors **/
const allSelector = (state) => {
  return JSON.parse(JSON.stringify(state.Email.emails))
}

/*************************************************/
/** Sagas **/
export default function* sagaWatcher() {
  yield takeEvery(emailActions.GET_EMAILS_TEMPLATE, getEmailsTemplateSaga)

  yield takeEvery(emailActions.UPDATE_EMAILS_CACHE, updateCacheSaga)
}

function* getEmailsTemplateSaga(data) {
  try {
    yield put(
      incrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Get emails start' })
    )

    let result = yield ProtectedCall(ApiService.EmailsTemplateGet, data.payload)

    yield put({ type: emailActions.UPDATE_EMAILS_CACHE, payload: result.data.data })

    yield put({ type: emailActions.UPDATE_EMAIL_SEARCH_RESULTS, payload: result.data.data })

    yield put(
      decrementStatusCreator({ statusRef: data.payload.statusRef, message: 'Get emails finsihed' })
    )
  } catch (e) {
    console.log('*** Error caught in getEmailsTemplateSaga ***')
    yield put(
      decrementStatusCreator({
        statusRef: data.payload.statusRef,
        message: 'Get emails start error',
        state: 'error',
        data: e.response.data,
      })
    )
  }
}

function* updateCacheSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) {
    return
  }

  let emails = yield select(allSelector) || []

  if (data.delete) {
    data.payload.map((item) => {
      let id = item
      if (typeof id === 'object') {
        id = id.id
      }

      delete emails[id]

      return null
    })
  } else {
    data.payload.map((item) => {
      if (!item.id) return

      emails[item.id] = item
      return null
    })
  }

  yield put({ type: emailActions.UPDATE_EMAILS_CACHE_SET, payload: emails })
}
