import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import Button from 'components/Button'
import './style.css'

class InviteInfo extends Component {
  render() {
    const { t } = this.props

    return (
      <div className="profile-modal-invite-info form-profile">
        <div className="data-row">
          <label>{t('ModalProfile.InviteInfo.label-1')}</label>
        </div>

        <div className="data-row data-row-half">
          <label>{t('ModalProfile.InviteInfo.label-2')}</label>
          <input
            type="email"
            name="emails"
            value={this.props.userData.emails || ''}
            onChange={this.props.inputChange}
          />
        </div>

        <div>
          <Button onClick={this.props.cancelClick}>{t('GLOBAL.cancel')}</Button>

          <Button onClick={this.props.submitClick}>{t('GLOBAL.submit')}</Button>
        </div>
      </div>
    )
  }
}

export default withTranslation()(InviteInfo)
