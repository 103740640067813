import React, { Component } from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import getTranslations from 'helpers/get-translations'

import FormModal from 'components/FormModal'
import Button from 'components/Button'
import CloseButton from 'components/CloseButton'
import AdminTable from 'components/AdminTable'
import Input from 'containers/Input'
import isEqual from 'lodash/isEqual'

import './style.css'

const baseClass = 'form-challenge-create-exercises-posture-selector'

class PostureSelector extends Component {
  constructor() {
    super()

    this.state = {
      form: {
        question: {},
      },
      postures: [],
      selectedPosture: null,
    }
  }

  componentDidMount() {
    this.setInitialData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.exercise && !isEqual(prevProps.exercise, this.props.exercise)) {
      this.setInitialData()
    }
  }

  setInitialData = () => {
    const { exercise } = this.props
    const { question, translations, test_answers } = exercise

    const questionTranslations =
      Object.keys(question || []).length > 1 ? question : getTranslations(translations, 'question')

    this.setState(
      {
        postures: test_answers || [],
        form: {
          question: questionTranslations,
        },
      },
      () => console.log(this.state)
    )
  }

  postureSelectChange = (ev) => {
    const { value } = ev.target
    if (value === '0') return this.setState({ selectedPosture: null })
    this.setState({ selectedPosture: parseInt(value, 10) })
  }

  onAddPostureClick = () => {
    if (this.state.selectedPosture === null) return
    const postures = [...this.state.postures]

    postures.push({ posture_id: this.state.selectedPosture, points: this.state.points })

    this.setState({
      ...this.state,
      postures,
      selectedPosture: '',
      points: '',
    })
  }

  onSubmit = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
    this.props.onSubmit(this.state.postures, this.state.form.question)
  }

  postureFromId = (id) => {
    let toReturn = {}

    this.props.postures.map((item) => {
      if (item.id === id) toReturn = item
    })

    return toReturn
  }

  renderTable = () => {
    const { t } = this.props

    const headers = {
      columns: [
        { value: t('FormTestCreate.Exercises.PostureSelector.subtitle-1') },
        { value: t('FormTestCreate.Exercises.PostureSelector.subtitle-2') },
      ],
    }

    let tableRows = []

    ;(this.state.postures || []).map((item) => {
      let columns = [{ value: this.postureFromId(item.posture_id).name }, { value: item.points }]

      tableRows.push({ columns })

      return null
    })

    return <AdminTable headerRow={headers} rows={tableRows} />
  }

  isDisabled = () => {
    if ((this.state.postures || []).length > 0) {
      const question = (this.state.form.question || {}).fr || ''
      if (question.length) {
        return false
      }
    }

    return true
  }

  handleQuestionChange = (event, values) => {
    const { target } = event
    const { name } = target

    if (name.includes('.')) {
      let splitName = name.split('.')
      this.setState({
        form: {
          ...this.state.form,
          [splitName[0]]: values,
        },
      })
    }
  }

  handlePointsChange = (e) => {
    const { value } = e.target
    this.setState({ points: value })
  }

  render() {
    const { t, handleClose, postures } = this.props
    const { form, points } = this.state
    const { question } = form

    return (
      <ReactModal
        className={`${baseClass} modal-content`}
        isOpen={true}
        onRequestClose={handleClose}
        shouldCloseOnOverlayClick={true}
        overlayClassName="modal-overlay"
      >
        <FormModal id="profile-form" className="profile-form posture-form" onSubmit={this.onSubmit}>
          <CloseButton onClick={handleClose} />

          <div className="row-container">
            <label>{t('FormTestCreate.Exercises.PostureSelector.label-1')}</label>
            <Input
              type="text"
              name="question"
              value={question || {}}
              onChange={this.handleQuestionChange}
              placeholder="Titre"
              autoComplete="false"
              required={true}
              maxLength={191}
              isTranslatable={true}
            />
          </div>

          <div>{this.renderTable()}</div>

          <select onChange={this.postureSelectChange}>
            <option value={0} key={`index`}>
              ---
            </option>
            {postures.map((item, index) => (
              <option value={item.id} key={`postures_${index}`}>
                {item.name}
              </option>
            ))}
          </select>

          <fieldset className="inline-block width-20">
            <label>{t('FormTestCreate.Exercises.PostureSelector.label-2')}</label>
            <input
              type="number"
              name="points"
              value={points || ''}
              onChange={this.handlePointsChange}
              placeholder="Points"
              autoComplete="false"
            />
          </fieldset>

          <Button type="button" onClick={this.onAddPostureClick}>
            {' '}
            {t('FormTestCreate.Exercises.PostureSelector.button')}{' '}
          </Button>

          <p>{t('FormTestCreate.Exercises.PostureSelector.text')}</p>

          <Button type="submit" disabled={this.isDisabled()} onClick={() => {}}>
            {' '}
            {t('GLOBAL.save')}{' '}
          </Button>
        </FormModal>
      </ReactModal>
    )
  }
}

PostureSelector.propTypes = {
  postures: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.isRequired,
}

PostureSelector.defaultProps = {
  postures: [],
}

export default withTranslation()(PostureSelector)
