import * as statusActions from 'store/modules/Status/actions.js'

const initialState = {
  statuses: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case statusActions.SET_STATUSES:
      return {
        ...state,
        statuses: action.payload,
      }

    default:
      return state
  }
}
