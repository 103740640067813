import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { programSearchCreator, updateProgramSearchFilters } from 'store/modules/Program/actions.js'
import { Link } from 'react-router-dom'
import AdminTable from 'components/AdminTable'

import Pagination from 'components/Pagination'
import SearchFilterBar from 'components/SearchFilterBar'

import './style.css'

const statusRefSearch = 'PageProgramsRequests-search'

class PageProgramsRequests extends Component {
  constructor() {
    super()
    this.state = {
      searchData: {},
    }

    this.fetchPrograms = this.fetchPrograms.bind(this)
    this.searchFilteroptions = this.searchFilteroptions.bind(this)
    this.updateStoredFilters = this.updateStoredFilters.bind(this)
  }

  componentDidMount() {
    const { searchFilters } = this.props.theState.Program
    if (
      Object.keys(searchFilters).length > 0 &&
      (searchFilters.search_text ||
        (searchFilters.multiSelect && searchFilters.multiSelect.levels.length))
    ) {
      this.props.dispatch(
        programSearchCreator({
          ...searchFilters,
          ...searchFilters.multiSelect,
          search: searchFilters.search_text,
          statusRef: statusRefSearch,
          public: false,
          getphy: false,
        })
      )
    } else {
      this.props.dispatch(
        programSearchCreator({ getphy: false, public: false, statusRef: statusRefSearch })
      )
    }
  }

  fetchPrograms(data) {
    if (!data) return

    this.setState(
      {
        searchData: data,
      },
      () => {
        this.props.dispatch(
          programSearchCreator({
            ...data,
            ...data.multiSelect,
            search: data.search_text,
            public: false,
            getphy: false,
            statusRef: statusRefSearch,
          })
        )
      }
    )
  }

  getSessionsCount(cycles) {
    let toReturn = 0

    cycles.map((item) => {
      toReturn += item.cycle_sessions.length
    })

    return toReturn
  }

  buildTableRows() {
    const programs = this.props.searchResults.programs

    if (!programs) {
      return []
    }

    return (programs || []).map((item) => {
      return {
        columns: [
          { value: item.getphy ? '✔' : '' },
          { value: <Link to={`/program/${item.id}`}> {item.name} </Link> },
          { value: (item.level || {}).name },
          { value: item.sessions_count },
          { value: item.challenges_count },
          { value: item.cycles_count },
        ],
      }
    })
  }

  getHeaderRow() {
    const { t } = this.props

    return {
      columns: [
        // { value: t('PageProgramsRequests.subtitle-1') },
        { value: t('PageProgramsRequests.subtitle-2') },
        { value: t('PageProgramsRequests.subtitle-3') },
        { value: t('PageProgramsRequests.subtitle-4') },
        { value: t('PageProgramsRequests.subtitle-5') },
        { value: t('PageProgramsRequests.subtitle-6') },
        { value: t('PageProgramsRequests.subtitle-7') },
      ],
    }
  }

  searchFilteroptions() {
    const { t } = this.props

    const resources = this.props.resources
    let toReturn = []

    if (resources.levels) {
      toReturn.push({
        label: t('PagePrograms.SearchFilters.levels'),
        name: 'levels',
        value: 'levels',
        options: resources.levels.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    return toReturn
  }

  updateStoredFilters(filters) {
    this.props.dispatch(updateProgramSearchFilters({ ...filters }))
  }

  render() {
    const { t } = this.props

    return (
      <div className="page-programs-requests">
        <div className="page-programs-requests-title">{t('PageProgramsRequests.title')}</div>
        <SearchFilterBar
          filterTitle={''}
          filterOptions={this.searchFilteroptions()}
          handleSearchFormSubmit={this.fetchPrograms}
          onfilterChange={this.fetchPrograms}
          filterType={'multi-select'}
          showLoadingIndicator={(this.props.statuses[statusRefSearch] || {}).state === 'busy'}
          updateStoredFilters={this.updateStoredFilters}
          storedFilters={this.props.theState.Program.searchFilters}
        />
        <AdminTable headerRow={this.getHeaderRow()} rows={this.buildTableRows()} />
        <Pagination
          searchData={this.state.searchData}
          limit={this.props.searchResults.limit}
          offset={this.props.searchResults.offset}
          total={this.props.searchResults.total_count}
          fetchData={this.fetchPrograms}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  activeModal: state.Modal.activeModal,
  searchResults: state.Program.requestsSearchResults,
  resources: state.Resource.programs,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PageProgramsRequests))
