import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import ChallengeCard from '../../components/ChallengeCard'
import Pagination from 'components/Pagination'
import SearchFilterBar from 'components/SearchFilterBar'

import {
  challengeSearchCreator,
  updateChallengeSearchFilters,
} from 'store/modules/Challenge/actions.js'
import { FETCH_CHALLENGES_RESOURCES_REQUEST } from 'store/modules/Resource/actions.js'

import './style.css'

const statusRefSearch = 'PageChallengesRequests-search'

class PageChallengesRequests extends Component {
  constructor() {
    super()
    this.state = {
      searchData: {},
    }

    this.fetchChallenges = this.fetchChallenges.bind(this)
    this.updateStoredFilters = this.updateStoredFilters.bind(this)
  }

  componentDidMount() {
    const { searchFilters } = this.props.theState.Challenge
    this.props.dispatch(
      challengeSearchCreator({
        ...searchFilters,
        ...searchFilters.multiSelect,
        search: searchFilters.search_text,
        statusRef: statusRefSearch,
        pending: true,
      })
    )
    this.props.dispatch({ type: FETCH_CHALLENGES_RESOURCES_REQUEST })
  }

  fetchChallenges(data) {
    if (!data) return

    this.setState(
      {
        searchData: data,
      },
      () => {
        this.props.dispatch(
          challengeSearchCreator({
            ...data,
            ...data.multiSelect,
            search: data.search_text,
            pending: true,
            statusRef: statusRefSearch,
          })
        )
      }
    )
  }

  searchFilteroptions() {
    const { t } = this.props

    const resources = this.props.resources
    let toReturn = []

    if (resources.objectives) {
      toReturn.push({
        label: t('PageChallenges.SearchFilters.objectives'),
        name: 'objectives',
        value: 'objectives',
        options: resources.objectives.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.muscles) {
      toReturn.push({
        label: t('PageChallenges.SearchFilters.muscles'),
        name: 'muscles',
        value: 'muscles',
        options: resources.muscles.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.positions) {
      toReturn.push({
        label: t('PageChallenges.SearchFilters.positions'),
        name: 'positions',
        value: 'positions',
        options: resources.positions.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.equipment) {
      toReturn.push({
        label: t('PageChallenges.SearchFilters.equipment'),
        name: 'equipment',
        value: 'equipment',
        options: resources.equipment.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    if (resources.levels) {
      toReturn.push({
        label: t('PageChallenges.levels'),
        name: 'levels',
        value: 'levels',
        options: resources.levels.map((item) => {
          return { value: item.id, label: item.name }
        }),
      })
    }

    return toReturn
  }
  updateStoredFilters(filters) {
    this.props.dispatch(updateChallengeSearchFilters({ ...filters }))
  }
  render() {
    const { t } = this.props

    return (
      <div className="page-challenges-requests">
        <div className="page-challenges-requests-title">{t('PageChallengesRequests.title')}</div>

        <SearchFilterBar
          filterTitle={''}
          filterOptions={this.searchFilteroptions()}
          handleSearchFormSubmit={this.fetchChallenges}
          onfilterChange={this.fetchChallenges}
          filterType={'multi-select'}
          showLoadingIndicator={(this.props.statuses[statusRefSearch] || {}).state === 'busy'}
          storedFilters={this.props.theState.Challenge.searchFilters}
          updateStoredFilters={this.updateStoredFilters}
        />

        {this.props.searchResults &&
          this.props.searchResults.challenges &&
          this.props.searchResults.challenges.map((item, index) => {
            return (
              <Link to={`/challenge/${item.id}`} key={`challenges_reults_${index}`}>
                <ChallengeCard item={item} />
              </Link>
            )
          })}

        <Pagination
          searchData={this.state.searchData}
          limit={this.props.searchResults.limit}
          offset={this.props.searchResults.offset}
          total={this.props.searchResults.total_count}
          fetchData={this.fetchChallenges}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  activeModal: state.Modal.activeModal,
  searchResults: state.Challenge.requestssearchResults,
  resources: state.Resource.challenges,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(PageChallengesRequests)
)
