export function saveToLocalStorage(data) {
  localStorage.setItem('getphy_role', data)
}

export function getCurrentRole() {
  return localStorage.getItem('getphy_role')
}

export function canBeCoach(roles) {
  return roles.indexOf('coach') >= 0
}

export function canBeManager(roles) {
  return roles.indexOf('manager') >= 0
}

export function canBeAdmin(roles) {
  return roles.indexOf('admin_referensiel') >= 0
}

export function getDefaultRole(roles) {
  let toReturn = null

  if (roles && roles.length > 0) {
    if (canBeCoach(roles)) {
      toReturn = 'coach'
    }
    if (canBeManager(roles)) {
      toReturn = 'manager'
    }
    if (canBeAdmin(roles)) {
      toReturn = 'admin_referensiel'
    }
  }

  return toReturn
}

export function clearRole() {
  localStorage.removeItem('getphy_role')
}
