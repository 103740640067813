import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import AdminTable from 'components/AdminTable'
import Button from 'components/Button'

import './style.css'

const baseClass = 'form-test-create-levels-selector'

class LevelsSelector extends Component {
  constructor() {
    super()

    this.state = {
      form: {},
      levels: [],
    }

    this.renderTable = this.renderTable.bind(this)
    this.handleAddLevel = this.handleAddLevel.bind(this)
    this.levelSelectChange = this.levelSelectChange.bind(this)
    this.getLevelName = this.getLevelName.bind(this)
    this.levelPointsChange = this.levelPointsChange.bind(this)
    this.alreadyLevelSelected = this.alreadyLevelSelected.bind(this)
  }

  renderTable() {
    const { t } = this.props

    const headers = {
      columns: [
        { value: t('FormTestCreate.LevelsSelector.subtitle-1') },
        { value: t('FormTestCreate.LevelsSelector.subtitle-2') },
      ],
    }

    let tableRows = []

    ;(this.props.selectedLevels || []).map((item) => {
      let columns = [
        { value: this.getLevelName(item.id) },
        {
          value: (
            <input
              type="number"
              value={item.min_points || 0}
              onChange={(e) => this.levelPointsChange(e, item)}
            />
          ),
        },
      ]

      tableRows.push({ columns })

      return null
    })

    return <AdminTable headerRow={headers} rows={tableRows} />
  }

  levelPointsChange(ev, level) {
    const { value } = ev.target
    let levels = [...this.props.selectedLevels]
    const index = levels.indexOf(level)
    levels[index].min_points = value

    this.setState(
      {
        levels,
      },
      () => this.props.handleLevelsChange(levels)
    )
  }

  levelSelectChange(ev) {
    const { value } = ev.target
    this.setState({
      form: {
        id: value,
      },
    })
  }

  getLevelName(id) {
    let toReturn = ''
    this.props.levels.map((item) => {
      if (parseInt(item.id, 10) === parseInt(id, 10)) toReturn = item.name
      return
    })

    return toReturn
  }

  handleAddLevel() {
    if (!this.state.form.id) return

    let levels = [...this.props.selectedLevels]
    levels.push({ ...this.state.form, min_points: 0 })

    this.setState(
      {
        form: {},
        levels,
      },
      () => this.props.handleLevelsChange(levels)
    )
  }

  alreadyLevelSelected(id) {
    let alreadySelected = false

    this.props.selectedLevels.map((item) => {
      if (parseInt(item.id, 10) === parseInt(id, 10)) alreadySelected = true

      return
    })

    return alreadySelected
  }

  render() {
    const { t } = this.props

    return (
      <div className={`${baseClass}`}>
        <div className="row-container">
          <div className="">
            <label>{t('FormTestCreate.LevelsSelector.title')}</label>

            <div>{this.renderTable()}</div>

            <div className="row-container">
              <div className="inline-block">
                <div className="left-indent">
                  <select
                    name="id"
                    autoComplete="off"
                    onChange={this.levelSelectChange}
                    value={this.state.form.id || ''}
                  >
                    <option value="" disabled="disabled">
                      -------
                    </option>
                    {this.props.levels.map((item, index) => {
                      if (!this.alreadyLevelSelected(item.id)) {
                        return (
                          <option value={item.id} key={`levelsB_${index}`}>
                            {item.name}
                          </option>
                        )
                      }

                      return
                    })}
                  </select>
                </div>
              </div>

              <div className="inline-block left-indent">
                <Button type="button" onClick={this.handleAddLevel}>
                  {' '}
                  {t('FormTestCreate.LevelsSelector.button')}{' '}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

LevelsSelector.propTypes = {
  levels: PropTypes.array,
  selectedLevels: PropTypes.array,
}

LevelsSelector.defaultProps = {
  levels: [],
  selectedLevels: [],
}

export default withTranslation()(LevelsSelector)
