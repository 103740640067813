import React, { Component } from 'react'
import { connect } from 'react-redux'

import FormSessionCreate from 'components/FormSessionCreate'

import { clearStatus } from 'store/modules/Status/actions.js'

import { FETCH_SESSIONS_RESOURCES_REQUEST } from 'store/modules/Resource/actions.js'

import './style.css'
import { createSessionCreator } from '../../store/modules/Session/actions'

const statusRef = 'PageSessionsCreate'

class PageSessionsCreate extends Component {
  constructor() {
    super()
    this.state = {
      form: {
        name: {},
        description: {},
        recommendations: {},
        rest_time: 30,
        public_request: false,
        objective_id: '',
        level_id: '',
        target_audience_id: '',
      },
      exercises: [],
    }
  }

  //TODO: Getting called on app initialisation. Why? Should only get called on modal open.
  componentDidMount() {
    this.props.dispatch(clearStatus({ statusRef: statusRef }))
    this.props.dispatch({ type: FETCH_SESSIONS_RESOURCES_REQUEST })
  }

  componentDidUpdate(prevProps) {
    const { statuses } = this.props
    if (
      statuses[statusRef] &&
      statuses[statusRef].state === 'finished' &&
      prevProps.statuses[statusRef].state !== 'finished'
    ) {
      const { newId } = this.props.statuses[statusRef].data || {}

      setTimeout(() => {
        this.props.history.push(`/session/${newId}`)
      }, 700)
    }
  }

  handleSessionCreated = () => {
    const id = this.props.statuses[statusRef].data?.newId

    setTimeout(() => {
      this.props.history.push(`/session/${id}`)
    }, 700)
  }

  handleSubmit = () => {
    const { exercises, form } = this.state
    const session_exercises = exercises.map((item) => {
      const { series, repetitions, duration, rest_time, exercise, position } = item
      return {
        series,
        repetitions,
        duration,
        rest_time,
        exercise_id: exercise.id,
        position,
      }
    })

    this.props.dispatch(
      createSessionCreator({
        ...form,
        session_exercises,
        statusRef: statusRef,
      })
    )
  }

  handleExercisesUpdate = (exercises) => {
    this.setState({
      exercises,
    })
  }

  handleCheckboxGroupChange = (event) => {
    const target = event.target
    const checked = target.checked
    const name = target.name

    if (name.includes('[')) {
      let openBracketIndex = name.indexOf('[')
      let closeBracketIndex = name.indexOf(']')
      let bracketLength = closeBracketIndex - openBracketIndex - 1

      let beforeBracketValue = name.substr(0, openBracketIndex)
      let bracketValue = name.substr(openBracketIndex + 1, bracketLength)

      let currentStateArray = this.state.form[beforeBracketValue] || []

      let newArray = [...currentStateArray]

      if (checked) {
        if (newArray.indexOf(bracketValue) < 0) {
          newArray.push(bracketValue)
        }
      } else {
        let index = newArray.indexOf(bracketValue)
        newArray.splice(index, 1)
      }

      this.setState({
        form: {
          ...this.state.form,
          [beforeBracketValue]: newArray,
        },
      })
    }
  }

  handleInputChange = (event) => {
    const target = event.target

    let value = target.type === 'checkbox' ? target.checked : target.value
    if (value === 'true' || value === 'false') {
      value = value === 'true'
    }

    const name = target.name

    if (name.includes('.')) {
      //TODO: ALlows language to be specified in the input name.
      //Look at a more scalable way of doing this.
      let splitName = name.split('.')
      this.setState({
        form: {
          ...this.state.form,
          [splitName[0]]: {
            ...this.state.form[splitName[0]],
            [splitName[1]]: value,
          },
        },
      })
    } else {
      this.setState({
        form: {
          ...this.state.form,
          [name]: value,
        },
      })
    }
  }

  handleInputTranslationChange = (event, values) => {
    const { target } = event
    const { name } = target

    if (name.includes('.')) {
      let splitName = name.split('.')
      this.setState({
        form: {
          ...this.state.form,
          [splitName[0]]: values,
        },
      })
    }
  }

  render() {
    const { resources, statuses } = this.props
    const { form, exercises } = this.state
    return (
      <div className="page-sessions-create">
        <FormSessionCreate
          form={form}
          exercises={exercises}
          resources={resources}
          handleSubmit={this.handleSubmit}
          submitStatus={statuses[statusRef]}
          handleExercisesUpdate={this.handleExercisesUpdate}
          handleCheckboxGroupChange={this.handleCheckboxGroupChange}
          handleInputChange={this.handleInputChange}
          handleInputTranslationChange={this.handleInputTranslationChange}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theState: state,
  resources: state.Resource.sessions,
  statuses: state.Status.statuses,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PageSessionsCreate)
