export const FETCH_EXERCISES_RESOURCES_REQUEST = 'FETCH_EXERCISES_RESOURCES_REQUEST'
export const FETCH_SESSIONS_RESOURCES_REQUEST = 'FETCH_SESSIONS_RESOURCES_REQUEST'
export const FETCH_CHALLENGES_RESOURCES_REQUEST = 'FETCH_CHALLENGES_RESOURCES_REQUEST'
export const FETCH_PROGRAMS_RESOURCES_REQUEST = 'FETCH_PROGRAMS_RESOURCES_REQUEST'

export const GET_TASKS_RESOURCES = 'GET_TASKS_RESOURCES'

export const GET_COUNTRIES = 'resource/GET_COUNTRIES'

export const UPDATE_RESOURCE_CACHE_SET = 'UPDATE_RESOURCE_CACHE_SET'

export function getCountries(role) {
  return { type: GET_COUNTRIES, payload: role }
}

export function getTasksResources(payload) {
  return { type: GET_TASKS_RESOURCES, payload: payload }
}
