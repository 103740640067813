import React, { Component } from 'react'
import Select from 'react-select'
import { withTranslation } from 'react-i18next'

import ConfirmDeleteMessage from 'components/ConfirmDeleteMessage'
import AdminTable from 'components/AdminTable'
import Icon from 'components/Icon'
import Button from 'components/Button'
import CustomPrograms from './components/CustomPrograms'

import './style.css'

class Programs extends Component {
  constructor() {
    super()

    this.state = {
      openModalConfirmationDeleteProgram: null,
      form: {},
    }

    this.getProgramsHeaderRow = this.getProgramsHeaderRow.bind(this)
    this.buildProgramsTableRows = this.buildProgramsTableRows.bind(this)
    this.getProgramsNotInAssessments = this.getProgramsNotInAssessments.bind(this)
    this.handleOpenModalConfirmationDeleteProgram = this.handleOpenModalConfirmationDeleteProgram.bind(
      this
    )
    this.handleCloseModalConfirmationDeleteProgram = this.handleCloseModalConfirmationDeleteProgram.bind(
      this
    )
    this.handleRemoveProgramsFromAssessments = this.handleRemoveProgramsFromAssessments.bind(this)
  }

  getProgramsNotInAssessments() {
    let difference = (this.props.allPrograms || []).filter((x) => {
      const found = (this.props.assessmentPrograms || []).filter((y) => x.id === y.id)
      return found.length <= 0
    })

    return (difference || []).map((item) => {
      return {
        value: item.id,
        label: `${item.name} || ${(item.level || {}).name} ${
          item.program_objective ? `|| ${item.program_objective.name}` : ''
        }`,
      }
    })
  }

  getProgramsHeaderRow() {
    const { t } = this.props

    return {
      columns: [
        { value: t('PageAssessments.ModalCreateAssessment.subtitle-name') },
        { value: t('PageAssessments.ModalCreateAssessment.subtitle-level') },
        { value: t('PageAssessments.ModalCreateAssessment.subtitle-goal') },
        { value: t('GLOBAL.empty') },
      ],
    }
  }

  buildProgramsTableRows() {
    return (this.props.assessmentPrograms || []).map((item, index) => {
      return {
        columns: [
          { value: item.name },
          { value: (item.level || {}).name },
          { value: (item.program_objective || {}).name },
          {
            value: (
              <Button
                className="narrow"
                onClick={() => this.handleOpenModalConfirmationDeleteProgram(item, index)}
              >
                <Icon className="small" src={'trash.svg'} />
              </Button>
            ),
          },
        ],
      }
    })
  }

  handleRemoveProgramsFromAssessments(item) {
    this.props.handleRemoveProgramsFromAssessments(item)
    this.handleCloseModalConfirmationDeleteProgram()
  }

  handleOpenModalConfirmationDeleteProgram(item, index) {
    this.setState({
      openModalConfirmationDeleteProgram: {
        ...item,
        index,
      },
    })
  }

  handleCloseModalConfirmationDeleteProgram() {
    this.setState({
      openModalConfirmationDeleteProgram: null,
    })
  }

  render() {
    const { t, programObjectives, formLevelObjectives, staticProgramObjectives } = this.props

    return (
      <div className="row-container">
        <Select
          onChange={(values) => this.props.handleSelectChange('program_id', values)}
          isMulti={false}
          value={this.state.form.program_id}
          name={'program_id'}
          options={this.getProgramsNotInAssessments()}
          className="search-filter-bar-multi-select search-program"
          classNamePrefix={'react-select'}
          placeholder={t('GLOBAL.search')}
        />

        <Button
          className="button-plus"
          onClick={this.props.handleAddProgramsIntoAssessments}
          disabled={this.props.program_id ? false : true}
        >
          +
        </Button>

        <AdminTable headerRow={this.getProgramsHeaderRow()} rows={this.buildProgramsTableRows()} />

        {this.props.canAssignCustomPrograms && (
          <div className="row-container">
            <div className="left-indent">
              <input
                type="checkbox"
                name="custom_programs_assignment"
                onChange={this.props.handleInputChangeConfiguration}
                maxLength={191}
                checked={
                  this.props.custom_programs_assignment === true ||
                  this.props.custom_programs_assignment === 1
                }
              />

              <label>
                {t('PageAssessments.ModalCreateAssessment.Programs.custom_programs_assignment')}
              </label>
            </div>

            {this.props.custom_programs_assignment && (
              <CustomPrograms
                staticProgramObjectives={staticProgramObjectives}
                programObjectives={programObjectives}
                formLevelObjectives={formLevelObjectives}
                custom_programs={this.props.custom_programs}
                custom_program_objective_id={this.props.custom_program_objective_id}
                handleInputChange={this.props.handleInputChange}
                handleSelectChange={this.props.handleSelectChange}
                handleAddCustomProgramObjectivesIntoAssessments={
                  this.props.handleAddCustomProgramObjectivesIntoAssessments
                }
                handleRemoveCustomProgramObjectiveFromAssessments={
                  this.props.handleRemoveCustomProgramObjectiveFromAssessments
                }
                handleCustomProgramsDragEnd={this.props.handleCustomProgramsDragEnd}
              />
            )}
          </div>
        )}

        {this.state.openModalConfirmationDeleteProgram && (
          <ConfirmDeleteMessage
            data={this.state.openModalConfirmationDeleteProgram}
            handleCancel={this.handleCloseModalConfirmationDeleteProgram}
            handleConfirm={this.handleRemoveProgramsFromAssessments}
          />
        )}
      </div>
    )
  }
}

export default withTranslation()(Programs)
